import { useAuth } from "../../context/AuthContext";
import {Redirect,Route} from 'react-router-dom';
import LoadingPage from "../../pages/loading-page";
import { useSelector } from "react-redux";

export default function PrivateLayout({children,path,exact}){
    const {authorized,loading} = useAuth();
    const {type:typeSession} = useSelector(state=>state.auth.authentication)

    if(loading){
        return <Route path={path} exact><LoadingPage type="user"/></Route>
    }
    else if(authorized){
        return <Route path={path} exact>{children}</Route>
    }
    else if(typeSession && typeSession === 'prem'){
        return <Redirect to={'/nouveau-mot-de-passe'}/>
    }
    else if(typeSession && typeSession === 'mdp'){
        return <Redirect to={'/nouveau-mot-de-passe'}/>
    }
    else return <Redirect to={'/connexion'}/>
}