import React from 'react';

export const TutoChrome1 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.49 38">
      <g id="bulle">
        <path
          fillRule="evenodd"
          fill={props.bg}
          d="M20.74,1A19,19,0,0,0,4.19,29.31,7.07,7.07,0,0,1,.81,35.2a1.17,1.17,0,0,0-.54,1.16,1.15,1.15,0,0,0,.85,1A12.56,12.56,0,0,0,10.46,36l.06,0A19,19,0,1,0,20.74,1Z"
          transform="translate(-0.26 -1)"
        />
      </g>
      <g id="chrome-1">
        <circle id="Oval" fill={props.icon ?? '#fff'} cx="19.98" cy="13.6" r="2.06" />
        <circle id="Oval-Copy" fill={props.icon ?? '#fff'} cy="20.09" r="2.06" />
        <circle id="Oval-Copy-2" fill={props.icon ?? '#fff'} cy="25.99" r="2.06" />
      </g>
    </svg>
  );
};
