import { errorMessage } from "../error_message";


export const onLoginFB = () =>{
 
    return new Promise((resolve)=>{
      window.FB.login(
        (response)=>{
         
          resolve(response)
        },{
          // Scopes that allow us to publish content to Instagram
          scope: "pages_manage_posts,pages_show_list,pages_read_engagement",
        }
      )
    })
  }


  export const getFacebookPages = (facebookUserAccessToken) => {
    return new Promise((resolve) => {
      window.FB.api(
        "/me/accounts",
        { access_token: facebookUserAccessToken,
          fields:'picture,id,name,access_token'
        },
        (response) => {
            if(!response.data.hasOwnProperty('error')){
                if(response.data.length === 0){
                    resolve({error:errorMessage.facebook.noaccount})
                }
               else if(response.data.length>0){
                    resolve(response.data.map(({picture,name,id,access_token})=>{
                        return {
                                name:name,
                                id:id,
                                picture_url:picture.data.url,
                                access_token:access_token
                            }
                        }))
               }
               else resolve({error:errorMessage.facebook.noaccount})
              }
              resolve({error:errorMessage.global.error_request});
        }
      );
    });
  };


  export const createMediaObjectContainerFacebook = (facebookUserAccessToken,account,image) => {

    return new Promise((resolve) => {
      window.FB.api(
        `/${account.id}/photos`,
        "POST",
        {
          access_token: account.access_token,
          url: image.img,
          published: false,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };


  export const createFeedWithMedia = (token,id_page,list_media,text) =>{
    return new Promise((resolve) => {
      window.FB.api(
        `/${id_page}/feed`,
        "POST",
        {
          message:text,
          access_token: token,
          attached_media: list_media.map(i=>{return { media_fbid: i }}),
        },
        (response) => {
          resolve(response);
        }
      );
    });
  }




  
  export const createFeedWithoutMedia = (token,id_page,text) =>{
    return new Promise((resolve) => {
      window.FB.api(
        `/${id_page}/feed`,
        "POST",
        {
          message:text,
          access_token: token,
        },
        (response) => {
          resolve(response);
        }
      );
    });
  }