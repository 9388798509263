import React, { Component } from 'react';
import styles from './BreadCrump.module.scss';
// LIBRAIRIES
import { Link } from 'react-router-dom';

export default class Breadcrump extends Component {
  // renderBreadcrumb = (items) => {
  //   return (
  //     <p>
  //       <button
  //         onClick={() => {
  //           this.setState({ submenu_active: false });
  //         }}
  //       >
  //         {item.nom}
  //       </button>
  //       {" > "}
  //       <span>{menu.nom}</span>
  //     </p>
  //   );
  // };

  renderItem = (item, last_element, mega_menu, pre_last_item = false) => {
    const { homeHook, onepost } = this.props;
    if (last_element) {
      return <span key={item.id}>{item.nom}</span>;
    } else {
     if (homeHook && item.id === 0) {
        return (
          <button key={item.id} onClick={() => homeHook()}>
            {item.nom}
          </button>
        );
      } else if (mega_menu && !this.props.shop && (!onepost || (onepost && !pre_last_item))) {
        return <Link to={`/menu/${item.id}`}>{item.nom}</Link>;
      } else if (onepost && !mega_menu && item.id !== 0) {
        return (
          <Link
            key={item.id}
            to={{
              pathname: `/actualites/${this.props.items[0].id}`,
              state: { tag: item.id },
            }}
          >
            {item.nom}
          </Link>
        );
      } else {
        const url = item.id === 0 ? '/' : this.getItemUrl(item);
        return (
          <Link to={url} key={item.id}>
            {item.nom}
          </Link>
        );
      }
    }
  };

  getItemUrl = (item) => {
    console.log(item)
    const { shop } = this.props;
    if(item.id === 0){
      return '/'
    }
    else if (shop) {
      if (item.slug !== 'boutique') {
        return `/boutique/${item.slug}`;
      } else {
        return '/boutique';
      }
    }
    else if(item.slug === 'partage'){
      return '/partage'
    }
    else if (item.screen) {
      switch (item.screen) {
        case 'pic':
          return `/photos/${item.id}`;

        case 'std':
        default:
          return `/actualites/${item.id}`;
      }
    }else return `/actualites/${item.id}`;
  };

  render() {
    const { items, mega_menu } = this.props;
    return (
      <ul className={styles.breadcrump}>
        <li>
          {this.renderItem({ nom: 'Accueil', id: 0 }, false)}
          {' > '}
        </li>
        {items.map((item, index) => {
          return (
            <li key={index}>
              {this.renderItem(
                item,
                item === items[items.length - 1],
                items.length >= 4 || mega_menu,
                item === items[items.length - 2]
              )}
              {item !== items[items.length - 1] && ' > '}
            </li>
          );
        })}
      </ul>
    );
  }
}
