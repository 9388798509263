import React from 'react';

export const Heart = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width??"16"} height={props.height??16} viewBox="0 0 17 15">
      <path
        fill={props.fill??""}
        fillRule="evenodd"
        d="M15.3,2.4c-0.8-0.9-1.9-1.3-3.1-1.3c-1.7,0-2.8,1-3.4,1.8c0,0.1-0.2,0.3-0.3,0.5C8.4,3.2,8.2,3,8.1,2.8
        C7.5,2,6.4,1,4.7,1c-1.1,0-2.2,0.5-3,1.4c-0.8,0.8-1.2,1.9-1.2,3c0,1.3,0.5,2.5,1.6,3.7c1,1.1,2.4,2.3,4.1,3.6
        c0.6,0.5,1.3,1,1.9,1.6l0,0c0.2,0.1,0.4,0.1,0.6,0l0,0c0.7-0.6,1.3-1.1,1.9-1.6c1.7-1.4,3.1-2.5,4.1-3.6c1.3-1.2,1.8-2.4,1.8-3.7
        C16.5,4.3,16.1,3.2,15.3,2.4"
      />
    </svg>
  );
};
