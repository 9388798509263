import React from 'react';

export const Download = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
      <g fill="" fillRule="evenodd">
        <path d="M18.522 9.63c-.288 0-.518.214-.518.48v4.35c0 1.183-1.046 2.143-2.325 2.143H4.297c-1.283 0-2.324-.963-2.324-2.143v-4.422c0-.265-.231-.478-.52-.478-.287 0-.518.213-.518.478v4.422c0 1.711 1.51 3.1 3.362 3.1H15.68c1.856 0 3.362-1.392 3.362-3.1v-4.35c0-.263-.23-.48-.519-.48" />
        <path d="M9.908 14.392c.095.109.224.168.35.168a.457.457 0 0 0 .349-.168l3.156-3.602a.623.623 0 0 0 0-.801.453.453 0 0 0-.703 0l-2.306 2.636V1.127c0-.315-.221-.567-.497-.567s-.496.252-.496.567v11.498L7.45 9.989a.453.453 0 0 0-.703 0 .622.622 0 0 0 0 .801l3.16 3.602z" />
      </g>
    </svg>
  );
};
