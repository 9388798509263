
import * as React from "react"

const AfdfAnnuaireAfdf = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.7 16.7"
    style={{
      enableBackground: "new 0 0 16.7 16.7",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M14.5 0H2.2C1 0 0 1 0 2.2v8.5c0 1.2 1 2.2 2.2 2.2h1.3c-.1.7-.2 1.4-.4 2-.2.6 0 1.2.5 1.5.5.4 1.1.4 1.6 0 1.5-1 2.8-2.2 3.9-3.6h5.4c1.2 0 2.2-1 2.2-2.2V2.2c0-1.2-1-2.2-2.2-2.2zm.8 10.7c0 .4-.3.8-.8.8H8.8c-.2 0-.4.1-.5.3-1.1 1.3-2.3 2.5-3.7 3.5.3-1 .4-2 .5-3 0-.2-.1-.4-.2-.5-.2-.2-.4-.3-.6-.3H2.2c-.4 0-.8-.3-.8-.8V2.2c0-.4.3-.8.8-.8h12.3c.4 0 .8.3.8.8v8.5z" />
    <path d="M11.5 3.2c-1.1-.1-2 .7-2.1 1.8v.2c0 .2.2.4.4.4h.6c.2 0 .4-.2.4-.4v-.1c0-.1 0-.6.8-.6.4 0 .7.1.7.6 0 .3-.5.7-.7.9-.5.3-.8.8-.8 1.4v.1c0 .2.2.4.4.4h.6c.2 0 .4-.2.4-.4v-.1c0-.1 0-.2.3-.4.6-.4 1.1-1.1 1.2-1.9 0-1-.8-1.9-1.8-1.9h-.4zM11.7 8.4H11c-.2 0-.4.2-.4.4v.6c0 .2.2.4.4.4h.7c.2 0 .4-.2.4-.4v-.6c0-.2-.2-.4-.4-.4zM8.1 3.2H3.6c-.2 0-.4.1-.4.3v.6c0 .2.2.4.4.4h4.5c.2 0 .4-.2.4-.4v-.6c-.1-.2-.2-.3-.4-.3zM8.1 5.8H3.6c-.2 0-.4.2-.4.4v.6c0 .2.2.4.4.4h4.5c.2 0 .4-.2.4-.4v-.6c-.1-.2-.2-.4-.4-.4zM8.1 8.4H3.6c-.2 0-.4.2-.4.4v.6c0 .2.2.4.4.4h4.5c.2 0 .4-.2.4-.4v-.6c-.1-.2-.2-.4-.4-.4z" />
  </svg>
)

export default AfdfAnnuaireAfdf
