import React from 'react';

export const Argumentaires = () => {
  return (
<svg version="1.1" id="Calque_1"  x="0px" y="0px"
	 viewBox="0 0 14.4 14.4">
<path id="Icon_material-format-align-left" d="M9.6,9.6H0v1.6h9.6V9.6z M9.6,3.2H0v1.6h9.6V3.2z M0,8h14.4V6.4H0V8z M0,14.4h14.4
	v-1.6H0V14.4z M0,0v1.6h14.4V0H0z"/>
</svg>

  );
};
