import React from 'react';

export const NavbarCommunication = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 205.134 205.134"
      style={{ enableBackground: 'new 0 0 205.134 205.134' }}
    >
      <path
        d="M63.189,185.534c-1.389,0-2.776-0.276-4.095-0.829c-2.615-1.095-4.646-3.143-5.719-5.767l-18.952-46.327h-9.799
	c-6.268,0-11.367-4.771-11.367-10.635v-1.637C5.163,115.946,0,107.363,0,98.126s5.163-17.819,13.257-22.214v-0.814
	c0-5.864,5.099-10.635,11.367-10.635h49.413c30.185,0,59.819-9.528,83.446-26.828l16.26-11.906c0.009-0.007,0.018-0.014,0.027-0.021
	l6.583-4.819c2.041-1.495,4.725-1.705,7.006-0.55c2.126,1.078,3.395,3.088,3.395,5.376v38.877
	c8.896,6.627,14.381,19.353,14.381,33.534s-5.484,26.907-14.381,33.534v38.877c0,2.288-1.269,4.298-3.395,5.376
	c-2.281,1.155-4.964,0.945-7.006-0.55l-21.747-15.924c-22.336-16.355-50.042-25.764-78.505-26.743l3.371,7.702
	c0.218,0.497,0.224,1.061,0.018,1.562c-0.207,0.502-0.607,0.898-1.111,1.099l-6.779,2.695l8.253,20.829
	c2.127,5.369-0.488,11.521-5.831,13.716l-10.804,4.436C65.917,185.268,64.553,185.534,63.189,185.534z M38.745,132.612
	l18.333,44.813c0.668,1.634,1.934,2.909,3.562,3.591c1.628,0.681,3.425,0.688,5.059,0.018l10.804-4.436
	c3.328-1.365,4.957-5.197,3.632-8.542l-8.979-22.663c-0.003-0.007-0.006-0.013-0.008-0.02c-0.003-0.009-0.007-0.017-0.01-0.025
	l-5.046-12.736H38.745z M176.937,167.905l5.779,4.232c0.979,0.718,2.107,0.578,2.833,0.209c0.362-0.184,1.205-0.728,1.205-1.809
	V25.715c0-1.081-0.842-1.625-1.205-1.809c-0.727-0.369-1.854-0.508-2.833,0.209l-5.779,4.232V167.905z M98.292,130.43
	c22.78,3.597,44.461,12.444,62.677,25.783l11.967,8.763V31.277l-13.091,9.585c-17.916,13.119-39.185,21.895-61.553,25.602V130.43z
	 M70.392,132.612l3.734,9.424l4.851-1.929l-3.28-7.494c-0.179-0.001-0.359-0.001-0.538-0.001H70.392z M24.624,68.463
	c-4.062,0-7.367,2.977-7.367,6.635v46.879c0,3.658,3.305,6.635,7.367,6.635H75.16c6.418,0,12.812,0.419,19.133,1.242V67.071
	c-6.687,0.922-13.457,1.393-20.256,1.393H24.624z M190.754,69.857v56.539c6.4-6.28,10.38-16.89,10.38-28.27
	S197.155,76.137,190.754,69.857z M13.257,80.598C7.547,84.532,4,91.106,4,98.126s3.547,13.595,9.257,17.528V80.598z M23.614,110.884
	c-1.104,0-2-0.896-2-2V78.103c0-3.062,2.491-5.552,5.552-5.552h50.906c1.104,0,2,0.896,2,2s-0.896,2-2,2H27.166
	c-0.855,0-1.552,0.696-1.552,1.552v30.781C25.614,109.989,24.719,110.884,23.614,110.884z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
