import { createSlice } from '@reduxjs/toolkit'
import { deleteSocialNetwork, getFirstRowSocialNetwork, getMoreSocialNetwork, getSocialNetWork } from './socialNetworkThunk'

type checkOutStateType = 'LOADING' | 'ERROR' | 'READY'

type SocialNetworkType = {
  posts: {
    [key: string]: {
      checkoutPublicationState?:checkOutStateType,
      CheckoutMoreState?: checkOutStateType,
      last?:boolean
      data?: Array<any>,
      page?:number
    }
  }
  list_category: Array<any>
  breadcrumb: Array<any>
  error: string
  checkOutState: checkOutStateType
}

const initialState: SocialNetworkType = {
  checkOutState: 'LOADING',
  error: '',
  posts: {},
  list_category: [],
  breadcrumb: [],
}

export const socialNetwork = createSlice({
  name: 'social-network',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {


    //GET FIRST LOAD PUBLICATION
    builder.addCase(getFirstRowSocialNetwork.pending, (state, { payload, meta }) => {
      state.checkOutState = 'LOADING'
    })

    builder.addCase(getFirstRowSocialNetwork.rejected, (state, { payload, meta }) => {
      state.checkOutState = 'ERROR'
    })

    builder.addCase(getFirstRowSocialNetwork.fulfilled, (state, { payload, meta }) => {
        console.log(payload[0])
      state.checkOutState = 'READY'
      state.posts[meta.arg.thematic] = {
        checkoutPublicationState:'READY',
        CheckoutMoreState:'READY',
        last : (payload[0] as any).last ?? false,
        data: ((payload[0] as any).posts &&  (payload[0] as any).posts.length > 0) ? (payload[0] as any).posts : [],
        page:2
      }
      state.breadcrumb = (payload[0] as any).breadcrumb
      state.list_category =
        (payload[1] as any).hasOwnProperty('thematiques') &&
        Object.keys((payload[1] as any).thematiques).length > 0
          ? Object.keys((payload[1] as any).thematiques).map((i) => ({
              id: i,
              name: (payload[1] as any).thematiques[i],
            }))
          : []
    })

    //GET PAGE BY THEMATIC ACTIONS
    builder.addCase(getSocialNetWork.pending, (state, { payload, meta }) => {
        state.posts[meta.arg.thematic] ={
            checkoutPublicationState : 'LOADING',
        }
      })
  
      builder.addCase(getSocialNetWork.rejected, (state, { payload, meta }) => {
        state.posts[meta.arg.thematic] ={
            checkoutPublicationState :'ERROR',
        }
      })
  
      builder.addCase(getSocialNetWork.fulfilled, (state, { payload, meta }) => {
       state.posts[meta.arg.thematic] = {
        data:(payload as any).posts,
        CheckoutMoreState: 'READY',
        checkoutPublicationState:'READY',
        last :(payload as any).last ?? false,
        page:2
       }
      })
    
    //GET MORE PAGE PUBLICATIONS
    builder.addCase(getMoreSocialNetwork.pending, (state, { payload, meta }) => {
        state.posts[meta.arg.thematic].CheckoutMoreState = 'LOADING'
      })
  
      builder.addCase(getMoreSocialNetwork.rejected, (state, { payload, meta }) => {
        state.posts[meta.arg.thematic].CheckoutMoreState = 'ERROR'
      })
  
      builder.addCase(getMoreSocialNetwork.fulfilled, (state, { payload, meta }) => {
       state.posts[meta.arg.thematic].CheckoutMoreState = 'READY'
       state.posts[meta.arg.thematic].data?.push(...(payload as any).posts)
       state.posts[meta.arg.thematic].last = (payload as any).last ?? false
       state.posts[meta.arg.thematic].page = meta.arg.page + 1
      })

      //DELETE PUBLICATIONS
      builder.addCase(deleteSocialNetwork.fulfilled,(state,{payload,meta})=>{
        state.posts[meta.arg.thematic].data = state.posts[meta.arg.thematic].data?.filter(i=>i.id!==meta.arg.id_publication);
      })
  },
})

