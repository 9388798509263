import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlockAuth from '../../components/block-page/block-auth';

import { stats } from '../../config/stats';
import EasyButton from '../../easy-ui/button';
import EasyInput from '../../easy-ui/input';
import Icon from '../../icons';
import styles from './NewPassword.module.scss';
import {useHistory} from 'react-router-dom';
import api from '../../config/pci/api';



function verifyPassword(first,second){
  if(String(first)!== '' && first === second) return true
  return false
}

export default function NewPassword(){

  const [password,setPassword] = useState<string>('');
  const [confirmPassword,setConfirmPassword] = useState<string>('');
  //@ts-ignore
  const {id:user_id,nonce} = useSelector(state=>state.auth.authentication);
  //@ts-ignore
  const {type} = useSelector(state=>state.auth);
  const [error,setError] = useState<string | false>(false);
  const dispatch = useDispatch();
  const history = useHistory()


  async function onNewPassord(){
    setError(false)
    if(!verifyPassword(password,confirmPassword)){
    return setError('Les mots de passe saisis ne correspondent pas.')
    }
    try{
      const response  = await api.get<AxiosResponse<any, any>>('/newpass', {
        params: {
          user: user_id,
          noken: nonce,
          pass: password,
          stat: stats(),
        },
      })
    //@ts-ignore next-line
    if(response.data.hasOwnProperty('error') || response.data?.length == 0) 
      return setError("Une erreur s'est produite lors de la tentative d'enregistrement de votre nouveau mot de passe")
     
    
    dispatch({ type: 'SET_NEW_PASSWORD', payload: response.data, password: password })
      if(type === 'prem')
        return history.replace('/validation-profil')
      else if(type === 'mdp')
      return  history.replace('/')
    
  }catch(error){
    setError("Une erreur s'est produite lors de la tentative d'enregistrement de votre nouveau mot de passe")
  }
  }

    return(
      <BlockAuth title={'Nouveau mot de passe'}  subtitle={'Veuillez renseigner votre nouveau mot de passe.'}>
        <div className={styles["form"]}>
          <EasyInput id='password' name="password" value={password} placeholder={"Entrer un nouveau mot de passe"} label={'Nouveau mot de passe'} categorie={"password"} type="password" onchange={(e)=>setPassword(e.target.value)} />
          <EasyInput id='confirm-password' name="confirm-password" value={confirmPassword} placeholder={"Confirmer votre mot de passe"} label={'Confirmation du mot de passe'} categorie={"password"} type="password" onchange={(e)=>setConfirmPassword(e.target.value)} />
          {/*@ts-ignore */}
          <EasyButton onClick={onNewPassord} type="primary" name="text" text={"Valider"}/>
          <div className={styles["error-auth"]}>{error}</div>
        </div>
      </BlockAuth>
    )
}