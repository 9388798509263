import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/pci/api'
import { stats } from '../../config/stats'
import { postLike as postLikeFromLike } from '../post-picture/postPictureSlice'
import { postLike as postLikeFromStandard } from '../post-standard/postStandardSlice'
import { postLike as postLikePublication } from '../publications/publicationSlices'
import { postLikeFeat as postLikeFeatFromPublication } from '../publications/publicationSlices'
import {postComment as postCommentFromPublicationStandard} from '../post-standard/postStandardSlice';
import {postComment as postCommentFromPublicationLike} from '../post-picture/postPictureSlice';

type checkOutStateType = 'LOADING' | 'ERROR' | 'READY'

type parentPostType = {
  list_category: Array<{ id: string; name: string }>
  posts: { [key: string]: postType }
}

type postType = {
  last: boolean
  data: Array<any>
  feat: any
  checkoutStateMore: 'READY'
}

const initHome = {
  parentPost: {
    carousel_partners: null,
    documents: null,
    checkOutState: 'LOADING',
  },
  error: '',
  checkOutState: 'LOADING',
}

export const getFirstFetchDataHome = createAsyncThunk(
  'home/firstfetch',
  async ({ parentCat, childCat }: { parentCat: number; childCat: number }, { rejectWithValue, getState }) => {
    const {
      session: { lastconnect },
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any

    try {
      const [responseData, responseCategorie] = await Promise.all([
        api.get('/filposts', {
          params: {
            user: id,
            nonce: nonce,
            page: 1,
            cat: childCat,
            lastconnect: lastconnect,
            stat: stats(),
          },
        }),
        api.get('/listpoststags', {
          params: {
            user: id,
            nonce: nonce,
            cat: parentCat,
          },
        }),
      ])

      const firtRowResponse = [responseData, responseCategorie].map((response) => {
        //@ts-ignore
        if (response.data.hasOwnProperty('error') && (response.data.posts.length === 0 || !response.data.posts))
          //@ts-ignore
          return rejectWithValue(response.data.error)
        //@ts-ignore
        return response.data
      })

      return firtRowResponse
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const getDataHome = createAsyncThunk(
  '/home/fetch/childcat',
  async ({ parentCat, childCat }: { parentCat: any; childCat: any }, { rejectWithValue, getState }) => {
    const {
      session: { lastconnect },
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any
    try {
      const response = await api.get('/filposts', {
        params: {
          user: id,
          nonce: nonce,
          page: 1,
          cat: childCat,
          lastconnect: lastconnect,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error') && (response.data.posts.length === 0 || !response.data.posts))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const getDataMoreHome = createAsyncThunk(
  '/home/fetchmore/childcat',
  async (
    { parentCat, childCat, page }: { parentCat: any; childCat: any; page: number },
    { rejectWithValue, getState }
  ) => {
    const {
      session: { lastconnect },
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any
    try {
      const response = await api.get('/filposts', {
        params: {
          user: id,
          nonce: nonce,
          page: page,
          cat: childCat,
          lastconnect: lastconnect,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const postLike = createAsyncThunk(
  '/home/publication/like',
  async (
    { id_post, parentCat, childCat }: { id_post: any; parentCat: any; childCat: any },
    { rejectWithValue, getState }
  ) => {
    console.log(id_post, parentCat, childCat)
    const {
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any

    try {
      const response = await api.get('/newlike', {
        params: {
          user: id,
          nonce: nonce,
          post: id_post,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const postLikeFeat = createAsyncThunk(
  '/home/publication/likeFeat',
  async (
    { id_post, parentCat, childCat }: { id_post: any; parentCat: any; childCat: any },
    { rejectWithValue, getState }
  ) => {
    console.log(id_post, parentCat, childCat)
    const {
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any

    try {
      const response = await api.get('/newlike', {
        params: {
          user: id,
          nonce: nonce,
          post: id_post,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const homeSlice = createSlice({
  name: 'home',
  initialState: initHome,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFirstFetchDataHome.pending, (state, { payload, meta }) => {
      state.parentPost.checkOutState = 'LOADING'
    })
    builder.addCase(getFirstFetchDataHome.rejected, (state, { payload, meta }) => {
      state.parentPost.checkOutState = 'ERROR'
    })
    builder.addCase(getFirstFetchDataHome.fulfilled, (state, { payload, meta }: { payload: any; meta: any }) => {
      state.checkOutState = 'READY'
      state.parentPost = {
        ...state.parentPost,
        checkOutState: 'READY',
        carousel_partners: payload[0]?.carousel_partners,
        documents: payload[0].documents,
        [meta.arg.parentCat]: {
          list_category: payload[1],
          posts: {
            [meta.arg.childCat]: {
              data: payload[0].posts.filter((_: any) => _.feat !== true),
              last:
                payload[0].posts.filter((_: any) => _.feat !== true).length == 0 ? true : payload[0].last ?? false,
              feat: payload[0].posts.find((_: any) => _.feat === true),
              checkoutStateMore: 'READY',
              page: 2,
            },
          },
        },
      }
    })

    builder.addCase(getDataHome.pending, (state, { payload, meta }: { payload: any; meta: any }) => {
      state.parentPost.checkOutState = 'LOADING'
    })

    builder.addCase(getDataHome.rejected, (state, { payload, meta }: { payload: any; meta: any }) => {
      state.parentPost.checkOutState = 'ERROR'
    })

    builder.addCase(getDataHome.fulfilled, (state, { payload, meta }: { payload: any; meta: any }) => {
      state.parentPost.checkOutState = 'READY'
      state.parentPost[meta.arg.parentCat].posts[meta.arg.childCat] = {
        data: payload.posts.filter((_: any) => _.feat !== true),
        last: payload.last ?? false,
        feat: payload.posts.find((_: any) => _.feat === true),
        checkoutStateMore: 'READY',
        page: 2,
      }
    })

    builder.addCase(getDataMoreHome.pending, (state, { payload, meta }: { payload: any; meta: any }) => {
      const post = state.parentPost[meta.arg.parentCat].posts[meta.arg.childCat]
      post.checkoutStateMore = 'LOADING'
    })

    builder.addCase(getDataMoreHome.rejected, (state, { payload, meta }: { payload: any; meta: any }) => {
      const post = state.parentPost[meta.arg.parentCat].posts[meta.arg.childCat]
      post.checkoutStateMore = 'ERROR'
    })

    builder.addCase(getDataMoreHome.fulfilled, (state, { payload, meta }: { payload: any; meta: any }) => {
      const post = state.parentPost[meta.arg.parentCat].posts[meta.arg.childCat]
      console.log(post)
      post.checkoutStateMore = 'READY'
      post.page = meta.arg.page + 1
      post.data.push(...payload.posts)
      post.last = payload.last ?? false
    })

    builder.addCase(postLike.fulfilled, (state, { payload, meta }: { payload: any; meta: any }) => {
      const post = state.parentPost[meta.arg.parentCat].posts[meta.arg.childCat]
      const index = post.data.findIndex((i) => i.id == meta.arg.id_post)
      post.data[index].likes = payload
    })
    builder.addCase(postLikeFeat.fulfilled, (state, { payload, meta }: { payload: any; meta: any }) => {
      const post = state.parentPost[meta.arg.parentCat].posts[meta.arg.childCat]
      post.feat.likes = payload
    })

    builder.addCase(postLikeFromLike.fulfilled, (state, { payload, meta }) => {
      const parentCat = meta.arg.lastContent.parentCat
      const childCat = meta.arg.lastContent.childCat
      if (meta.arg.lastContent.type == 'feat') {
        if (state.parentPost[parentCat]  &&  state.parentPost[parentCat].posts[parentCat] && state.parentPost[parentCat].posts[parentCat].feat) {
          state.parentPost[parentCat].posts[parentCat].feat.likes = payload
        }
        if (state.parentPost[parentCat] &&  state.parentPost[parentCat].posts[childCat] && state.parentPost[parentCat].posts[childCat].feat) {
            state.parentPost[parentCat].posts[childCat].feat.likes = payload
        }
      } else {
        if (state.parentPost[parentCat] && state.parentPost[parentCat].posts[parentCat]) {
          const index = state.parentPost[parentCat].posts[parentCat].data.findIndex(
            (i) => i.id == meta.arg.id_post
          )
          if (index >= 0) state.parentPost[parentCat].posts[parentCat].data[index].likes = payload
        }
        if (state.parentPost[parentCat] && state.parentPost[parentCat].posts[childCat]) {
          const indexChild = state.parentPost[parentCat].posts[childCat].data.findIndex(
            (i) => i.id == meta.arg.id_post
          )
          if (indexChild >= 0) state.parentPost[parentCat].posts[childCat].data[indexChild].likes = payload
        }
      }
    })
    builder.addCase(postLikeFromStandard.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.lastContent !== undefined) {
        const parentCat = meta.arg.lastContent.parentCat
        const childCat = meta.arg.lastContent.childCat

        if (meta.arg.lastContent.type == 'feat') {
          if (state.parentPost[parentCat]  &&  state.parentPost[parentCat].posts[parentCat] && state.parentPost[parentCat].posts[parentCat].feat) {
            state.parentPost[parentCat].posts[parentCat].feat.likes = payload
          }
          if (state.parentPost[parentCat] &&  state.parentPost[parentCat].posts[childCat] && state.parentPost[parentCat].posts[childCat].feat) {
              state.parentPost[parentCat].posts[childCat].feat.likes = payload
          }
        } else {

        if (state.parentPost[parentCat] && state.parentPost[parentCat].posts[parentCat]) {
          const index = state.parentPost[parentCat].posts[parentCat].data.findIndex(
            (i) => i.id == meta.arg.id_post
          )
          if (index >= 0) state.parentPost[parentCat].posts[parentCat].data[index].likes = payload
        }
        if (state.parentPost[parentCat] && state.parentPost[parentCat].posts[childCat]) {
          const indexChild = state.parentPost[parentCat].posts[childCat].data.findIndex(
            (i) => i.id == meta.arg.id_post
          )
          if (indexChild >= 0) state.parentPost[parentCat].posts[childCat].data[indexChild].likes = payload
        }
      }
      }
    })

    builder.addCase(postLikePublication.fulfilled, (state, { payload, meta }) => {
      const parentCat = meta.arg.parentCat
      if (state.parentPost[parentCat] && state.parentPost[parentCat].posts[parentCat]) {
        const index = state.parentPost[parentCat].posts[parentCat].data.findIndex((i) => i.id == meta.arg.id_post)
        if (index >= 0) state.parentPost[parentCat].posts[parentCat].data[index].likes = payload
      }
    })

    builder.addCase(postLikeFeatFromPublication.fulfilled, (state, { payload, meta }) => {
      const parentCat = meta.arg.parentCat
      if (
        parentCat &&
        state.parentPost[parentCat] &&
        state.parentPost[parentCat].posts[parentCat] &&
        state.parentPost[parentCat].posts[parentCat].feat
      ) {
        state.parentPost[parentCat].posts[parentCat].feat.likes = payload
      }
    })

    builder.addCase(postCommentFromPublicationStandard.fulfilled,(state,{payload,meta})=>{
        if(meta.arg.lastContent !== undefined &&  (payload as any).comments[(payload as any).comments.length -1].status === "approved"){
          const parentCat = meta.arg.lastContent.parentCat
          const childCat = meta.arg.lastContent.childCat
          if(meta.arg.lastContent.type == 'feat'){
            if(  parentCat &&
              state.parentPost[parentCat] &&
              state.parentPost[parentCat].posts[childCat] &&
              state.parentPost[parentCat].posts[childCat].feat
              && childCat !=parentCat
              ){
                state.parentPost[parentCat].posts[childCat].feat.comments.approved = Number(state.parentPost[parentCat].posts[childCat].feat.comments.approved) + 1
              }
              if(  parentCat &&
                state.parentPost[parentCat] &&
                state.parentPost[parentCat].posts[parentCat] &&
                state.parentPost[parentCat].posts[parentCat].feat
                ){
                  state.parentPost[parentCat].posts[childCat].feat.comments.approved = Number(state.parentPost[parentCat].posts[parentCat].feat.comments.approved) + 1
                }
            }else{
                
                if(state.parentPost[parentCat] && state.parentPost[parentCat].posts[childCat] && parentCat !==childCat){
                  const index = state.parentPost[parentCat].posts[childCat].data.findIndex(
                    (i) => i.id == meta.arg.id_post
                  )
                  if (index >= 0) 
                    state.parentPost[parentCat].posts[childCat].data[index].comments.approved = Number(state.parentPost[parentCat].posts[childCat].data[index].comments.approved) + 1
                } 
                if(state.parentPost[parentCat] && state.parentPost[parentCat].posts[parentCat]){
                  const indexParent = state.parentPost[parentCat].posts[parentCat].data.findIndex(
                    (i) => i.id == meta.arg.id_post
                  )
                  if (indexParent >= 0) 
                    state.parentPost[parentCat].posts[parentCat].data[indexParent].comments.approved = Number(state.parentPost[parentCat].posts[parentCat].data[indexParent].comments.approved) + 1
                } 
              }
          }
    
    })
    builder.addCase(postCommentFromPublicationLike.fulfilled,(state,{payload,meta})=>{
      if(meta.arg.lastContent !== undefined &&  (payload as any).comments[(payload as any).comments.length -1].status === "approved"){
        const parentCat = meta.arg.lastContent.parentCat
        const childCat = meta.arg.lastContent.childCat
        if(meta.arg.lastContent.type == 'feat'){
          if(  parentCat &&
            state.parentPost[parentCat] &&
            state.parentPost[parentCat].posts[childCat] &&
            state.parentPost[parentCat].posts[childCat].feat
            && childCat !=parentCat
            ){
              state.parentPost[parentCat].posts[childCat].feat.comments.approved = Number(state.parentPost[parentCat].posts[childCat].feat.comments.approved) + 1
            }
            if(  parentCat &&
              state.parentPost[parentCat] &&
              state.parentPost[parentCat].posts[parentCat] &&
              state.parentPost[parentCat].posts[parentCat].feat
              ){
                state.parentPost[parentCat].posts[childCat].feat.comments.approved = Number(state.parentPost[parentCat].posts[parentCat].feat.comments.approved) + 1
              }
          }else{
              
              if(state.parentPost[parentCat] && state.parentPost[parentCat].posts[childCat] && parentCat !==childCat){
                const index = state.parentPost[parentCat].posts[childCat].data.findIndex(
                  (i) => i.id == meta.arg.id_post
                )
                if (index >= 0) 
                  state.parentPost[parentCat].posts[childCat].data[index].comments.approved = Number(state.parentPost[parentCat].posts[childCat].data[index].comments.approved) + 1
              } 
              if(state.parentPost[parentCat] && state.parentPost[parentCat].posts[parentCat]){
                const indexParent = state.parentPost[parentCat].posts[parentCat].data.findIndex(
                  (i) => i.id == meta.arg.id_post
                )
                if (indexParent >= 0) 
                  state.parentPost[parentCat].posts[parentCat].data[indexParent].comments.approved = Number(state.parentPost[parentCat].posts[parentCat].data[indexParent].comments.approved) + 1
              } 
            }
        }
  
  })
  },
})
