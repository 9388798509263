import { useEffect, useState } from 'react';
import LayoutSocial from '../layout-social';
import styles from './LinkedinPage.module.scss';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import useDidMountEffect from '../../../../hooks/useDidmountEffect';
import api from '../../../../config/pci/api';
import { useSelector } from 'react-redux';
import { stats } from '../../../../config/stats';
import { errorMessage } from '../../../../components/social-network/error_message';
import { AxiosResponse } from 'axios';


export default function LinkedinPage({post_id}:{post_id:string}):JSX.Element{

    const [token,setToken] = useState(null);
    //@ts-ignore next-line
    const post = useSelector(state=>state.social);
    const [code,setCode] = useState<string | null>(null)
    const [linkedinMessage,setLinkedingMessage] = useState<any>({type:'',message:'',open:false});
    //@ts-ignore next-line
    const {id:user_id,nonce:nonce} = useSelector(state=>state.auth.authentication);
    const [modalPublish,setShowModalPublish] = useState<boolean>(false);
    const text = post.texte ?  post.tags ? `${post.texte}\n ${post.tags.map(i=>{if(!i.startsWith('#'))return `#${i}`;return i}).join(' ')}` : post.texte : post.texte
    //state loading publication
    const [loadingPublication,setLoadingPublication] = useState<boolean>(false)

    const { linkedInLogin } = useLinkedIn({
        clientId: '78ogwze9yifnwx',
        redirectUri: `${window.location.origin}/linkedin-popup`,
        onSuccess: (code) => {
         setCode(code)
        },
        scope: 'r_emailaddress,r_liteprofile,w_member_social,rw_organization_admin,w_organization_social,r_organization_social',
        onError: (error) => {
            setLinkedingMessage({message:errorMessage.linkedin.authentication,type:'error',open:true})
        },
      });

      async function fetchPage(token:string){
       try{ 
        const response = await api.post<AxiosResponse<unknown, any>>('/linkedinconnect', {
            user: user_id,
            nonce: nonce,
            code: token,
            uri:`${window.location.origin}/linkedin-popup`,
            stat: stats(),
          })


          //@ts-ignore next-line
          if(response?.data?.access_token){
          //@ts-ignore next-line
            setToken(response?.data?.access_token)
            setShowModalPublish(true)
            }
          else{
            setLinkedingMessage({message:errorMessage.linkedin.authentication,type:'error',open:true})
          }
       }catch(error){
        setLinkedingMessage({message:errorMessage.linkedin.authentication,type:'error',open:true})
       }
      }


     async function onPublishContent(account,type){
        setLoadingPublication(true);
        try{
        const response = await api.post<AxiosResponse<unknown, any>>('/linkedinpost', {
            user: user_id,
            nonce: nonce,
            access_token:token,
            id: post_id,
            text:text,
            urn:account.id,
          })
          //@ts-ignore next-line
          if(response.data.id){
            setLoadingPublication(false)
            setShowModalPublish(false)
            return setLinkedingMessage({type:"success",message:errorMessage.linkedin.succes_publication,open:true})   
          }
          else{
            setLoadingPublication(false)
            setShowModalPublish(false)
            return setLinkedingMessage({type:'error',message:errorMessage.linkedin.error_publication,open:true})  
          }
       

        }catch(error){
            setLoadingPublication(false);
            setShowModalPublish(false)
            return setLinkedingMessage({type:'error',message:errorMessage.linkedin.error_publication,open:true})  
        }
      }


      useDidMountEffect(()=>{
        if(code)
            fetchPage(code as string);
      },[code])

      
    return(
        <LayoutSocial onLogin={linkedInLogin}
          showModalPublish={modalPublish} 
          plateform={"linkedin"} 
          onCloseModalPublish={()=>setShowModalPublish(false)} 
          auth_token={token} 
          onPublishContent={onPublishContent} 
          loadingPublication={loadingPublication}
          responseMessage={linkedinMessage}
          onCloseModalResponseMessage={() => setLinkedingMessage((prev:{type:string,open:boolean,message:string})=>{return {...prev,open:false}})}
        
        
        />
    )
}
