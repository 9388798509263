// Import de la config de base de l'api
import api from '../../../config/pgl/api'
import { stats } from '../../../config/stats'
import axios from 'axios'
// Import des types dynamiques d'action
import {
  PGL_PROFILE,
  PGL_LEADS,
  PGL_COMPANIES,
  PGL_SELLERS,
  PGL_SORT_LEADS,
  PGL_LEADS_LOADING,
  PGL_CHANGE_LEADS_CONTEXT,
  PGL_LEADS_COLUMNS,
  PGL_LEADS_COUNT,
  PGL_LEADS_FILTERS,
  PGL_LEADS_FILTERS_FIELD,
  PGL_LEADS_FILTERS_FIELD_RESET,
  PGL_LEADS_EXPORT,
  PGL_LEADS_EXPORT_RESET,
  PGL_LEADS_LEAD_GET,
  PGL_LEADS_LEAD_SET,
  PGL_LEADS_LEAD_NEW,
  PGL_LEADS_LEAD_PRINT,
  PGL_LEADS_LEAD_QUALIF,
  PGL_LEADS_LEAD_HISTORY,
  PGL_LEADS_LEAD_ALERT,
  PGL_CALLBACK,
  PGL_CALL,
  PGL_EMAIL,
  PGL_RESULT_MAIL,
  PGL_POPIN,
  PGL_POPIN_CLEAN,
  PGL_LEADS_LEAD_RESET,
  PGL_POPIN_RESET,
  PGL_LEADS_LEAD_QUALIF_RESET,
  PGL_CALLBACK_RESET,
  PGL_SET_CITY,
  PGL_SET_CP,
  PGL_SET_STATUS
  
} from './types'
// export const LOADING_STATE = { LOADING: 'En chargement', REQREQUEST: 'Demande reçue', ERROR: 'Erreur' }

// Récupère le profil de l'internaute (rôle)




export const pgl_getProfile = (user_id, noken) => async (dispatch) => {
  const response = await api.get('/profile', {
    params: {
      user: user_id,
      noken,
      stat: stats(),
    },
  })
  // console.log('Check-Point Nomade EL - response.data:' + JSON.stringify(response.data))
  dispatch({ type: PGL_PROFILE, payload: response.data })
}

// Change le sort des leads
export const pgl_sortLeads = (orderby, order, filters) => async (dispatch) => {
  dispatch({ type: PGL_SORT_LEADS, payload: { orderby, order, filters } })
}

// Change le context de l'app
/*export const pgl_changeLeadContext = (context) => async (dispatch) => {
console.log(context)
  dispatch({ type: PGL_CHANGE_LEADS_CONTEXT, payload: context })
}
*/
export function pgl_changeLeadContext(context){

  return function(dispatch){
    dispatch({ type: PGL_CHANGE_LEADS_CONTEXT, payload: context })

  }
}

// Récupère la liste des leads en fonction des paramètres de recherche
//context, page = 1, itemsperpage = 10, orderby, order, filters_received
export const pgl_getLeads =
  (user_id, noken,isStart) =>
  async (dispatch) => {

    dispatch({type:'PGL_GET_LEADS_IS_LOADING',payload:true});

    const initParams = {
      user: user_id,
      noken,
      stat: stats(),
    }

    const params = isStart ? {...initParams,page:1,itemsperpage:1}:initParams

    const response = await api.get('/leads', {
      params:params
    })

    dispatch({
      type: PGL_LEADS,
      payload: {data:response.data,isStart:isStart},
    })
  }

  

// Récupère la légende de chaque colonne du tableau
export const pgl_getLeadsColumns = (user_id, noken) => async (dispatch) => {
  const response = await api.get('/leads/columns', {
    params: {
      user: user_id,
      noken,
      stat: stats(),
    },
  })
  dispatch({ type: PGL_LEADS_COLUMNS, payload: response.data })
}

// Récupère le nombre de leads en fonction du contexte
export const pgl_getLeadsCount = (user_id, noken, context) => async (dispatch) => {
  const response = await api.get('/leads/count', {
    params: {
      user: user_id,
      noken,
      ...(context ? { context: context } : {}),
      stat: stats(),
    },
  })
  dispatch({ type: PGL_LEADS_COUNT, payload: response.data })
}

// Récupère les critères de recherche possibles sur le tableau de leads
export const pgl_getLeadsFilters = (user_id, noken) => async (dispatch) => {
  const response = await api.get('/leads/filters', {
    params: {
      user: user_id,
      noken,
      stat: stats(),
    },
  })

  dispatch({ type: PGL_LEADS_FILTERS, payload: response.data })
}

// Récupère les critères de recherche possibles sur le tableau de leads
export const pgl_getLeadsFiltersField =
  (user_id, noken, field, search = false) =>{

 return async (dispatch) => {
    const response = await api.get(`/leads/filters/${field}`, {
      params: {
        user: user_id,
        noken,
        field,
        search,
        stat: stats(),
      },
    })
    dispatch({ type: PGL_LEADS_FILTERS_FIELD, filterId: field, suggestions: response.data })

  }
}

// Récupère les critères de recherche possibles sur le tableau de leads
export const pgl_resetLeadsFiltersField = () => async (dispatch) => {
  dispatch({ type: PGL_LEADS_FILTERS_FIELD_RESET })
}

export const pgl_getLeadsExport = (user_id, nonce, ids) => async (dispatch) => {
  dispatch({ type: PGL_LEADS_LOADING, isLoading: true })
  const params = {
    stat: stats(),
    user: user_id,
    noken: nonce,
    ids,
  }

  try {
    const response = await api.post('/leads/export', params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    dispatch({ type: PGL_LEADS_EXPORT, payload: response.data })
  } catch (error) {
    console.log(`error`, error)
  }
}

// Récupère tous les leads accessibles au user
export const pgl_getLeadsExportAll = (user_id, nonce) => async (dispatch) => {
  dispatch({ type: PGL_LEADS_LOADING, isLoading: true })
  const params = {
    user: user_id,
    noken: nonce,
    stat: stats(),
  }
  const response = await api.post('/leads/export', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  dispatch({ type: PGL_LEADS_EXPORT, payload: response.data })
}

export const pgl_resetLeadsExport = () => async (dispatch) => {
  dispatch({ type: PGL_LEADS_EXPORT_RESET })
}

// Récupère la fiche d'un lead
export const pgl_getLead = (lead_id, user_id, noken) => async (dispatch) => {
  dispatch({ type: PGL_LEADS_LEAD_RESET })
  dispatch({ type: PGL_POPIN_RESET })
  dispatch({ type: PGL_LEADS_LEAD_QUALIF_RESET })
  dispatch({ type: PGL_CALLBACK_RESET })

  const response = await api.get(`/leads/${lead_id}`, {
    params: {
      id: lead_id,
      user: user_id,
      noken,
      stat: stats(),
    },
  })
  // console.log('logEL -> ////////////////// PGL GET LEAD DEBUT ! ///////////////////')
  // console.log('logEL -> response.data' + JSON.stringify(response.data))
  // console.log('logEL -> ////////////////// FIN PGL GET LEAD ///////////////////////')
  // dispatch({ type})
  dispatch({ type: PGL_LEADS_LEAD_GET, payload: response.data })
}

/////////////////////////////////////////////////////////////////////
// Mise à jour de la fiche d'un lead
export const pgl_setLead = (lead_id, params) => async (dispatch) => {
  try {
    const response = await api.post(`/leads/${lead_id}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch({ type: PGL_LEADS_LEAD_QUALIF, payload: response.data })
    if(response.data.hasOwnProperty('popin')){
      dispatch({ type: PGL_POPIN, payload: response.data.popin })
    }
    dispatch({ type: PGL_LEADS_LEAD_SET, payload: response.data, isLoading: false })
  } catch (error) {
    
  }
}
//////////////////////////////////////////////////////////////////////

// Ajout d'un nouveau lead
export const pgl_setNewLead = (form_data) => async (dispatch) => {
  const response = await api.post('/leads/new', form_data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  dispatch({
    type: PGL_LEADS_LEAD_NEW,
    payload: response.data,
  })
}

// Génère le PDF d'une fiche lead
export const pgl_getLeadPrint = (lead_id, user_id, noken) => async (dispatch) => {
  const response = await api.get(`/leads/${lead_id}/print`, {
    params: {
      id: lead_id,
      user: user_id,
      noken,
      stat: stats(),
    },
  })
  dispatch({ type: PGL_LEADS_LEAD_PRINT, payload: response.data })
}


// Récupère l'historique des actions réalisées sur une fiche lead
export const pgl_getLeadHistory = (lead_id, user_id, noken) => async (dispatch) => {
  const response = await api.get(`/leads/${lead_id}/history`, {
    params: {
      id: lead_id,
      user: user_id,
      noken,
      stat: stats(),
    },
  })
  dispatch({ type: PGL_LEADS_LEAD_HISTORY, payload: response.data })
}


export const pgl_getCompanies = (user_id, noken) => async (dispatch) => {
  const response = await api.get('/companies', {
    params: {
      user: user_id,
      noken,
      stat: stats(),
    },
  })
  // console.log('logEL -> pgl_Companies, response: ' + JSON.stringify(response.data))
  dispatch({ type: PGL_COMPANIES, payload: response.data })
}

// Récupère la liste des commerciaux potentiels pour le lead
export const pgl_getSellers = (lead_id, id_entreprise, user_id, noken) => async (dispatch) => {
  // console.log(lead_id, id_entreprise, user_id, noken)

  const response = await api.get(`/companies/${id_entreprise}/sellers`, {
    params: {
      id: id_entreprise,
      user: user_id,
      noken,
      stat: stats(),
    },
  })

  //LOOK IF HAVE A ERROR HASOWNPROPERTY

    dispatch({ type: PGL_SELLERS, payload: response.data, isLoading: false })
  
}

// Récupère les information de 'callback' ( à rappeler)
export const pgl_getCallback = (lead_id, user_id, noken) => async (dispatch) => {
  const response = await api.get(`/leads/${lead_id}/callback`, {
    params: {
      user: user_id,
      noken,
      stat: stats(),
    },
  })
  // console.log('logEL -> pgl_getCallback <- ok')
  // console.log('logEL -> response: ' + JSON.stringify(response.data))
  dispatch({ type: PGL_CALLBACK, payload: response.data })
}

// Définition d'une date pour rappeler le prospect
export const pgl_setCallback = (lead_id, params) => async (dispatch) => {
  const response = await api.post(`/leads/${lead_id}/callback`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  dispatch({ type: PGL_CALLBACK, payload: response.data })

}

// Récupère les information de 'call' ( suivi appel ... ?)
export const pgl_getCall = (lead_id, user_id, noken) => async (dispatch) => {
  const response = await api.get(`/leads/${lead_id}/call`, {
    params: {
      user: user_id,
      noken,
      stat: stats(),
    },
  })
  // console.log('logEL -> pgl_getCallback <- ok')
  // console.log('logEL -> response: ' + JSON.stringify(response.data))
  dispatch({ type: PGL_CALL, payload: response.data })
}

// Enregistrement d'une alerte pour un projet/lead
export const pgl_setAlerte = (lead_id, params) => async (dispatch) => {
  const response = await api.post(`/leads/${lead_id}/alert`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })


  dispatch({ type: PGL_LEADS_LEAD_ALERT, payload: response.data })
}

export const pgl_setBadAttribution = (lead_id, params) => async (dispatch) => {
  const response = await api.post(`/leads/${lead_id}/badattribution`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })


  dispatch({ type: PGL_LEADS_LEAD_ALERT, payload: response.data })
}

// Enregistrement des suites d'un appel téléphonique pour un projet/lead
export const pgl_setLeadCall = (lead_id, params, user_id, noken) => async (dispatch) => {

  const response = await api.post(`/leads/${lead_id}/call`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

 // dispatch({ type: PGL_POPIN, payload: response.data })
}

// Récupère les information de 'call' ( suivi appel ... ?)
export const pgl_getEmail = (lead_id, user_id, noken) => async (dispatch) => {
  
  const response = await api.get(`/leads/${lead_id}/mail`, {
    params: {
      user: user_id,
      noken,
      stat: stats(),

    },
  })

  // console.log('logEL -> pgl_getEmailback <- ok')
  // console.log('logEL -> response: ' + JSON.stringify(response.data))
  dispatch({ type: PGL_EMAIL, payload: response.data })
}

// Enregistrement des suites d'un appel téléphonique pour un projet/lead
export const pgl_setLeadEmail = (lead_id, params, user_id, noken) => async (dispatch) => {

  const response = await api.post(`/leads/${lead_id}/mail`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })


  dispatch({ type: PGL_RESULT_MAIL, payload: response.data })
}

// Modification du statut d'un projet/lead
/*export const pgl_putLeadStatus = (lead_id, params, user_id, noken) => async (dispatch) => {
  const response = await api.put(`/leads/${lead_id}/status`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return dispatch({type:PGL_POPIN_CLEAN})
  // console.log('logEL -> pgl_putLeadStatus action response: ' + JSON.stringify(response.data))
  //dispatch({ type: PGL_POPIN, payload: response.data })
}
*/


export function pgl_cleanLead(){
  return function (dispatch){
    return dispatch({type:'CLEAN_LEAD'})
  }
}

export function pgl_putLeadStatus(lead_id, params, user_id, noken){
  return function (dispatch){
   return api.put(`/leads/${lead_id}/status`, params, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response)=>{
        return dispatch({type:PGL_POPIN_CLEAN})
    })
  }
}


export function pgl_update_status(statut,lead_id, params, user_id, noken){
  return function (dispatch){
   return api.put(`/leads/${lead_id}/status`, params, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response)=>{
        return dispatch({type:PGL_SET_STATUS,payload:statut})
    })
  }
}

export const pgl_cleanPopin = (lead_id, params, user_id, noken) => async (dispatch) => {
  dispatch({type:PGL_POPIN_CLEAN})
}




