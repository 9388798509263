import React from 'react';

export const ShoppingBag = () => {
  return (
    <svg width="47px" height="56px" viewBox="0 0 47 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symboles" fillRule="evenodd">
        <g id="Btn/panier" transform="translate(-22.000000, -15.000000)">
          <g id="shopping" transform="translate(22.000000, 15.000000)">
            <path
              d="M42,13.9453125 L42,33.0703125 C42,34.1706641 41.129537,35.0625 40.0555556,35.0625 C38.9815741,35.0625 38.1111111,34.1706641 38.1111111,33.0703125 L38.1111111,15.9375 L34.2222222,15.9375 L34.2222222,21.9140625 C34.2222222,23.0144141 33.3517593,23.90625 32.2777778,23.90625 C31.2037963,23.90625 30.3333333,23.0144141 30.3333333,21.9140625 L30.3333333,15.9375 L11.6666667,15.9375 L11.6666667,21.9140625 C11.6666667,23.0144141 10.7962037,23.90625 9.72222222,23.90625 C8.64824074,23.90625 7.77777778,23.0144141 7.77777778,21.9140625 L7.77777778,15.9375 L3.88888889,15.9375 L3.88888889,47.015625 L24.5,47.015625 C25.5739815,47.015625 26.4444444,47.9074609 26.4444444,49.0078125 C26.4444444,50.1081641 25.5739815,51 24.5,51 L1.94444444,51 C0.870462963,51 0,50.1081641 0,49.0078125 L0,13.9453125 C0,12.8449609 0.870462963,11.953125 1.94444444,11.953125 L7.86994444,11.953125 C8.64214815,5.23161719 14.2354074,0 21,0 C27.7645926,0 33.3578519,5.23161719 34.1300556,11.953125 L40.0555556,11.953125 C41.129537,11.953125 42,12.8449609 42,13.9453125 Z M30.2031852,11.953125 C29.4604074,7.4364375 25.6165,3.984375 21,3.984375 C16.3835,3.984375 12.5395926,7.4364375 11.7968148,11.953125 L30.2031852,11.953125 Z"
              id="Fill-1"
            ></path>
            <path
              d="M47,46.9210526 C47,48.1292818 46.0207292,49.1085526 44.8125,49.1085526 L40.6875,49.1085526 L40.6875,53.2335526 C40.6875,54.4417818 39.7082292,55.4210526 38.5,55.4210526 C37.2917708,55.4210526 36.3125,54.4417818 36.3125,53.2335526 L36.3125,49.1085526 L32.1875,49.1085526 C30.9792708,49.1085526 30,48.1292818 30,46.9210526 C30,45.7128235 30.9792708,44.7335526 32.1875,44.7335526 L36.3125,44.7335526 L36.3125,40.6085526 C36.3125,39.4003235 37.2917708,38.4210526 38.5,38.4210526 C39.7082292,38.4210526 40.6875,39.4003235 40.6875,40.6085526 L40.6875,44.7335526 L44.8125,44.7335526 C46.0207292,44.7335526 47,45.7128235 47,46.9210526 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
