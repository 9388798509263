import styles from './Home.module.scss'
import Layout from '../../layout'
import { Fragment, useEffect, useState } from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { RootState } from '../../redux'
import { getFirstFetchDataHome, getDataHome, getDataMoreHome, postLike, postLikeFeat } from './homeSlice'
import Loading from '../../components/pgl/Loading'
import PublicationCard from '../publications/card-publication'
import Masonry from 'react-masonry-css'
import TabsCategory from './tabs-category'
import useDidMountEffect from '../../hooks/useDidmountEffect'
import Caroussel from '../../components/home/Caroussel'
import ListCategory from '../publications/list-category'
import { useQuery } from '../../hooks/useQuery'
import { useHistory } from 'react-router-dom'
import DocumentFast from '../document-board/document-fast'

//breakpoint column
const breakpointColumn = {
  default: 4,
  1400: 3,
  768: 2,
  600: 1,
}

//SELECTOR FOR HOME
export const homeSelector = createSelector([(state) => state.items], (menu) => {
  const actuality = menu.find((i: any) => i.home === 'home_1')
  const otherHome = menu.filter(
    (i: any) => i.hasOwnProperty('home') && i.home && i.home !== '' && i.home !== 'home_1'
  )
  return [actuality, ...otherHome]
})
//Selector permettant de récupérer le type de posts
export const typeOfCard = createSelector(
  [(state) => state.items, (state, categorie: number | string) => categorie],
  (menu, categorie) => {
    return menu.find((item: any) => item.id == categorie)
  }
)

function startCategorie(parentCat, homeCategory) {
  if (parentCat && homeCategory.find((i) => i.id == parentCat)) {
    return parentCat
  } else return homeCategory[0].id
}

export default function Home() {
  /**
   * Starter
   */
  const dispatch = useAppDispatch()
  const history = useHistory()
  const homeCategorie = useAppSelector((state: RootState) => homeSelector(state.navbar))
  /**
   * Query Params
   */
  const query = useQuery()
  const parentCat = query.get('parentCat')
  const childCat = query.get('childCat')
  const currentParentCat = parentCat ?? homeCategorie[0].id
  const currentChildCat = childCat ?? currentParentCat
  /**
   * Selector Rexdux
   */
  const cardType = useAppSelector((state) => typeOfCard(state.navbar, currentParentCat))
  const { parentPost, checkOutState } = useAppSelector((state) => state.home)

  function onLikePublication(val: number | string) {
    //dispatch(postLike({id_post:val,cat:parentCategorie}))
  }

  //function for select new categorie
  function onSelectTabs(number: number) {
    history.push({ pathname: '/', search: `?parentCat=${number}` })
  }

  //function
  function onChangeCategory(id) {
    if (parentPost.checkOutState !== 'LOADING') {
      history.push({
        pathname: '/',
        search: `?parentCat=${currentParentCat}&childCat=${id}`,
      })
      //dispatch(getDataHome({ parentCat: currentParentCat, childCat: id }))
      //if (!parentPost[currentParentCat].posts[id])
      dispatch(getDataHome({ parentCat: currentParentCat, childCat: id }))
    }
  }

  useEffect(() => {
    if (!parentPost[currentParentCat] && homeCategorie.find((i) => i.id == currentParentCat))
      dispatch(getFirstFetchDataHome({ parentCat: currentParentCat, childCat: currentChildCat }))
  }, [currentParentCat, currentChildCat])

  const existingPost = parentPost[currentParentCat] && parentPost[currentParentCat].posts[childCat]

  function onFetchMore() {
    dispatch(
      getDataMoreHome({
        parentCat: currentParentCat,
        childCat: currentChildCat,
        page: parentPost[currentParentCat].posts[currentChildCat].page,
      })
    )
  }

  function handleLife(id_post) {
    dispatch(postLike({ id_post: id_post, parentCat: currentParentCat, childCat: currentChildCat }))
  }

  function onLikeFeat(val: number | string) {
    dispatch(postLikeFeat({ id_post: val, parentCat: currentParentCat, childCat: currentChildCat }))
  }

  return (
    <Layout type={'nofetch'} title={'ACCUEIL'}>
      <div className={styles['home-page']}>
        {parentPost.documents ? (
          <DocumentFast
            count={(parentPost.documents as { count })?.count}
            libelle={(parentPost.documents as { libelle })?.libelle}
          />
        ) : null}
        {homeCategorie ? (
          <TabsCategory
            onChoose={onSelectTabs}
            categorie={currentParentCat}
            homeRubrique={homeCategorie}
            onChange={onSelectTabs}
          />
        ) : null}
        {parentPost[currentParentCat] &&
        parentPost[currentParentCat].hasOwnProperty('list_category') &&
        parentPost[currentParentCat].list_category.length > 0 &&
        checkOutState === 'READY' ? (
          <ListCategory
            currentCategorie={currentChildCat}
            categories={[
              { name: 'Tous les articles', id: currentParentCat },
              ...parentPost[currentParentCat].list_category,
            ]}
            onSelect={onChangeCategory}
          />
        ) : null}
        {parentPost ? (
          parentPost.checkOutState == 'LOADING' ? (
            <Loading />
          ) : parentPost.checkOutState == 'ERROR' ? (
            <div style={{ padding: '1rem' }}>Aucune publication dans cette rubrique.</div>
          ) : (
            <Fragment>
              {parentPost[currentParentCat]?.posts[currentChildCat].feat && (
                <PublicationCard
                  currentLocation={{ type: 'feat', parentCat: currentParentCat, childCat: currentChildCat }}
                  id={parentPost[currentParentCat]?.posts[currentChildCat].feat?.id}
                  type={cardType.screen !== 'pic' ? 'standard' : 'photo'}
                  name="feat"
                  onLikeFeat={onLikeFeat}
                  title={parentPost[currentParentCat]?.posts[currentChildCat].feat?.titre}
                  img={parentPost[currentParentCat]?.posts[currentChildCat].feat?.photo?.url}
                  text={parentPost[currentParentCat]?.posts[currentChildCat].feat?.texte}
                  auteur={parentPost[currentParentCat]?.posts[currentChildCat].feat?.auteur}
                  date={parentPost[currentParentCat]?.posts[currentChildCat].feat?.date}
                  entreprise={parentPost[currentParentCat]?.posts[currentChildCat].feat?.gps?.entreprise?.gp}
                  likes={parentPost[currentParentCat]?.posts[currentChildCat].feat.likes}
                  comments={parentPost[currentParentCat]?.posts[currentChildCat].feat.comments}
                />
              )}
              {parentPost[currentParentCat]?.posts[currentChildCat]?.data.length > 0 ? (
                <Masonry
                  breakpointCols={breakpointColumn}
                  className={styles['grid-posts']}
                  columnClassName={styles['grid-posts-column']}
                >
                  {parentPost[currentParentCat]?.posts[currentChildCat]?.data.map((pub) => {
                    if (cardType.screen !== 'pic') {
                      return (
                        <PublicationCard
                          currentLocation={{ type: 'post', parentCat: currentParentCat, childCat: currentChildCat }}
                          onLike={handleLife}
                          key={'post' + pub.id}
                          name={'post'}
                          title={pub?.titre}
                          img={pub?.photo?.url}
                          auteur={pub?.auteur}
                          date={pub?.date}
                          entreprise={pub?.gps?.entreprise?.gp}
                          notif={pub?.new}
                          likes={pub?.likes}
                          comments={pub?.comments}
                          status={pub?.status}
                          id={pub?.id}
                          slug={pub?.slug}
                          rub={pub?.rub}
                        />
                      )
                    } else {
                      return (
                        <PublicationCard
                          currentLocation={{ type: 'post', parentCat: currentParentCat, childCat: currentChildCat }}
                          key={'pic' + pub.id}
                          onLike={handleLife}
                          id={pub?.id}
                          name={'life'}
                          images={pub?.galerie}
                          new={pub?.new}
                          likes={pub?.likes}
                          comments={pub?.comments}
                          status={pub?.status}
                          slug={pub?.slug}
                        />
                      )
                    }
                  })}
                </Masonry>
              ) : (
                <div className={styles['']}>Aucune publication dans cette rubrique.</div>
              )}
              {!parentPost[currentParentCat]?.posts[currentChildCat].last ? (
                <button onClick={onFetchMore} className={styles['button-more']}>
                  {parentPost[currentParentCat]?.posts[currentChildCat]?.checkoutStateMore === 'LOADING'
                    ? 'Chargement ...'
                    : 'Charger plus de publications'}
                </button>
              ) : null}
            </Fragment>
          )
        ) : null}
      </div>
    </Layout>
  )
}
