const ImageIcon = ({width,height,fill}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.139 20.139"
    width={width}
    height={height}
  >
    <path
    fill={fill}
      d="M2.014 2.014h7.049V0H2.014A2.02 2.02 0 0 0 0 2.014v7.049h2.014Zm6.042 9.062-4.028 5.035h12.083l-3.021-4.028-2.044 2.729ZM15.1 6.545a1.51 1.51 0 1 0-1.51 1.51 1.508 1.508 0 0 0 1.51-1.51ZM18.125 0h-7.049v2.014h7.049v7.049h2.014V2.014A2.02 2.02 0 0 0 18.125 0Zm0 18.125h-7.049v2.014h7.049a2.02 2.02 0 0 0 2.014-2.014v-7.049h-2.014ZM2.014 11.076H0v7.049a2.02 2.02 0 0 0 2.014 2.014h7.049v-2.014H2.014Z"
    />
  </svg>
)

export default ImageIcon
