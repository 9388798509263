import {Switch,Route,useLocation,Redirect} from 'react-router-dom';
import config from '../config';
import NoMatch from '../pages/NoMatch';
import routes from '../routes';
import Layout from '../components/pgl/Layout';
import { AuthProvider } from '../context/AuthContext';
import PrivateRoute from './private-routes';
import { Suspense } from 'react';
import Loading from '../components/pgl/Loading';
import LayoutApp from '../layout/layout-app';
import { LastLocationProvider } from 'react-router-last-location';
import LayoutAuth from '../layout/layout-auth';
import LoosePassword from '../pages/loose-password';
import PrivateLayout from './private-layout';
import { useTransition,a, animated } from 'react-spring';
import AuthRouter from './auth-router';

import Protected from '../features/protected';
import Tutoriel from '../features/tutoriel';
import TvShow from '../features/tv-show';
import IconsGallery from '../features/icons-gallery';
import PostPicture from '../features/post-picture';
import { useQuery } from '../hooks/useQuery';



export default function Routing(){

    const pathPGL  =routes.filter(item=> item.partOfPciApp == true).map(i=>i.path);
    const pathPCI = routes.filter(item=>item.partOfPciApp!==true && item.type!=="connexion" && item.type!=='protected').map(i=>i.path);
    const routesConnection = routes.filter(item=> item.type=="connexion");
    const routeProtected = routes.filter(item=> item.type === 'protected')
    const pathConnection = routesConnection.map(i=>i.path)
    const location= useLocation();
    const query = useQuery();
    let background = location.state && location.state.background;

    if (!background &&  location.pathname.startsWith('/photo/')) {
      let rub = query.get('rub');
      if(rub){
        background = {pathname: `/photos/${rub}`};
      }
      else background = {pathname: `/`};
     
    }

    return(

            <Suspense fallback={<Loading />}>
               <LastLocationProvider>
                  <Route path={[...routes.map(i=>i.path),
                  '/mention-legal',
                  '/politique-de-confidentialite',
                  '/tutoriel',
                  '/photo/:id',
                  ]}>
                  <Switch location={background||location}>
                    <Route exact path={[...pathPCI,...pathPGL]} > 
                      <AuthProvider>
                        <PrivateLayout  exact path={[...pathPCI]}>
                          <LayoutApp>
                          {routes
                            .filter((route) => route.partOfPciApp !== true)
                            ?.map(({ path, component }, idx) => (
                            <Route exact  key={`pci-global${idx}`} activeClassName="active" component={component} path={path}  />
                            ))}
                            
                          </LayoutApp>
                        
                        </PrivateLayout>
                        <PrivateLayout exact path={pathPGL}>
                          <Layout>
                            {routes
                                .filter((route) => route.partOfPciApp == true)
                                .map( (item, key) => {
                                return  <Route key={`pgl${key}`} component={item.component}  exact  path={item.path}/>
                              })}
                          </Layout>
                        </PrivateLayout>
                      </AuthProvider>
                    </Route>
                   
                    <Route exact  path={[...pathConnection]}>
                      <AuthRouter/>
                    </Route>
                    <Route exact path='/politique-de-confidentialite' render={()=>(<Protected type="private-policy" name="page"/>)} /> 
                    <Route exact path='/mentions-legales'render={()=>(<Protected type="use-term" name="page"/>)}/>
                    <Route exact path='/tutoriel' component={Tutoriel}  />
                    <Route exact path='/tv-show' component={TvShow}/>
                    <Route exact path="/gallery-icon" component={IconsGallery}/>
                    <Route component={NoMatch}></Route>
                </Switch>
                {background &&  <Route exact path='/politique-de-confidentialite' render={()=>(<Protected type="private-policy" name="modal"/>)} /> }
                {background &&  <Route exact path='/mentions-legales'render={()=>(<Protected type="use-term" name="modal"/>)}/> }
                {background && <Route exact path='/tutoriel' component={Tutoriel}  />}
                {background && <Route exact path='/photo/:id' component={PostPicture}/>}
                </Route>
               
               
        
            </LastLocationProvider>
          </Suspense>
          )
}

