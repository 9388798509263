import React from 'react';

export default({...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.1 36.17">
    <g id="bulle">
      <path
        fill={props.bg}
        d="M164.7,8H56.2a9.34,9.34,0,0,1-2.1-6A1.51,1.51,0,0,0,53.2.6a1.36,1.36,0,0,0-1.6.2A15.31,15.31,0,0,0,47.3,8h-29a14.3,14.3,0,0,0,0,28.6H164.8A14.3,14.3,0,1,0,164.7,8Z"
        transform="translate(-4 -0.43)"
      />
    </g>
    <g id="texte">
      <g id="Privée-32-IOS">
        <g id="Downlad">
          <g>
            <path
              fill={'var(--ui-primary-color)'}
              d="M44.6,19.5a3.59,3.59,0,0,0-1-.7,3.34,3.34,0,0,0-1.5-.3,3,3,0,0,0-1.8.5,1.49,1.49,0,0,0-.6,1.3,1.48,1.48,0,0,0,.1.7c.1.2.3.3.5.5s.5.2.8.3a10.38,10.38,0,0,0,1.2.3,5,5,0,0,1,1.3.4,3.13,3.13,0,0,1,1,.5,1.34,1.34,0,0,1,.6.7,3,3,0,0,1,.2,1,3.59,3.59,0,0,1-.2,1.1,2.7,2.7,0,0,1-.7.8,2.81,2.81,0,0,1-1.1.5,6.15,6.15,0,0,1-1.3.1,5.41,5.41,0,0,1-3.7-1.3l.5-.7c.2.2.4.3.6.5a2,2,0,0,0,.8.4l.9.3a3.4,3.4,0,0,0,1,.1,2.86,2.86,0,0,0,1.7-.4,1.43,1.43,0,0,0,.6-1.2,1.45,1.45,0,0,0-.2-.7,1.21,1.21,0,0,0-.5-.5,3.92,3.92,0,0,0-.9-.4c-.3-.1-.8-.2-1.2-.3a5,5,0,0,1-1.3-.4,1.93,1.93,0,0,1-.9-.5,4.35,4.35,0,0,1-.6-.7,1.49,1.49,0,0,1-.2-.9,3.59,3.59,0,0,1,.2-1.1,2.7,2.7,0,0,1,.7-.8,3.13,3.13,0,0,1,1-.5,5.07,5.07,0,0,1,1.3-.2,3.81,3.81,0,0,1,1.6.3,4.58,4.58,0,0,1,1.3.8Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M49.2,27.1a1.75,1.75,0,0,1-1.6-.7,3.43,3.43,0,0,1-.5-2.2v-4H48V24c0,1.5.5,2.3,1.6,2.3a2.2,2.2,0,0,0,.8-.1,1.61,1.61,0,0,0,.7-.4l.6-.6a2,2,0,0,0,.4-.8V20.1H53v5.6c0,.2.1.4.3.4V27h-.4a.76.76,0,0,1-.5-.2.85.85,0,0,1-.2-.6v-.8A3.45,3.45,0,0,1,51,26.6,3.09,3.09,0,0,1,49.2,27.1Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M58.5,21a3,3,0,0,0-1.6.5,2.07,2.07,0,0,0-.9,1.3V27h-.9V20.2h.8v1.6a4,4,0,0,1,1-1.2,2.3,2.3,0,0,1,1.4-.4h.2Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M63.2,17.5h.9v8a1.07,1.07,0,0,0,.2.6.85.85,0,0,0,.6.2h.3c.1,0,.3-.1.4-.1l.2.7c-.2.1-.4.1-.6.2s-.4.1-.6.1a1.69,1.69,0,0,1-1-.3,1.53,1.53,0,0,1-.4-1Z"
              transform="translate(-4 -0.43)"
            />
            <path fill={'var(--ui-primary-color)'} d="M66.8,20.2l.2-1.4h-.3V17.5h.9v1.3l-.3,1.4Z" transform="translate(-4 -0.43)" />
            <path
              fill={'var(--ui-primary-color)'}
              d="M72.3,27.1a3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1,3.19,3.19,0,0,1-.3-1.4,2.77,2.77,0,0,1,.3-1.3,4.13,4.13,0,0,1,.7-1.1,4.44,4.44,0,0,1,1.1-.8,3.19,3.19,0,0,1,1.4-.3,3.53,3.53,0,0,1,1.4.3,2.26,2.26,0,0,1,1.1.8,4.13,4.13,0,0,1,.7,1.1,3,3,0,0,1,.2,1.3v.3H69.9a1.69,1.69,0,0,0,.3,1,2.41,2.41,0,0,0,.6.8,2.18,2.18,0,0,0,.8.5,2.92,2.92,0,0,0,.9.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.2a1.76,1.76,0,0,0,.5-.4c.1-.2.3-.3.3-.5l.8.2a4.35,4.35,0,0,1-.4.7,1.79,1.79,0,0,1-.7.5,3,3,0,0,1-.8.4A2.56,2.56,0,0,1,72.3,27.1Zm2.5-3.8a1.69,1.69,0,0,0-.3-1,5.58,5.58,0,0,0-.5-.8,2.18,2.18,0,0,0-.8-.5,2.13,2.13,0,0,0-1.8,0,2.1,2.1,0,0,0-1.3,1.3,3,3,0,0,0-.2,1Zm-2.5-4.4-.6-.2.8-1.2h.9Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M76.8,23.6a6.75,6.75,0,0,1,.2-1.4,2.1,2.1,0,0,1,.7-1.1,2.59,2.59,0,0,1,1.1-.7,3.19,3.19,0,0,1,1.4-.3,3.29,3.29,0,0,1,1.7.4A3.18,3.18,0,0,1,83,21.7l-.9.3a3,3,0,0,0-.8-.8,2,2,0,0,0-1.1-.3,3.55,3.55,0,0,0-1,.2,1.56,1.56,0,0,0-.8.6,1.93,1.93,0,0,0-.5.9,3.59,3.59,0,0,0-.2,1.1,4.25,4.25,0,0,0,.2,1.1,2.62,2.62,0,0,0,.5.9,5.55,5.55,0,0,0,.8.6,3,3,0,0,0,1,.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.3s.3-.2.5-.4.2-.3.3-.4l.9.3a2.51,2.51,0,0,1-.4.7,2.65,2.65,0,0,1-.6.5,2,2,0,0,1-.8.4,2.77,2.77,0,0,1-.9.1A3.53,3.53,0,0,1,79,27a2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1A3.07,3.07,0,0,1,76.8,23.6Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M88,21a3,3,0,0,0-1.6.5,2.07,2.07,0,0,0-.9,1.3V27h-.9V20.2h.8v1.6a4,4,0,0,1,1-1.2,2.3,2.3,0,0,1,1.4-.4H88Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M91.1,27.1a2.92,2.92,0,0,1-.9-.2,4.35,4.35,0,0,1-.7-.4,1.79,1.79,0,0,1-.5-.7,2.35,2.35,0,0,1-.2-.8,1.88,1.88,0,0,1,.2-.8,1.34,1.34,0,0,1,.6-.7,3.55,3.55,0,0,1,.9-.4,3.75,3.75,0,0,1,1.1-.1,4.48,4.48,0,0,1,1.1.1c.4.1.7.1.9.3v-.6a2,2,0,0,0-.5-1.4,1.62,1.62,0,0,0-1.4-.5,5.16,5.16,0,0,0-1.1.2,3,3,0,0,0-1.1.6l-.2-.7a4.5,4.5,0,0,1,2.5-.9,2.72,2.72,0,0,1,2,.7,2.58,2.58,0,0,1,.7,2v3.1c0,.2.1.4.3.4V27h-.3a.68.68,0,0,1-.7-.7v-.5a2.73,2.73,0,0,1-1.2.9A2.82,2.82,0,0,1,91.1,27.1Zm.2-.6a2.77,2.77,0,0,0,1.3-.3,2.18,2.18,0,0,0,.9-.7c.1-.1.1-.2.2-.3s.1-.2.1-.3V23.8l-.9-.3a3.08,3.08,0,0,0-1-.1,2.41,2.41,0,0,0-1.5.4,1.22,1.22,0,0,0-.6,1,1.27,1.27,0,0,0,.1.6c.1.2.2.3.3.5s.3.2.5.3A.6.6,0,0,0,91.3,26.5Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M102.4,27h-.9V23.2a3,3,0,0,0-.4-1.8,1.17,1.17,0,0,0-1.1-.5,2.2,2.2,0,0,0-.8.1,1.61,1.61,0,0,0-.7.4l-.6.6a2,2,0,0,0-.4.8V27h-.9V20.2h.8v1.5a3.45,3.45,0,0,1,1.2-1.2,3.37,3.37,0,0,1,1.7-.5,2,2,0,0,1,.9.2,1.17,1.17,0,0,1,.6.6,1.61,1.61,0,0,1,.3.9c0,.3.1.8.1,1.2V27Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M110.9,27.1a2.77,2.77,0,0,1-1.3-.3,3.92,3.92,0,0,1-1-.8,4.13,4.13,0,0,1-.7-1.1,3,3,0,0,1-.2-1.3,6.75,6.75,0,0,1,.2-1.4,2.87,2.87,0,0,1,.6-1.1,3.92,3.92,0,0,1,1-.8,2.66,2.66,0,0,1,1.2-.3,2.41,2.41,0,0,1,1.5.4,3.46,3.46,0,0,1,1,1.1V17.4h.9v8.3c0,.2.1.4.3.4V27h-.3a.76.76,0,0,1-.5-.2c-.2-.1-.2-.3-.2-.5v-.7a3.84,3.84,0,0,1-1.1,1.1A2.3,2.3,0,0,1,110.9,27.1Zm.2-.8a2,2,0,0,0,.7-.1,2.51,2.51,0,0,0,.7-.4c.2-.2.4-.3.6-.5s.2-.4.3-.7V22.5a2.51,2.51,0,0,0-.4-.7,2.65,2.65,0,0,0-.6-.5,4.35,4.35,0,0,0-.7-.4,1.7,1.7,0,0,0-.7-.1,3,3,0,0,0-1,.2c-.3.2-.5.4-.8.6a2.62,2.62,0,0,0-.5.9,3.55,3.55,0,0,0-.2,1,3,3,0,0,0,.2,1,4.05,4.05,0,0,0,.5.9,5.55,5.55,0,0,0,.8.6A1.55,1.55,0,0,0,111.1,26.3Z"
              transform="translate(-4 -0.43)"
            />
            <path fill={'var(--ui-primary-color)'} d="M116.4,20.2l.2-1.4h-.3V17.5h.9v1.3l-.3,1.4Z" transform="translate(-4 -0.43)" />
            <path
              fill={'var(--ui-primary-color)'}
              d="M120.7,27.1a2.92,2.92,0,0,1-.9-.2,4.35,4.35,0,0,1-.7-.4,1.79,1.79,0,0,1-.5-.7,2.35,2.35,0,0,1-.2-.8,1.88,1.88,0,0,1,.2-.8,1.34,1.34,0,0,1,.6-.7,3.55,3.55,0,0,1,.9-.4,3.75,3.75,0,0,1,1.1-.1,4.48,4.48,0,0,1,1.1.1c.4.1.7.1.9.3v-.6a2,2,0,0,0-.5-1.4,1.62,1.62,0,0,0-1.4-.5,5.16,5.16,0,0,0-1.1.2,3,3,0,0,0-1.1.6l-.3-.6a4.5,4.5,0,0,1,2.5-.9,2.72,2.72,0,0,1,2,.7,2.58,2.58,0,0,1,.7,2V26c0,.2.1.4.3.4V27H124a.68.68,0,0,1-.7-.7v-.5a2.73,2.73,0,0,1-1.2.9A2,2,0,0,1,120.7,27.1Zm.2-.6a2.77,2.77,0,0,0,1.3-.3,2.18,2.18,0,0,0,.9-.7c.1-.1.1-.2.2-.3s.1-.2.1-.3V23.8l-.9-.3a3.08,3.08,0,0,0-1-.1,2.41,2.41,0,0,0-1.5.4,1.22,1.22,0,0,0-.6,1,1.27,1.27,0,0,0,.1.6c.1.2.2.3.3.5s.3.2.5.3S120.7,26.5,120.9,26.5Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M125.9,23.6a6.75,6.75,0,0,1,.2-1.4,2.1,2.1,0,0,1,.7-1.1,2.59,2.59,0,0,1,1.1-.7,3.19,3.19,0,0,1,1.4-.3,3.29,3.29,0,0,1,1.7.4,3.18,3.18,0,0,1,1.1,1.2l-.9.3a3,3,0,0,0-.8-.8,2,2,0,0,0-1.1-.3,3.55,3.55,0,0,0-1,.2,1.56,1.56,0,0,0-.8.6,1.93,1.93,0,0,0-.5.9,3.59,3.59,0,0,0-.2,1.1,4.25,4.25,0,0,0,.2,1.1,4.05,4.05,0,0,0,.5.9,2.41,2.41,0,0,0,.8.6,3,3,0,0,0,1,.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.3a1.76,1.76,0,0,0,.5-.4c.2-.2.2-.3.3-.4l.9.3a2.51,2.51,0,0,1-.4.7,2.65,2.65,0,0,1-.6.5,2,2,0,0,1-.8.4,2.77,2.77,0,0,1-.9.1,3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1A3.71,3.71,0,0,1,125.9,23.6Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M133.2,23.6a6.75,6.75,0,0,1,.2-1.4,2.1,2.1,0,0,1,.7-1.1,2.59,2.59,0,0,1,1.1-.7,3.19,3.19,0,0,1,1.4-.3,3.29,3.29,0,0,1,1.7.4,3.18,3.18,0,0,1,1.1,1.2l-.9.3a3,3,0,0,0-.8-.8,2,2,0,0,0-1.1-.3,3.55,3.55,0,0,0-1,.2,1.56,1.56,0,0,0-.8.6,1.93,1.93,0,0,0-.5.9,3.59,3.59,0,0,0-.2,1.1,4.25,4.25,0,0,0,.2,1.1,4.05,4.05,0,0,0,.5.9,2.41,2.41,0,0,0,.8.6,3,3,0,0,0,1,.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.3a1.76,1.76,0,0,0,.5-.4c.2-.2.2-.3.3-.4l.9.3a2.51,2.51,0,0,1-.4.7,2.65,2.65,0,0,1-.6.5,2,2,0,0,1-.8.4,2.77,2.77,0,0,1-.9.1,3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1A3.71,3.71,0,0,1,133.2,23.6Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M143.2,27.1a1.75,1.75,0,0,1-1.6-.7,3.43,3.43,0,0,1-.5-2.2v-4h.9V24c0,1.5.5,2.3,1.6,2.3a2.2,2.2,0,0,0,.8-.1,1.61,1.61,0,0,0,.7-.4l.6-.6a2,2,0,0,0,.4-.8V20.1h.9v5.6c0,.2.1.4.3.4V27h-.4a.76.76,0,0,1-.5-.2.85.85,0,0,1-.2-.6v-.8a3.45,3.45,0,0,1-1.2,1.2A3.29,3.29,0,0,1,143.2,27.1Z"
              transform="translate(-4 -0.43)"
            />
            <path
              fill={'var(--ui-primary-color)'}
              d="M152,27.1a3.53,3.53,0,0,1-1.4-.3,2.84,2.84,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1,3.19,3.19,0,0,1-.3-1.4,2.77,2.77,0,0,1,.3-1.3,4.13,4.13,0,0,1,.7-1.1,4.44,4.44,0,0,1,1.1-.8A3.19,3.19,0,0,1,152,20a3.53,3.53,0,0,1,1.4.3,2.84,2.84,0,0,1,1.1.8,4.13,4.13,0,0,1,.7,1.1,3,3,0,0,1,.2,1.3v.3h-5.8a1.69,1.69,0,0,0,.3,1,2.41,2.41,0,0,0,.6.8,2.18,2.18,0,0,0,.8.5,2.92,2.92,0,0,0,.9.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.2a1.76,1.76,0,0,0,.5-.4c.1-.2.3-.3.3-.5l.8.2a4.35,4.35,0,0,1-.4.7,1.79,1.79,0,0,1-.7.5,3,3,0,0,1-.8.4A2.34,2.34,0,0,1,152,27.1Zm2.5-3.8a1.69,1.69,0,0,0-.3-1,5.58,5.58,0,0,0-.5-.8,2.18,2.18,0,0,0-.8-.5,2.12,2.12,0,0,0-1.8,0,2.1,2.1,0,0,0-1.3,1.3,3,3,0,0,0-.2,1Z"
              transform="translate(-4 -0.43)"
            />
            <path fill={'var(--ui-primary-color)'} d="M157,18.8V17.5h.9v1.3Zm0,8.2V20.2h.9V27Z" transform="translate(-4 -0.43)" />
            <path
              fill={'var(--ui-primary-color)'}
              d="M160.1,17.5h.9v8a1.07,1.07,0,0,0,.2.6.85.85,0,0,0,.6.2h.3c.1,0,.3-.1.4-.1l.2.7c-.2.1-.4.1-.6.2s-.4.1-.6.1a1.69,1.69,0,0,1-1-.3,1.16,1.16,0,0,1-.4-1V17.5Z"
              transform="translate(-4 -0.43)"
            />
          </g>
          <path
            fill={'var(--ui-primary-color)'}
            d="M26.5,14h-7A4.48,4.48,0,0,0,15,18.5v7A4.48,4.48,0,0,0,19.5,30h7A4.48,4.48,0,0,0,31,25.5v-7A4.48,4.48,0,0,0,26.5,14ZM30,25.5A3.54,3.54,0,0,1,26.5,29h-7A3.54,3.54,0,0,1,16,25.5v-7A3.54,3.54,0,0,1,19.5,15h7A3.54,3.54,0,0,1,30,18.5Z"
            transform="translate(-4 -0.43)"
          />
          <polygon
            fill={'var(--ui-primary-color)'}
            points="19.5 16.97 18.5 16.97 18.5 21.07 14.2 21.07 14.2 22.07 18.5 22.07 18.5 26.17 19.5 26.17 19.5 22.07 23.8 22.07 23.8 21.07 19.5 21.07 19.5 16.97"
          />
        </g>
      </g>
    </g>
  </svg>


  );
};
