import React from 'react';
import styles from './Button.module.scss';

export default function ButtonText(props){
  if(props.type == "submit"){
    return(
      <input type="submit"
            value={props.text}    className={`${styles["button-text"]} ${props.classNames} ${styles[props.typeColor]}`} onClick={props.onAction}>
        
      </input>
    )
  }
  else{
  return(
    <button type="button"
        disabled={props.disabled}  className={`${styles["button-text"]} ${props.classNames} ${props.disabled?styles['disabled']:styles[props.typeColor]} ${props.big?styles['big']:''}  ${props.semibig?styles['semibig']:''}  ${props.queen?styles['queen']:''}`} onClick={props.onAction}>
      {props.text}
    </button>
   )
  }
}

ButtonText.defaultProps = {
  type:'button',
  classNames:'',
}