import React from 'react';

export const NavbarDownload = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
      <g>
        <path d="M19.165 10.838a.552.552 0 0 0-.557.552v5.02c0 1.366-1.122 2.473-2.497 2.473H3.89c-1.379 0-2.497-1.112-2.497-2.473v-5.102a.552.552 0 0 0-.557-.552.552.552 0 0 0-.557.552v5.102c0 1.975 1.622 3.577 3.61 3.577h12.223c1.993 0 3.611-1.606 3.611-3.577v-5.02a.555.555 0 0 0-.557-.552" />
        <path d="M9.597 16.744c.11.108.26.166.405.166a.57.57 0 0 0 .405-.166l3.661-3.562a.548.548 0 0 0 0-.793.586.586 0 0 0-.815 0l-2.675 2.607V1.573a.566.566 0 0 0-.576-.56c-.32 0-.576.249-.576.56v13.423l-2.68-2.607a.586.586 0 0 0-.814 0 .548.548 0 0 0 0 .793l3.665 3.562z" />
      </g>
    </svg>
  );
};
