import React from 'react';

export const MondialBoutiqueSpa = () => {
  return (
    <svg
      version="1.1"
      id="noun_jacuzzi_870899"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18.6 18.6"
      style={{ enableBackground: 'new 0 0 18.6 18.6' }}
    >
      <path
        id="Tracé_794"
        d="M18.2,10H7.6l-7-3.9c-0.1-0.1-0.3-0.1-0.4,0C0.1,6.2,0,6.4,0,6.5v9.8c0,1.3,1,2.3,2.3,2.3h14.1
   c1.3,0,2.3-1,2.3-2.3v-5.9C18.6,10.2,18.4,10,18.2,10L18.2,10z M17.8,16.4c0,0.8-0.6,1.4-1.4,1.4H2.3c-0.8,0-1.4-0.6-1.4-1.4
   c0,0,0,0,0,0V9.7l5.8,3.5c0.1,0,0.1,0.1,0.2,0.1h11V16.4z M17.8,12.4H6.9L0.8,8.7V7.2l6.4,3.6c0.1,0,0.1,0.1,0.2,0.1h10.3L17.8,12.4
   z M6.4,5c1.4,0,2.5-1.1,2.5-2.5C8.9,1.1,7.8,0,6.4,0C5.1,0,4,1.1,4,2.5c0,0,0,0,0,0C4,3.8,5.1,5,6.4,5z M6.4,0.8
   c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7C4.8,1.6,5.5,0.8,6.4,0.8z"
      />
      <path
        id="Tracé_795"
        d="M8,6.2c-0.7,0-1.3,0.6-1.3,1.3S7.3,8.8,8,8.8s1.3-0.6,1.3-1.3l0,0C9.3,6.8,8.7,6.2,8,6.2z M8,8
   C7.7,8,7.5,7.7,7.5,7.5S7.7,7,8,7s0.5,0.2,0.5,0.5C8.4,7.7,8.2,7.9,8,8C8,8,8,8,8,8z M15.8,2.7c0.7,0,1.3-0.6,1.3-1.3
   s-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3S15.1,2.7,15.8,2.7z M15.8,0.9c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5
   s-0.5-0.2-0.5-0.5c0,0,0,0,0,0C15.3,1.1,15.5,0.9,15.8,0.9C15.8,0.9,15.8,0.9,15.8,0.9z M1.3,5.3C2,5.3,2.6,4.7,2.6,4S2,2.7,1.3,2.7
   S0,3.3,0,4S0.6,5.3,1.3,5.3z M1.3,3.5c0.3,0,0.5,0.2,0.5,0.5S1.6,4.4,1.3,4.4S0.8,4.2,0.8,4C0.8,3.7,1,3.5,1.3,3.5
   C1.3,3.5,1.3,3.5,1.3,3.5L1.3,3.5z M14.1,8.4c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
   C14.7,7.1,14.1,7.6,14.1,8.4L14.1,8.4z M15.9,8.4c0,0.3-0.2,0.5-0.5,0.5S15,8.6,15,8.4c0-0.3,0.2-0.5,0.5-0.5h0
   C15.7,7.9,15.9,8.1,15.9,8.4L15.9,8.4z M12.8,7c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5
   C10.4,5.9,11.5,7,12.8,7z M12.8,2.9c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7l0,0C11.2,3.6,11.9,2.9,12.8,2.9
   z"
      />
    </svg>
  );
};
