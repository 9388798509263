import { AxiosResponse } from 'axios';
import React, {createContext,useState,useEffect,useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../config/pci/api';
import { stats } from '../config/stats';
import {IRootState} from '../types/state';
import {useHistory,useLocation} from 'react-router-dom';
import useDidMountEffect from '../hooks/useDidmountEffect';


/*Interface du context auth*/
interface AuthContextInterface{
    authorized?:boolean,
    setAuthorized:React.Dispatch<React.SetStateAction<boolean>>
    loading:boolean
}


//Creation du context d'authentification
const AuthContext = createContext({} as AuthContextInterface);



export function AuthProvider({children}:{children:Array<JSX.Element> | JSX.Element}){
    const location = useLocation();
    const dispatch  = useDispatch();
    const history  = useHistory();
    
    //state user redux
    const {id,type,nonce} = useSelector((state:IRootState)=>state.auth.authentication);
    const {email}=useSelector((state:IRootState)=>state.auth);

    //state context
    const [authorized,setAuthorized] = useState(false);
    const [loading,setLoading] = useState(true)  

    async function fetchData(){
        setLoading(true)
    try{
    const response = await api.get<AxiosResponse<unknown, any>>('/session',{
       
        params: {
            user: id,
            nonce: nonce,
            auto: null,
            stat: stats(),
          },
       })

       if(response.data.hasOwnProperty('error') || response.data.length === 0){
             setAuthorized(false);
             setLoading(false);
             history.replace('/connexion')
        
       }
       else if(response.data.hasOwnProperty('src') && email){
        //@ts-ignore next-line
            if(response.data?.src == 'log'){
                dispatch({type:'CHECK_AUTH',payload:response.data})
                setAuthorized(true);
                setLoading(false);
                //const array = ["/connexion","/nouveau-mot-de-passe","/nouveau-profil"];
                //if(array.includes(location.pathname)) history.push('/') 
            }
            else{
                
                setAuthorized(false);
                setLoading(false);
                history.replace('/connexion')
            }
}
        else{
            setAuthorized(false);
            setLoading(false); 
        }
       }catch(error){
            setLoading(false)
            setAuthorized(false)
        }
    }

    useEffect(()=>{
        fetchData();
        return ()=> {setLoading(false); setAuthorized(false)}
    },
    [id])


    const contextData = {
        setAuthorized:setAuthorized,
        authorized:authorized,
        loading:loading
    }

    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )

}


function useAuth(){
    const context = useContext(AuthContext);
    if(context === undefined){
        throw new Error('useAuth must be used widthin a AuthProvider')
    }
    return context
}

export {AuthContext,useAuth}