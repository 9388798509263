

const RocketIcon = ({fill,width,height}) => (
    <svg    viewBox="0 0 24 24" width={width} height={height} xmlns="http://www.w3.org/2000/svg" >
   
   <path
      d="M13.057 7.431a2.5 2.5 0 1 1 3.536 3.536 2.5 2.5 0 0 1-3.536-3.536Zm2.475 1.06a1 1 0 1 0-1.414 1.415 1 1 0 0 0 1.414-1.414Z"
      fill={fill}
    />
    <path
      d="M21.509 4.323a2.75 2.75 0 0 0-1.811-1.81l-.663-.206a6.75 6.75 0 0 0-6.773 1.674l-.996.995a3.498 3.498 0 0 0-4.57.327L5.455 6.546a.75.75 0 0 0 0 1.06l1.591 1.591-.18.18a1.75 1.75 0 0 0 0 2.475l.496.495-1.396.796a.75.75 0 0 0-.158 1.182l3.889 3.89a.75.75 0 0 0 1.181-.159l.798-1.395.497.497a1.75 1.75 0 0 0 2.475 0l.177-.176 1.59 1.59a.75.75 0 0 0 1.06 0l1.242-1.243a3.497 3.497 0 0 0 .328-4.568l.998-.998a6.75 6.75 0 0 0 1.673-6.776l-.206-.664Zm-2.256-.378c.393.122.701.43.823.823l.207.664a5.25 5.25 0 0 1-1.302 5.27l-5.395 5.396a.25.25 0 0 1-.353 0L7.926 10.79a.25.25 0 0 1 0-.353l5.396-5.397a5.25 5.25 0 0 1 5.269-1.301l.662.205Zm-1.289 9.896c.453.766.35 1.769-.308 2.427l-.712.712-1.06-1.059 2.08-2.08ZM7.758 6.364a1.998 1.998 0 0 1 2.428-.308l-2.08 2.08-1.06-1.06.712-.712Zm2.818 9.198-.514.897-2.5-2.5.898-.512 2.116 2.115ZM6.69 18.395a.75.75 0 0 0-1.06-1.061l-2.476 2.475a.75.75 0 0 0 1.061 1.06l2.475-2.474ZM4.745 15.39a.75.75 0 0 1 0 1.06l-1.06 1.06a.75.75 0 1 1-1.061-1.06l1.06-1.06a.75.75 0 0 1 1.061 0ZM8.632 20.341a.75.75 0 1 0-1.06-1.06l-1.059 1.058a.75.75 0 0 0 1.06 1.06l1.06-1.058Z"
      fill={fill}
    />
    </svg>
  )
  
  export default RocketIcon
  