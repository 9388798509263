import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { useMediaQuery } from "react-responsive";
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.locale('fr');
dayjs().locale('fr').format()

type CellDateProps = {
    value:string
}

export default function CellDate({value}){
    const isMobile = useMediaQuery({ query: '(max-width:768px)' })

    return(
        <span>{dayjs(value).format(`DD/MM/${isMobile ? 'YY' : 'YYYY'}`)}</span>
    )
}