// transformFactory.js
import { convertNodeToElement } from 'react-html-parser';

export  function transformFactory(styles) {
  return function transform(node) {
    if (node.type === 'tag') {
      let classNames = node.attribs.class?.split(' ');
      if (!classNames) return;

      const className = classNames.reduce((acc, cur) => {
        if (styles[cur]) {
          return acc ? `${acc} ${styles[cur]}` : styles[cur];
        }

        return acc;
      }, "");

      if (className) {
        node.attribs.class = className;
      }
    }

    return convertNodeToElement(node, 0, transform);
  };
}