import { animated,useSpring } from 'react-spring';
import BlockAuth from '../../components/block-page/block-auth';
import EasyButton from '../../easy-ui/button';
import styles from './Welcome.module.scss';
import {useHistory,useLocation} from 'react-router-dom';

export default function Welcome(){
    const history = useHistory();
    const { state } = useLocation();
    function goToFirstConnection(){
        
        history.push(`/nouveau-mot-de-passe`)
    }


    /**
     * Animation Hand
     */
    const springHand = useSpring({
        from :{transform:'translateY(0%)'},
        to:[{
            transform:'translateY(50%)'
        },{
            transform:'translateY(0%)'
        }],
        config:{duration:1000},
        loop:true
    })


    
    return(
        <BlockAuth title={state && state.user && 'Bienvenue !'}  subtitle={""} loading={false}>
            {state && state.user?
            <div className={styles["first-connection-container"]}>
                <h2>{`Bonjour ${state.user?.prenom} ${state.user?.nom}`}</h2>
                <div className={styles["sentence-first-connection"]}>
                    <span>Afin de vous connecter à l'application, merci de cliquer sur le bouton valider pour renseigner votre mot de passe.</span>
                    <animated.span style={springHand} className={styles["hand-first-connexion"]}>👇</animated.span>
                    
                </div>
                <div className={styles["action-first-connection"]}><EasyButton onClick={goToFirstConnection} type="primary" name="text" text={"Valider"}/></div>
            </div>
            :
            <div className={styles["error-welcome-container"]}>
                <div className={styles["error-first-connecion"]}>Vous n'êtes pas autorisé à accèder à cette page</div>
                <div className={styles["action-first-connection"]}><EasyButton onClick={()=>history.replace('/connexion')} type="primary" name="text" text={"Page de connexion"}/></div>
            </div>
            }
         </BlockAuth>
    )
}