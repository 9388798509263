
import axios from 'axios'
import config from '../index'


export const source = axios.CancelToken.source();
export default axios.create({
  baseURL: config.apis.pci.baseURL + config.apis.pci.version,
  // headers: { "Access-Control-Allow-Origin": "*" },
})

