import React from 'react';

export const NavbarSecurite = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.666" height="17.937" viewBox="0 0 13.666 17.937">
      <path
        id="Icon_material-lock-open"
        data-name="Icon material-lock-open"
        d="M12.833,15.166a1.708,1.708,0,1,0-1.708-1.708A1.713,1.713,0,0,0,12.833,15.166Zm5.125-7.687H17.1V5.771a4.271,4.271,0,1,0-8.541,0h1.623a2.648,2.648,0,1,1,5.3,0V7.479H7.708A1.713,1.713,0,0,0,6,9.187v8.541a1.713,1.713,0,0,0,1.708,1.708h10.25a1.713,1.713,0,0,0,1.708-1.708V9.187A1.713,1.713,0,0,0,17.958,7.479Zm0,10.25H7.708V9.187h10.25Z"
        transform="translate(-6 -1.5)"
      />
    </svg>
  );
};
