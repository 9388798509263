import React, { Component, Fragment } from 'react'

// LIBRAIRIES
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { withLastLocation } from 'react-router-last-location'
// COMPONENTS
import Swipe from './Swipe'
import Loader from './Loader'
import { withRouter } from 'react-router-dom'
// FUNCTIONS
import { renderIcon } from '../functions/renderIcon'
import Icon from '../icons'
// ICONS
import { Bar } from '../icons/Bar'
import { LongArrowRight } from '../icons/LongArrowRight'

import { NavbarLogout } from '../icons/NavbarLogout'
import { NavbarPosts } from '../icons/NavbarPosts'

import { ChevronRight } from '../icons/ChevronRight'
import { Star } from '../icons/Star'
import { Cross } from '../icons/Cross'
import { Linkedin } from '../icons/Linkedin'
import { Instagram } from '../icons/Instagram'
import { Facebook } from '../icons/Facebook'
import Logo from './logo'
// ACTIONS
import { trackingExternalLink } from '../redux/actions/pci'

// CONFIG CLIENT
import config from '../config'
import SearchBar from './search-bar/SearchBar'
import Emitter from '../utils/event'

function isAvailableMenuStart(last_location, location, direction) {
  return (
    (last_location === null ||
      last_location.pathname == '/connexion' ||
      last_location.pathname == '/validation-profil') &&
    location.pathname === '/' &&
    process.env.REACT_APP_CLIENT === 'afdf' &&
    (direction === 'less' ? window.innerWidth <= 1200 : window.innerWidth >= 1200)
  )
}

class Navbar extends Component {
  state = { opened: isAvailableMenuStart(this.props.lastLocation, this.props.location, 'less'), redirect: false }

  componentDidMount() {
    if (process.env.REACT_APP_CLIENT === 'afdf') {
      window.addEventListener('resize', this.starterMenuForAfdf)

      if (isAvailableMenuStart(this.props.lastLocation, this.props.location, 'less')) {
        document.querySelector('body').style.overflow = 'hidden'
      }
    } else {
      window.addEventListener('resize', this.updateOverflowBody)
    }
  }

  updateOverflowBody = () => {
    if (window.innerWidth >= 1200 && this.state.opened == true) {
      document.querySelector('body').style.overflow = 'auto'
    }
    if (window.innerWidth <= 1200 && this.state.opened == true) {
      document.querySelector('body').style.overflow = 'hidden'
    }
  }

  componentWillUnmount() {
    document.querySelector('body').style.overflow = 'auto'
    if (process.env.REACT_APP_CLIENT === 'afdf') {
      window.removeEventListener('resize', this.starterMenuForAfdf)
    } else {
      window.removeEventListener('resize', this.updateOverflowBody)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.navbar_error && this.props.navbar_error) {
      this.setState({ redirect: '/deconnexion' })
    }
  }

  closeMenu = () => {
    this.setState({ opened: false })
  }

  starterMenuForAfdf = (e) => {
    if (isAvailableMenuStart(this.props.lastLocation, this.props.location, 'more')) {
      document.querySelector('body').style.overflow = 'auto'
    }
    if (isAvailableMenuStart(this.props.lastLocation, this.props.location, 'less')) {
      document.querySelector('body').style.overflow = 'hidden'
    }
  }

  // AFFICHAGE OU NON DU MENU AVEC LA LISTE DES RUBRIQUES
  toggleNavbar = () => {
    if (this.state.opened) {
      document.querySelector('body').style.overflow = 'auto'
    } else {
      document.querySelector('body').style.overflow = 'hidden'
    }
    this.setState({ opened: !this.state.opened })
  }
  // FIN AFFICHAGE OU NON DU MENU AVEC LA LISTE DES RUBRIQUES

  // GESTION DE L'ITEM AFFICHE DANS LE COIN GAUCHE DE LA NAVBAR TOP
  leftShortLink() {
    if (this.state.opened) {
      return (
        <div className="return" onClick={this.toggleNavbar}>
          <LongArrowRight />
        </div>
      )
    } else if (this.props.return) {
      return (
        <div className="return" onClick={this.props.return}>
          <LongArrowRight />
        </div>
      )
    } else {
      return (
        <button className="toggle" onClick={this.toggleNavbar}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      )
    }
  }
  // FIN GESTION DE L'ITEM AFFICHE DANS LE COIN GAUCHE DE LA NAVBAR TOP

  // GESTION DE L'ITEM AFFICHE DANS LE COIN DROIT DE LA NAVBAR TOP
  rightShortLink() {
    const PublicationNotAllowed =
      this.props.authentication.profil == 'adherent' &&
      (config.name == 'Mondial Piscine App' || config.name == 'Mon France Matériaux')

    if (this.props.contact) {
      const active = this.props.favorite ? 'active' : 'inactive'
      return (
        <button className={`star ${active}`} onClick={this.props.toggleFavorite}>
          <Star />
        </button>
      )
    } else if (this.props.admin && !this.state.opened) {
      return (
        <button className="close" onClick={() => this.props.leave()}>
          <Cross />
        </button>
      )
    } else if (this.props.authentication.profil && !this.state.opened && !PublicationNotAllowed) {
      if (
        window.innerWidth > 768 &&
        this.props.authentication.profil !== 'adherent' &&
        this.props.authentication.profil !== 'partenaire'
      ) {
        return (
          <a target="_blank" rel="noopener noreferrer" href={config.admin_url} className="add-post">
            <span className="mb">+</span>
            <span className="dk">Publier</span>
          </a>
        )
      } else {
        return (
          <Link to="/nouvelle-publication" className="add-post">
            <span className="mb">+</span>
            <span className="dk">Publier</span>
          </Link>
        )
      }
    }
    return null
  }

  // FIN GESTION DE L'ITEM AFFICHE DANS LE COIN DROIT DE LA NAVBAR TOP
  logo = () => {
    return (
      <div className="dk-logo">
        <div className={'left-side-header-app'}></div>
        <div className={'container-logo-app'}>
          <Logo className="logo" type="color" />
        </div>
      </div>
    )
  }

  // NAVBAR EN HAUT DE LA PAGE
  navbarTop(principal, id) {
    const { title, admin } = this.props
    const { opened } = this.state

    // console.log('props:' + JSON.stringify(this.props));

    return (
      <div className="navbar-top">
        <div className="tab">
          <div className="svg svg-left">
            <Bar
              id={id}
              top_color={
                opened && !principal
                  ? config.colors.navbar.top_burger_active
                  : admin
                  ? config.colors.navbar.top_admin
                  : config.colors.navbar.top
              }
              bottom_color={
                opened && !principal
                  ? config.colors.navbar.bottom_burger_active
                  : admin
                  ? config.colors.navbar.bottom_admin
                  : config.colors.navbar.bottom
              }
            />
          </div>
          <div className="title">
            <h1>{opened && !principal ? 'Menu' : title}</h1>

            {/* Versionning 2021-2022 ELasmezas */}
            <div className="version">
              {/* Environment (React): {process.env.REACT_APP_ENV}
              Environment (Node): {process.env.NODE_ENV} mP © Ver  */}
              {/* User profil type */}
              {/* {this.props.authentication.profil?.slice(0, 1).toUpperCase()} - {process.env.REACT_APP_VERSION} */}
            </div>
          </div>
          <div className="svg svg-right">
            <Bar
              id={id}
              top_color={
                opened && !principal
                  ? config.colors.navbar.top_burger_active
                  : admin
                  ? config.colors.navbar.top_admin
                  : config.colors.navbar.top
              }
              bottom_color={
                opened && !principal
                  ? config.colors.navbar.bottom_burger_active
                  : admin
                  ? config.colors.navbar.bottom_admin
                  : config.colors.navbar.bottom
              }
            />
          </div>
        </div>
        {this.leftShortLink()}
        {this.logo()}
        {this.rightShortLink()}
      </div>
    )
  }
  // FIN NAVBAR EN HAUT DE LA PAGE

  // ON FERME LE MENU GAUCHE AU SWIPE (QUAND IL EST OUVERT)
  onSwipeLeft = (diffX) => {
    if (diffX > 10) {
      this.setState({ opened: false })
    }
  }
  // ON FERME LE MENU GAUCHE AU SWIPE (QUAND IL EST OUVERT)

  renderNavbar = () => {
    if (this.props.navbar_items.length) {
      return this.props.navbar_items.map((item, index) => {
        return this.renderNavbarItem(item, index)
      })
    }
    return null
  }

  trackLink = (item) => {
    if (item.type === 'perso_link' || item.external) {
      this.props.trackingExternalLink(this.props.authentication.id, this.props.authentication.nonce, item.url)
    }
    return null
  }

  renderNavbarItem = (item, index) => {
    if (item.type === 'titre') {
      return (
        <p key={index} className="title">
          {item.nom}
        </p>
      )
    } else {
      if (item.menu) {
        return (
          // <MegaMenu item={item} renderIcon={this.renderIcon} key={index} />
          <Link
            key={index}
            className={`item ${`/menu/${item.id}` === this.props.location.pathname ? 'selected' : ''}`}
            to={{
              pathname: `/menu/${item.id}`,
              state: { prev_path: window.location.pathname },
            }}
            onClick={() => {
              this.trackLink(item)
              this.setState({ opened: false })
            }}
          >
            <Icon name={item.icon} />
            {item.nom}
            <div className="right">
              <ChevronRight />
            </div>
          </Link>
        )
      } else {
        if (item.type === 'external_link' || item.type === 'perso_link') {
          if (item.nom !== '') {
            if (item.type === 'perso_link' || item.external) {
              return (
                <a
                  key={index}
                  href={item.url}
                  target={item.type === 'perso_link' || item.external ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                  onClick={() => {
                    this.trackLink(item)
                    this.setState({ opened: false })
                  }}
                >
                  <Icon name={item.icon} />
                  {item.nom}
                  <div className="right">
                    <ChevronRight />
                  </div>
                </a>
              )
            } else {
              return (
                <Link
                  key={index}
                  to={item.url}
                  onClick={() => {
                    this.trackLink(item)
                    this.setState({ opened: false })
                  }}
                >
                  <Icon name={item.icon} />
                  {item.nom}
                  {item.new && parseInt(item.new, 10) > 0 ? (
                    <span>
                      <span className="new">{item.slug === 'leads' ? '!' : item.new}</span>
                    </span>
                  ) : null}
                  <div className="right">
                    <ChevronRight />
                  </div>
                </Link>
              )
            }
          } else {
            return null
          }
        } else {
          const to =
            item.screen && item.screen === 'std' && item.slug !== 'agenda'
              ? `/actualites/${item.id}`
              : item.screen === 'pic'
              ? `/photos/${item.id}`
              : `/${item.slug}`

          return (
            <Link
              onClick={() => this.setState({ opened: false })}
              key={index}
              className={`item ${to === this.props.location.pathname ? 'selected' : ''}`}
              to={to}
            >
              <Icon name={item.icon} />
              <span>
                {item.nom}
                {item.new && parseInt(item.new, 10) > 0 ? <span className="new">{item.new}</span> : null}
              </span>
              <div className="right">
                <ChevronRight />
              </div>
            </Link>
          )
        }
      }
    }
  }

  getSocials = () => {
    if (this.props.navbar_socials) {
      return Object.entries(this.props.navbar_socials).map((item, index) => {
        return this.getSocial(item)
      })
    } else {
      return null
    }
  }

  getSocial = (item) => {
    if (item[1]) {
      return (
        <a
          key={item[0]}
          href={item[1]}
          target="_blank"
          rel="noopener noreferrer"
          className="socials--item"
          onClick={() =>
            this.props.trackingExternalLink(this.props.authentication.id, this.props.authentication.nonce, item[1])
          }
        >
          {this.getSocialIcon(item[0])}
        </a>
      )
    }
    return null
  }

  getSocialIcon = (social_network) => {
    switch (social_network) {
      case 'facebook':
      default:
        return <Facebook />
      case 'linkedin':
        return <Linkedin />
      case 'instagram':
        return <Instagram />
    }
  }

  render() {
    const { opened, redirect } = this.state
    const header_classname = opened ? 'active' : null
    if (redirect) {
      return <Redirect to={redirect} />
    }
    return (
      <Fragment>
        <SearchBar menu_active={opened} />
        <div id="header" className={header_classname}>
          {this.navbarTop(true, 'bara')}

          {!this.props.hide_navbar_left && (
            <div className="navbar-left">
              {!this.props.navbar_items.length && <Loader />}
              {window.innerWidth < 1200 && this.navbarTop(false, 'barb')}
              <Swipe onSwipeLeft={this.onSwipeLeft}>
                <div className="container">
                  {/* <section className="search">
                <form>
                  <div className="input-container">
                    <Search />
                    <input type="text" placeholder="Tapez votre recherche" />
                  </div>
                </form>
              </section> */}
                  {/* <section className="search">
                <form>
                  <div className="input-container">
                    <Search />
                    <input type="text" placeholder="Tapez votre recherche" />
                  </div>
                </form>
              </section> */}
                  {/*(config.name == "DevApp"  || config.name == "UGD APP") &&
                 <>
             <p className="title">New Feature en attente</p>
           
               <Link to="/adherents" className="deconnexion">
                  <NavbarPosts />
                  Liste adhérents
                  <div className="right">
                    <ChevronRight />
                  </div>
                </Link>

                <Link to="/partenaires" className="deconnexion">
                  <NavbarPosts />
                  Liste partenaires
                  <div className="right">
                    <ChevronRight />
                  </div>
                </Link>
                </>
            */}
                  {config.name == 'DevApp' && (
                    <>
                      <p className="title">New Feature en attente</p>

                      <Link to="/map-adherent" className="deconnexion">
                        <NavbarPosts />
                        Map adherent
                        <div className="right">
                          <ChevronRight />
                        </div>
                      </Link>
                    </>
                  )}
                  {this.renderNavbar()}

                  <p className="title empty"></p>
                  <Link to="/deconnexion" className="deconnexion">
                    <NavbarLogout />
                    Déconnexion
                    <div className="right">
                      <ChevronRight />
                    </div>
                  </Link>
                  {(this.props.navbar_socials?.facebook !== false ||
                    this.props.navbar_socials?.instagram !== false ||
                    this.props.navbar_socials?.linkedin !== false) && (
                    <div className="socials">
                      <div className="svg">
                        <Bar
                          id="social"
                          top_color={config.colors.navbar.social_top}
                          bottom_color={config.colors.navbar.social_bottom}
                        />
                      </div>
                      <div className="socials--header"></div>
                      <div className="svg">
                        <Bar
                          id="social"
                          top_color={config.colors.navbar.social_top}
                          bottom_color={config.colors.navbar.social_bottom}
                        />
                      </div>
                      <div className="socials--container">{this.getSocials()}</div>
                    </div>
                  )}
                </div>
              </Swipe>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.auth.authentication,
    navbar_items: state.navbar.items,
    navbar_socials: state.navbar.socials,
    navbar_error: state.navbar.error,
    new_navbar_items: state.navbar.updated,
  }
}

export default withRouter(
  withLastLocation(
    connect(mapStateToProps, {
      trackingExternalLink,
    })(Navbar)
  )
)
