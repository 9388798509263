import React from 'react';

export const MondialCirculaires = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 17.3 15.7"
      style={{ enableBackground: 'new 0 0 17.3 15.7' }}
    >
      <path
        d="M16.5,0h-4.7c-1.3,0-2.4,0.6-3.2,1.6C7.9,0.6,6.8,0,5.5,0H0.8C0.3,0,0,0.3,0,0.7v11.8c0,0.4,0.3,0.8,0.8,0.8h5.5
   c0.9,0,1.6,0.7,1.6,1.6v0c0,0.4,0.3,0.8,0.7,0.8h0c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0
   c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0-0.9,0.7-1.6,1.6-1.6h5.5c0.4,0,0.8-0.3,0.8-0.8V0.7
   C17.3,0.3,16.9,0,16.5,0z M6.3,11.8H1.5V1.5h4c1.3,0,2.4,1.1,2.4,2.4v8.4C7.4,12,6.9,11.8,6.3,11.8z M15.8,11.8H11
   c-0.6,0-1.1,0.2-1.6,0.5V3.9c0-1.3,1.1-2.4,2.4-2.4h4V11.8z"
      />
    </svg>
  );
};
