import React, { ChangeEvent, CSSProperties, forwardRef, Fragment, useEffect, useRef, useState } from 'react'
import Icon from '../../../icons'
import { Comments } from '../postPictureSlice'
import styles from './PostPictureMobile.module.scss'
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom'
import { useClickAway } from 'react-use'
import CarousselSwiper from '../../../components/caroussel-swiper'
import VisioPicture from '../../../components/visio-picture'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useAppSelector } from '../../../redux/hooks'
import LikeList from '../../post-standard/like-list/LikeList'
import { transformFactory } from '../../../utils/html_helpers';
import { useLastLocation } from 'react-router-last-location';
type PostPictureMobileProps = {
  texte: string
  galerie: Array<{ h: number; w: number; url: string }>
  breadcrumb: Array<{ id: number; slug: string; nom: string }>
  titre: string
  auteur: { id: number | string; nom: string }
  date: string
  handleLife: () => void
  likes: {
    count: number
    me: boolean
    list: { [key: string]: { avatar: string | boolean; firstname: string; lastname: string } }
  }
  coms: Comments
  handleComment: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onSendComment: () => void
  comment: string
}

export default forwardRef(function PostPictureMobile(
  {
    texte,
    galerie,
    breadcrumb,
    titre,
    auteur,
    date,
    handleLife,
    likes,
    coms,
    comment,
    handleComment,
    onSendComment,
  }: PostPictureMobileProps,
  ref: any
) {
  const history = useHistory()
  const [imgFullScreen, setImagFullScreen] = useState<false | number>(false)
  const [showLiker, setShowLiker] = useState(false)
  const refVisio = useRef<HTMLElement>(null)
  const profil = useAppSelector((state) => state.auth.authentication.profil)
  const isModerateur = profil !== 'adherent'
  const transform = transformFactory(styles);
  const lastLocation = useLastLocation();

  useClickAway(ref, () => {
    if (ref.current && refVisio.current == null && !showLiker) {
      goBack()
    }
  })

  function onShowMedia(val: number) {
    setImagFullScreen(val)
  }
  useEffect(() => {
    disableBodyScroll(ref.current)
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  function onViewLiker() {
    setShowLiker(true)
  }


  function goBack(){
    if(lastLocation && lastLocation.pathname){
      history.goBack();
    }
    else if(breadcrumb && breadcrumb.length > 0 && breadcrumb[breadcrumb.length - 2].id){
      history.push(`/photos/${breadcrumb[0].id}`)
    }
    else{
      history.replace('/')
    }
  }

  const isIphone = /iPhone/i.test(navigator.userAgent)
  const viewPortIsDown = window.visualViewport && window.visualViewport.pageTop !== 0

  const styledComment = {
    '--comment-wrapper-border-bottom': '1rem',
    '--comment-wrapper-height': '4rem',
    '--comment-wrapper-position': 'fixed',
  } as CSSProperties

  const styledModal = {
    '--modal-padding-bottom':
      /iPhone|iPod/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent) ? '4rem' : '4rem',
  } as CSSProperties

  const number_of_comments = coms && coms.length > 0 ? coms.filter((i) => i.status == 'approved').length : '0'
  return (
    <Fragment>
      <div style={styledModal} ref={ref} className={styles['modal-container']}>
        <div className={styles['header-life']}>
          <button onClick={goBack} className={styles['back-life']}>
            <Icon name="chevronleft" />
          </button>
          <div className={styles['title-post']}>{breadcrumb[0].nom}</div>
          <button onClick={() => history.push('/')} className={styles['back-life']}>
            <Icon name="house" />
          </button>
        </div>
        <div className={styles['content-life']}>
          <div className={styles['galerie-life']}>
            {galerie.length > 1 ? (
              <CarousselSwiper type="cover" medias={galerie.map((i) => i.url)} onClick={onShowMedia} />
            ) : (
              <img onClick={() => onShowMedia(0)} className={styles['image-galerie-life']} src={galerie[0].url} />
            )}
          </div>
          <div className={styles['content']}>
            {/*<div className={styles["life-categorie"]}>{breadcrumb[0].nom}</div>*/}
            <div className={styles['title-life']}>{titre}</div>
            <div className={styles['description-life']}>{ReactHtmlParser(texte,{transform})}</div>
            {auteur?.nom ? (
              <div className={styles['author-life']}>
                <span className={styles['author-life-prefix']}>Publié par </span>
                <span className={styles['author-life-name']}>{auteur.nom}</span>
              </div>
            ) : null}
            {date ? (
              <div className={styles['date-life']}>
                <span className={styles['date-life-prefix']}>Le </span>
                <span className={styles['date-life-datetime']}>{date}</span>
              </div>
            ) : null}
            {(likes || coms) && (
              <div className={styles['actions-life']}>
                {likes && (
                  <div onClick={handleLife} className={styles['like-life']}>
                    <Icon name={likes?.me ? 'like-plain' : 'like'} />
                    <span>{likes?.count}</span>
                  </div>
                )}
                {likes && coms && <div className={styles['actions-separation']} />}
                {coms && <div className={styles['comments-life']}>
                  <Icon name="comments" />
                  <span>{number_of_comments}</span>
                </div>
                }
              </div>
            )}
            {likes && Object.keys(likes?.list).length > 0 ? (
              <div className={styles['likes-persons-life']}>
                <span>Aimé par </span>
                {Object.keys(likes?.list)
                  .slice(0, 2)
                  .map((item) => {
                    return (
                      <span onClick={() => history.push(`/user/${item}`)} className={styles['user-like-life']}>
                        <span>{likes.list[item].firstname}</span>
                        <span>{likes.list[item].lastname}</span>
                      </span>
                    )
                  })}
                {likes.count >= 3 ? (
                  <span className={styles['more-viewer']} onClick={onViewLiker}>{` et par ${
                    likes.count - 2
                  } autre(s) personne(s)`}</span>
                ) : null}
              </div>
            ) : null}
            {coms && coms.length > 0 ? (
              <div className={styles['comments-container']}>
                {coms.map((com) => {
                  const styled = {
                    '--border-bottom-comment': com.status === 'hold' ? '0px' : '0.4rem',
                  } as CSSProperties
                  return (
                    <div>
                      <div style={styled} className={styles['comment-container-life']}>
                        <div className={styles['comment-user-life']}>
                          <span>{com.user}</span>
                        </div>
                        <div className={styles['comment-date-life']}>
                          <span>Le {com?.date}</span>
                        </div>
                        <div className={styles['comment-content-life']}>{com.content}</div>
                      </div>
                      {com.status == 'hold' ? (
                        <div className={styles['comment-statut']}>
                          {isModerateur ? 'A modérer' : 'En cours de modération'}
                        </div>
                      ) : null}
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div>
        </div>

        {coms!==undefined && <div style={styledComment} className={styles['comment-editor-wrapper-life']}>
          <textarea value={comment} onChange={handleComment} placeholder="Ajouter un commentaire" />
          <button onClick={onSendComment} className={styles['button-send-comments-life']}>
            <Icon name="send" />
          </button>
        </div>
        }
      </div>
   
      {typeof imgFullScreen === 'number' ? (
        <VisioPicture
          ref={refVisio}
          onClose={() => setImagFullScreen(false)}
          media={galerie[imgFullScreen as number].url}
        />
      ) : null}
      {showLiker && <LikeList list={likes.list} onHide={() => setShowLiker(false)} />}
    </Fragment>
  )
})
