import styles from './ListContacts.module.scss';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { partnerListByCategorie,nameOfGroup,partnerListByCategories} from './selector';
import { ArrowLeft } from '../../../icons/ArrowLeft';
import {useHistory} from 'react-router-dom';
import { Star } from '../../../icons/Star';
export default function ListContacts({categorie}){
    console.log(categorie)

const history = useHistory();

const list_partenaires = useSelector(state=>partnerListByCategories(state,categorie))
const groups = useSelector(state=>state.groups);



function goToPageEntreprise(id){
    if(categorie == "partner")
    history.push({pathname:`/entreprise-partner/${id}`})
  else return
}

function goToProfil(partner){
    history.push({pathname:`/user-partner/${partner}`})
}

   return(
        <div className={styles["container-list"]}>
                  {
           list_partenaires.length > 0 ? _.orderBy(list_partenaires,'groupe').map((groupe,key)=>{
             
            return(
                <div key={key}>
                <div onClick={(e)=>goToPageEntreprise(groupe.id)} style={{cursor:categorie == "partner" ? 'pointer':'normal'}} className={styles["header-list"]}>
                 <div className={styles["title-header"]}>{groupe.groupe}</div>
                   {categorie == "partner" && <div className={styles["button-header"]} >
                        <ArrowLeft/>
                    </div>
                    }
                </div>
                <div className={styles["container"]}>{
                            groupe.data.map((partner,keys)=>{
                             if(partner.id !== "ghost"){
                                return(
                                    <div key={keys} onClick={(e)=>goToProfil(partner.id)} className={styles["container-item"]}>
                                        <div className={styles["item-list"]} key={keys}>
                                            {partner.favori && <div style={{position:'absolute',marginLeft:'-20px'}}> <Star/> </div> }
                                            <div className={styles["container-content"]}>
                                            <div className={styles["corporation"]}>{partner.name}</div>
                                            <div className={styles["job"]}>{`${partner.additif}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                             }
                            })    
                        }</div>
                </div>  
            )
        })
        :
        <div className={styles["error-resultat"]}>Il n'y a pas de résultat pour {groups.find(item=>item.id === categorie).name}</div>
        }

        </div>
    )
}