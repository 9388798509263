import React from 'react';

export const NavbarProfile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g>
        <path d="M10 3.327a2.562 2.562 0 1 1 0 5.124 2.562 2.562 0 0 1 0-5.124m0 6.93a4.359 4.359 0 1 0 0-8.718 4.359 4.359 0 0 0 0 8.717M5.458 17.084c.387-2.581 2.319-4.425 4.799-4.425 2.479 0 4.42 1.844 4.797 4.425H5.458zm4.799-6.315c-3.738 0-6.667 3.186-6.667 7.26.001.522.403.944.898.945h11.537c.495-.001.897-.423.898-.945 0-4.074-2.93-7.26-6.666-7.26z" />
      </g>
    </svg>
  );
};
