export default function Boutique(props){
    return(
<svg version="1.1" id="Calque_1" x="0px" y="0px"
	 viewBox="0 0 18.6 16.6" >
<path id="Icon_metro-shop" d="M5.3,5.4L6.1,0H1.9L0.1,4.7C0,4.8,0,5,0,5.2c0.2,1.3,1.4,2.2,2.7,2.1C3.9,7.4,5,6.6,5.3,5.4L5.3,5.4z
	 M9.3,7.2c1.3,0.1,2.5-0.8,2.7-2.1c0,0,0-0.1,0-0.1L11.4,0H7.2L6.7,5c0,0,0,0.1,0,0.1C6.8,6.5,8,7.4,9.3,7.2L9.3,7.2z M14.5,8.3v4.1
	H4.1V8.3c-0.5,0.2-1,0.3-1.5,0.3c-0.2,0-0.4,0-0.6-0.1v6.6c0,0.8,0.6,1.4,1.4,1.4h11.6c0.8,0,1.4-0.6,1.4-1.4V8.5
	c-0.2,0-0.4,0-0.6,0.1C15.5,8.6,15,8.5,14.5,8.3z M18.6,4.7L16.8,0h-4.3l0.8,5.4c0.3,1.2,1.4,1.9,2.6,1.8c1.3,0.1,2.5-0.8,2.7-2.1
	C18.6,5,18.6,4.8,18.6,4.7L18.6,4.7z"/>
</svg>
    )
}