import { CSSProperties, forwardRef, Fragment, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ImageAlone from '../../../../icons/ImageAlone';
import MultiImageIcon from '../../../../icons/MultiImageIcon';
import useChangeSizeStyle from '../../hooks/useChangeSizeStyle';
import { useMeasureImage } from '../../hooks/useMeasureImage';
import VisioMedia from '../../visio-media';
import VisioPicture from '../../visio-media/visio-picture';
import styles from './ContainerPicture.module.scss';


type ContainerPictureProps = {
    medias:Array<string>,
    type:string
}

function ContainerPicture({medias,type}:ContainerPictureProps,ref){
   const mobile = useMediaQuery({ query: '(max-width:760px )' })
   const {size,isLoad} =useMeasureImage(medias[0]);
   const styled = useChangeSizeStyle(size,isLoad,mobile);
   const [hover,setHover] = useState<boolean>(false);
   const [showVisio,setShowVisio] = useState<boolean>(false)

   const overlayStyles = {
    '--overlay-bg':hover ? 'rgba(0,0,0,0.5)' : 'transparent',
   
} as CSSProperties
    if(medias[0])
        return(
            <Fragment>
                <div 
                ref={ref}
                style={{...styled,...overlayStyles}}
                onClick={()=>setShowVisio(true)}
                onMouseEnter={()=>setHover(true)} 
                onMouseLeave={()=>setHover(false)} 
                className={styles["container-picture"]}
            >
                {isLoad && <img src={medias[0]} alt='instagram-picture'/> }
                {/*<div  className={styles["shadow-block-container"]}>
                       <div className={styles["logo-container"]}>{medias.length > 1 ? <MultiImageIcon/> : <ImageAlone/>}</div>
                    </div>*/}
            </div>
            {showVisio ? <VisioMedia medias={medias} type={type} onClose={()=>setShowVisio(false)}/> : null}
        </Fragment>
        )
    return null
}

export default forwardRef(ContainerPicture)
