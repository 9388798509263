import { forwardRef } from 'react';
import styles from './ContainerWithoutMedia.module.scss';

 function ContainerWithoutMedia({...props},ref){
    return(
        <div ref={ref} className={styles['container-without-media']}/>
    )
}


export default forwardRef(ContainerWithoutMedia)