import React from 'react';

export const MondialDocumentsTechniques = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 19 18"
      style={{ enableBackground: 'new 0 0 19 18' }}
    >
      <g id="Icon_feather-settings" transform="translate(0.65 0.65)">
        <g>
          <path
            d="M8.8,11.6c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3C12.1,10.1,10.6,11.6,8.8,11.6z M8.8,6.3
       c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2C10.8,7.2,9.9,6.3,8.8,6.3z"
          />
        </g>
        <g>
          <path
            d="M8.9,17.3c0,0-0.1,0-0.1,0c-1.1-0.1-2-1-2.1-2.1c0,0,0-0.1,0-0.1c0-0.2-0.2-0.4-0.4-0.5c-0.3-0.1-0.6-0.1-0.8,0.1
       c-0.9,0.9-2.3,0.9-3.2,0c-0.9-0.8-0.9-2.1-0.1-3c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.5,0.2-0.7c-0.1-0.2-0.3-0.4-0.6-0.4
       c0,0,0,0,0,0H1.6c-1.2,0.1-2.2-0.8-2.2-2c-0.1-1.2,0.8-2.2,2-2.3c0.1,0,0.2,0,0.2,0c0.3,0,0.5-0.2,0.6-0.4
       c0.1-0.2,0.1-0.5-0.1-0.7C1.3,4.3,1.2,3,2,2.1c0,0,0.1-0.1,0.1-0.1C3,1.2,4.4,1.2,5.3,2c0.3,0.2,0.6,0.3,0.8,0.2
       c0.1,0,0.1,0,0.2,0C6.4,2,6.6,1.8,6.6,1.6V1.5C6.6,0.2,7.7-0.7,9-0.6c1.1,0.1,2,1,2.1,2.1c0,0,0,0.1,0,0.1c0,0.2,0.2,0.4,0.4,0.5
       C11.7,2.2,12,2.2,12.3,2c0.9-0.9,2.3-0.9,3.2,0c0.4,0.4,0.7,0.9,0.7,1.5c0,0.6-0.2,1.1-0.6,1.5c0,0-0.1,0.1-0.1,0.1
       c-0.2,0.2-0.3,0.5-0.2,0.7c0,0,0,0.1,0,0.1c0.1,0.2,0.4,0.3,0.6,0.3H16c1.2-0.1,2.2,0.8,2.2,2c0,0.6-0.2,1.1-0.5,1.6
       c-0.4,0.4-0.9,0.7-1.5,0.7c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.3,0-0.5,0.1-0.6,0.3c0,0,0,0,0,0c-0.1,0.2-0.1,0.5,0.1,0.6
       c0.5,0.4,0.7,0.9,0.7,1.5s-0.2,1.1-0.6,1.5c0,0-0.1,0.1-0.1,0.1c-0.9,0.8-2.3,0.8-3.2,0c-0.3-0.2-0.6-0.3-0.8-0.2
       c-0.2,0.1-0.4,0.3-0.4,0.6l0,0.1C11.1,16.4,10.1,17.3,8.9,17.3z M5.9,13.2c0.3,0,0.5,0.1,0.8,0.2c0.7,0.2,1.2,0.9,1.3,1.7
       c0,0.5,0.4,0.9,0.9,1c0.5,0,1-0.4,1-0.9l0-0.1c0-0.8,0.5-1.5,1.2-1.7c0.7-0.3,1.5-0.2,2.1,0.4c0.4,0.4,1.1,0.4,1.4,0
       c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.6-0.6-0.8-1.4-0.5-2.1c0,0,0-0.1,0-0.1c0.3-0.7,1-1.1,1.8-1.1c0,0,0.1,0,0.1,0
       c0.3,0,0.5-0.1,0.7-0.3C16.9,8.7,17,8.5,17,8.3c0-0.5-0.4-0.8-0.9-0.8l-0.2,0c-0.8,0-1.5-0.4-1.8-1.1c0-0.1,0-0.1-0.1-0.2
       c-0.3-0.7-0.1-1.5,0.5-2L14.6,4c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.4-0.4-1-0.4-1.4,0c-0.6,0.6-1.5,0.7-2.2,0.4
       c-0.7-0.3-1.2-1-1.2-1.7c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-1,0.4-1,0.9l0,0.1c0,0.8-0.5,1.5-1.2,1.7c-0.1,0-0.1,0-0.2,0
       C5.8,3.7,5,3.5,4.5,3C4,2.6,3.4,2.6,3,3C2.7,3.3,2.7,3.9,3,4.2c0.6,0.6,0.8,1.5,0.5,2.2C3.2,7,2.5,7.5,1.7,7.5c0,0-0.1,0-0.1,0
       C1,7.5,0.6,8,0.7,8.4c0,0.5,0.4,0.8,0.9,0.8l0.2,0c0.7,0,1.5,0.4,1.8,1.1c0.3,0.7,0.2,1.6-0.4,2.1L3,12.6c-0.3,0.3-0.3,0.8,0,1.1
       c0.4,0.4,1,0.4,1.4,0C4.9,13.4,5.4,13.2,5.9,13.2z"
          />
        </g>
      </g>
    </svg>
  );
};
