import { ChangeEventHandler, Fragment,useRef,useState,useEffect, CSSProperties,useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useMeasure } from 'react-use';
import useDidMountEffect from '../../../../hooks/useDidmountEffect';
import { Edit } from '../../../../icons/Edit';
import SmileyIcon from '../../../../icons/SmileyIcon';
import EmojiPicker from '../../../emoji-picker';
import styles from './TextEditable.module.scss';
import parse from 'html-react-parser';


type TextEditableProps = {
    sentence:string,
    onChange:(val:string)=>void
    width:string,
    className:string,
    isEdit:boolean
}
const MIN_TEXTAREA_HEIGHT = 32;
export default function TextEditable({sentence,onChange,width,className="",isEdit}:TextEditableProps){
  console.log(sentence)
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [toggle, setToggle] = useState(false);
    const [edit,setEdit] = useState(false);

    const mobile = useMediaQuery({ query: '(max-width:760px )' });



      useLayoutEffect(() => {

        if(inputRef.current){
        inputRef.current.style.height = "1px";
        inputRef.current.style.height = `${Math.max(
            inputRef.current.scrollHeight,
          MIN_TEXTAREA_HEIGHT
        )}px`;
        }
      }, [isEdit]);

      useDidMountEffect(() => {
        if(inputRef.current){
        inputRef.current.style.height = "1px";
        inputRef.current.style.height = `${Math.max(
            inputRef.current.scrollHeight,
          MIN_TEXTAREA_HEIGHT
        )}px`;
        }
      }, [sentence]);


        /**
         * FUNCTION FORMAT LINE BREAK FOR CONTENT HTML
         * @param text 
         * @returns 
         */

      function FormatText(text){
        let txt = text.replaceAll('\n','<br>');
        /*var matches = text.match(/\#\w\w+\s?/g);
         if(matches == null) return text
         console.log(matches)
         for(var i=0;i<matches.length;i++){
          
          txt=txt.replace(matches[i],"<strong>"+ matches[i] +"</strong>")
         }*/
         return txt ;
         
      }

   
    if(isEdit){
    return(
        <Fragment>
        <div style={{width:width+'px'}}  
            className={`${styles["content-editable-container"]} ${className}`}>
            <textarea 
                maxLength={2200}
                style={{'--min-height': `${MIN_TEXTAREA_HEIGHT}px`} as CSSProperties} 
                onChange={(e)=>onChange(e.target.value)} 
                className={styles["editor"]} 
                ref={inputRef} value={sentence}
                />
            <div 
                className={styles["action-editor"]}>
                {!mobile ? 
                <button  
                    onClick={() => setToggle(!toggle)}  
                    className={styles["button-smiley"]}><SmileyIcon/></button>
                    : 
                    <div/>}
                <div 
                    className={styles["count-sentence"]}>
                    {`${sentence.length ?? '0'}/2200`}
                </div>
            </div>
        </div>
        <EmojiPicker
            onChange={onChange}
            darkMode={false}
            ref={inputRef}
            visibility={toggle}
            setVisibility={setToggle}
            bottom={10}
            />
      
        </Fragment>
    )
  }
  else{
    return(
      <div className={styles["sentence-text"]}>
        <div>{parse(FormatText(sentence))}</div>
      </div>
    )
  }
}