import {useEffect,useState} from 'react';
import { fetchToken } from '../service/firebase';




export function useTokenFCM ():{tokenFCM:{token_fcm:string,isFound:boolean}}{

    const [tokenFCM,SetTokenFCM] = useState<{token_fcm:string,isFound:boolean}>({token_fcm:'no-notif',isFound:false});

    async function getTokenFcm(){
        try {
            const token = await fetchToken()
            if (token) {
               SetTokenFCM(token)  
                
            }
          } catch (error) {
              SetTokenFCM({token_fcm:'no-notif',isFound:true})
              console.log(error)
          }
    }

    useEffect(()=>{
        getTokenFcm()
    },[])

    return {tokenFCM}

}