import InputStandard from "./input-standard";
import InputPassword from "./input-password";


const Components = {
    standard:InputStandard,
    password:InputPassword
}


export default function EasyInput({categorie,...props}){
    
    let Component = Components[categorie];

    if(Component !==undefined) return <Component {...props} />
    return null

}