import { SET_CONTEXT } from '../../actions/pci/types'

const INITIAL_STATE = {
  opened_from_app: null,
}

const contextReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTEXT:
      return {
        ...state,
        opened_from_app: action.opened_from_app,
      }
    default:
      return state
  }
}

export default contextReducer
