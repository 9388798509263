import React from 'react';

export const ChevronRight = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width??'7px'} height={props.height??'14px'} viewBox="0 0 7 14">
      <path
        fill={props.fill??''}
        fillRule="evenodd"
        d="M5.396 6.61L.195 1.184a.715.715 0 0 1 0-.98c.26-.272.68-.272.94 0l5.67 5.916c.26.27.26.71 0 .98l-5.67 5.917a.649.649 0 0 1-.94 0 .715.715 0 0 1 0-.98l5.2-5.427z"
      />
    </svg>
  );
};
