import styles from './CardSocialPosts.module.scss'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/fr'
import Icon from '../../../icons'
import { CSSProperties, Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import OptionMenu from './options-menu'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers'
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.locale('fr')

type CardSocialPostProps = {
  item: {
    date: string
    gps: { entreprise: { gp: string } }
    id: number
    new: boolean
    photo: { url: string }
    rs: string
    slug: string
    status: string
    tags: string[]
    titre: string
    auteur: string
  }
  onDeleteAdminPublication: (id: string | number) => void
  onUpdateAdminPublication: (id: string | number) => void
}

export default function CardSocialPosts({
  item,
  onDeleteAdminPublication,
  onUpdateAdminPublication,
}: CardSocialPostProps) {
  const history = useHistory()

  const [options, setOptions] = useState<boolean>(false)

  const optionsRef = useRef<HTMLDivElement>(null)
  const cardPostRef = useRef<HTMLDivElement>(null)
  const user_profil = useSelector((state: RootState) => state.auth.authentication.profil)

  //OPTION MENU CLICK AWAY
  useClickAway(optionsRef, (event) => {
    const clickCard = cardPostRef.current && cardPostRef?.current?.contains(event.target as Node)
    if (!clickCard) {
      setOptions(false)
    }
  })

  function handlerClickCardPost(event: SyntheticEvent) {
    if (options) {
      setOptions(false)
    } else {
      history.push({ pathname: `/partage/${item.id}` })
    }
  }

  const styledCard = {
    '--padding-top-card': user_profil !== 'admin_reseau' && !item.date ? '1rem ' : '0rem',
  } as CSSProperties

  const styledRs = {
    '--background-banner-rs':
      item.rs === 'Instagram'
        ? 'linear-gradient(45deg,#f09433,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888)'
        : item.rs === 'Linkedin'
        ? '#2B89C9'
        : item.rs === 'Facebook'
        ? '#2E4A85'
        : 'var(--ui-primary-color)',
  } as CSSProperties

  useEffect(()=>{
    return ()=>{setOptions(false)}
  },[])

  function onDelete(){
    setOptions(false);
    onDeleteAdminPublication(item.id)
  }

  function onUpdate(){
    setOptions(false);
    onUpdateAdminPublication(item.id)
  }
if(item.rs){
  return (
    <div style={styledCard} onClick={handlerClickCardPost} ref={cardPostRef} className={styles['wrapper-card']}>
      {item.status === 'draft' && <div className={styles['moderation']}>A moderer</div>}
      {user_profil !== 'admin_reseau' && !item.date ? null : (
        <div className={styles['header-card']}>
          {options && item.id &&  (
            <OptionMenu
              draft={item.status === 'draft'?true:false}
              onDeleteAdminPublication={onDelete}
              onUpdateAdminPublication={onUpdate}
              ref={optionsRef}
              onClose={setOptions}
            />
          )}
          <div className={styles['header-card-post']}>
            <div className={styles['date-container']}>
              {item.date && (
                <Fragment>
                  <Icon name="date" />
                  <span>{dayjs(item.date, 'DD/MM/YYYY').fromNow()}</span>
                </Fragment>
              )}
            </div>
          </div>
          {user_profil === 'admin_reseau' && (
            <div className={styles['option-post-card']}>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setOptions(true)
                }}
                className={styles['option-button']}
              >
                <Icon name="more" />
              </button>
            </div>
          )}
        </div>
      )}
      {item?.hasOwnProperty('photo') && item?.photo.hasOwnProperty('url') && (
        <div className={styles['image-container']}>
          <img loading="lazy" src={item.photo.url} />
        </div>
      )}
      <div className={styles['author-container']}>
        <div>
          <span className={styles['author']}>{`${item?.auteur}`}</span>
          {item.gps && item.gps?.entreprise && item.gps?.entreprise?.gp && (
            <span className={styles['entreprise']}>{`${item.gps.entreprise.gp}`}</span>
          )}
        </div>
      </div>
      <div className={styles['title-container']}>{item.titre}</div>
      <div style={styledRs} className={styles['tag-container']}>
        {item.tags &&
          item.tags.map((item, key) => {
            return <div key={key} className={styles['tag']}>{`${!item.startsWith('#') ? '#' : ''}${item}`}</div>
          })}
      </div>
      <div style={styledRs} className={styles['banner-rs']}>
        <div className={styles['rs-identity']}>
          <span className={styles['container-icon']}>
            <Icon name={item.rs.toLowerCase() as any} />
          </span>
          <span className={styles['name-rs']}>{item.rs}</span>
        </div>
        <div className={styles['arrow']}>
          <Icon name="chevronright" />
        </div>
      </div>
    </div>
    )
  }
  return null
}
