import { forwardRef } from "react"
import AlertIcon from "../../../../icons/AlertIcon";
import styles from './ModalError.module.scss';
import {animated} from 'react-spring';

type ModalErrorProps = {
    message:string,
    onClose
}

 function ModalError({message,onClose,style},ref){
    return(
        <animated.div style={style} ref={ref} className={styles["modal-error"]}>
            <div className={styles["bg-modal"]}></div>
            <div className={styles["logo-modal"]}>
                <AlertIcon/>
            </div>
            <div className={styles["modal-content"]}>
                <div className={styles["modal-title"]}>Oops !</div>
                <div className={styles["modal-message"]}><span>{message}</span></div>
                <button onClick={onClose} className={styles["button-close"]}>Fermer</button>
            </div>
        </animated.div>
    )
}

export default forwardRef(ModalError)