import styles from './ListTabItem.module.scss';
import React,{useRef,useEffect,useState} from 'react';




export default function ListTabItem(props){
    const [isScrolling,setIsScrolling] = useState(false);
    const [isPressDown,setIsPressDown] = useState(false);
    const [startX,setStartX] = useState(0);
    const [scrollLeft,setScrollLeft] = useState(0)
    const [isActive,setIsActive] = useState(false)

    const tabsRef = useRef(null);

    useEffect(()=>{
        if(tabsRef.current){
            
            window.addEventListener("mousedown", onMouseDown);
            window.addEventListener("mouseleave", onMouseLeave);
            window.addEventListener("mouseup", onMouseUp);
            window.addEventListener("mousemove", onMouseMove);  
        }
        return ()=>{
            window.removeEventListener("mousedown", onMouseDown);
            window.removeEventListener("mouseleave", onMouseLeave);
            window.removeEventListener("mouseup", onMouseUp);
            window.removeEventListener("mousemove", onMouseMove);
        }
    })


   const onMouseDown = e => {
     
        setIsActive(true)
        setIsPressDown(true)
        setStartX( e.pageX - tabsRef.current.getBoundingClientRect().x)
       
        setScrollLeft(tabsRef.current.scrollLeft)
    };

    const onMouseLeave = () => {
        setIsActive(false)
        setIsPressDown(false)
       
        setTimeout(() => {
            setIsScrolling(false)
        }, 500);
      };

     const onMouseUp = () => {
        setIsActive(false)
        setIsPressDown(false)
        setIsScrolling(false)
      };


   const onMouseMove = e => {
        
        if (!isPressDown) return;
        e.preventDefault();
       setIsScrolling(true)
        const x = e.pageX - tabsRef.current.getBoundingClientRect().x;
        const walk = x - startX;
       
        tabsRef.current.scrollLeft = scrollLeft - walk;
      };

    return(
        <div className={styles["maxi-wrapper"]} ref={tabsRef}>
            <div className={styles["slider-container"]}>
                <div  className={`${styles["tabsH-wrapper"]} ${isActive? styles['active'] :""}`}>
                  {props.children}
                </div>
            </div>
        </div>
    )
}
