const SmileyIcon = (props) => (
    <svg
      fill="none"
      height={props.height}
      width={props.width}
      viewBox='0 0 24 24'
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.25 10a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0Zm6 0a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0Zm-6.114 5.107a.75.75 0 1 0-.772 1.286c.767.46 1.72.67 2.636.67.915 0 1.869-.21 2.636-.67a.75.75 0 0 0-.772-1.286c-.483.29-1.155.456-1.864.456-.71 0-1.381-.166-1.864-.456ZM22 12.001c0-5.524-4.478-10.002-10.001-10.002-5.524 0-10.002 4.478-10.002 10.002 0 5.523 4.478 10.001 10.002 10.001C17.523 22.002 22 17.524 22 12.001ZM3.498 12a8.502 8.502 0 1 1 17.003 0 8.502 8.502 0 0 1-17.003 0Z"
        fill={props.fill}
      />
    </svg>
  )
  
  export default SmileyIcon
  