import React from 'react';

export const TutoInternet1 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.49 38">
      <g id="bulle">
        <path
          fillRule="evenodd"
          fill={props.bg}
          d="M20.74,1A19,19,0,0,0,4.19,29.31,7.07,7.07,0,0,1,.81,35.2a1.17,1.17,0,0,0-.54,1.16,1.15,1.15,0,0,0,.85,1A12.56,12.56,0,0,0,10.46,36l.06,0A19,19,0,1,0,20.74,1Z"
          transform="translate(-0.26 -1)"
        />
      </g>
      <g id="internet-1">
        <path fill={props.icon ?? '#fff'} d="M10.37,13.62h21a.7.7,0,0,0,0-1.4h-21a.7.7,0,0,0,0,1.4Z" transform="translate(-0.26 -1)" />
        <path fill={props.icon ?? '#fff'} d="M31.33,19.21h-21a.7.7,0,1,0,0,1.39h21a.7.7,0,1,0,0-1.39Z" transform="translate(-0.26 -1)" />
        <path fill={props.icon ?? '#fff'} d="M31.33,26.19h-21a.7.7,0,0,0,0,1.4h21a.7.7,0,0,0,0-1.4Z" transform="translate(-0.26 -1)" />
      </g>
    </svg>
  );
};
