import React from 'react';

export const VekaContact = () => {
  return (
<svg version="1.1" id="Calque_1" x="0px" y="0px"
	 viewBox="0 0 18.3 18.3" >
<g>
	<path d="M18,0.3C17.8,0,17.3,0,17,0.3l-4.9,4.9V1.5C12.2,1.3,12,1,11.5,1c-0.4,0-0.7,0.2-0.7,0.6V7c0,0.3,0.2,0.6,0.6,0.6h5.3
		c0.3,0,0.6-0.3,0.7-0.6c0-0.4-0.2-0.7-0.6-0.7h-4L18,1.1C18.2,0.8,18.2,0.5,18,0.3z"/>
	<path d="M15.3,11.3c-0.7-0.1-1.4-0.2-2.1-0.5c-0.8-0.3-1.8-0.1-2.4,0.5L10.2,12C8.5,11,7.2,9.6,6.2,8L7,7.3C7.5,6.8,7.8,5.8,7.5,5
		C7.2,4.3,7,3.5,7,2.8c-0.1-1.2-1.1-2-2.2-2H2.3c-0.1,0-0.1,0-0.2,0C0.8,1,0,2.1,0,3.3C0.2,6,1.2,8.5,2.7,10.6c1.2,2,3,3.7,5,5
		C9.8,17,12.3,18,15,18.3c0.1,0,0.1,0,0.2,0c1.2,0,2.2-1,2.1-2.3v-2.4C17.3,12.3,16.5,11.5,15.3,11.3z M16,16L16,16c0,0.7-0.5,1-1,1
		c-2.4-0.2-4.7-1-6.8-2.4c-1.8-1.1-3.4-2.7-4.6-4.6C2.3,8,1.5,5.6,1.2,3.3V3.3c0-0.6,0.5-1,1-1h2.4c0.5,0,0.9,0.3,1,0.8
		C5.8,3.8,6,4.6,6.2,5.5c0.1,0.3,0.1,0.7-0.2,1l-1,1C4.8,7.6,4.8,8,5,8.1c1.2,2.1,3,3.9,5.1,5.1c0.3,0.1,0.6,0.1,0.8-0.1l1-1
		c0.2-0.2,0.6-0.3,1-0.2c0.8,0.3,1.6,0.5,2.4,0.6c0.5,0.1,0.8,0.5,0.8,1V16z"/>
</g>
</svg>


  );
};
