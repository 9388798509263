import React from 'react';

export const MondialDocumentationMarketing = () => {
  return (
    <svg
      version="1.1"
      id="Icon_ionic-ios-megaphone"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 15.7 15.6"
      style={{ enableBackground: 'new 0 0 15.7 15.6' }}
    >
      <path
        id="Tracé_228"
        d="M1.6,5.2c0-1.2,0.6-2.3,1.6-3c0.1,0,0.1-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1l0,0C1.7,2.1,0.5,3.2,0.4,4.7
   C0.1,4.7,0,5,0,5.2c0,0.3,0.1,0.5,0.4,0.6c0.3,1.2,1.2,2.1,2.3,2.4l0,0c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1-0.1-0.2
   C2.1,7.2,1.6,6.3,1.6,5.2z"
      />
      <path
        id="Tracé_229"
        d="M15.1,2.1c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.5-0.5-0.9-0.8-1.2C13.9,0.2,13.4,0,12.9,0c-0.3,0-0.7,0.1-1,0.3
   c-1.8,1-3.8,1.6-5.8,1.7C4.6,2,4.6,2,4.6,2C2.9,2.8,2.1,4.9,2.9,6.7c0.3,0.6,0.8,1.2,1.4,1.5C4.7,8.6,5.1,8.6,5,9.1
   s-0.8,3.7-0.9,4.3s-0.1,1,0.1,1.4c0.8,0.5,1.6,0.8,2.6,0.9c0.7,0,0.5-0.3,0.5-1.6S7,9.1,8,9.1c1.8,0,2.8,0.7,4.2,1.2
   c0.2,0.1,0.5,0.2,0.8,0.2c0.5,0,1-0.3,1.3-0.7C14.5,9.4,14.8,9,15,8.5c0-0.1,0.1-0.2,0.1-0.3c0.4-1,0.5-2,0.5-3.1
   C15.7,4.1,15.5,3,15.1,2.1z M14.1,8c-0.2,0.6-0.7,1.1-1,1.1S12.5,8.6,12.2,8c-0.3-0.9-0.5-1.9-0.5-2.8c0-1,0.1-1.9,0.5-2.8
   c0.2-0.6,0.6-0.9,0.9-0.9s0.8,0.4,1,0.9C14.9,4.1,14.9,6.2,14.1,8L14.1,8z"
      />
    </svg>
  );
};
