import React from 'react';

export const MondialTrombinoscope = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 15.7 17.5"
      style={{ enableBackground: 'new 0 0 15.7 17.5' }}
    >
      <path
        id="Icon_material-perm-contact-calendar"
        d="M14,1.8h-0.9V0h-1.8v1.8h-7V0H2.6v1.8H1.8C0.8,1.7,0,2.5,0,3.5c0,0,0,0,0,0v12.2
   c0,1,0.8,1.8,1.7,1.8c0,0,0,0,0,0H14c1,0,1.7-0.8,1.8-1.8V3.5C15.7,2.5,15,1.8,14,1.8z M7.9,4.4c1.4,0,2.6,1.2,2.6,2.6
   c0,1.4-1.2,2.6-2.6,2.6C6.4,9.6,5.3,8.4,5.2,7C5.2,5.6,6.4,4.4,7.9,4.4C7.9,4.4,7.9,4.4,7.9,4.4z M13.1,14.9H2.6V14
   c0-1.8,3.5-2.7,5.2-2.7s5.2,1,5.2,2.7V14.9z"
      />
    </svg>
  );
};
