import React from 'react';

export const ArrowLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25 19">
      <g fill="" fillRule="evenodd">
        <path d="M23.565 8.805H1.435c-.537 0-.972.415-.972.927s.435.927.972.927h22.13c.537 0 .972-.415.972-.927s-.435-.927-.972-.927" />
        <path d="M2.691 9.5l7.224-7.418c.36-.37.36-.97 0-1.341a.906.906 0 0 0-1.306 0L.733 8.83c-.36.37-.36.97 0 1.34L8.61 18.26a.908.908 0 0 0 1.306 0c.36-.37.36-.97 0-1.34L2.69 9.5z" />
      </g>
    </svg>
  );
};
