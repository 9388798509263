import Layout from "../../layout";
import { useDispatch,useSelector } from "react-redux";
import { getMoreSocialPosts, getSocialPosts, getSocialsThematic } from "./socialsSlice";
import { RootState } from "../../redux/reducers";
import { Fragment, useEffect, useState } from "react";
import styles from './Socials.module.scss';
import CardSocialPosts from "../social-network/cards-social-posts";
import Masonry from 'react-masonry-css'
import ThematicsSocial from "./thematics-social";
import { createSelector } from "reselect";
import Loading from "../../components/pgl/Loading";
import Breadcrump from "../../components/breadcrump/BreadCrump";

const breakpointColumn = {
    default: 4,
    1000: 3,
    768: 2,
    600: 1,
  }

export const listPostSocial = createSelector([
    (state) => state,
],
  (state) =>{
        if(state.thematic.selected==='tous') return state.posts.posts
        else
          return state.posts.posts.filter(i=>{
            return i.tags && i.tags.length > 0 && i.tags.includes(state.thematic.selected)
          }) 
   })



export default function Socials(){
    const dispatch = useDispatch();
    const socialsPost = useSelector((state:RootState)=>state.socials.posts);
    const breadcrumb = useSelector((state:RootState)=>state.socials.breadcrumb);
    const thematic = useSelector((state:RootState)=>state.socials.thematic);
    const posts = useSelector(((state:RootState)=>listPostSocial(state.socials)));
 
    useEffect(()=>{
        dispatch(getSocialPosts({page:0,cat:'partage'}));
        dispatch(getSocialsThematic());
    },[])

    function fetchMore(){
        dispatch(getMoreSocialPosts({page:socialsPost.currentPage+1,cat:'partage'}))
    }


   

    return(
        <Layout title={'Réseaux social'}  type={"nofetch"}>
            
            <div className={styles["container-rs-page"]}>
            {socialsPost.checkOutState === 'LOADING' 
            ?<Loading/>
            :socialsPost.checkOutState === 'ERROR' 
            ?<div>Erreur ...</div>
            :(
            <Fragment>
                <Breadcrump items={breadcrumb}/>
                <ThematicsSocial/>
                {posts.length === 0 &&
                  <div className={styles["no-post"]}>
                        Il n'y a aucune publication pour cette rubrique
                  </div>
                }
                <Masonry
                    breakpointCols={breakpointColumn}  
                    className={styles["grid-posts"]}
                    columnClassName={styles["grid-posts-column"]}>
                    {posts.map((item,key)=>{
                            return <CardSocialPosts key={'rs'+key}  item={item}/>
                    })}
                    </Masonry>
            
                {(!socialsPost.endReached && thematic.selected === 'tous')
                    ? <button onClick={fetchMore} 
                            className={styles["load-more"]}
                            >
                            {socialsPost.checkOutMoreState === 'LOADING' ?
                            "Chargement ..."
                            :"Charger plus de publication"
                            }
                        </button>
                    :null}
             </Fragment>
              )
            }
            </div>
        
        </Layout>
    )
}