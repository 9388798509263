import React from "react";
import { ArrowLeft } from "../../icons/ArrowLeft";
import { useHistory } from "react-router-dom";
const BackArrowButton = () => {
  const history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };
  return (
    <div className="back-arrow-container" onClick={goToPreviousPath}>
      <ArrowLeft />
    </div>
  );
};

export { BackArrowButton };
