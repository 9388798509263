import { PGL_POPIN, PGL_POPIN_RESET,PGL_POPIN_CLEAN } from '../../actions/pgl/types'


var initState ={
  popin:false,
}
const popinReducer = (state = initState, action) => {
  switch (action.type) {
    case PGL_POPIN:
          return{
            ...state,
            popin:action.payload
          }
    
      
    case PGL_POPIN_RESET:
      return []
    case PGL_POPIN_CLEAN:
      return {
        popin:false
      }
    default:
      return state
  }
}

export default popinReducer
