import React from 'react';

export const MondialAnnuaire = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 14.3 16.3"
      style={{ enableBackground: 'new 0 0 14.3 16.3' }}
    >
      <path
        d="M11.5,16.3H1.9c-1.1,0-1.9-0.9-1.9-1.9V1.9C0,0.9,0.9,0,1.9,0h9.5c1.1,0,1.9,0.9,1.9,1.9v0.9h0.1c0.5,0,0.9,0.4,0.9,0.9v1.2
   c0,0.5-0.4,0.9-0.9,0.9h-0.1v0.9h0.1c0.5,0,0.9,0.4,0.9,0.9v1.2c0,0.5-0.4,0.9-0.9,0.9h-0.1v0.9h0.1c0.5,0,0.9,0.4,0.9,0.9v1.2
   c0,0.5-0.4,0.9-0.9,0.9h-0.1v0.9C13.4,15.4,12.5,16.3,11.5,16.3z M1.9,1C1.4,1,1,1.4,1,1.9v12.4c0,0.5,0.4,0.9,0.9,0.9h9.5
   c0.5,0,0.9-0.4,0.9-0.9v-1.9h1v-0.9h-1V8.6h1V7.7h-1V4.8h1V3.9h-1V1.9C12.4,1.4,12,1,11.5,1H1.9z M4,12.4c-0.5,0-1-0.4-1.1-1l0-0.6
   C3,9.5,4.1,8.5,5.4,8.6l0.2,0l0.1,0c0.6,0.3,1.4,0.3,2,0l0.2,0H8c1.3-0.1,2.4,0.9,2.5,2.2l0,0.6l0,0c-0.1,0.6-0.6,1.1-1.2,1L4,12.4
   C4,12.4,4,12.4,4,12.4z M5.3,9.6c-0.7,0-1.3,0.5-1.4,1.3l0,0.5c0,0,0.1,0.1,0.1,0.1l5.4,0c0.1,0,0.2,0,0.2-0.1l0-0.5
   c-0.1-0.7-0.7-1.3-1.5-1.2L8,9.6c-0.8,0.3-1.7,0.3-2.6,0H5.4C5.3,9.6,5.3,9.6,5.3,9.6z M6.7,7.6C6.7,7.6,6.7,7.6,6.7,7.6
   C6,7.6,5.4,7.4,5,6.9S4.3,5.8,4.3,5.2c0-1.3,1.1-2.4,2.4-2.4c0,0,0,0,0,0h0c1.3,0,2.4,1.1,2.4,2.4c0,0.6-0.3,1.2-0.7,1.7
   C7.9,7.4,7.3,7.6,6.7,7.6z M6.7,3.8c-0.8,0-1.4,0.6-1.4,1.4c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4
   c0.3-0.3,0.4-0.6,0.4-1C8.1,4.4,7.5,3.8,6.7,3.8L6.7,3.8C6.7,3.8,6.7,3.8,6.7,3.8z"
      />
    </svg>
  );
};
