import React from 'react';

export const Check = () => {
  return (
    <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.49 8.78">
      <title>check</title>
      <path
        d="M13.55,3.91a.77.77,0,0,0-1.07.15L7.31,10.73l-3.73-3a.78.78,0,0,0-1.07.15A.75.75,0,0,0,2.66,9L7,12.39a.79.79,0,0,0,.46.15.76.76,0,0,0,.61-.3L13.7,5A.76.76,0,0,0,13.55,3.91Z"
        transform="translate(-2.36 -3.76)"
      />
    </svg>
  );
};
