import React from 'react';

export const NavbarActisParc = () => {
  return (
    <svg id="actis-parc" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 161.51 130.01">
      <circle cx="17.01" cy="113" r="17.01" />
      <circle cx="77.58" cy="112.61" r="17.01" />
      <path
        d="M240,244.5v-114H226v67l-30.75-67H135.5v94.28a24,24,0,0,1,38,25.72h14.77a23.77,23.77,0,0,1-1.17-7.39,24,24,0,1,1,46.85,7.39H295v-6ZM183,213v-8.19a6.51,6.51,0,0,0,0-.77A7.08,7.08,0,0,0,176,197a6.51,6.51,0,0,0-.77,0H142.5V137.5h48.25L225.4,213Z"
        transform="translate(-133.49 -130.5)"
      />
      <polygon points="45.01 64 23.76 64 17.51 36.5 23.89 36.16 28.26 58 45.01 58 45.01 64" />
    </svg>
  );
};
