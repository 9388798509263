import ChromeStep1 from "../../../../icons/tuto-icons/ChromeStep1";
import ChromeStep2 from "../../../../icons/tuto-icons/ChromeStep2";
import ChromeStep3 from "../../../../icons/tuto-icons/ChromeStep3";
import FirefoxStep1 from "../../../../icons/tuto-icons/FirefoxStep1";
import FirefoxStep2 from "../../../../icons/tuto-icons/FirefoxStep2";
import FirefoxStep3 from "../../../../icons/tuto-icons/FirefoxStep3";
import SafariStep1 from "../../../../icons/tuto-icons/SafariStep1";
import SafariStep2 from "../../../../icons/tuto-icons/SafariStep2";
import SafariStep3 from "../../../../icons/tuto-icons/SafariStep3";

const Components ={
    'tuto_chrome':ChromeStep1,
    'tuto_2_chrome_firefox':ChromeStep2,
    'tuto_3_installed':ChromeStep3,
    'tuto_1_firefox':FirefoxStep1,
    'tuto_1_safari':SafariStep1,
    'tuto_2_safari':SafariStep2
}

export default  function BubbleTuto({name,...props}){
    let Component = Components[name];
    if(Component!==undefined) return <Component {...props} />
    return null
}