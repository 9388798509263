import React from 'react';

import { NavbarAdmin } from '../icons/NavbarAdmin';
import { NavbarArobase } from '../icons/NavbarArobase';
import { NavbarFeuille } from '../icons/NavbarFeuille';
import { NavbarBalance } from '../icons/NavbarBalance';
import { NavbarDiplome } from '../icons/NavbarDiplome';
import { NavbarActisParc } from '../icons/NavbarActisParc';
import { NavbarCalendar } from '../icons/NavbarCalendar';
import { NavbarContacts } from '../icons/NavbarContacts';
import { NavbarDownload } from '../icons/NavbarDownload';
import { NavbarReporting } from '../icons/NavbarReporting';
import { NavbarPartners } from '../icons/NavbarPartners';
import { NavbarPictures } from '../icons/NavbarPictures';
import { NavbarPosts } from '../icons/NavbarPosts';
import { NavbarProfile } from '../icons/NavbarProfile';
import { NavbarLink1 } from '../icons/NavbarLink1';
import { NavbarLink2 } from '../icons/NavbarLink2';
import { MondialActualites } from '../icons/MondialActualites';
import { MondialAnnuaire } from '../icons/MondialAnnuaire';
import { MondialAnnuairePartenaires } from '../icons/MondialAnnuairePartenaires';
import { MondialBoutiqueMarketing } from '../icons/MondialBoutiqueMarketing';
import { MondialBoutiqueSpa } from '../icons/MondialBoutiqueSpa';
import { MondialCirculaires } from '../icons/MondialCirculaires';
import { MondialClub } from '../icons/MondialClub';
import { MondialDeconnexion } from '../icons/MondialDeconnexion';
import { MondialDeviseur } from '../icons/MondialDeviseur';
import { MondialDocumentationMarketing } from '../icons/MondialDocumentationMarketing';
import { MondialDocumentsTechniques } from '../icons/MondialDocumentsTechniques';
import { MondialEvenements } from '../icons/MondialEvenements';
import { MondialExtranet } from '../icons/MondialExtranet';
import { MondialFacebook } from '../icons/MondialFacebook';
import { MondialInstagram } from '../icons/MondialInstagram';
import { MondialLinkedin } from '../icons/MondialLinkedin';
import { MondialPhotos } from '../icons/MondialPhotos';
import { MondialRealisations } from '../icons/MondialRealisations';
import { MondialReseaux } from '../icons/MondialReseaux';
import { MondialTrombinoscope } from '../icons/MondialTrombinoscope';
import { ShoppingBag } from '../icons/ShoppingBag';
import { NavbarPolls } from '../icons/NavbarPolls';
import { NavbarTraining } from '../icons/NavbarTraining';
import { NavbarAchat } from '../icons/NavbarAchat';
import { NavbarCommunication } from '../icons/NavbarCommunication';
import { NavbarSecurite } from '../icons/NavbarSecurite';
import { NavbarCadeau } from '../icons/NavbarCadeau';
import { NavbarPrint } from '../icons/NavbarPrint';
import { Cse } from '../icons/Cse';
import { Teams } from '../icons/Teams';
import { SharePoint } from '../icons/SharePoint';
import { N2F } from '../icons/N2F';
import { Corporama } from '../icons/Corporama';
import { Ines } from '../icons/Ines';
import { Talents } from '../icons/Talents';
import { Campus } from '../icons/Campus';
import Boutique from '../icons/Boutique';
import VekaCa from '../icons/VekaCa';
import Ca from '../icons/Ca';
import { Argumentaires } from '../icons/Argumentaires';
import { Realisation } from '../icons/Realisation';
import { Morussati } from '../icons/Morussati';
import { Referencement } from '../icons/Referencement';
import { VekaContact } from '../icons/VekaContact';
import AfdfPublicationsOuvertes from '../icons/AfdfPublicationsOuvertes';
import AfdfAnnuaireAfdf from '../icons/AfdfAnnuaireAfdf';
import AfdfAnnuaireFournisseurs from '../icons/AfdfAnnuaireFournisseurs';
import AfdfBouquets from '../icons/AfdfBouquets';
import AfdfOffresFournisseurs from '../icons/AfdfOffresFournisseurs';
import AfdfSiteVente from '../icons/AfdfSiteVente';
import AfdfTendances from '../icons/AfdfTendances';
import { MarkerIcon } from '../icons/MarkerIcon';
export const renderIcon = (icon) => {
  switch (icon) {
    case 'achat':
      return <NavbarAchat />;
    case 'communication':
      return <NavbarCommunication />;
    case 'securite':
      return <NavbarSecurite />;
    case 'cadeau':
      return <NavbarCadeau />;
    case 'imprimante':
      return <NavbarPrint />;
    case 'profil':
      return <NavbarProfile />;
    case 'arobase':
      return <NavbarArobase />;
    case 'diplome':
      return <NavbarDiplome />;
    case 'feuille':
      return <NavbarFeuille />;
    case 'balance':
      return <NavbarBalance />;
    case 'administration':
      return <NavbarAdmin />;
    case 'actis-parc':
      return <NavbarActisParc />;
    case 'lien-1':
      return <NavbarLink1 />;
    case 'lien-2':
      return <NavbarLink2 />;
    case 'actualites':
      return <NavbarPosts />;
    case 'life':
      return <NavbarPictures />;
    case 'agenda':
      return <NavbarCalendar />;
    case 'annuaire-reseau':
      return <NavbarContacts />;
    case 'annuaire-partenaires':
      return <NavbarPartners />;
    case 'comptes-rendus':
      return <NavbarReporting />;
    case 'telechargements':
      return <NavbarDownload />;
    case 'boutique':
      return <ShoppingBag />;
    case 'boutique-formations':
      return <NavbarTraining />;
    case 'sondages':
      return <NavbarPolls />;
    case 'mondial-actualites':
      return <MondialActualites />;
    case 'mondial-annuaire':
      return <MondialAnnuaire />;
    case 'mondial-annuaire-partenaires':
      return <MondialAnnuairePartenaires />;
    case 'mondial-boutique-marketing':
      return <MondialBoutiqueMarketing />;
    case 'mondial-boutique-spa':
      return <MondialBoutiqueSpa />;
    case 'mondial-circulaires':
      return <MondialCirculaires />;
    case 'mondial-club':
      return <MondialClub />;
    case 'mondial-deconnexion':
      return <MondialDeconnexion />;
    case 'mondial-deviseur':
      return <MondialDeviseur />;
    case 'mondial-documentation-marketing':
      return <MondialDocumentationMarketing />;
    case 'mondial-documents-techniques':
      return <MondialDocumentsTechniques />;
    case 'mondial-evenements':
      return <MondialEvenements />;
    case 'mondial-extranet':
      return <MondialExtranet />;
    case 'mondial-facebook':
      return <MondialFacebook />;
    case 'mondial-instagram':
      return <MondialInstagram />;
    case 'mondial-linkedin':
      return <MondialLinkedin />;
    case 'mondial-photos':
      return <MondialPhotos />;
    case 'mondial-realisations':
      return <MondialRealisations />;
    case 'mondial-reseaux':
      return <MondialReseaux />;
    case 'mondial-trombinoscope':
      return <MondialTrombinoscope />;
    case 'SharePoint':
      return <SharePoint/>
    case 'Teams':
      return <Teams/>
    case 'Cse':
      return <Cse/>
    case 'N2F':
      return <N2F/>
    case 'Campus':
      return <Campus/>
    case 'Corporama':
      return <Corporama/>
    case 'Ines':
      return <Ines/>
    case 'Talents':
      return <Talents/>
    case 'boutique-cahra':
      return <Boutique/>
    case 'VekaChiffre':
      return <VekaCa/>
      case 'ca':
      return <Ca/>
      case 'VekaArgumentaires':
        return <Argumentaires/>
      case 'VekaRéalisations':
        return <Realisation/>
      case 'VekaMorassuti':
        return <Morussati/>
      case 'VekaRéférencement':
        return <Referencement/>
      case 'Vekacontact':
        return <VekaContact/>
      case 'AfdfPublicationsOuvertes':
        return <AfdfPublicationsOuvertes/>
      case 'AfdfAnnuaireAfdf':
        return <AfdfAnnuaireAfdf/>
      case 'AfdfAnnuaireFournisseurs':
        return <AfdfAnnuaireFournisseurs/>
      case 'AfdfBouquets':
        return <AfdfBouquets/>
        case 'AfdfOffresFournisseurs':
        return <AfdfOffresFournisseurs/>
      case 'AfdfSiteVente':
        return <AfdfSiteVente/>
      case 'AfdfTendances':
        return <AfdfTendances/>
      case 'map':
        return <MarkerIcon/>
    default:
      return <NavbarPosts />;
  }
};
