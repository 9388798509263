import React from 'react';

export const Comment = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width??"16"} height={props.height??"16"} viewBox="0 0 17 17">
      <path
        fill={props.fill??""}
        fillRule="evenodd"
        d="M16.2,7.6C16.2,4,12.8,1,8.5,1S0.8,3.9,0.8,7.6c0,3.6,3.4,6.6,7.7,6.6c0.1,0,0.3,0,0.4,0L12,16
  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.3-0.2,0.3-0.4v-2.5C14.9,11.8,16.2,9.8,16.2,7.6"
      />
    </svg>
  );
};
