import { PGL_SELLERS } from '../../actions/pgl/types'
const INITIAL_STATE = { sellers: false, isLoading: false }
const sellersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PGL_SELLERS:
      if (action.payload) {
        if (action.payload.error) {
          // console.log('------------------- check. action.payload:' + JSON.stringify(action.payload))
          INITIAL_STATE.isLoading = true
          return action.payload
        } else {
          // console.log('------------------- check. action.payload:' + JSON.stringify(action.payload))
          // let arraySellers = []
          // for (let i = 0; i < action.payload.length; i++) {
          //   arraySellers.push(action.payload[i])
          //   console.log('logEl ***************** ' + JSON.stringify(action.payload[i]))
          // }
          INITIAL_STATE.isLoading = true
          return action.payload
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default sellersReducer
