import { Fragment } from 'react';
import api from '../../../../config/pci/api';
import CardSearchLite from '../../modal-search/card-search-lite/CardSearchLite';
import { useSelector } from 'react-redux';
import {useHistory,useLocation} from 'react-router-dom';
import styles from './ListSearchMobile.module.scss';

export type listSearchMobileProps ={
    listSearch:Array<any>,
    textSearch:string,
    loading:boolean
}

export default function ListSearchMobile({listSearch,textSearch,loading}):JSX.Element{
    const menu = useSelector(state=>state.navbar.items);

    const history = useHistory();
    const location = useLocation();

    function onGoToPageSearch(id_rub,id_detail){
        const rubric = menu.find(i=>id_rub ==i.id) ?? false;
    
        if(rubric && (id_rub!=='partner' && id_rub !== 'entreprise' && id_rub!== 'user_partenaire' && id_rub !== 'user_adherent' && id_rub!=="boutique")){
            if(rubric.type === 'rub'){
                const firstPath = rubric.screen === 'std' ? 'actualite' :  'photo'
                if(rubric.screen === 'std'){
                    history.push({pathname:`/actualite/${rubric.slug}/${id_detail}`});
                        location.pathname?.startsWith(`/actualite/${rubric.slug}`) &&  window.location.reload();
                } 
                else
                    history.push({pathname:`/photo/${id_detail}`});
                  
            }
            else if(rubric.type === 'scr'){
                 if(rubric.id === 'agenda'){
                    history.replace({pathname:`/agenda`,hash:id_detail})
                    location.pathname?.startsWith(`/agenda`) &&  window.location.reload();
                }
                else if(rubric.id === 'sondage'){
                    history.push({pathname:`/sondage`,hash:id_detail})
                    location.pathname?.startsWith(`/sondage`) &&  window.location.reload();
                }
                else{
                    history.push({pathname:`/${rubric.slug}/${id_detail}`}) 
                    location.pathname?.startsWith(`/${rubric.slug}`) &&  window.location.reload();
                }
               
            }
            else{
                history.push({pathname:rubric.slug})
                
            }
        }else{
            if(id_rub === 'boutique'){
            history.push({pathname:`/boutique/produit/${id_detail}`})
            location.pathname?.startsWith(`/boutique/produit/`) &&  window.location.reload();
            }
            else if(id_rub === 'entreprise'){
               
                history.push({pathname:`/entreprise/${id_detail}`}) 
                location.pathname?.startsWith(`/entreprise/`) &&  window.location.reload();
            }
            else if(id_rub === 'partner'){
                history.push({pathname:`/entreprise-partner/${id_detail}`})
                location.pathname?.startsWith(`/entreprise-partner/`) &&  window.location.reload();
            }
            else if(id_rub === 'user_partenaire'){
          
                history.push({pathname:`/user-partner/${id_detail}`})
                location.pathname?.startsWith(`/user-partner/`) &&  window.location.reload();
            }
            else if(id_rub == 'user_adherent'){
                history.push({pathname:`/user/${id_detail}`})
                location.pathname?.startsWith(`/user/`) &&  window.location.reload();
            }
        }
       } 
    
       function goTo(url,id){
        if(url.startsWith('/sondage') || url.startsWith('/agenda')){
            const starterUrl = url.startsWith('/sondage') ? '/sondage' :'/agenda'
            //return console.log(result.url)
            return ()=>{ history.push({pathname:starterUrl,hash:id});window.location.reload()}
        }
        history.push({pathname:url}); 
        window.location.reload()
       }

    return(
        <div className={styles["list-search-mobile"]}>
            <Fragment>
                    <div className={styles['list-container']}>
                    {listSearch.length === 0 && textSearch.length >0 && !loading  ? 
                    (<div className={styles['no-results-container']}>
                        <div className={styles["no-results"]}>Aucun résultat trouvé</div>
                        <div className={styles["no-result-sentence"]}>Essayer d'autres mots clés</div>
                    </div>)
                    :listSearch.length > 0 ?listSearch.map(({title,type,content,id,url},key)=>{
                        return <CardSearchLite onGoToPageSearch={goTo} url={url} className={styles["card-search"]} id={id} content={content} key={key} title={title} type={type} />
                    })
                    :null
                }
                    </div>
                    
            </Fragment>
        
        </div>
    )
}