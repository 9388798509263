import React from 'react';

export const MondialExtranet = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16.7 18.1"
      style={{ enableBackground: 'new 0 0 16.7 18.1' }}
    >
      <path
        d="M16.3,4.7c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.4-0.5-0.7-0.6l-2.8-1.6c-0.1-0.1-0.2-0.2-0.3-0.2L9.5,0.3
   c-0.7-0.4-1.5-0.4-2.2,0L4.5,1.9C4.3,1.9,4.2,2,4.1,2.1L1.3,3.7C1,3.9,0.8,4.1,0.6,4.3c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2
   C0.3,5,0.2,5.3,0.2,5.6v6.8c0,0.8,0.4,1.5,1.1,1.9L4.1,16c0.1,0.1,0.2,0.2,0.3,0.2l2.8,1.6C7.5,17.9,7.8,18,8,18
   c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.3,0,0.5-0.1,0.8-0.3l2.8-1.6c0.1,0,0.3-0.1,0.3-0.2l2.8-1.6
   c0.7-0.4,1.1-1.1,1.1-1.9V5.6C16.5,5.3,16.4,5,16.3,4.7z M7.8,1.2C7.9,1.1,8.1,1,8.4,1C8.6,1,8.8,1.1,9,1.2l2.2,1.2L8.4,4L5.6,2.4
   L7.8,1.2z M4.6,3l3.5,2c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1l3.5-2l2.8,1.6c0,0,0,0,0.1,0L8.4,8.5L1.7,4.6c0,0,0,0,0.1,0L4.6,3z
    M1.2,12.4V9.9L4,11.5v3.2l-2.2-1.3C1.4,13.3,1.2,12.9,1.2,12.4z M5,15.3v-4.1c0-0.2-0.1-0.3-0.2-0.4L1.2,8.8V5.6c0,0,0-0.1,0-0.1
   l6.6,3.8v7.6c0,0-0.1,0-0.1,0L5,15.3z M8.9,16.9C8.9,16.9,8.9,16.9,8.9,16.9L8.9,9.3l6.6-3.8c0,0,0,0.1,0,0.1v3.1l-3.6,2.1
   c-0.2,0.1-0.2,0.3-0.2,0.4v4.1L8.9,16.9z M14.9,13.5l-2.2,1.3v-3.2l2.8-1.6v2.5C15.5,12.9,15.3,13.3,14.9,13.5z"
      />
    </svg>
  );
};
