import React from 'react';

export const MondialDeviseur = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 15.5 15.5"
      style={{ enableBackground: 'new 0 0 15.5 15.5' }}
    >
      <path
        id="Icon_awesome-wpforms"
        d="M15.5,1.5v12.6c0,0.8-0.6,1.5-1.5,1.5c0,0,0,0,0,0H1.5c-0.8,0-1.5-0.7-1.5-1.5c0,0,0,0,0,0V1.5
   C0,0.7,0.6,0,1.5,0c0,0,0,0,0,0h12.6C14.9,0,15.5,0.6,15.5,1.5C15.5,1.5,15.5,1.5,15.5,1.5z M14.2,14.1V1.5c0-0.1-0.1-0.2-0.2-0.2
   h-0.3L9.9,3.9L7.8,2.2L5.6,3.9L1.8,1.3H1.5c-0.1,0-0.2,0.1-0.2,0.2v12.6c0,0.1,0.1,0.2,0.2,0.2h12.6C14.2,14.3,14.2,14.2,14.2,14.1
   L14.2,14.1z M5.2,5.4v1.3H2.7V5.3L5.2,5.4z M5.2,7.9v1.3H2.7V7.9L5.2,7.9z M5.6,2.8l1.9-1.5H3.4L5.6,2.8z M12.9,5.3v1.3H6.1V5.3
   L12.9,5.3z M12.9,7.9v1.3H6.1V7.9L12.9,7.9z M9.9,2.8l2.2-1.5H8.1L9.9,2.8z M12.9,10.5v1.3H9.4v-1.3L12.9,10.5z"
      />
    </svg>
  );
};
