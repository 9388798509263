import React, { Component } from 'react';

export default class Swipe extends Component {
  constructor(props) {
    super(props);
    this.state = { initialX: null, initialY: null };
    this.container = React.createRef();
  }

  // AU CHARGEMENT, ON DETECTE LE SWIPE
  componentDidMount() {
    const container = this.container.current;
    container.addEventListener('touchstart', this.startTouch);
    container.addEventListener('touchmove', this.moveTouch);
  }
  // FIN AU CHARGEMENT, ON DETECTE LE SWIPE

  // A LA FERMETURE DU COMPONENT, ON ENLEVE LES LISTENERS
  componentWillUnmount() {
    const container = this.container.current;
    container.removeEventListener('touchstart', this.startTouch);
    container.removeEventListener('touchmove', this.moveTouch);
  }
  // FIN A LA FERMETURE DU COMPONENT, ON ENLEVE LES LISTENERS

  // AU DEBUT DU GESTE DE SWIPE, ON DETECTE LES COORDONNEES
  startTouch = (e) => {
    this.setState({
      initialX: e.touches[0].clientX,
      initialY: e.touches[0].clientY,
    });
  };
  // FIN AU DEBUT DU GESTE DE SWIPE, ON DETECTE LES COORDONNEES

  // AU SWIPE, ON RECUPERE LE DIFFERENTIEL ENTRE DEBUT ET FIN DU GESTE
  moveTouch = (e) => {
    const { initialX, initialY } = this.state;
    if (initialX === null) {
      return;
    }

    if (initialY === null) {
      return;
    }

    var currentX = e.touches[0].clientX;
    var currentY = e.touches[0].clientY;

    var diffX = initialX - currentX;
    var diffY = initialY - currentY;

    this.setState({
      initialX: null,
      initialY: null,
    });

    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (diffX > 0 && this.props.onSwipeLeft) {
        this.props.onSwipeLeft(diffX);
      } else if (diffX < 0 && this.props.onSwipeRight) {
        this.props.onSwipeRight(diffX);
      }
    } else {
      if (diffY > 0) {
        return;
      } else {
        return;
      }
    }

    e.preventDefault();
  };
  // FIN AU SWIPE, ON RECUPERE LE DIFFERENTIEL ENTRE DEBUT ET FIN DU GESTE

  render() {
    return (
      <div ref={this.container} className="swipe">
        {this.props.children}
       
      </div>
    );
  }
}
