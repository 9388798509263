import InstagramIcon from "../InstagramIcon";
import { Linkedin } from "../Linkedin";
import { Facebook } from "../Facebook";

const Components = {
    instagram:InstagramIcon,
    linkedin:Linkedin,
    facebook:Facebook
}


export default function SocialIcons({name,...props}):JSX.Element | null{
    let Component = Components[name]
    
    if(Component!==undefined) return <Component {...props} />
    return null
}