import React from 'react';

export const Home = () => {
  return (
    <svg height="29" width="29" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29">
      <path
        fillRule="evenodd"
        d="M32.05,20a.84.84,0,0,1-1.2,0h0L20.16,9.35a.85.85,0,0,0-1.2,0L8.15,20A.85.85,0,0,1,7,18.85L19,7a.85.85,0,0,1,1.2,0h0L32.05,18.84A.85.85,0,0,1,32.05,20ZM28.9,32.3H23.8V24.54a.85.85,0,0,0-.85-.85H16.16a.85.85,0,0,0-.85.85h0V32.3H10.1V20.51l9.46-9.36L28.9,20.5V32.3ZM17,32.3h5.1V25.39H17ZM25.5,8.4h1.7v3.19l-1.7-1.7V8.4Zm7.75,9.24L28.9,13.29V7.55a.85.85,0,0,0-.85-.85h-3.4a.85.85,0,0,0-.85.85v.64L21.36,5.75a2.55,2.55,0,0,0-3.6,0l-12,11.9a2.53,2.53,0,0,0,0,3.6h0a2.57,2.57,0,0,0,2.65.6v11.3a.85.85,0,0,0,.85.85h20.5a.85.85,0,0,0,.85-.85V21.85a2.55,2.55,0,0,0,3.25-1.55,2.54,2.54,0,0,0-.6-2.66Z"
        transform="translate(-5 -5)"
      />
    </svg>
  );
};
