import axios from 'axios'
import api from '../../../config/pci/api'
import { stats } from '../../../config/stats'

// Import des types dynamiques d'action
import {
  CHECK_AUTH,
  SET_TOKEN,
  SIGN_IN,
  SET_LOGIN_AUTH,
  RESET_SIGNING_MESSAGE,
  RESET_AUTH,
  GET_TEMPORARY_CODE,
  RESET_PASSWORD,
  RESET_AUTH_MESSAGE,
  SEND_MAIL,
  SEND_EMAIL_TO_ADMIN,
  GET_NAVBAR,
  GET_POSTS,
  RESET_POSTS,
  GET_POSTS_TAGS,
  GET_EVENTS,
  GET_POLLS,
  GET_CONTACTS,
  GET_CONTACT,
  TOGGLE_FAVORITE_CONTACT,
  GET_PARTNERS,
  TOGGLE_FAVORITE_PARTNER,
  GET_ADMINS,
  GET_PROFILE,
  SET_PROFILE,
  ACCEPT_CGU,
  SET_PROFILE_PICTURE,
  SET_PROFILE_MESSAGE,
  RESET_PROFILE_MESSAGE,
  SET_NEW_PASSWORD,
  SET_PROFILE_STATUS,
  GET_POST,
  RESET_POST,
  SET_LIKE,
  SET_COMMENT,
  MODERATE_COMMENT,
  GET_PDF,
  ADMIN_SET_POST,
  ADMIN_DELETE_POST,
  ADMIN_DELETE_SOCIAL_POST,
  ADMIN_DELETE_PICTURE,
  ADMIN_GET_GROUPS,
  ADMIN_GET_CATEGORIES,
  ADMIN_GET_POSTS,
  ADMIN_GET_POST_FOR_UPDATE,
  ACCEPT_EVENT_PARTICIPATION,
  REFUSE_EVENT_PARTICIPATION,
  SET_POLL_ANSWER,
  SET_CONTEXT,
  GET_GROUPS,
  GET_NAVBAR_NOTIFICATIONS,
  DELETE_POST_NOTIFICATION,
  DELETE_POSTS_NOTIFICATION,
  DELETE_POLLS_NOTIFICATION,
  DELETE_EVENTS_NOTIFICATION,
  DELETE_CATEGORY_NOTIFICATION,
  DELETE_SOCIALS_NOTIFICATION,
  GET_NAVBAR_SOCIALS,
  GET_SOCIAL_POSTS,
  GET_SOCIAL_POST,
  GET_SOCIAL_THEMES,
  ADMIN_GET_SOCIAL_TAGS,
  ADMIN_GET_SOCIAL_THEMES,
  ADMIN_RESET_SOCIAL_TAGS,
  ADMIN_RESET_SOCIAL_THEMES,
  ADMIN_RESET_POST_FOR_UPDATE,
  GET_SHOPPING_POSTS,
  GET_SHOPPING_POST,
  RESET_SHOPPING_POST,
  GET_SHOPPING_CART,
  RESET_SHOPPING_CART,
  UPDATE_CART,
  GET_SHOPPING_TYPOLOGIES,
  SET_SHOPPING_TYPOLOGIE,
  DELETE_SHOPPING_ITEM,
  SET_SHOPPING_PROMOCODE,
  VALIDATE_SHOPPING_ORDER,
  RESET_SHOPPING_ORDER,
  TRACKING_CONTACT_CALL,
  TRACKING_CONTACT_EMAIL,
  TRACKING_DOCUMENT_DOWNLOAD,
  TRACKING_EXTERNAL_LINK,
  TRACKING_SOCIAL_PUBLICATION,
  LINKEDIN_CONNECT,
  LINKEDIN_GET_ACCOUNTS,
  LINKEDIN_SET_POST,
  LINKEDIN_RESET_POST,
  GET_CA,
  SET_CA,
  GET_SOCIAL_POST_ERROR,
  GET_SOCIAL_POST_LOADING,
  INIT_PAGE_FLUX,
} from './types'

// Connexion
export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    token: token,
  }
}

export const resetPostsCategorie = () =>{
 return  function(dispatch){
   dispatch({type: 'RESET_POSTS_ALL'})
 }
}




  // Vérifie si l'utilisateur est déjà connecté
export const checkAuth =
(user_id, nonce, auto = null) =>
async (dispatch) => {
  const response = await api.get('/session', {
    params: {
      user: user_id,
      nonce: nonce,
      auto: auto,
      stat: stats(),
    },
  })
  dispatch({ type: CHECK_AUTH, payload: response.data })
}

// Connexion
export const signIn = (log, pass, noken) => async (dispatch) => {
  const response = await api.get('/login', {
    params: {
      log: log,
      pass: pass,
      noken: noken || 'no-notif',
      stat: stats(),
    },
  })
  dispatch({
    type: SIGN_IN,
    payload: { response: response.data, email: log, password: pass },
  })
}

// Deconnexion
export const setLoginAuth = () => {
  return {
    type: SET_LOGIN_AUTH,
  }
}

// Deconnexion
export const resetAuth = () => {
  return {
    type: RESET_AUTH,
  }
}

// Génération code temporaire (première connexion)
export const getTemporaryCode = (email) => async (dispatch) => {
  const response = await api.get('/gencode', {
    params: {
      email: email,
      stat: stats(),
    },
  })
  dispatch({ type: GET_TEMPORARY_CODE, payload: response.data })
}

// Génération code temporaire (mot de passe oublié)
export const resetPassword = (email) => async (dispatch) => {
  const response = await api.get('/lostpass', {
    params: {
      email: email,
      stat: stats(),
    },
  })
  dispatch({ type: RESET_PASSWORD, payload: response.data })
}

// Deconnexion
export const resetAuthMessage = () => {
  return {
    type: RESET_AUTH_MESSAGE,
  }
}

// Réinitialisation du message d'erreur à la saisie du mot de passe
export const resetSigningMessage = () => {
  return {
    type: RESET_SIGNING_MESSAGE,
  }
}

// Deconnexion
export const setContext = (opened_from_app) => {
  return {
    type: SET_CONTEXT,
    opened_from_app: opened_from_app,
  }
}

// Envoi d'email à un autre utilisateur via l'annuaire
export const sendMail = () => async (dispatch) => {
  const response = await api.get('/directmail', {
    params: {
      user: 1,
      nonce: '',
      dest: 1,
      partner: false,
      obj: '',
      mess: '',
      stat: stats(),
    },
  })
  dispatch({ type: SEND_MAIL, payload: response.data })
}

// Envoi d'un mail à un admin
export const sendEmailToAdmin = () => async (dispatch) => {
  const response = await api.post('/adminmail')
  dispatch({ type: SEND_EMAIL_TO_ADMIN, payload: response.data })
}

// Affichage du menu
export const getNavbar = (user_id, nonce) => async (dispatch) => {
  dispatch({type:'GET_NAVBAR_LOADING'})
  const response = await api.get('/callmenu', {
    params: {
      user: user_id,
      nonce: nonce,
    },
  })
  dispatch({ type: GET_NAVBAR, payload: response.data })
 // dispatch({type:INIT_PAGE_FLUX,payload:response.data})
}

// Affichage du menu
export const getNavbarSocials = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/socials', {
    params: {
      user: user_id,
      nonce: nonce,
    },
  })
  dispatch({ type: GET_NAVBAR_SOCIALS, payload: response.data })
}

// Affichage du menu
export const getNavbarNotifications = (user_id, nonce, lastconnect) => async (dispatch) => {
  const response = await api.get('/updatemenu', {
    params: {
      user: user_id,
      nonce: nonce,
      lastconnect: lastconnect,
    },
  })
  dispatch({ type: GET_NAVBAR_NOTIFICATIONS, payload: response.data })
}

// Liste des posts d'une rubrique (fil d'actu, life)
export const getPosts =
  (user_id, nonce, cat, page, lastconnect, r = '', parent = cat) =>
  async (dispatch) => {

    const response = await api.get('/filposts', {
      params: {
        user: user_id,
        nonce: nonce,
        cat: cat,
        page: page,
        lastconnect: lastconnect,
        r: r,
        stat: stats(),
      },
    })
 
    dispatch({
      type: GET_POSTS,
      payload: {
        data: response.data,
        page: page,
        id: cat,
        r: r,
        parent: parent,
      },
    })
  }


export const resetPosts = (category_id) => {
  return {
    type: RESET_POSTS,
    payload: { category_id },
  }
}

// Liste des tags d'une rubrique (fil d'actu, life)
export const getPostsTags = (user_id, nonce, cat) => async (dispatch) => {
  const response = await api.get('/listpoststags', {
    params: {
      user: user_id,
      nonce: nonce,
      cat: cat,
      stat: stats(),
    },
  })
  dispatch({
    type: GET_POSTS_TAGS,
    payload: { data: response.data },
  })
}

// Liste des posts de la rubrique agenda
export const getEvents =
  (user_id, nonce, cat, page, lastconnect, r = '') =>
  async (dispatch) => {
    const response = await api.get('/filposts', {
      params: {
        user: user_id,
        nonce: nonce,
        cat: cat,
        page: page,
        lastconnect: lastconnect,
        r: r,
        stat: stats(),
      },
    })
    dispatch({
      type: GET_EVENTS,
      payload: { data: response.data, page: page, id: cat, r: r },
    })
  }

// Liste des posts de la rubrique réseaux sociaux
export const getSocialPosts =
  (user_id, nonce, cat, page, lastconnect, r = '', categ_rs, thematique) =>
  async (dispatch) => {
    
    const response = await api.get('/filposts', {
      params: {
        user: user_id,
        nonce: nonce,
        cat: cat,
        page: page,
        lastconnect: lastconnect,
        r: r,
        stat: stats(),
        categ_rs: categ_rs,
        thematique: thematique,
      },
    })
    //const response = await axios.get('data/social-network.json')
    dispatch({
      type: GET_SOCIAL_POSTS,
      payload: { data: response.data, page: page, id: cat, r: r },
    })
  }

export const getSocialThemes = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/listthematiquesrs', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({
    type: GET_SOCIAL_THEMES,
    payload: { data: response.data },
  })
}

// Liste des posts de la rubrique sondage
export const getPolls =
  (user_id, nonce, cat, page, lastconnect, r = '') =>
  async (dispatch) => {

    const response = await api.get('/filposts', {
      params: {
        user: user_id,
        nonce: nonce,
        cat: cat,
        page: page,
        lastconnect: lastconnect,
        r: r,
        stat: stats(),
      },
    })
    dispatch({
      type: GET_POLLS,
      payload: { data: response.data, page: page, id: cat, r: r },
    })
  }

// Liste des posts de la rubrique sondage
export const setPollAnswers = (user_id, nonce, poll_id, answers) => async (dispatch) => {
  const response = await api.get('/votesondage', {
    params: {
      user: user_id,
      nonce: nonce,
      sondage: poll_id,
      reponses: JSON.stringify(answers),
      stat: stats(),
    },
  })
  dispatch({
    type: SET_POLL_ANSWER,
    payload: { data: response.data },
    poll_id: poll_id,
    answers: answers,
  })
}

// Annuaire
export const getContacts = (user_id, nonce, r, lastconnect,is_not_cache) => async (dispatch) => {
  console.log("zezezez")
  if(is_not_cache) dispatch({type:'GET_CONTACTS_LOADING'});

  const response = await api.get('/filannu', {
    params: {
      user: user_id,
      nonce: nonce,
      r: r,
      lastconnect: lastconnect,
      cache:is_not_cache ? 0 : 1,
      stat: stats(),
    },
  })
  if(is_not_cache)return dispatch({ type: GET_CONTACTS, payload: { data: response.data, r: r } })

  return false;
  
}

// Annuaire
export const getContact = (user_id, nonce, contact_id) => async (dispatch) => {
  const response = await api.get('/getcontactannu', {
    params: {
      user: user_id,
      nonce: nonce,
      contact: contact_id,
      stat: stats(),
    },
  })
  dispatch({ type: GET_CONTACT, payload: { data: response.data } })
}

// Liste des groupes (CPT) ajoutés par chaque client
export const getGroups = (user_id, nonce,type) => async (dispatch) => {
  const response = await api.get('/getgroups', {
    params: {
      user: user_id,
      nonce: nonce,
      type:type
    },
  })
  dispatch({ type: GET_GROUPS, payload: response.data })
}

// Ajout / suppression d'un contact en favoris
export const toggleFavoriteContact = (user_id, nonce, contact_id) => async (dispatch) => {
  const response = await api.get('/togglecontactfavoris', {
    params: {
      user: user_id,
      nonce: nonce,
      contact: contact_id,
    },
  })
  dispatch({
    type: TOGGLE_FAVORITE_CONTACT,
    payload: response.data,
    contact_id: contact_id,
  })
}

// Annuaire des partenaires
export const getPartners = (user_id, nonce, r, lastconnect,is_not_cache) => async (dispatch) => {
 if (is_not_cache) dispatch({ type: 'GET_PARTNERS_LOADING'})
  const response = await api.get('/filpartners', {
    params: {
      user: user_id,
      nonce: nonce,
      r: r,
      lastconnect: lastconnect,
      stat: stats(),
      cache:is_not_cache ? 0 : 1
    },
  })
  if(is_not_cache)return dispatch({ type: GET_PARTNERS, payload: { data: response.data, r: r } })
  return false
}

export const getPartnerss = (user_id, nonce, r, lastconnect) => async (dispatch) => {
  const response = await api.get('/filpartners', {
    params: {
      user: user_id,
      nonce: nonce,
      r: r,
      lastconnect: lastconnect,
      stat: stats(),
    },
  })
  dispatch({ type: GET_PARTNERS, payload: { data: response.data, r: r } })
}

// Ajout / suppression d'un contact en favoris
export const toggleFavoritePartner = (user_id, nonce, contact_id) => async (dispatch) => {
  const response = await api.get('/togglepartenairefavoris', {
    params: {
      user: user_id,
      nonce: nonce,
      partenaire: contact_id,
    },
  })
  dispatch({
    type: TOGGLE_FAVORITE_PARTNER,
    payload: response.data,
    contact_id: contact_id,
  })
}

// Liste des administrateurs
export const getAdmins = () => async (dispatch) => {
  const response = await api.get('/getadmins', {
    params: {
      user: 1,
      nonce: '',
      stat: stats(),
    },
  })
  dispatch({ type: GET_ADMINS, payload: response.data })
}

// Profil de l'utilisateur connecté
export const getProfile = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/profile', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({ type: GET_PROFILE, payload: response.data })
}




// Mise à jour du profil
export const setProfile =
  (user_id, nonce, first_name, last_name, pro_number, tel_second,email,email_second,fonction, cgu = false,mob )=>
  async (dispatch) => {
    const response = await api.get('/updateprofile', {
      params: {
        user: user_id,
        nonce: nonce,
        nom: last_name,
        prenom: first_name,
        tel: pro_number,
        tel_second: tel_second,
        email:email,
        email_second:email_second,
        fonction:fonction,
        cgu: cgu,
        mob:mob,
        stat: stats(),
      },
    })
    dispatch({ type: SET_PROFILE, payload: response.data })
  }

// Mise à jour du profil
export const acceptCGU =
  (user_id, nonce, cgu = false) =>
  async (dispatch) => {
    const response = await api.get('/updateprofile', {
      params: {
        user: user_id,
        nonce: nonce,
        cgu: cgu,
        stat: stats(),
      },
    })
    dispatch({ type: ACCEPT_CGU, payload: response.data })
  }

// Modification de la photo de profil
export const setProfilePicture = (form_data) => async (dispatch) => {
  const response = await api.post('/updatephoto', form_data)
  dispatch({ type: SET_PROFILE_PICTURE, payload: response.data })
}

export const resetProfileMessage = () => {
  return {
    type: RESET_PROFILE_MESSAGE,
  }
}

export const setProfileMessage = (message) => {
  return {
    type: SET_PROFILE_MESSAGE,
    message: message,
  }
}

export const deletePostNotification = (post_id, rubric_id) => {
  return {
    type: DELETE_POST_NOTIFICATION,
    post_id: post_id,
    rubric_id: rubric_id,
  }
}

export const deletePostsNotification = (rubric_id) => {
  return {
    type: DELETE_POSTS_NOTIFICATION,
    rubric_id: rubric_id,
  }
}

export const deletePollsNotification = (rubric_id) => {
  return {
    type: DELETE_POLLS_NOTIFICATION,
    rubric_id: rubric_id,
  }
}

export const deleteEventsNotification = (rubric_id) => {
  return {
    type: DELETE_EVENTS_NOTIFICATION,
    rubric_id: rubric_id,
  }
}

export const deleteSocialsNotification = (rubric_id) => {
  return {
    type: DELETE_SOCIALS_NOTIFICATION,
    rubric_id: rubric_id,
  }
}

export const deleteCategoryNotification = (rubric_id) => {
  return {
    type: DELETE_CATEGORY_NOTIFICATION,
    rubric_id: rubric_id,
  }
}

// Changement de mot de passe
export const setNewPassword = (user_id, nonce, pass) => async (dispatch) => {
  const response = await api.get('/newpass', {
    params: {
      user: user_id,
      noken: nonce,
      pass: pass,
      stat: stats(),
    },
  })
  dispatch({ type: SET_NEW_PASSWORD, payload: response.data, password: pass })
}

// Mise hors-ligne ou en ligne
export const setProfileStatus =
  (user_id, nonce, new_status, date_begin = false, date_end = false) =>
  async (dispatch) => {
    const response = await api.get('/togglestatususer', {
      params: {
        user: user_id,
        nonce: nonce,
        new_status: new_status,
        date_begin: date_begin,
        date_end: date_end,
        stat: stats(),
      },
    })
    dispatch({
      type: SET_PROFILE_STATUS,
      payload: response.data,
      status: new_status,
      date_begin,
      date_end,
    })
  }

// Post
export const getPost = (user_id, nonce, post_id) => async (dispatch) => {
  const response = await api.get('/onepost', {
    params: {
      user: user_id,
      nonce: nonce,
      post: post_id,
      stat: stats(),
    },
  })
  dispatch({ type: GET_POST, payload: response.data })
}

export const getSocialPost = (user_id, nonce, post_id) => async (dispatch) => {



  try{
    dispatch({type:GET_SOCIAL_POST_LOADING})

  const response=  
     await api.get('/onepost', {
      params: {
        user: user_id,
        nonce: nonce,
        post: post_id,
        stat: stats(),
      },
    })
    if(response.data){
      dispatch({ type: GET_SOCIAL_POST, payload: response.data })
      }
    else dispatch({type:GET_SOCIAL_POST_ERROR}) 
    
  }
  catch(error){
    dispatch({type:GET_SOCIAL_POST_ERROR}) 
  }
  
}

export const resetPost = () => {
  return {
    type: RESET_POST,
  }
}

// Ajout / suppression d'un like sur un post
export const setLike = (user_id, nonce, post_id, rubric_id) => async (dispatch) => {
  const response = await api.get('/newlike', {
    params: {
      user: user_id,
      nonce: nonce,
      post: post_id,
      stat: stats(),
    },
  })
  dispatch({ type: SET_LIKE, payload: response.data, rubric_id, post_id })
}

// Ajout d'un commentaire sur un post
export const setComment = (user_id, nonce, post_id, comment) => async (dispatch) => {
  const response = await api.get('/newcom', {
    params: {
      user: user_id,
      nonce: nonce,
      post: post_id,
      com: comment,
      stat: stats(),
    },
  })
  dispatch({ type: SET_COMMENT, payload: response.data })
}

export const moderateComment =
  (user_id, nonce, comment_id, state, reason = false) =>
  async (dispatch) => {
    const response = await api.get('/moderatecomment', {
      params: {
        user: user_id,
        nonce: nonce,
        comment: comment_id,
        status: state,
        motif: reason,
        stat: stats(),
      },
    })
    dispatch({
      type: MODERATE_COMMENT,
      payload: response.data,
      com_id: comment_id,
      state: state,
    })
  }

// Nouveau téléchargement de PDF lié à un article
export const getPDF = () => async (dispatch) => {
  const response = await api.get('/newdload', {
    params: {
      user: 1,
      nonce: '',
      post: 1,
      stat: stats(),
    },
  })
  dispatch({ type: GET_PDF, payload: response.data })
}

// ADMIN : Création de post
export const adminSetPost = (form_data, rubric_id) => async (dispatch) => {
  const response = await api.post('/adcreatepost', form_data)
  console.log(form_data)
  dispatch({
    type: ADMIN_SET_POST,
    payload: response.data,
    rubric_id: rubric_id,
  })
}

// ADMIN : Suppression de post
export const adminDeletePost =
  (user_id, nonce, post_id, rubric_id, post_index = null) =>
  async (dispatch) => {
    const response = await api.get('/addelpost', {
      params: {
        user: user_id,
        nonce: nonce,
        post: post_id,
        stat: stats(),
      },
    })
    dispatch({
      type: ADMIN_DELETE_POST,
      payload: response.data,
      rubric_id: rubric_id,
      post_index: post_index,
      post_id: post_id,
    })
  }

// ADMIN : Suppression de post
export const adminDeleteSocialPost = (user_id, nonce, post_id) => async (dispatch) => {
  const response = await api.get('/addelpost', {
    params: {
      user: user_id,
      nonce: nonce,
      post: post_id,
      stat: stats(),
    },
  })
  dispatch({
    type: ADMIN_DELETE_SOCIAL_POST,
    payload: response.data,
    post_id: post_id,
  })
}

// ADMIN : Suppression de post
export const adminDeletePicture =
  (user_id, nonce, picture_id, rubric_id, picture_index = null) =>
  async (dispatch) => {
    const response = await api.get('/addelpost', {
      params: {
        user: user_id,
        nonce: nonce,
        post: picture_id,
        stat: stats(),
      },
    })
    dispatch({
      type: ADMIN_DELETE_PICTURE,
      payload: response.data,
      rubric_id: rubric_id,
      picture_index: picture_index,
    })
  }

// ADMIN : Liste des groupes
export const adminGetGroups = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/adlistgroups', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({ type: ADMIN_GET_GROUPS, payload: response.data })
}

// ADMIN : Liste des types de catégories de publications
export const adminGetCategories = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/adlistcats', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({ type: ADMIN_GET_CATEGORIES, payload: response.data })
}

// ADMIN : Liste des posts
export const getAdminPosts = () => async (dispatch) => {
  const response = await api.get('/adlistposts', {
    params: {
      user: 1,
      nonce: '',
      page: 1,
      rub: 1,
      etat: '',
      ante: false,
      r: '',
      stat: stats(),
    },
  })
  dispatch({ type: ADMIN_GET_POSTS, payload: response.data })
}

// ADMIN : Accès à un post pour le modifier
export const adminGetPostForUpdate = (user_id, nonce, post_id) => async (dispatch) => {
  const response = await api.get('/adeditpost', {
    params: {
      user: user_id,
      nonce: nonce,
      post: post_id,
      stat: stats(),
    },
  })
  dispatch({ type: ADMIN_GET_POST_FOR_UPDATE, payload: response.data })
}

// ADMIN : Liste les tags liés à la rubrique des réseaux sociaux
export const adminGetSocialTags = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/adlistsocialtags', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({ type: ADMIN_GET_SOCIAL_TAGS, payload: response.data })
}

export const adminResetSocialTags = () => {
  return {
    type: ADMIN_RESET_SOCIAL_TAGS,
  }
}

// ADMIN : Liste les thématiques liées à la rubrique des réseaux sociaux
export const adminGetSocialThemes = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/adlistsocialthemes', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({ type: ADMIN_GET_SOCIAL_THEMES, payload: response.data })
}

export const adminResetSocialThemes = () => {
  return {
    type: ADMIN_RESET_SOCIAL_THEMES,
  }
}

export const adminResetPostForUpdate = () => {
  return {
    type: ADMIN_RESET_POST_FOR_UPDATE,
  }
}

// ADMIN : Accès à un post pour le modifier
export const acceptEventParticipation = (user_id, nonce, post_id) => async (dispatch) => {
  const response = await api.get('/acceptparticipationevent', {
    params: {
      user: user_id,
      nonce: nonce,
      event: post_id,
    },
  })
  dispatch({
    type: ACCEPT_EVENT_PARTICIPATION,
    payload: response.data,
    event_id: post_id,
  })
}

// ADMIN : Accès à un post pour le modifier
export const refuseEventParticipation = (user_id, nonce, post_id) => async (dispatch) => {
  const response = await api.get('/refuseparticipationevent', {
    params: {
      user: user_id,
      nonce: nonce,
      event: post_id,
    },
  })
  dispatch({
    type: REFUSE_EVENT_PARTICIPATION,
    payload: response.data,
    event_id: post_id,
  })
}

// Liste des posts de la boutique
export const getShoppingPosts =
  (user_id, nonce, typologie, page, lastconnect, r = '') =>
  async (dispatch) => {
    const response = await api.get('/getallarticles', {
      params: {
        user: user_id,
        nonce: nonce,
        typologie: typologie,
        page: page,
        lastconnect: lastconnect,
        r: r,
        stat: stats(),
      },
    })
    dispatch({
      type: GET_SHOPPING_POSTS,
      payload: { data: response.data, page: page, r: r, typologie: typologie },
    })
  }

// Post de la boutique
export const getShoppingPost = (user_id, nonce, post_id) => async (dispatch) => {
  const response = await api.get('/getonearticle', {
    params: {
      user: user_id,
      nonce: nonce,
      article: post_id,
      stat: stats(),
    },
  })
  dispatch({ type: GET_SHOPPING_POST, payload: response.data })
}

export const resetShoppingPost = () => {
  return {
    type: RESET_SHOPPING_POST,
  }
}

// Contenu du panier
export const getShoppingCart = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/getcart', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({ type: GET_SHOPPING_CART, payload: response.data })
}

export const resetShoppingCart = () => {
  return {
    type: RESET_SHOPPING_CART,
  }
}

// Mise à jour du contenu du panier
export const setShoppingCart = (user_id, nonce, article) => async (dispatch) => {
  const response = await api.get('/addupdatecart', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
      article: article,
    },
  })
  dispatch({ type: UPDATE_CART, payload: response.data })
}

// Mise à jour du contenu du panier
export const deleteShoppingItem = (user_id, nonce, panier_id) => async (dispatch) => {
  const response = await api.get('/removefromcart', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
      panier_id: panier_id,
    },
  })
  dispatch({ type: DELETE_SHOPPING_ITEM, payload: response.data })
}

// Check la validité d'un code promo
export const setShoppingPromocode = (user_id, nonce, codepromo) => async (dispatch) => {
  const response = await api.get('/verifcodepromo', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
      codepromo: codepromo,
    },
  })
  dispatch({ type: SET_SHOPPING_PROMOCODE, payload: response.data })
}

// Finalise la commande
export const validateShoppingOrder = (form_data) => async (dispatch) => {
  // console.log(form_data);
  const response = await api.post('/passercommande', form_data)
  dispatch({ type: VALIDATE_SHOPPING_ORDER, payload: response.data })
}

// Réinitialise la commande dans redux
export const setShoppingTypologie = (typologie) => {
  return {
    type: SET_SHOPPING_TYPOLOGIE,
    typologie: typologie,
  }
}

// Réinitialise la commande dans redux
export const resetShoppingOrder = () => {
  return {
    type: RESET_SHOPPING_ORDER,
  }
}

// Récupère les taxonomies des produits
export const getShoppingTypologies = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/gettypologies', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({ type: GET_SHOPPING_TYPOLOGIES, payload: response.data })
}

// Tracking du clic sur "Appel" sur la fiche d'un adhérent via l'annuaire
export const trackingContactCall =
  (user_id, nonce, contact_id, interne = null) =>
  async (dispatch) => {
    const response = await api.get('/track_appel', {
      params: {
        user: user_id,
        nonce: nonce,
        post: contact_id,
        stat: stats(),
        in: interne,
      },
    })
    dispatch({ type: TRACKING_CONTACT_CALL, payload: response.data })
  }

// Tracking du clic sur "Email" sur la fiche d'un adhérent via l'annuaire
export const trackingContactEmail =
  (user_id, nonce, contact_id, interne = null) =>
  async (dispatch) => {
    const response = await api.get('/track_mail', {
      params: {
        user: user_id,
        nonce: nonce,
        post: contact_id,
        stat: stats(),
        in: interne,
      },
    })
    dispatch({ type: TRACKING_CONTACT_EMAIL, payload: response.data })
  }

// Tracking des ouvertures de PDF sur les articles
export const trackingDocumentDownload = (user_id, nonce, post_id) => async (dispatch) => {
  const response = await api.get('/track_dload', {
    params: {
      user: user_id,
      nonce: nonce,
      post: post_id,
      stat: stats(),
    },
  })
  dispatch({ type: TRACKING_DOCUMENT_DOWNLOAD, payload: response.data })
}

// Tracking des clics vers les liens externes
export const trackingExternalLink = (user_id, nonce, url) => async (dispatch) => {
  const response = await api.get('/track_externe', {
    params: {
      user: user_id,
      nonce: nonce,
      link: url,
      stat: stats(),
    },
  })
  dispatch({ type: TRACKING_EXTERNAL_LINK, payload: response.data })
}

// Tracking du partage d'un article de la rubrique "Réseau social"
export const trackingSocialPublication = (user_id, nonce, post_id) => async (dispatch) => {
  const response = await api.get('/track_partage', {
    params: {
      user: user_id,
      nonce: nonce,
      post: post_id,
      stat: stats(),
    },
  })
  //dispatch({ type: TRACKING_SOCIAL_PUBLICATION, payload: response.data })
}

// Gère la connexion Linkedin
export const linkedinConnect = (user_id, nonce, auth_code, uri) => async (dispatch) => {
  const response = await api.post('/linkedinconnect', {
    user: user_id,
    nonce: nonce,
    code: auth_code,
    uri,
    stat: stats(),
  })
  dispatch({ type: LINKEDIN_CONNECT, payload: response.data })
}

// Récupère les comptes Linkedin où la publication est autorisée
export const linkedinGetAccounts = (user_id, nonce, access_token) => async (dispatch) => {
  const response = await api.get('/linkedinpages', {
    params: {
      user: user_id,
      nonce: nonce,
      access_token: access_token,
      stat: stats(),
    },
  })
  dispatch({ type: LINKEDIN_GET_ACCOUNTS, payload: response.data })
}

// Publie sur un compte Linkedin
export const linkedinSetPost = (user_id, nonce, access_token, post_id, text, urn) => async (dispatch) => {
  const response = await api.post('/linkedinpost', {
    user: user_id,
    nonce: nonce,
    access_token,
    id: post_id,
    text,
    urn,
  })
  dispatch({ type: LINKEDIN_SET_POST, payload: response.data })
}

// Réinitialise la commande dans redux
export const linkedinResetPost = () => {
  return {
    type: LINKEDIN_RESET_POST,
  }
}

// Récupère les Chiffres d'Affaires liés à l'entreprise de l'utilisateur
export const getCA = (user_id, nonce) => async (dispatch) => {
  const response = await api.get('/getcompanyca', {
    params: {
      user: user_id,
      nonce: nonce,
      stat: stats(),
    },
  })
  dispatch({ type: GET_CA, payload: response.data })
}

// Ajoute un Chiffre d'Affaires pour l'entreprise liée à l'utilisateur
export const setCA = (form_data) => async (dispatch) => {
  const response = await api.post('/setcompanyca', form_data)
  dispatch({ type: SET_CA, payload: response.data })
}
