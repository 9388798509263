import { PGL_CALLBACK, PGL_CALLBACK_RESET } from '../../actions/pgl/types'

const callbackReducer = (state = [], action) => {
  switch (action.type) {
    case PGL_CALLBACK:
      if (action.payload) {
        return action.payload
      } else {
        return state
      }
    case PGL_CALLBACK_RESET:
      return []
    default:
      return state
  }
}

export default callbackReducer
