import React from 'react';

export const MondialInstagram = () => {
  return (
    <svg
      version="1.1"
      id="Icon_ionic-logo-instagram"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 21.6 21.6"
      style={{ enableBackground: 'new 0 0 21.6 21.6' }}
    >
      <path
        id="Tracé_250"
        d="M15.3,1.8c2.5,0,4.5,2,4.5,4.5v9c0,2.5-2,4.5-4.5,4.5h-9c-2.5,0-4.5-2-4.5-4.5v-9c0-2.5,2-4.5,4.5-4.5H15.3
    M15.3,0h-9C2.8,0,0,2.8,0,6.3v9c0,3.5,2.8,6.3,6.3,6.3h9c3.5,0,6.3-2.8,6.3-6.3v-9C21.6,2.8,18.8,0,15.3,0z"
      />
      <path
        id="Tracé_251"
        d="M16.6,6.3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3S18,4.2,18,4.9C18,5.7,17.4,6.3,16.6,6.3
   C16.6,6.3,16.6,6.3,16.6,6.3z"
      />
      <path
        id="Tracé_252"
        d="M10.8,7.2c2,0,3.6,1.6,3.6,3.6s-1.6,3.6-3.6,3.6s-3.6-1.6-3.6-3.6S8.8,7.2,10.8,7.2
   C10.8,7.2,10.8,7.2,10.8,7.2 M10.8,5.4c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4s5.4-2.4,5.4-5.4l0,0C16.2,7.8,13.8,5.4,10.8,5.4z"
      />
    </svg>
  );
};
