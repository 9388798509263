import React from 'react';

export const NavbarBalance = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 474.384 474.384"
      style={{ enableBackground: 'new 0 0 474.384 474.384' }}
    >
      <g>
        <g>
          <path
            d="M467.592,238.353L407.51,111.398h25.078c5.771,0,10.449-4.678,10.449-10.449S438.359,90.5,432.588,90.5H281.6
			c-5.352-24.526-29.572-40.07-54.098-34.718c-17.365,3.789-30.929,17.353-34.718,34.718H41.796
			c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h25.078L6.792,238.353c-3.956,1.475-6.636,5.185-6.792,9.404
			c-0.001,45.589,36.956,82.548,82.545,82.549c0.175,0,0.349-0.001,0.524-0.002c45.731-0.557,82.748-37.343,83.592-83.069
			c0.068-4.353-3.029-8.114-7.314-8.882L99.265,111.398h93.518c4.704,15.995,17.698,28.189,33.959,31.869v195.396
			c-35.531,4.743-61.964,35.209-61.649,71.053c-0.008,5.482,4.43,9.933,9.913,9.94c0.179,0,0.358-0.004,0.536-0.014h123.298
			c5.475,0.289,10.147-3.916,10.435-9.39c0.009-0.179,0.014-0.357,0.014-0.536c0.315-35.844-26.118-66.31-61.649-71.053V143.267
			c16.261-3.68,29.255-15.875,33.959-31.869h93.518l-60.082,126.955c-4.366,0.994-7.426,4.928-7.314,9.404
			c-0.001,45.589,36.956,82.548,82.545,82.549c0.175,0,0.349-0.001,0.524-0.002c45.646-0.276,82.742-36.908,83.592-82.547
			C474.098,243.584,471.463,239.936,467.592,238.353z M83.069,309.406L83.069,309.406c-30.323,0.139-56.244-21.795-61.127-51.722
			h122.776C139.025,287.357,113.281,308.956,83.069,309.406z M135.837,236.785H30.302l52.767-113.371L135.837,236.785z
			 M287.347,398.745h-100.31c5.793-23.228,26.233-39.836,50.155-40.751C261.113,358.909,281.554,375.517,287.347,398.745z
			 M237.192,123.414c-13.561,0-24.555-10.994-24.555-24.555s10.994-24.555,24.555-24.555s24.555,10.994,24.555,24.555
			S250.753,123.414,237.192,123.414z M391.314,123.414l52.767,113.371H338.547L391.314,123.414z M390.792,309.406
			c-30.323,0.139-56.244-21.795-61.127-51.722h122.776C446.748,287.357,421.003,308.956,390.792,309.406z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
