import styles from './BackButtonPage.module.scss';
import { ArrowLeft } from "../../icons/ArrowLeft"
import {useHistory,useLocation} from 'react-router-dom';
import { goBack } from '../../utils/routing';
import { useLastLocation } from 'react-router-last-location';
export default function BackButtonPage({classNames}){
    
    const history = useHistory();
    const lastLocation = useLastLocation();
    const location = useLocation();

    function goBack(){
        if(lastLocation!== null){
            history.goBack();
        }else{
            if(location.pathname.startsWith('/user')){
                
                if(location.pathname.startsWith('/user-partner')){
                    history.replace('/partenaires')
                }
                else{
                    history.replace('/adherents') 
                    }
                
                }
                else if(location.pathname.startsWith('/entreprise')){
                    if(location.pathname.startsWith('/entreprise-partner')){
                        history.replace('/partenaires')
                    }
                    else{
                        history.replace('/adherents') 
                        }
                }
                else{
                    history.replace('/')
                }
         
        }
    
    }

    return(
        <button onClick={goBack} className={classNames}>
           <div className={styles["container-back-button"]}> 
           <ArrowLeft/>
            <div>Retour</div>
            </div>
        </button>
    )
}