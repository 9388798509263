import React from 'react';

export const Tab = (props) => {
  return (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 50"
    >
      <defs>
        <linearGradient id="a" x1="200" y1="50" x2="200" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <title>path</title>
      <path fill="url(#a)" d="M0,0V4H38C83,4,59,50,83,50H317c24,0,0-46,45-46h38V0Z" transform="translate(0 0)" />
    </svg>
  );
};
