import { forwardRef } from "react";
import ModalError from "./modal-error";
import ModalSuccess from "./modal-success";

const Components = {
    error:ModalError,
    success:ModalSuccess
}

function ModalContainer({type,...props},ref){
    console.log(type)
    let Component = Components[type];
    if(Component!==undefined) return <Component ref={ref} {...props} />
    return null
}


export default forwardRef(ModalContainer)