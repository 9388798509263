import React from 'react';

export const MondialPhotos = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 17.9 14.9"
      style={{ enableBackground: 'new 0 0 17.9 14.9' }}
    >
      <g id="Icon_feather-camera" transform="translate(0.6 0.6)">
        <path
          d="M15.2,14.3H1.5c-1.2,0-2.1-1-2.1-2.1V3.8c0-0.6,0.2-1.1,0.6-1.5s0.9-0.6,1.5-0.6h2.7l1.3-2c0.1-0.2,0.3-0.3,0.5-0.3h4.6
     c0.2,0,0.4,0.1,0.5,0.3l1.3,2h2.7c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5v8.4C17.3,13.3,16.4,14.3,15.2,14.3z M1.5,2.9
     C1.3,2.9,1,3,0.9,3.1S0.6,3.6,0.6,3.8v8.4c0,0.5,0.4,0.9,0.9,0.9h13.7c0.5,0,0.9-0.4,0.9-0.9V3.8c0-0.2-0.1-0.5-0.3-0.7
     c-0.2-0.2-0.4-0.3-0.6-0.3h-3c-0.2,0-0.4-0.1-0.5-0.3l-1.3-2H6.4l-1.3,2C4.9,2.8,4.8,2.9,4.6,2.9H1.5z"
        />
        <path
          d="M8.4,11.2c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6c0,0,0,0,0,0c1,0,1.9,0.4,2.6,1.1C11.6,5.7,12,6.6,12,7.6c0,0,0,0,0,0
     C12,9.6,10.4,11.2,8.4,11.2z M8.4,5.2C8.4,5.2,8.4,5.2,8.4,5.2C7,5.2,5.9,6.2,5.9,7.6C5.9,8.9,7,10,8.4,10s2.4-1.1,2.4-2.4
     c0-0.7-0.3-1.3-0.7-1.7C9.6,5.4,9,5.2,8.4,5.2z"
        />
      </g>
    </svg>
  );
};
