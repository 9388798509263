import * as React from "react"

const AfdfPublicationsOuvertes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.9 15.4"
    style={{
      enableBackground: "new 0 0 17.9 15.4",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M9 0c2.3 0 4.5.7 6.3 2.1 1.6 1.2 2.6 3.1 2.7 5.1 0 2-1 3.9-2.7 5.1-1.8 1.4-4 2.1-6.3 2.1-1.2 0-2.3-.2-3.5-.6-1.4 1-3 1.6-4.7 1.6-.3 0-.6-.2-.7-.5-.1-.3-.1-.6.2-.8.7-.8 1.2-1.6 1.6-2.6C.7 10.4 0 8.8 0 7.2c0-2 1-3.9 2.7-5.1C4.5.7 6.7 0 9 0zm0 13.4c4.4 0 8-2.8 8-6.2S13.4 1 9 1 1 3.8 1 7.2c0 1.5.7 2.9 1.7 3.9l.2.2-.1.3c-.3 1.1-.8 2-1.6 2.9 1.4-.1 2.7-.7 3.8-1.5l.2-.2.3.1c1.2.3 2.3.5 3.5.5zM4.7 5.7c.8 0 1.5.7 1.5 1.6 0 .8-.7 1.5-1.6 1.5-.8-.1-1.4-.8-1.4-1.6 0-.9.7-1.5 1.5-1.5zm0 2c.3 0 .5-.3.5-.6s-.2-.4-.5-.4-.5.2-.5.5.2.5.5.5zm4.3-2c.8 0 1.5.7 1.5 1.6 0 .8-.7 1.5-1.6 1.5-.8-.1-1.5-.8-1.5-1.6 0-.9.7-1.5 1.6-1.5zm0 2c.3 0 .5-.3.5-.6s-.3-.4-.6-.4-.5.2-.5.5.3.5.6.5zm4.2-2c.8 0 1.5.7 1.5 1.6s-.7 1.4-1.6 1.4c-.8 0-1.5-.7-1.5-1.5 0-.9.7-1.5 1.6-1.5zm0 2c.3 0 .5-.3.5-.6s-.3-.5-.6-.5-.5.2-.5.5c0 .4.3.6.6.6z" />
  </svg>
)

export default AfdfPublicationsOuvertes
