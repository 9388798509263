// import {
//   CHECK_AUTH,
//   SIGN_IN,
//   GET_TEMPORARY_CODE,
//   SEND_MAIL,
//   SEND_EMAIL_TO_ADMIN,
//   GET_NAVBAR,
//   GET_POSTS,
//   GET_CONTACTS,
//   GET_PARTNERS,
//   GET_ADMINS,
//   GET_PROFILE,
//   SET_PROFILE,
//   SET_PROFILE_PICTURE,
//   SET_NEW_PASSWORD,
//   GET_POST,
//   SET_LIKE,
//   SET_COMMENT,
//   GET_PDF,
//   ADMIN_SET_POST,
//   ADMIN_DELETE_POST,
//   ADMIN_GET_GROUPS,
//   ADMIN_GET_CATEGORIES,
//   ADMIN_GET_POSTS,
//   ADMIN_GET_POST_FOR_UPDATE
// } from "reduxactions/pci/types";
import { GET_POST, RESET_POST, SET_LIKE, SET_COMMENT, MODERATE_COMMENT, ADMIN_DELETE_POST } from '../../actions/pci/types'
import update from 'immutability-helper'

const postReducer = (state = [], action) => {
  switch (action.type) {
    case ADMIN_DELETE_POST:
      return false
    case GET_POST:
      return action.payload
    case SET_COMMENT:
      return update(state, { coms: { $set: action.payload.comments } })
    case SET_LIKE:
      return update(state, { likes: { $set: action.payload } })
    case MODERATE_COMMENT:
      if (action.state === 'publish') {
        const comment_to_update = state.coms.findIndex((comment) => comment.id_com === action.com_id)

        return update(state, {
          coms: {
            [comment_to_update]: { status: { $set: action.state } },
          },
        })
      } else {
        let coms = []
        state.coms.forEach((com) => {
          if (com.id_com !== action.com_id) {
            coms.push(com)
          }
        })
        return update(state, { coms: { $set: coms } })
      }

    case RESET_POST:
      return false
    default:
      return state
  }
}

export default postReducer
