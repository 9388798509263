import { createSlice,createAsyncThunk, } from "@reduxjs/toolkit"; 
import { AxiosResponse } from "axios";
import api from "../../config/pci/api";
import { errorRequest } from "../../redux";
import { RootState } from "../../redux/reducers";
import { stats } from "../../config/stats";

type SocialsPosts = {
    auteur:string,
    date:string,
    gps:{entreprise:{gp:string,id:number,nom:string}},
    id:number,
    new:boolean,
    photo:{url:string,w:number,h:number},
    rs:string,
    slug:string,
    status:string,
    tags:Array<string>,
    titre:string
}

type socialPostsResponses = {
    breadcrumb:Array<{slug:string,nom:string}>,
    mega_menu:null,
    posts:SocialsPosts[]| [] ,
    error:string,
    last:boolean
} 

type socialThematiqueResponse = {
    thematiques:{[key:string]:string},
    error:string

}

type CheckoutType = 'NOT_USE' |'LOADING' | 'READY' |  'ERROR'


type SocialPostInitPost  = {
    posts:{
    checkOutState: CheckoutType,
    errorMessage:string,
    posts : SocialsPosts[] | []
    checkOutMoreState:CheckoutType,
    nextPage:number,
    currentPage:number,
    endReached:boolean,
    },
    thematic:{
        checkoutThematic:CheckoutType,
        thematic:{[key:string]:string},
        selected:string
    },
    breadcrumb:any[]
}


const initialState : SocialPostInitPost = {
   posts:{
    checkOutMoreState:'READY',
    checkOutState : "LOADING",
    errorMessage  : "",
    posts:[],
    nextPage:2,
    currentPage:1,
    endReached:false
   },
   thematic:{
    checkoutThematic:'LOADING',
    thematic:{},
    selected:'tous'
   },
   breadcrumb:[]
};


export const getSocialPosts = createAsyncThunk("/socials/getPosts",
    async({page}:{page:number,cat:string},{rejectWithValue,getState})=>{
        const {auth:{authentication:{id,nonce}}}  = getState() as any
        try{
            const response = await api.get<socialPostsResponses>('/filposts',{
                params:{
                    user:id,
                    nonce:nonce,
                    page:1,
                    cat:'partage',
                    stat:stats(),
                    r: null,
                    categ_rs: false,
                    thematique: false
                }
            })

            if(response.hasOwnProperty("error") && response?.data?.error) 
                return rejectWithValue(response?.data?.error);
            
            return response.data

        }catch(error:any){
            errorRequest(error,rejectWithValue)
}});



export const getMoreSocialPosts = createAsyncThunk("/socials/getMoreSocialPosts",
    async({page}:{page:number,cat:string},{rejectWithValue,getState})=>{
        const {auth:{authentication:{id,nonce}}}  = getState() as any
        try{
            const response = await api.get<socialPostsResponses>('/filposts',{
                params:{
                    user:id,
                    nonce:nonce,
                    page:page,
                    cat:'partage',
                    stat:stats(),
                    r: null,
                    categ_rs: false,
                    thematique: false
                }
            })

            if(response.hasOwnProperty("error") && response?.data?.error) 
                return rejectWithValue(response?.data?.error);
            
            return response.data

        }catch(error:any){
            errorRequest(error,rejectWithValue)
}});


export const getSocialsThematic = createAsyncThunk("/socials/getThematic",
    async(_,{rejectWithValue,getState})=>{
        const {auth:{authentication:{id,nonce}}}  = getState() as any
        try{
            const response = await api.get<socialThematiqueResponse>('/listthematiquesrs',{
                params:{
                    user:id,
                    nonce:nonce,
                    stats:stats()
                }
            })

            if(response.hasOwnProperty("error") && response?.data?.error) 
                return rejectWithValue(response?.data?.error);
            
            return response.data

        }catch(error:any){
            errorRequest(error,rejectWithValue)
}})





export const socialsSlice = createSlice({
  name: 'socials',
  initialState,
  reducers: {
    setThematicSelected:(state,{payload})=>{
        state.thematic.selected = payload
    }
  },
  extraReducers:(builder)=>{

    /** FIRST PAGE POST */
    builder.addCase(
        getSocialPosts.pending,(state)=>{
            state.posts.checkOutState = 'LOADING'
        });
    builder.addCase(
        getSocialPosts.rejected,(state)=>{
            state.posts.checkOutState = "ERROR";
        });
    builder.addCase(
        getSocialPosts.fulfilled,(state,{payload})=>{
            state.posts.checkOutState = "READY";
            state.posts.posts = payload?.posts as SocialsPosts[]
            state.posts.endReached = payload?.last ?? false
            state.breadcrumb = (payload?.breadcrumb as any[])
        });
    

    /**MORE POSTS */
    builder.addCase(
        getMoreSocialPosts.pending,(state,{payload})=>{
            state.posts.checkOutMoreState = "LOADING";
        });

    builder.addCase(
        getMoreSocialPosts.fulfilled,(state,{payload})=>{
            state.posts.checkOutState = "READY";
            state.posts.posts =  [...state.posts.posts,...payload?.posts as any | SocialsPosts[]]
            state.posts.endReached = payload?.last ?? false
        });
    builder.addCase(
        getMoreSocialPosts.rejected,(state,{payload})=>{
            state.posts.checkOutMoreState = "ERROR";
        });
    
     /** THEMATIC */
    builder.addCase(
        getSocialsThematic.pending,(state)=>{
            state.thematic.checkoutThematic = 'LOADING'
        });
    builder.addCase(
        getSocialsThematic.rejected,(state)=>{
            state.thematic.checkoutThematic = "ERROR";
        });
    builder.addCase(
        getSocialsThematic.fulfilled,(state,{payload})=>{
            state.thematic.checkoutThematic = "READY";
            state.thematic.thematic = payload?.thematiques as {[key:string]:string}
        });

    }
});

export const {setThematicSelected} = socialsSlice.actions;

export default socialsSlice.reducer;
