import { ComponentProps } from "react";
import CellDate from "./cell-date";
import CellDocument from "./cell-document";
import CellLink from "./cell-link";


/**
 * Factory Cell for table by name type
 */

const cells = {
    document:CellDocument,
    link:CellLink,
    date:CellDate
}


type CellTableProps = {
    name:keyof typeof cells
} & ComponentProps<any>

export default function CellTable({name,...props}:CellTableProps):JSX.Element | null{
    let Component = cells[name];
    
    if(Component!==undefined) return <Component {...props}/>
    else return null
}