import Layout from '../../layout'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import styles from './SocialNetwork.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import { Fragment, useEffect } from 'react'
import {
  deleteSocialNetwork,
  getFirstRowSocialNetwork,
  getMoreSocialNetwork,
  getSocialNetWork,
} from './socialNetworkThunk'
import Loading from '../../components/pgl/Loading'
import Breadcrump from '../../components/breadcrump/BreadCrump'
import ListCategory from '../publications/list-category'
import Masonry from 'react-masonry-css'
import CardSocialPosts from './cards-social-posts'
import config from '../../config'

//breakpoint column
const breakpointColumn = {
  default: 4,
  1000: 3,
  768: 2,
  600: 1,
}

export default function SocialNetwork() {
  //instancie hooks
  const history = useHistory()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const query = useQuery()
  const thematic = query.get('thematic')
  //query init
  const currentThematic = thematic ?? 'all'
  //reducer state
  const { checkOutState, error, posts, breadcrumb, list_category } = useAppSelector(
    (state) => state.socialsNetwork
  )
  const publication = posts[currentThematic]

  useEffect(() => {
    if (Object.keys(posts).length === 0) {
      dispatch(getFirstRowSocialNetwork({ thematic: currentThematic }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentThematic])

  function onChangeCategory(theme, check, pub) {
    if (pub.checkoutPublicationState !== 'LOADING' && check !== 'LOADING') {
      history.push({
        pathname: location.pathname,
        search: `?thematic=${theme}`,
      })
      dispatch(getSocialNetWork({ thematic: theme }))
    }
  }

  function createTitle(check, bread) {
    if (check === 'READY' && bread.length > 0) {
      return bread[bread.length - 1].nom
    }
    return ''
  }

  function onFetchMore() {
    if (publication && publication.CheckoutMoreState === 'READY')
      dispatch(getMoreSocialNetwork({ thematic: currentThematic, page: Number(publication.page) }))
    else return false
  }

  function onUpdateAdminPublication(val: string | number) {
    window.open(`${config.admin_url}post.php?post=${val}&action=edit`, '_blank')
  }

  function onDeleteAdminPublication(val: string | number) {
    dispatch(deleteSocialNetwork({ id_publication: val, thematic: currentThematic }))
  }

  return (
    <Layout type={'nofetch'} title={createTitle(checkOutState, breadcrumb)}>
      {checkOutState === 'LOADING' ? (
        <Loading />
      ) : checkOutState === 'ERROR' ? (
        <div style={{ padding: '1rem' }}>Aucune publication dans cette rubrique.</div>
      ) : (
        <div className={styles['publications-page']}>
          {breadcrumb.length > 0 ? <Breadcrump mega_menu={false} items={breadcrumb} /> : null}
          <ListCategory
            currentCategorie={currentThematic}
            categories={[{ name: 'Toutes les publications', id: 'all' }, ...list_category]}
            onSelect={(theme) => onChangeCategory(theme, checkOutState, publication)}
          />
          <Fragment>
            {publication.checkoutPublicationState == 'LOADING' && <Loading />}
            {publication.checkoutPublicationState == 'ERROR' && <div>Aucune publication dans cette rubrique.</div>}
            {publication && publication.data && publication?.data.length > 0 ? (
              <Masonry
                breakpointCols={breakpointColumn}
                className={styles['grid-posts']}
                columnClassName={styles['grid-posts-column']}
              >
                {publication.data?.map((item, key) => {
                  return (
                    <CardSocialPosts
                      onDeleteAdminPublication={onDeleteAdminPublication}
                      onUpdateAdminPublication={onUpdateAdminPublication}
                      key={'rs' + key}
                      item={item}
                    />
                  )
                })}
              </Masonry>
            ) : (
              <div className={styles['']}>Aucune publication dans cette rubrique.</div>
            )}
            {!publication.last && publication.checkoutPublicationState == 'READY' ? (
              <button onClick={onFetchMore} className={styles['button-more']}>
                {publication?.CheckoutMoreState === 'LOADING' ? 'Chargement ...' : 'Charger plus de publications'}
              </button>
            ) : null}
          </Fragment>
        </div>
      )}
    </Layout>
  )
}
