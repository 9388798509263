import { useEffect,useRef } from "react";
import Portal from "../../../common/portal/Portal";
import styles from './BackgroundVisio.module.scss';
import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import { useClickAway } from "react-use";
import CrossClose from "../../../../icons/CrossClose";
import { Download } from "../../../../icons/Download";

type BackgroundVisioProps ={
    id:string,
    onClose:()=>void,
    children:JSX.Element
}

export default function BackgroundVisio({id,onClose,children}:BackgroundVisioProps){
    //Ref Modal
    const modalRef = useRef(null);
    const targetRef = useRef(null);
    
  

    useEffect(()=>{
        targetRef.current && disableBodyScroll(targetRef.current)
       return ()=>{
          clearAllBodyScrollLocks();
        }
      },[])
    
      useClickAway(modalRef,()=>{
        if(onClose !==undefined && modalRef.current){
            
            return  onClose()
        }
        return false
      })

  
      
    return(
        <Portal id={id}>
            <div ref={targetRef} className={styles['modal-container']}>
                <button onClick={onClose} className={styles["button-close"]}><CrossClose/></button>
                {/*<button className={styles["button-download"]}><Download/><span>Téléchargement</span></button>*/}
                {children}
            </div>
            
        </Portal>
    )
    
};