
import * as React from "react"

const AfdfOffresFournisseurs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.6 17.9"
    style={{
      enableBackground: "new 0 0 25.6 17.9",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M25.6 9.9c0-.7-.7-1.3-2.1-1.7-1.2-.3-2.5-.5-3.8-.5V2.3c0-.1 0-.2-.1-.3-.1-.6-.8-1.1-2-1.5-1.4-.3-2.8-.5-4.2-.5-1.4 0-2.8.2-4.2.5-.9.3-2 .8-2 1.7v.2l.1 2.3C3.9 4.1.7 6.3.1 9.6c-.6 3.3 1.5 6.5 4.8 7.1 1.8.3 3.6-.1 5-1.3 1.1.2 2.2.4 3.3.4.3 1.4 3.3 2.1 6.2 2.1 1.4 0 2.8-.2 4.2-.5.9-.3 2-.8 2-1.7V9.9c0 .1 0 0 0 0zm-7-4.8c0 .1-.2.4-1.3.8-1.3.3-2.6.5-3.9.5-1.2-.1-2.4-.2-3.5-.4-.5-.4-1.1-.7-1.6-1V3.6c.3.2.6.3 1 .4 1.3.3 2.7.5 4 .5 1.4 0 2.8-.2 4.2-.5.4-.1.8-.3 1.1-.4v1.5zM9.5 1.5c1.3-.3 2.6-.5 3.9-.5 1.3 0 2.6.1 3.9.5 1.1.3 1.3.7 1.3.7s-.2.5-1.3.8c-1.3.3-2.6.5-3.9.5-1.3 0-2.5-.1-3.8-.5-1.1-.3-1.4-.6-1.4-.7 0-.1.2-.5 1.3-.8zm0 13c-2.1 1.9-5.3 1.7-7.2-.3S.6 8.9 2.7 7c.9-.9 2.2-1.3 3.4-1.3.5 0 1 .1 1.5.2 1.8.6 3.1 2 3.5 3.8.1.3.1.7.1 1 0 1.5-.6 2.8-1.7 3.8zm1.3.1c.4-.5.7-1.1 1-1.7.4 0 .9.1 1.3.1v1.8c-.7 0-1.5-.1-2.3-.2zm1.4-4.5h.9v1.8h-1c.1-.4.1-.8.1-1.2v-.6zm1.2-.9c-.5 0-1 0-1.5-.1-.2-.7-.5-1.3-.9-1.9.8.1 1.5.1 2.3.1 1.4 0 2.8-.2 4.2-.5.4-.1.7-.3 1.1-.4v1.3c-2.1.1-4.3.5-5.2 1.5zm.7.8s0-.1 0 0c.1-.2.4-.5 1.5-.8 1.2-.3 2.4-.4 3.6-.4h.1c1.3 0 2.6.1 3.9.5 1.1.3 1.3.7 1.3.7 0 .1-.3.4-1.4.8-1.3.3-2.6.5-3.9.5-1.3 0-2.5-.1-3.8-.5-1-.4-1.3-.7-1.3-.8zm9.1 6.4c-1.3.4-2.6.5-3.9.5-1.3 0-2.6-.1-3.9-.5-1.1-.3-1.3-.7-1.3-.7v-1.5c.4.2.8.4 1.3.5 1.3.3 2.5.4 3.8.4 1.4 0 2.8-.2 4.2-.5.4-.1.7-.3 1.1-.4v1.5s-.2.3-1.3.7zm1.4-3.8v.2c0 .1-.2.4-1.3.8-1.3.3-2.6.5-3.9.5-2.8 0-4.7-.6-5.1-1.1v-1.6c.4.2.7.3 1.1.4 1.3.3 2.7.5 4 .5 1.4 0 2.8-.2 4.2-.5.4-.1.8-.3 1.1-.4l-.1 1.2z" />
    <path
      d="M3.2 10.2c.1-.3.2-.6.3-1s.5-.5.7-.8c.3-.2.6-.4.9-.5.3-.1.7-.2 1.1-.2.6 0 1.1.1 1.5.4s.7.6.9 1l-.7.5c-.1-.2-.2-.4-.3-.5-.1-.1-.3-.2-.4-.3s-.3-.2-.5-.2h-.5c-.3 0-.5 0-.7.1s-.4.2-.6.4c-.1.1-.3.3-.4.5-.1.2-.2.4-.2.6h2.6l-.2.6H4.2v.5h2.3l-.2.6h-2l.3.6c.1.2.3.3.4.4s.3.2.5.3c.2.1.4.1.6.1.2 0 .3 0 .5-.1s.3-.1.5-.2.3-.2.4-.3.2-.3.3-.5l.8.5c-.1.2-.2.5-.4.6-.2.2-.4.3-.6.5-.1.1-.4.2-.6.2-.3.1-.5.1-.8.1-.4 0-.7-.1-1-.2-.3-.1-.6-.3-.8-.5-.3-.2-.5-.5-.6-.7-.2-.3-.3-.6-.4-.9h-1l.2-.6h.6v-.5h-.7l.2-.6h.5z"
      style={{
        enableBackground: "new",
      }}
    />
  </svg>
)

export default AfdfOffresFournisseurs
