import React from 'react'

export const Star = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
      <path
        stroke={props.stroke??''}
        fill={props.fill??''}
        fillRule="evenodd"
        d="M9.501 0L6.565 6.253 0 7.256l4.75 4.869L3.63 19l5.871-3.247L15.371 19l-1.12-6.875L19 7.256l-6.565-1.003z"
      />
    </svg>
  )
}
