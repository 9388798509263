import { useMeasure } from '@react-hookz/web';
import { CSSProperties } from 'react';
import styles from './VisioCaroussel.module.scss';
import Slider from 'react-slick';
import BackgroundVisio from '../background-visio';

      const settings = {
        className:styles["img-slider"],
        dots: true,
        swipeToSlide: true, 
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

type VisioCarousselProp ={
    onClose:()=>void,
    medias:Array<string>
}

      
export default function VisioCaroussel({medias,onClose}){
    console.log(medias)
    const [measurements, ref] = useMeasure<HTMLDivElement>();
    const styled = {
        '--height-visio':measurements?.height+'px', 
        '--width-visio':measurements?.width+'px'
} as CSSProperties
    return(
        <BackgroundVisio id={'visio-caroussel'} onClose={onClose}>
        <div style={styled}   ref={ref} className={styles["container-slider"]}>
            <Slider>
            {medias.map(url_img=>{
                return <img   className={styles["image"]} src={url_img}/>
            })} 
            </Slider>
        </div>
        </BackgroundVisio>
        )
}
