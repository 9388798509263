import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/pgl/Loading';
import api from '../../config/pci/api';
import EasyTable from '../../easy-ui/table';
import Layout from '../../layout';
import { useAppSelector } from '../../redux/hooks';
import styles from './DocumentBoard.module.scss';
import { getDocuments,removeNew } from './documentSlice';
export default function DocumentBoard(){
    const {entitled,values,has_more,checkOutState,error,page,checkOutMoreState} = useSelector(state=>state.documents);
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getDocuments({page:1}))
    },[])


    function onFetchMore(){
        dispatch(getDocuments({page:page}))      
    }

    function handleLink(val){
        dispatch(removeNew(val))
    }


    return(
        <Layout errorText={false} title="Documents"  has_back={true}  type={"nofetch"}>
            {checkOutState == 'LOADING'
                ? <Loading/>
                :checkOutState == 'ERROR'
                ? <div>{error}</div>
                :
                <div className={styles["document-board"]}>
                    <EasyTable handleLink={handleLink} entitled={entitled} values={values}/>
                    {has_more &&  
                    <button onClick={onFetchMore} className={styles["button-load"]}>
                        {checkOutMoreState === 'LOADING' ?  `Chargement ...` : `Charger plus de documents`}
                    </button>}
                </div>
            }
        </Layout>
    )
}