import React from 'react';

export const MondialEvenements = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 17.6 17.6"
      style={{ enableBackground: 'new 0 0 17.6 17.6' }}
    >
      <path
        id="Icon_ionic-md-calendar"
        d="M13.9,9.5H9.5v4.4h4.4V9.5z M12.5,0v1.5H5.1V0H2.9v1.5H1.8C0.8,1.5,0,2.3,0,3.3v12.5
   c0,1,0.8,1.8,1.8,1.8h13.9c1,0,1.8-0.8,1.8-1.8V3.3c0-1-0.8-1.8-1.8-1.8h-1.1V0H12.5z M15.8,15.8H1.8V6h13.9L15.8,15.8z"
      />
    </svg>
  );
};
