import React from 'react';

export const Information = () => {
  return (
    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        id="Fill-1"
        fillRule="evenodd"
        d="M7,13.18A6.18,6.18,0,1,1,13.18,7,6.17,6.17,0,0,1,7,13.18M12,2.05A7,7,0,0,0,2.05,12,7,7,0,0,0,12,2.05"
        transform="translate(0 0)"
      />
      <path
        id="Fill-4"
        fillRule="evenodd"
        d="M6.82,10.92a.39.39,0,0,0,.38-.4V6.15a.38.38,0,1,0-.76,0v4.37a.39.39,0,0,0,.38.4m0,.82a1.18,1.18,0,0,1-1.14-1.22V6.15A1.19,1.19,0,0,1,6.82,4.92,1.18,1.18,0,0,1,8,6.15v4.37a1.18,1.18,0,0,1-1.13,1.22"
        transform="translate(0 0)"
      />
      <path
        id="Fill-6"
        fillRule="evenodd"
        d="M6.82,2.65A.38.38,0,1,0,7.2,3a.38.38,0,0,0-.38-.38m0,1.52A1.14,1.14,0,1,1,8,3,1.14,1.14,0,0,1,6.82,4.17"
        transform="translate(0 0)"
      />
    </svg>
  );
};
