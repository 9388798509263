import React from 'react';

export const NavbarContacts = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
      <path d="M12.0393 6.01656C9.5726 6.18879 7.625 8.24439 7.625 10.7549C7.625 13.3782 9.75165 15.5049 12.375 15.5049C14.9984 15.5049 17.125 13.3782 17.125 10.7549C17.125 8.13153 14.9984 6.00488 12.375 6.00488C12.3717 6.00488 12.3684 6.00489 12.3651 6.00489C12.3274 6.00167 12.2891 6 12.25 6C12.1773 6 12.1071 6.00578 12.0393 6.01656ZM10.4183 7.85249C10.1924 8.52871 10.0498 9.3576 10.0108 10.25H8.91115C9.05517 9.25313 9.61933 8.3922 10.4183 7.85249ZM10.044 11.75C10.1073 12.4565 10.2372 13.1094 10.4226 13.6601C9.75575 13.2111 9.25194 12.5386 9.01849 11.75H10.044ZM14.0096 13.8505C14.0107 13.8476 14.0118 13.8447 14.0129 13.8418C14.2328 13.2554 14.3856 12.5359 14.456 11.75H15.7315C15.4623 12.6595 14.8334 13.4146 14.0096 13.8505ZM12.949 11.75C12.8843 12.3676 12.7625 12.9043 12.6084 13.3151C12.4911 13.6278 12.3696 13.8288 12.2727 13.9381C12.2645 13.9473 12.2569 13.9554 12.25 13.9626C12.2431 13.9554 12.2355 13.9473 12.2273 13.9381C12.1304 13.8288 12.0089 13.6278 11.8916 13.3151C11.7375 12.9043 11.6157 12.3676 11.551 11.75H12.949ZM12.9875 10.25H11.5125C11.5542 9.42163 11.6973 8.70308 11.8916 8.18492C12.0089 7.87219 12.1304 7.67121 12.2273 7.56189C12.2355 7.55267 12.2431 7.54456 12.25 7.53743C12.2569 7.54456 12.2645 7.55267 12.2727 7.56189C12.3696 7.67121 12.4911 7.87219 12.6084 8.18492C12.8027 8.70308 12.9458 9.42163 12.9875 10.25ZM14.4892 10.25C14.4465 9.27176 14.2792 8.36984 14.0142 7.66168C14.9796 8.17434 15.6764 9.12572 15.8389 10.25H14.4892ZM12.3075 7.48948C12.3079 7.48922 12.3075 7.48953 12.3075 7.48948C12.3075 7.48935 12.3061 7.48991 12.3034 7.49154C12.305 7.49088 12.3068 7.48985 12.3075 7.48948ZM12.194 7.49035C12.1946 7.49068 12.1955 7.49109 12.1966 7.49154C12.1939 7.48991 12.1925 7.48935 12.1925 7.48948C12.1925 7.48955 12.193 7.48987 12.194 7.49035Z"/>
      <path d="M6.5 2C5.11929 2 4 3.11929 4 4.5V19.5C4 20.8807 5.11929 22 6.5 22H19.75C20.1642 22 20.5 21.6642 20.5 21.25C20.5 20.8358 20.1642 20.5 19.75 20.5H6.5C5.94772 20.5 5.5 20.0523 5.5 19.5H19.75C20.1642 19.5 20.5 19.1642 20.5 18.75V4.5C20.5 3.11929 19.3807 2 18 2H6.5ZM19 4.5V18H5.5V4.5C5.5 3.94772 5.94772 3.5 6.5 3.5H18C18.5523 3.5 19 3.94772 19 4.5Z"/>
    </svg>
  );
};
