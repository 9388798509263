import {
  PGL_LEADS,
  PGL_SORT_LEADS,
  PGL_LEADS_FILTERS,
  PGL_LEADS_COLUMNS,
  PGL_LEADS_COUNT,
  PGL_LEADS_EXPORT,
  PGL_LEADS_EXPORT_RESET,
  PGL_LEADS_FILTERS_FIELD,
  PGL_LEADS_FILTERS_FIELD_RESET,
  PGL_CHANGE_LEADS_CONTEXT,
  PGL_LEADS_LOADING,
} from '../../actions/pgl/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  columns: false,
  count: [],
  export: false,
  leads: [],
  context: 'a_qualifier',
  isLoading: false,
  leadsIsloading:false,
  isStart:false,
  filters: {
    filterList: [],
    input_suggestions: {
      filterId: 0,
      suggestions: [],
    },
    orderby: 'date_demande',
    order: 'desc',
    actives: [],
  },
}

const leadsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {


    case 'PGL_GET_LEADS_IS_LOADING':
      return{
        ...state,
        leadsIsloading:action.payload
      }

    case PGL_LEADS:
    
    let items
      items = action.payload.data.leads
      if (items === undefined) {
        items = []
      }
      return {
        ...state,
        leads: items,
        leadsIsloading:false,
        isStart:action.payload.isStart
      }
    case PGL_CHANGE_LEADS_CONTEXT:
     
      return {
        ...state,
        context: action.payload,
      }
    case PGL_LEADS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case PGL_SORT_LEADS:
      return update(state, {
        filters: {
          orderby: { $set: action.payload.orderby },
          order: { $set: action.payload.order },
          actives: { $set: action.payload.filters },
        },
      })
    case PGL_LEADS_COLUMNS:
      return {
        ...state,
        columns: action.payload,
      }
    case PGL_LEADS_FILTERS_FIELD:
      var array = [];
       if(action.filterId == "entreprise")
        {
          array = ["Zone Blanche",...action.suggestions]
        }
       else array= [...action.suggestions];
      
      return update(state, {
        filters: {
          input_suggestions: {
            filterId: { $set: action.filterId },
            suggestions: { $set: array },
          },
        },
      })
    case PGL_LEADS_FILTERS_FIELD_RESET:
  
      return update(state, {
        filters: {
          input_suggestions: {
            filterId: { $set: 0 },
            suggestions: { $set: false },
          },
        },
      })
    case PGL_LEADS_FILTERS:

      const sorted = Object.entries(action.payload)
        .sort(([, v1], [, v2]) => v1.order - v2.order)
        .reduce(
          (obj, [k, v]) => ({
            ...obj,
            [k]: v,
          }),
          {}
        )
      return update(state, {
        filters: {
          filterList: { $set: sorted },
        },
      })
    case PGL_LEADS_COUNT:
      return {
        ...state,
        count: action.payload,
      }
    case PGL_LEADS_EXPORT:
      return {
        ...state,
        export: action.payload,
        isLoading: false,
      }
    case PGL_LEADS_EXPORT_RESET:
      return {
        ...state,
        export: false,
      }
    default:
      return state
  }
}

export default leadsReducer
