import React from 'react';

export const Calendar = () => {
  return (
    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
      <path
        id="Fill-1"
        fillRule="evenodd"
        d="M32.78,12.48H1.22V7.8A4.23,4.23,0,0,1,5.48,3.61H7v4a.61.61,0,0,0,.61.61h4.7a.61.61,0,0,0,.62-.61v-4h7.78v4a.61.61,0,0,0,.61.61H26a.61.61,0,0,0,.62-.61v-4h1.9A4.23,4.23,0,0,1,32.78,7.8Zm0,16.12a4.23,4.23,0,0,1-4.26,4.19h-23A4.23,4.23,0,0,1,1.22,28.6V13.68H32.78ZM8.2,1.21h3.48V7H8.2V1.21Zm13.71,0h3.48V7H21.91V1.21ZM28.52,2.4h-1.9V.6A.61.61,0,0,0,26,0H21.3a.6.6,0,0,0-.61.6V2.4H12.91V.6a.61.61,0,0,0-.62-.6H7.59A.6.6,0,0,0,7,.6V2.4H5.48A5.45,5.45,0,0,0,0,7.8V28.6A5.45,5.45,0,0,0,5.48,34h23A5.45,5.45,0,0,0,34,28.6V7.8a5.45,5.45,0,0,0-5.48-5.4Z"
      />
      <path
        id="Fill-4"
        fillRule="evenodd"
        d="M5.23,19.4H9.72V17.22H5.23Zm5.69,0h5.13V17.22H10.92Zm6.33,0h5.13V17.22H17.25Zm6.33,0H28V17.22H23.58Zm0,10.3H28V27.29H23.58Zm-6.33,0h5.13V27.29H17.25Zm-6.33,0h5.13V27.29H10.92Zm-5.69,0H9.72V27.29H5.23Zm0-7H9.72v-2H5.23Zm5.69,0h5.13v-2H10.92Zm6.33,0h5.13v-2H17.25Zm6.33,0H28v-2H23.58Zm0,3.3H28V24H23.58Zm-6.33,0h5.13V24H17.25Zm-6.33,0h5.13V24H10.92ZM5.23,26H9.72V24H5.23Zm-.6,5h24a.63.63,0,0,0,.6-.65V16.57a.62.62,0,0,0-.6-.64h-24a.62.62,0,0,0-.6.64V30.34a.63.63,0,0,0,.6.65Z"
      />
    </svg>
  );
};
