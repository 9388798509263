import { createSlice,createAction,createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/pci/api";


type checkOutStateType =  'LOADING' | 'ERROR' | 'READY'
type entitledType = {label:string,'label-mb':string,orderBy:string,type:'date'| 'document' | 'link'} 
type DocumentState  ={
    error:string,
    entitled:{
        date:entitledType,
        document:entitledType,
        fournisseur:entitledType
    } | {},
    values:any[],
    total:number,
    has_more:boolean,
    checkOutState:checkOutStateType,
    page:number,
    checkOutMoreState:checkOutStateType
}

let initDocumentState : DocumentState = {
    error:'',
    entitled:{},
    values:[],
    total:0,
    has_more:false,
    checkOutState:'LOADING',
    page:1,
    checkOutMoreState:'READY'

}

export const getDocuments = createAsyncThunk("/documents/getDocuments",
    async ({page:page},{rejectWithValue,getState}) =>{
        const {session:{lastconnect},auth:{authentication:{id,nonce}}} = getState() as any
        try{
            const response = await api.get('/documents',{
                params:{
                    user:id,
                    nonce:nonce,
                    page:page,
                    lastconnect:lastconnect
                }
            })

            //@ts-ignore
            if(response.data.hasOwnProperty('error'))
            //@ts-ignore
            return rejectWithValue(response.data.error)
                //@ts-ignore
                return response.data.documents
            

        }catch(err:any){
            if(!err.response){
                throw err
            }else if(err.statut == 404){
                return rejectWithValue('Une erreur s\'est produite lors de la récupération des données.')
            }
            return rejectWithValue('Une erreur s\'est produite lors de la récupération des données.')
        }
    }

);



export const documentsSlice = createSlice({
    name:'documents',
    initialState:initDocumentState,
    reducers:{
        removeNew:(state,{payload})=>{
            state.values[payload] ={
                ...state.values[payload],
                new:false
                }
            },


    },
    extraReducers:(builder)=>{

        builder.addCase(getDocuments.pending,(state,{meta})=>{
            if(meta.arg.page === 1)
            state.checkOutState = 'LOADING'
            else state.checkOutMoreState = 'LOADING'
        })

        builder.addCase(getDocuments.rejected,(state,{payload})=>{
            if(meta.arg.page === 1){
            state.checkOutState = 'ERROR'
            state.error = payload as string
            }
            else 
            state.checkOutMoreState = 'ERROR'
        });

        builder.addCase(getDocuments.fulfilled,(state,{payload,meta})=>{
            if(meta.arg.page === 1){
                state.checkOutState = 'READY'
                state.entitled = payload.entitled
                state.values = payload.values
            }else{
                state.checkOutMoreState = 'READY'
                state.values = [...state.values,...payload.values]
            }
          
            state.has_more = payload.has_more ?? false
            state.total = payload.total
            state.page = state.page +1
        });
    }
    })


   
export const {removeNew} = documentsSlice.actions;
export default documentsSlice.reducer;