import React, { Component } from 'react'

// ICONS
import { Search } from '../icons/Search'
import { Bar } from '../icons/Bar'
import { Cross } from '../icons/Cross'

// CONFIG CLIENT
import config from '../config'

export default class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = { opened: false, search_value: '' }
    this.container = React.createRef()
  }

  componentDidMount() {
    if (this.props.search_text) {
      this.setState({ search_value: this.props.search_text })
    }
  }

  // RECHERCHE
  search = (e) => {
    e.preventDefault()
    this.setState({ opened: false })
    this.props.search(this.state.search_value)
  }
  // FIN RECHERCHE

  // ANNULATION DE LA RECHERCHE
  resetSearch = () => {
    this.setState({ opened: false, search_value: '' })
    this.props.search('')
  }
  // FIN ANNULATION DE LA RECHERCHE

  // AU CLIC SUR LE BANDEAU, ON AFFICHE LE FORMULAIRE DE RECHERCHE
  activateSearchBar = () => {
    this.setState({ opened: true })
    this.container.current.querySelector('input').focus()
  }
  // FIN AU CLIC SUR LE BANDEAU, ON AFFICHE LE FORMULAIRE DE RECHERCHE

  render() {
    const active = this.state.opened ? 'active' : null
    return (
      <section ref={this.container} id="searchbar">
        <div
          className="closed"
          onClick={() => {
            this.props.search_text && this.props.search_text !== '' ? this.resetSearch() : this.activateSearchBar()
          }}
        >
          <div className="svg">
            <Bar id="b" top_color={config.colors.searchbar.color} bottom_color={config.colors.searchbar.color} />
          </div>
          <div className="title">
            {this.props.search_text && this.props.search_text !== '' ? <Cross /> : <Search />}
            {!this.props.search_text || this.props.search_text === '' ? 'Rechercher' : this.props.search_text}
          </div>
          <div className="svg right">
            {/* <Bar id="b tab-right" top_color={'#d10000'} bottom_color={config.colors.searchbar.color} /> */}
            <Bar id="b" top_color={config.colors.searchbar.color} bottom_color={config.colors.searchbar.color} />
          </div>
        </div>
        <div className={`opened ${active}`}>
          <span>Votre recherche dans : {this.props.title}</span>
          <form onSubmit={this.search}>
            <div className="input-container">
              <input
                type="text"
                required
                placeholder="Tapez votre recherche"
                value={this.state.search_value}
                onChange={(e) => this.setState({ search_value: e.target.value })}
              />
              <button type="submit">
                <Search />
              </button>
            </div>
          </form>
          <button onClick={() => this.resetSearch()} id="reset">
            Annuler
          </button>
        </div>
      </section>
    )
  }
}
