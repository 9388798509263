import { GET_GROUPS } from '../../actions/pci/types'

const groupsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_GROUPS:
      if (action.payload) {
        let groups = action.payload
        
        return groups
      } else {
        return state
      }
    default:
      return state
  }
}

export default groupsReducer
