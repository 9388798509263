import styles from './PostPicture.module.scss'
import Portal from '../../components/common/portal/Portal'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPostLife, Life, postComment, postLike } from './postPictureSlice'
import { useParams } from 'react-router-dom'
import { RootState } from '../../redux'
import { useLockBodyScroll, useMedia } from 'react-use'
import parse from 'html-react-parser'
import Icon from '../../icons'
import PostPictureDesktop from './desktop'
import PostPictureMobile from './mobile'
import { useHistory,useLocation } from 'react-router-dom'
import React from 'react'
import { useAppSelector } from '../../redux/hooks'

export default function PostPicture() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams()
  const { galerie, breadcrumb, titre, texte, auteur, date, likes, coms } = useSelector(
    (state: RootState) => state.life.post as Life
  )
  const checkOutState = useSelector((state: RootState) => state.life.checkOutState)
  const checkOutComsState = useAppSelector((state) => state.life.checkOutComsState)
  const [comment, setComment] = useState<string>('')
  const refContentDesktop = useRef<HTMLDivElement>(null)
  const refModalContentMobile = useRef<HTMLDivElement>(null)
  let isScrolling = false
  useEffect(() => {
    dispatch(getPostLife({ id_post: id }))
  }, [id])

  const isMobile = useMedia('(max-width:768px)')
  const history = useHistory()
  function handleComment(e: ChangeEvent<HTMLTextAreaElement>) {
    setComment(e.target.value)
  }

  function handleKeyDownComments(e) {
    if (e.keyCode == 13 && checkOutComsState != 'LOADING') {
      e.preventDefault()
      onSendComment()
    }
  }

  function scrollToBottom(element) {
    const myDiv = element
    if (myDiv) {
      const scrollHeight = myDiv.scrollHeight
      const scrollTop = myDiv.scrollTop
      const difference = scrollHeight - scrollTop
      const perTick = difference / 10

      setTimeout(() => {
        myDiv.scrollTop = myDiv.scrollTop + perTick

        if (Math.abs(myDiv.scrollTop + myDiv.clientHeight - scrollHeight) <= 1) {
          return
        }

        scrollToBottom(element)
      }, 10)
    }
  }

  async function onSendComment() {
    if (comment.length > 0 && checkOutComsState != 'LOADING'  && comment.trim() !== '') {
      dispatch(postComment({ com: comment, id_post: id,lastContent:location?.state?.lastContent}))
      //@ts-ignore
        .unwrap()
        .then(() => {
          setComment('')
          if (refContentDesktop.current) scrollToBottom(refContentDesktop.current)
          if (refModalContentMobile.current) scrollToBottom(refModalContentMobile.current)
        })
    }
  }

  function handleLife() {
    dispatch(postLike({ id_post: id,lastContent:location?.state?.lastContent }))
  }
  if (checkOutState === 'READY') {
    return (
      <Portal id="modal-picture">
        <div className={styles['background-modal']}>
          {isMobile ? (
            <PostPictureMobile
              ref={refModalContentMobile}
              texte={texte}
              galerie={galerie}
              breadcrumb={breadcrumb}
              titre={titre}
              auteur={auteur}
              date={date}
              handleLife={handleLife}
              likes={likes}
              coms={coms}
              handleComment={handleComment}
              onSendComment={onSendComment}
              comment={comment}
            />
          ) : (
            <PostPictureDesktop
              handleKeyDownComments={handleKeyDownComments}
              ref={refContentDesktop}
              texte={texte}
              galerie={galerie}
              breadcrumb={breadcrumb}
              titre={titre}
              auteur={auteur}
              date={date}
              handleLife={handleLife}
              likes={likes}
              coms={coms}
              handleComment={handleComment}
              onSendComment={onSendComment}
              comment={comment}
            />
          )}
        </div>
      </Portal>
    )
  }
  return null
}
