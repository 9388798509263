import styles from './ListCategory.module.scss'
import {useState,useRef,useEffect, CSSProperties} from 'react';
import useMeasureDirty from '../../../hooks/useMeasureDirty';
import Icon from '../../../icons';


export function ItemCategory({id,name,selected,onSelect}){
    //State
    const [ClientXMoudseDown,setClientXMouseDown] = useState(0);
    const [ClientYMoudseDown,setClientYMouseDown] = useState(0);
    

    //HandlerMouseDown
    function handleOnMouseDown (e) {
        setClientXMouseDown(e.clientX)
        setClientYMouseDown(e.clientY)
        e.preventDefault()
      }


      //HandlerOnClick
      function onClick(e){
        e.stopPropagation()
        if (Math.abs((ClientXMoudseDown - e.clientX)) >=20 || Math.abs(ClientYMoudseDown - e.clientY) >= 20)
            e.preventDefault();
        else onSelect(id)
        
      }


    const styled ={
        '--background-items': selected == id  ? 'var(--ui-primary-color)' : 'var(--grey-very-lighter)',
        '--color-items': selected == id ? 'var(--ui-text-primary-color)' : 'var(--grey-color)'
    } as CSSProperties

    return(
        <span
            onMouseDown={handleOnMouseDown}
            onClick={onClick} 
            style={styled} 
            className={styles["item-categorie"]}>
            {name}
        </span>  
    )
}




export default function ListCategory({categories,currentCategorie,onSelect}){


    const [isScrolling,setIsScrolling] = useState(false);
    const [isPressDown,setIsPressDown] = useState(false);
    const [startX,setStartX] = useState(0);
    const [scrollLeft,setScrollLeft] = useState(0)
    const [isActive,setIsActive] = useState(false)

    const tabsRef = useRef<HTMLDivElement>(null);
    const slideRef = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        if(tabsRef.current){
            
            window.addEventListener("mousedown", onMouseDown);
            window.addEventListener("mouseleave", onMouseLeave);
            window.addEventListener("mouseup", onMouseUp);
            window.addEventListener("mousemove", onMouseMove);  
        }
        return ()=>{
            window.removeEventListener("mousedown", onMouseDown);
            window.removeEventListener("mouseleave", onMouseLeave);
            window.removeEventListener("mouseup", onMouseUp);
            window.removeEventListener("mousemove", onMouseMove);
        }
    });

    const {width:wrapperWidth} = useMeasureDirty(tabsRef);
    const {width:sliderWidth} = useMeasureDirty(slideRef);


   const onMouseDown = e => {
     
        setIsActive(true)
        setIsPressDown(true)
        setStartX( e.pageX - tabsRef.current.getBoundingClientRect().x)
       
        setScrollLeft(tabsRef.current.scrollLeft)
    };

    const onMouseLeave = () => {
        setIsActive(false)
        setIsPressDown(false)
       
        setTimeout(() => {
            setIsScrolling(false)
        }, 500);
      };

     const onMouseUp = () => {
        setIsActive(false)
        setIsPressDown(false)
        setIsScrolling(false)
      };


   const onMouseMove = e => {
        
        if (!isPressDown) return;
        e.preventDefault();
       setIsScrolling(true)
        const x = e.pageX - tabsRef.current.getBoundingClientRect().x;
        const walk = x - startX;
       
        tabsRef.current.scrollLeft = scrollLeft - walk;
      };


      const needController = wrapperWidth < sliderWidth;

      function onRunRight(){
        if(tabsRef.current){
            const nextPosition =  tabsRef.current.scrollLeft - 200
            tabsRef.current.scrollTo({
             left: nextPosition,
             behavior: 'smooth',
           });
        }
      }

      function onRunLeft(){
        if(tabsRef.current){
           const nextPosition =  tabsRef.current.scrollLeft + 200
           tabsRef.current.scrollTo({
            left: nextPosition,
            behavior: 'smooth',
          });
        }

      }


    return(
        <div className={styles["categorie-slider"]}>
            {needController ? 
                <button onClick={onRunRight} className={styles["controller-slide"]}>
                    <Icon name='chevronleft'/>
                </button>
            : null
            }
            <div ref={tabsRef} className={styles["list-categorie-wrapper"]}>
                    <div ref={slideRef} className={styles["slide-categorie-container"]}>
                        {categories.map(i=>{
                            return(
                                <ItemCategory 
                                    name={i.name} 
                                    id={i.id} 
                                    selected={currentCategorie}
                                    onSelect={onSelect}
                                />
                            )
                        })}
                    </div>
            </div>
            {needController ? 
                <button onClick={onRunLeft} className={styles["controller-slide"]}>
                    <Icon name='chevronright'/>
                </button>
            : null
            }
        </div>
    )
}