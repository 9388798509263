import React from 'react';

export const N2F = (props) => {
  return (
<svg version="1.1" id="Calque_1"  x="0px" y="0px"
	 viewBox="0 0 14.3 17.5" >
<g>
	<path d="M14.2,5.3c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0L9.3,0.2C9.1,0.1,8.9,0,8.7,0H2.3C1.1,0,0,1.1,0,2.4v12.8
		c0,1.3,1.1,2.3,2.3,2.3h9.6c1.3,0,2.3-1.1,2.3-2.3V5.5C14.3,5.4,14.3,5.3,14.2,5.3z M9.5,2.6l2.2,2.2H9.5V2.6z M11.9,16H2.3
		c-0.5,0-0.8-0.4-0.8-0.8V2.4c0,0,0,0,0,0c0-0.5,0.4-0.8,0.8-0.8H8v4C8,6,8.3,6.3,8.7,6.3h4v8.8C12.8,15.6,12.4,16,11.9,16z"/>
	<path d="M10.3,8.8H3.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.4c0.4,0,0.8-0.3,0.8-0.8S10.7,8.8,10.3,8.8z"/>
	<path d="M10.3,12H3.9c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h6.4c0.4,0,0.8-0.3,0.8-0.8C11.1,12.3,10.7,12,10.3,12z"/>
	<path d="M3.9,7.1h1.6c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H3.9c-0.4,0-0.8,0.3-0.8,0.8C3.2,6.8,3.5,7.1,3.9,7.1z"/>
</g>
</svg>
  );
};
