export const config_no_template = {
 colors: {
    annuaire:{
      favorite:process.env.REACT_APP_PRIMARY_COLOR,
      tabsheader:process.env.REACT_APP_PRIMARY_COLOR,
      linkheader:process.env.REACT_APP_SECONDARY_COLOR
    },
    partner:{
      circle:`${process.env.REACT_APP_SECONDARY_COLOR}4d`,
      logo:{
        circleLogoOne:`${process.env.REACT_APP_SECONDARY_COLOR}1a`
      }
    },
    entreprise:{
      circle:`${process.env.REACT_APP_PRIMARY_COLOR}4d`,
      logo:{
        circleLogoOne:`${process.env.REACT_APP_PRIMARY_COLOR}1a`,
        circleLogoTwo:process.env.REACT_APP_PRIMARY_DARKER
      },
      nom:process.env.REACT_APP_BLACK_COLOR,
      jetonUrl:process.env.REACT_APP_SECONDARY_COLOR,
      jetonEmail:process.env.REACT_APP_GREY_LIGHTER,
      linkFile:process.env.REACT_APP_PRIMARY_COLOR,

    },
    user:{
      entreprise:process.env.REACT_APP_SECONDARY_COLOR,
    },
    button:{
      gradient:{
        primary:process.env.REACT_APP_PRIMARY_COLOR
      }
    },
    navbar: {
      top: process.env.REACT_APP_NAVBAR_TOP_COLOR,
      font_color: process.env.REACT_APP_NAVBAR_FONT_COLOR,
      bottom: process.env.REACT_APP_NAVBAR_BOTTOM_COLOR,
      top_admin: process.env.REACT_APP_NAVBAR_TOP_ADMIN_COLOR,
      bottom_admin: process.env.REACT_APP_NAVBAR_BOTTOM_ADMIN_COLOR,
      top_burger_active: process.env.REACT_APP_NAVBAR_TOP_BURGER,
      bottom_burger_active: process.env.REACT_APP_NAVBAR_BOTTOM_BURGER,
      social_top: process.env.REACT_APP_NAVBAR_SOCIAL_TOP_COLOR,
      social_bottom: process.env.REACT_APP_NAVBAR_SOCIAL_BOTTOM_COLOR,
    },
    alert: {
      bg_color: process.env.REACT_APP_ALERT_COLOR,
      color: process.env.REACT_APP_BLANK_COLOR,
    },
    searchbar: {
      color: process.env.REACT_APP_SEARCH_BAR_COLOR,
      
    },
    content: {
      color: process.env.REACT_APP_CONTENT_BG_COLOR,
    },
    tuto: {
      color: process.env.REACT_APP_TUTO_COLOR,
      icons: process.env.REACT_APP_TUTO_ICONS_COLOR,
    },
    graph_remplissage: {
      actif: process.env.REACT_APP_GRAPH_ACTIF,
      inactif:process.env.REACT_APP_GRAPH_INACTIF,
    },
  }
}