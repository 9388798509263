import * as React from "react"

const AfdfBouquets = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.5 18.1"
    style={{
      enableBackground: "new 0 0 16.5 18.1",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M16.3 3.6 13.8.3c-.1-.2-.4-.3-.6-.3h-10c-.2 0-.4.1-.6.3L.2 3.6c-.1.2-.2.3-.2.5v11.6c0 1.3 1.1 2.4 2.4 2.4H14c1.3 0 2.4-1.1 2.4-2.4V4.1c.1-.2 0-.3-.1-.5zM3.6 1.5h9.2l1.6 2.1H2l1.6-2.1zM14 16.6H2.4c-.5 0-.9-.4-.9-.9V5.1H15v10.6c0 .5-.5.9-1 .9z" />
    <path d="M11.9 6.5c-.4 0-.8.3-.8.8 0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9 0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8 0 2.4 2 4.4 4.4 4.4 2.4 0 4.4-2 4.4-4.4.1-.5-.2-.8-.6-.8z" />
  </svg>
)

export default AfdfBouquets
