import React from 'react';

export const Morussati = () => {
  return (
<svg version="1.1" id="Calque_1"  x="0px" y="0px"
	 viewBox="0 0 14.3 16.7" >
<path id="Icon_metro-file-image" d="M13.6,3.5C13.8,3.7,14,4,14.1,4.2c0.1,0.3,0.2,0.5,0.2,0.8v10.7c0,0.5-0.4,0.9-0.9,0.9
	c0,0,0,0,0,0H0.9c-0.5,0-0.9-0.4-0.9-0.9c0,0,0,0,0,0V0.9C0,0.4,0.4,0,0.9,0c0,0,0,0,0,0h8.3C9.5,0,9.8,0.1,10,0.2
	c0.3,0.1,0.5,0.3,0.7,0.4L13.6,3.5z M9.5,1.3v3.5H13c0-0.1-0.1-0.3-0.2-0.4L9.9,1.5C9.8,1.4,9.7,1.3,9.5,1.3L9.5,1.3z M13.1,15.5
	V5.9H9.2c-0.5,0-0.9-0.4-0.9-0.9c0,0,0,0,0,0V1.2H1.2v14.3L13.1,15.5L13.1,15.5z M11.9,11.3v3H2.4v-1.8l1.8-1.8l1.2,1.2l3.6-3.6
	L11.9,11.3z M4.2,9.5c-1,0-1.8-0.8-1.8-1.8c0-0.5,0.2-0.9,0.5-1.3c0.7-0.7,1.8-0.7,2.5,0c0.7,0.7,0.7,1.8,0,2.5
	C5.1,9.3,4.6,9.5,4.2,9.5L4.2,9.5z"/>
</svg>

  );
};
