import styles from './Chalances.module.scss'
import InputWhite from '../../ui/input/InputWhite'
import List from './List'
import { useEffect, useRef } from 'react'
export default function AutoCompleteConcession(props) {
  const inputRef = useRef()

  return (
    <div className={styles.form}>
      <InputWhite
        onFocus={props.onFocusConcession}
        ref={inputRef}
        stylesContainer={{ width: '100%' }}
        stylesInput={{ width: '100%' }}
        label="Rechercher une concession :"
        type="text"
        value={props.value}
        onChange={props.onChangeConcession}
        placeholder="Rechercher une concession"
      />
      <List onSelect={props.onSelectConcession} data={props.companies} />
    </div>
  )
}
