import { base_config } from './base_config';
import { config_template_std } from './colors_config_template/config_template_std';
import { config_template_bm } from './colors_config_template/config_template_bm';
import { config_no_template } from './colors_config_template/config_no_template';
import { config_template_mono } from './colors_config_template/config_template_mono';



 const config = {
    ...base_config,
   ...(
     process.env.REACT_APP_TEMPLATE_COLOR !== 'true' ? config_no_template 
     :process.env.REACT_APP_TEMPLATE_TYPE === 'standard' ? config_template_std 
     :process.env.REACT_APP_TEMPLATE_TYPE === 'black-mamba' ? config_template_bm
     :process.env.REACT_APP_TEMPLATE_TYPE === 'reverse' ? config_template_std
     : config_template_mono
     )

}

export default config
