import { useEffect, useState } from "react";
import axios, { AxiosResponse } from 'axios';
import config  from "../../config";



export function useFetchProtected(type:string){

    const [data,setData] = useState({loading:false,data:false,error:false,fullfiled:false});

    async function fetchData(){
        setData((prev)=> {return{data:false,error:false,loading:true,fullfiled:false}});
        try{    
        
        //@ts-ignore next-line
        const response =  await axios.get<AxiosResponse<any, any>>(`${config?.apis?.pci?.baseURL.slice(0, -1)}${type==='private-policy' ?  'policonf' : 'mentions'}`);
            if(response.data.hasOwnProperty('error')){
                return setData((prev)=> {return{data:false,error:true,loading:false,fullfiled:false}});  
            }
        //@ts-ignore next-line
            setData((prev)=> {return{data:response.data,error:false,loading:false,fullfiled:true}});  
        }
        catch(error){
            setData((prev)=> {return{data:false,error:true,loading:false,fullfiled:false}});  
        }
    }


    useEffect(()=>{
        fetchData()
    },[])

    return {loading:data.loading,data:data.data,error:data.error,fullfiled:data.fullfiled}
}