// import {
//   CHECK_AUTH,
//   SIGN_IN,
//   GET_TEMPORARY_CODE,
//   SEND_MAIL,
//   SEND_EMAIL_TO_ADMIN,
//   GET_NAVBAR,
//   GET_POSTS,
//   GET_CONTACTS,
//   GET_PARTNERS,
//   GET_ADMINS,
//   GET_PROFILE,
//   SET_PROFILE,
//   SET_PROFILE_PICTURE,
//   SET_NEW_PASSWORD,
//   GET_POST,
//   SET_LIKE,
//   SET_COMMENT,
//   GET_PDF,
//   ADMIN_SET_POST,
//   ADMIN_DELETE_POST,
//   ADMIN_GET_GROUPS,
//   ADMIN_GET_CATEGORIES,
//   ADMIN_GET_POSTS,
//   ADMIN_GET_POST_FOR_UPDATE
// } from "reduxactions/pci/types";
import { SET_LOGIN_AUTH, CHECK_AUTH } from '../../actions/pci/types'
import update from 'immutability-helper'

const sessionReducer = (state = [], action) => {
  switch (action.type) {
    case CHECK_AUTH:
      return action.payload
    case SET_LOGIN_AUTH:
      return update(state, {
        lastconnect: { $set: '1970-01-01 07:20:49' },
      })
    default:
      return state
  }
}

export default sessionReducer
