const initialState = {  
    id:false,
    slug:false,
    nom:false,
    sreen:false
};



export default function newsReducers(state = initialState, action) {
  switch (action.type) {
    

    case 'SET_BREADCRUMB':
    return{
      ...state,
      id:action.payload.data.breadcrumb[0].id,
      slug:action.payload.data.breadcrumb[0].slug,
      nom:action.payload.data.breadcrumb[0].nom,
      sreen:action.payload.data.breadcrumb[0].sreen
   }
  default:
      return state;
  }



}