import SearchIcon from '../../../icons/SearchIcon';
import styles from './SearchBarDesktop.module.scss';
import {CSSProperties, useEffect, useState,useRef,KeyboardEvent} from 'react';
import {useSpring,animated} from 'react-spring';
import ModalSearch from '../modal-search/ModalSearch';
import api from '../../../config/pci/api';
import axios, { AxiosResponse } from 'axios';
import {useClickAway, useLocation} from 'react-use'
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import useDidMountEffect from '../../../hooks/useDidmountEffect';
import EasySpinner from '../../../easy-ui/spinner';
import ModalSearchError from '../modal-search-error';
import { useAppSelector } from '../../../redux/hooks';

let cancelToken;
export default function SearchBar({type}):JSX.Element{


    
    //STATE
    const [focus,setFocus] = useState<boolean>(false);
    const [searchText,setSearchText] = useState<string>('');
    const [listSearch,setListSearch] = useState<Array<any>>([]);
    const authentication = useAppSelector(state=>state.auth.authentication);
    const [loadingSearch,setLoadingSearch]= useState(false);
    const [errorSearch,setErrorSearch] = useState(false);

    // @ts-ignore-next-line
    const menu = useSelector(state=>state.navbar.items);
    
    //REF 
    const refModal = useRef<HTMLDivElement>(null);
    const refInput = useRef<HTMLDivElement>(null)

    //HOOKS
    const history = useHistory();
    const location = useLocation();

    useClickAway(refInput,(event)=>{
        setFocus(false)
        setErrorSearch(false)
    })

    function onCloseSearch(){
      setFocus(false)
      setErrorSearch(false)
    }

    const inputSpring = useSpring({
           width:focus ? '350px' : '250px',
       
    })
    
    const styled = {
        '--color-focus': focus ?  'var(--primary-color)' : 'rgb(218, 218, 218)'
    } as CSSProperties

    
    async function getSearch(result){
        const searchTerm = result;

        //Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }
    
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source();
        setLoadingSearch(true);
        setErrorSearch(false)
        try {
            
            const results = await api.get(
            `/litesearch?user=${authentication.id}&nonce=${authentication.nonce}&search=${searchTerm}`,
            { cancelToken: cancelToken.token } //Pass the cancel token to the current request
            )
            // @ts-ignore:next-line
            if(!results?.data.hasOwnProperty('error')){
                if(searchTerm.length==0){
                    setLoadingSearch(false)
                    return setListSearch([]) 
                }
                // @ts-ignore:next-line
                setListSearch(results?.data?.results)
                setLoadingSearch(false)
            }else{
                setErrorSearch(true)
                setListSearch([])
                setLoadingSearch(false)
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                setLoadingSearch(false)
              } 
            }
    }

  
  
    const handleSearchChange =  (e) => {
        setSearchText(e.target.value)
    };

  


    useDidMountEffect(()=>{
        getSearch(searchText)
    },[searchText])

    useDidMountEffect(()=>{
        if(focus && searchText.length > 0)
        getSearch(searchText)
    },[focus])

    function onViewAllSearch(){
        history.push({
            pathname:'/search',
            search:`?value=${searchText}&filter=all`
        })
        setFocus(false)
    }
    
    function onKeyUp(e:KeyboardEvent<HTMLInputElement>){
        if (e.key === 'Enter' || e.keyCode === 13) {
           onViewAllSearch()
        }
    }


    function onGoToPageSearch(id_rub,id_detail){
        const rubric = menu.find(i=>id_rub ==i.id) ?? false;
    
        if(rubric && (id_rub!=='partner' && id_rub !== 'entreprise' && id_rub!== 'user_partenaire' && id_rub !== 'user_adherent' && id_rub!=="boutique")){
            if(rubric.type === 'rub'){
                const firstPath = rubric.screen === 'std' ? 'actualite' :  'photo'
                if(rubric.screen === 'std'){
                    history.push({pathname:`/actualite/${rubric.slug}/${id_detail}`});
                        location.pathname?.startsWith(`/actualite/${rubric.slug}`) &&  window.location.reload();
                } 
                else
                    history.push({pathname:`/photo/${id_detail}`});
                  
            }
            else if(rubric.type === 'scr'){
                 if(rubric.id === 'agenda'){
                    history.replace({pathname:`/agenda`,hash:id_detail})
                    location.pathname?.startsWith(`/agenda`) &&  window.location.reload();
                }
                else if(rubric.id === 'sondage'){
                    history.push({pathname:`/sondage`,hash:id_detail})
                    location.pathname?.startsWith(`/sondage`) &&  window.location.reload();
                }
                else{
                    history.push({pathname:`/${rubric.slug}/${id_detail}`}) 
                    location.pathname?.startsWith(`/${rubric.slug}`) &&  window.location.reload();
                }
               
            }
            else{
                history.push({pathname:rubric.slug})
                
            }
        }else{
            if(id_rub === 'boutique'){
            history.push({pathname:`/boutique/produit/${id_detail}`})
            location.pathname?.startsWith(`/boutique/produit/`) &&  window.location.reload();
            }
            else if(id_rub === 'entreprise'){
               
                history.push({pathname:`/entreprise/${id_detail}`}) 
                location.pathname?.startsWith(`/entreprise/`) &&  window.location.reload();
            }
            else if(id_rub === 'partner'){
                history.push({pathname:`/entreprise-partner/${id_detail}`})
                location.pathname?.startsWith(`/entreprise-partner/`) &&  window.location.reload();
            }
            else if(id_rub === 'user_partenaire'){
          
                history.push({pathname:`/user-partner/${id_detail}`})
                location.pathname?.startsWith(`/user-partner/`) &&  window.location.reload();
            }
            else if(id_rub == 'user_adherent'){
                history.push({pathname:`/user/${id_detail}`})
                location.pathname?.startsWith(`/user/`) &&  window.location.reload();
            }
        }
       }
       
       function goTo(url,id){
        if(url.startsWith('/sondage') || url.startsWith('/agenda')){
            const starterUrl = url.startsWith('/sondage') ? '/sondage' :'/agenda'
            //return console.log(result.url)
            return ()=>{ window.location.reload(); history.push({pathname:starterUrl,hash:id});}
        }
        history.push({pathname:url}); window.location.reload()
       }
    
    return(
        <div style={styled} className={`${styles['search-bar-wrapper']}`}>
            <animated.div  ref={refInput} style={inputSpring} className={`${styles["search-bar-container"]} ${(type==='boutique' && process.env.REACT_APP_CLIENT !=='actis') ? styles["boutique"]:""}`}>               
            <input onKeyUp={onKeyUp} onChange={handleSearchChange}  onFocus={()=>{setFocus(true)}} placeholder={"Saisir votre recherche..."}></input>
            <div onClick={onViewAllSearch} className={`${styles["icon-search"]} ${(type==='boutique'  && process.env.REACT_APP_CLIENT !=='actis')? styles["boutique"]:""}`}>{loadingSearch ? <EasySpinner color={(type==="boutique"  && process.env.REACT_APP_CLIENT !=='actis') ? "var(--ui-primary-color)": "#FFFFFF" } size={0.5}/> : <SearchIcon/>}</div>                {focus && <ModalSearch onGoToPageSearch={goTo} onViewAllSearch={onViewAllSearch} ref={refModal} listSearch={listSearch} searchText={''}/>}
                {errorSearch && searchText.length > 0 ?  <ModalSearchError onCloseSearch={onCloseSearch}/> : null}
            </animated.div>   
        </div>
    )
}