import React from 'react';

export const Cross = (props) => {
  return (
    <svg  viewBox="0 0 10.11 10.11">
      <title>croix-fermer</title>
      <path
        d="M9.06,8l3.78-3.78a.75.75,0,0,0-1.06-1.06L8,6.94,4.22,3.16A.75.75,0,0,0,3.16,4.22L6.94,8,3.16,11.78a.75.75,0,0,0,0,1.06.79.79,0,0,0,.53.22.75.75,0,0,0,.53-.22L8,9.06l3.78,3.78a.75.75,0,0,0,.53.22.79.79,0,0,0,.53-.22.75.75,0,0,0,0-1.06Z"
        transform="translate(-2.94 -2.94)"
      />
    </svg>
  );
};
