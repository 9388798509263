import React, { Component } from 'react';

// ICONS
import { ShoppingBag as ShoppingBagIcon } from '../../icons/ShoppingBag';

// LIBRAIRIES
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// CONFIG CLIENT
import config from '../../config';

class ShoppingBag extends Component {
  constructor(props) {
    super(props);
    this.state = { redirect: false };
    this.container = React.createRef();
  }

  // AU CLIC SUR LE BANDEAU, ON AFFICHE LE FORMULAIRE DE RECHERCHE
  activateSearchBar = () => {
    this.setState({ opened: true });
    this.container.current.querySelector('input').focus();
  };
  // FIN AU CLIC SUR LE BANDEAU, ON AFFICHE LE FORMULAIRE DE RECHERCHE

  redirect = () => {
    let redirect = '/panier';
    if (this.props.from_global_app) {
      redirect = '/boutique';
    }
    this.setState({ redirect });
  };

  render() {
    const { redirect } = this.state;
    if ((String(config.shopping).toLowerCase() === "true") && this.props.auth.authentication.profil !=="partenaire") {
      if (!redirect) {
        return (
          <button
            onClick={this.redirect}
            ref={this.container}
            id="shopping-bag"
            className={this.props.from_global_app ? 'outer-shop' : 'inner-shop'}
          >
            <ShoppingBagIcon />
            {this.props.shopping.cart.articles && this.props.shopping.cart.articles.length ? (
              <span>{this.props.shopping.cart.articles.length < 10 ? this.props.shopping.cart.articles.length : '+'}</span>
            ) : (
              ''
            )}
          </button>
        );
      } else {
        return <Redirect to={redirect} />;
      }
    }
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    shopping: state.shopping,
    auth:state.auth
  };
};

export default connect(mapStateToProps)(ShoppingBag);
