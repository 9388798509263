import React from 'react';

export const Euro = () => {
  return (
    <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.48 28.72">
      <defs>
        <mask id="mask" x="0" y="0" width="23.48" height="28.72" maskUnits="userSpaceOnUse">
          <g transform="translate(-0.05 -0.64)">
            <g id="mask-2">
              <polygon id="path-1" fillRule="evenodd" points="0.05 0.64 23.52 0.64 23.52 29.36 0.05 29.36 0.05 0.64" />
            </g>
          </g>
        </mask>
      </defs>
      <g className="euro-mask">
        <path
          id="Fill-1"
          fillRule="evenodd"
          d="M17.65,27.31a11.8,11.8,0,0,1-11-8.21h11V17.05H6.09A13.83,13.83,0,0,1,5.92,15,13.83,13.83,0,0,1,6.09,13H17.65v-2H6.6a11.8,11.8,0,0,1,11-8.21,11.26,11.26,0,0,1,5,1.2L23.52,2A13,13,0,0,0,17.65.64,13.81,13.81,0,0,0,4.54,10.9H.05v2H4.12a13.21,13.21,0,0,0,0,4.1H.05V19.1H4.54A13.81,13.81,0,0,0,17.65,29.36,13,13,0,0,0,23.52,28l-.83-1.85A11.26,11.26,0,0,1,17.65,27.31Z"
          transform="translate(-0.05 -0.64)"
        />
      </g>
    </svg>
  );
};
