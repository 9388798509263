import React from 'react';

export const SharePoint = (props) => {
  return (
<svg version="1.1" id="Calque_1" x="0px" y="0px"
	 viewBox="0 0 17 17" >
<g id="Groupe_1435" transform="translate(-1601.654 -937)">
	<path id="Ellipse_171" d="M1610.2,938.3c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2
		C1617.3,941.5,1614.1,938.3,1610.2,938.3 M1610.2,937c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5s-8.5-3.8-8.5-8.5
		S1605.5,937,1610.2,937z"/>
	<g class="st0">
		<path d="M1612.2,943c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.5-0.1-0.7-0.1
			c-0.5,0-0.9,0.1-1.1,0.3c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.4,0.2,0.6,0.2
			c0.2,0.1,0.5,0.1,0.8,0.2c0.4,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.4c0.2,0.2,0.4,0.4,0.5,0.6s0.2,0.5,0.2,0.9
			c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.5-0.6,0.7s-0.6,0.3-0.9,0.4c-0.3,0.1-0.7,0.1-1.1,0.1c-0.6,0-1.2-0.1-1.7-0.3
			s-1.1-0.4-1.5-0.8l0.6-1.1c0.1,0.1,0.2,0.2,0.4,0.3s0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.1,0.9,0.1
			c0.5,0,0.9-0.1,1.1-0.3c0.3-0.2,0.4-0.4,0.4-0.7c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
			c-0.3-0.1-0.6-0.2-0.9-0.3c-0.4-0.1-0.7-0.2-1-0.3c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.8
			c0-0.4,0.1-0.7,0.2-1c0.1-0.3,0.3-0.5,0.6-0.7c0.3-0.2,0.6-0.4,0.9-0.5s0.7-0.2,1.1-0.2c0.5,0,1,0.1,1.5,0.3
			c0.4,0.2,0.8,0.4,1.2,0.6L1612.2,943z"/>
	</g>
</g>
</svg>
  );
};
