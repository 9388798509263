import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import api from "../../config/pci/api";
import { stats } from "../../config/stats";
import Layout from "../../layout";
import { GET_SOCIAL_POST } from "../../redux/actions/pci/types";
import SocialPage from "./social-page";

export default function SocialNetworksPost({...props}){
    const dispatch = useDispatch();
    //@ts-ignore next-line
    const {id:user_id,nonce} = useSelector(state=>state.auth.authentication);
        //@ts-ignore next-line
    const post = useSelector(state=>state.social);
    console.log(post)
    const post_id = props.match.params.id;


    async function fetchData(){

        const fetching =  (post_id == 6025) ?  
        axios.get('/data/insta-post.json') 
        : api.get('/onepost', {
            params: {
              user: user_id,
              nonce: nonce,
              post: props.match.params.id,
              stat: stats(),
            }})

        return fetching
        .then((response)=>{
            console.log(response)
            //@ts-ignore next-line
             dispatch({type:GET_SOCIAL_POST,payload:response.data})
             return response
        })
    }

    
    return(
        <Layout title="Réseaux Sociaux" type='classic' has_back={true}   fetchData={fetchData}>
            {post.hasOwnProperty("reseau") && post.reseau  ? <SocialPage post_id={ props.match.params.id} type={post.reseau} /> : null }
        </Layout>
    )
}