import React from 'react';

export const Alert = (props) => {
  return (
    <svg id="alert" xmlns="http://www.w3.org/2000/svg" width={props.width??'15'} height={props.height??"16.3"} viewBox="0 0 15 16.3">
      <g id="alert-2" data-name="Nouveauté">
        <path
          fill={props.fill??''}
          id="Fill-1"
          fillRule="evenodd"
          d="M12.6.9a.48.48,0,0,0-.7,0,.61.61,0,0,0,0,.8A8.12,8.12,0,0,1,14,7.3a.56.56,0,0,0,.5.6c.3,0,.5-.2.5-.6A9.26,9.26,0,0,0,12.6.9"
          transform="translate(0 -0.1)"
        />
        <path
         fill={props.fill??''}
          fillRule="evenodd"
          d="M13.9,12.3a4.63,4.63,0,0,1-1.6-3.5V6.9A4.69,4.69,0,0,0,8,2.2V.6a.5.5,0,1,0-1,0V2.2A4.69,4.69,0,0,0,2.7,6.9V8.8a4.79,4.79,0,0,1-1.6,3.5,1.09,1.09,0,0,0-.4.9,1.16,1.16,0,0,0,1.2,1.2H5a2.48,2.48,0,0,0,2.5,2,2.58,2.58,0,0,0,2.5-2h3.1a1.16,1.16,0,0,0,1.2-1.2A.81.81,0,0,0,13.9,12.3ZM7.5,15.4a1.64,1.64,0,0,1-1.5-1H9A1.54,1.54,0,0,1,7.5,15.4Zm5.7-2H1.9a.22.22,0,0,1-.2-.2c0-.1,0-.1.1-.1a5.5,5.5,0,0,0,2-4.3V6.9A3.71,3.71,0,0,1,7.6,3.2a3.78,3.78,0,0,1,3.8,3.7V8.8a5.65,5.65,0,0,0,2,4.3l.1.1C13.3,13.3,13.3,13.4,13.2,13.4Z"
          transform="translate(0 -0.1)"
        />
        <path
         fill={props.fill??''}
          id="Fill-4"
          fillRule="evenodd"
          d="M1,7.4A8.37,8.37,0,0,1,3.1,1.7a.61.61,0,0,0,0-.8.48.48,0,0,0-.7,0A9.81,9.81,0,0,0,0,7.4.56.56,0,0,0,.5,8,.56.56,0,0,0,1,7.4"
          transform="translate(0 -0.1)"
        />
      </g>
    </svg>
  );
};
