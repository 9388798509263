import styles from './ModalPublish.module.scss';
import {useRef,useEffect,useState, CSSProperties} from 'react';
import { useClickAway } from 'react-use';
import Portal from "../../common/portal/Portal";
import { getInstagramBusinessAccount } from '../api/instagram-api';
import { accountPage, useGetPageAccount } from '../hooks/useGetPageAccount';
import Modal from '../../modal';
import CrossClose from '../../../icons/CrossClose';
import { errorMessage } from '../error_message';
import no_image from '../../../img/no-image.svg';
import { animated } from 'react-spring';

type ModalPublishProps ={
    onClose:()=>void,
    token:string,
    onPublishPicture:()=>void,
    loadingPublication:boolean,
    type_media:string,
    type_content:string,
    style:string

}

export default function ModalPublish({onClose,id,token,onPublishContent,loadingPublication,type_media,type_content,style}){
    
        //Ref Modal
        const modalRef = useRef(null);
        const targetRef = useRef(null);
        const [accountSelect,setAccountSelect] = useState<{id:string,access_token:string} | null>(null);
        const [errorSelect,setErrorSelect] = useState<null | string>(null);

       const {data,loading,error} =  useGetPageAccount(token,type_content)

       useClickAway(modalRef,()=>{
        if(onClose !==undefined && modalRef.current){
            
            return  onClose()
        }
        return false
      })


       function onPublish(){
        if(accountSelect ===null)setErrorSelect(errorMessage[type_content].no_select_account)
        else onPublishContent(accountSelect,type_media)
       }

       useEffect(()=>{if(accountSelect) setErrorSelect(null)},[accountSelect])

        if(loading){
            return null
        }
        else if(error){
            return <Modal style={undefined} onClose={onClose} id={"modal-error-publish"} type={'error'} message={error}/>
        }
        else if(data){
            return(
                <Portal id={id}>
                    <div  ref={targetRef} className={styles['modal-background']}>
                        <animated.div style={style} ref={modalRef} className={styles["modal"]}>
                            <button onClick={onClose} className={styles["close-button"]}><CrossClose/></button>
                            <div className={styles["modal-contains"]}>
                                <div className={styles["title"]}>
                                Choisissez le compte sur lequel vous souhaitez publier :
                                </div>
                                {(data.length > 0) && 
                                <div className={styles["account-list"]}>
                                    {data.map(({id,picture_url,name,access_token}:accountPage,key:number)=>{
                                        const styled = {
                                            '--border-account-color':id === accountSelect?.id ? 'var(--secondary-color)' : 'rgba(0,0,0,0.3)'
                                        } as CSSProperties

                                        return(
                                        <div key={id} style={styled} onClick={(e)=>setAccountSelect({id:id,access_token:access_token})} className={styles["account-page"]}>
                                            <img alt={name} className={styles["account-picture"]} src={picture_url??no_image}/>
                                            <div className={styles["account-name"]}>{name}</div>
                                        </div>
                                )})}
                                </div>
                            }
                                <div className={styles["wrapper-publish"]}>
                                    <button onClick={onPublish} className={styles["button-publish"]}>{loadingPublication ? 'Chargement ...':'Publier'}</button>
                                    {errorSelect && <div className={styles["error"]}>{errorSelect}</div>}
                                </div>
                            </div>
                        </animated.div>
                    </div>
                </Portal>
            )
    }
    return null
}