import { PGL_LEADS_LEAD_QUALIF, PGL_LEADS_LEAD_QUALIF_RESET } from '../../actions/pgl/types'

const qualifReducer = (state = [], action) => {
  switch (action.type) {
    case PGL_LEADS_LEAD_QUALIF:
      if (action.payload) {
        return action.payload.qualif || null
      } else {
        return state
      }
    case PGL_LEADS_LEAD_QUALIF_RESET:
      return []
    default:
      return state
  }
}

export default qualifReducer
