import React from 'react';

export const Account = () => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="fill" transform="translate(-275.000000, -26.000000)">
          <g id="Header" transform="translate(0.000000, -3.000000)">
            <g id="Picto/mon-espace" transform="translate(275.000000, 29.000000)">
              <path
                d="M12.5,0.5 C15.8137085,0.5 18.8137085,1.84314575 20.9852814,4.01471863 C23.1568542,6.1862915 24.5,9.1862915 24.5,12.5 C24.5,15.8137085 23.1568542,18.8137085 20.9852814,20.9852814 C18.8137085,23.1568542 15.8137085,24.5 12.5,24.5 C9.1862915,24.5 6.1862915,23.1568542 4.01471863,20.9852814 C1.84314575,18.8137085 0.5,15.8137085 0.5,12.5 C0.5,9.1862915 1.84314575,6.1862915 4.01471863,4.01471863 C6.1862915,1.84314575 9.1862915,0.5 12.5,0.5 Z M12.5,1.42307692 C9.44119215,1.42307692 6.67196139,2.66290377 4.66743258,4.66743258 C2.66290377,6.67196139 1.42307692,9.44119215 1.42307692,12.5 C1.42307692,15.5588078 2.66290377,18.3280386 4.66743258,20.3325674 C6.67196139,22.3370962 9.44119215,23.5769231 12.5,23.5769231 C15.5588078,23.5769231 18.3280386,22.3370962 20.3325674,20.3325674 C22.3370962,18.3280386 23.5769231,15.5588078 23.5769231,12.5 C23.5769231,9.44119215 22.3370962,6.67196139 20.3325674,4.66743258 C18.3280386,2.66290377 15.5588078,1.42307692 12.5,1.42307692 Z"
                id="Combined-Shape"
              ></path>
              <g id="Picto/Profil" transform="translate(3.846154, 3.349783)">
                <path
                  d="M8.65402969,1.83136095 C9.55754061,1.83136095 10.3755524,2.19761435 10.9676807,2.78977146 C11.5597938,3.38191336 11.9260355,4.19994889 11.9260355,5.1035503 C11.9260355,6.0071517 11.5597938,6.82518723 10.9676807,7.41732914 C10.3755524,8.00948624 9.55754061,8.37573964 8.65447027,8.37573984 C7.75108931,8.37494381 6.93343942,8.00847989 6.34136251,7.41643179 C5.74915509,6.82425318 5.38261202,6.00639714 5.3816568,5.1035503 C5.3816568,4.19993594 5.74791023,3.38188991 6.34008068,2.78974827 C6.93225994,2.19759782 7.75035908,1.83136095 8.65402969,1.83136095 Z M8.65402969,2.37921757 C7.90373526,2.38056736 7.22549423,2.68484606 6.73424402,3.17607237 C6.24291375,3.66737874 5.93864726,4.34568931 5.937298,5.09494181 C5.93729719,5.84596448 6.24139803,6.52512998 6.7330524,7.01676044 C7.22469798,7.50838209 7.9038832,7.81246686 8.65402969,7.81246686 C9.4040048,7.81246686 10.0832463,7.50835958 10.5749596,7.01667021 C11.0666104,6.52504329 11.3707622,5.84591091 11.3707622,5.09584221 C11.3707622,4.34577351 11.0666104,3.66664114 10.5749596,3.17501421 C10.0832463,2.68332484 9.4040048,2.37921757 8.65402969,2.37921757 Z"
                  id="Fill-1"
                ></path>
                <path
                  d="M8.87592312,9.81952663 C10.3486211,9.81952663 11.6610928,10.4488112 12.6058574,11.4776628 C13.5047807,12.456593 14.0709589,13.7944974 14.1382209,15.2979721 L14.1449719,15.6008173 L3.88540701,15.9201202 C3.60650888,13.9744635 4.18678262,12.5218252 5.14569576,11.4776322 C6.09050869,10.4487935 7.40306451,9.81952663 8.87592312,9.81952663 Z M8.87592312,10.4553034 C7.67832971,10.4553034 6.61259348,10.8982436 5.79669642,11.6505453 C4.85965444,12.5145492 4.25087904,13.7914875 4.17115808,15.2839555 L4.17115808,15.2839555 L13.5781699,15.2839555 L13.558199,15.0069322 C13.4275371,13.6348947 12.8385151,12.4626499 11.9600074,11.6525462 C11.1428647,10.8990294 10.0742672,10.4553034 8.87592312,10.4553034 Z"
                  id="Fill-4"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
