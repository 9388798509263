import React from 'react';

export const NavbarAchat = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 512 512" width="512" height="512">
      <path d="M192,448a24,24,0,1,0-24-24A24.027,24.027,0,0,0,192,448Zm0-32a8,8,0,1,1-8,8A8.009,8.009,0,0,1,192,416Z" />
      <path d="M440,400a24,24,0,1,0,24,24A24.027,24.027,0,0,0,440,400Zm0,32a8,8,0,1,1,8-8A8.009,8.009,0,0,1,440,432Z" />
      <path d="M40,80H99.932a8.033,8.033,0,0,1,7.691,5.8L168,297.12v76.294A56.036,56.036,0,1,0,231.138,384H400.862a56,56,0,1,0,77.4-.834A24,24,0,0,0,472,336H216V303.485l232.532-15.5a8,8,0,0,0,7.313-6.413l32-160A8,8,0,0,0,480,112H164.56L146.982,49.5a24.083,24.083,0,0,0-23.1-17.5H40a24,24,0,0,0,0,48ZM232,424a40,40,0,1,1-40-40A40.045,40.045,0,0,1,232,424Zm248,0a40,40,0,1,1-40-40A40.045,40.045,0,0,1,480,424ZM300.989,256H338.36l-2.32,23.2,1.453.145-34.065,2.271Zm-13.531,26.685-31.922,2.128L250.048,256h34.868ZM299.465,240l-4.572-48H344.76l-4.8,48Zm54.975,16h34.2L384.6,276.209l-32.4,2.16Zm1.6-16,4.8-48h40.6l-9.6,48Zm6.4-64,4.8-48h47l-9.6,48Zm-16.08,0H293.369L288.8,128H351.16ZM277.3,176H234.81l-9.143-48h47.059Zm1.524,16,4.571,48H247l-9.143-48Zm-48.107,48H200.56l-13.5-48h34.511Zm3.047,16,5.693,29.885-25.51,1.7L205.06,256Zm167.376,19.106L404.958,256h39.683l-3.285,16.425ZM447.841,240H408.158l9.6-48h39.682Zm12.8-64H420.958l9.6-48h39.683ZM209.38,128l9.143,48H182.56l-13.5-48Zm-77.8-74.166L200,297.1V344a8,8,0,0,0,8,8H472a8,8,0,0,1,0,16H184V296a8.013,8.013,0,0,0-.308-2.2l-60.684-212.4A24.1,24.1,0,0,0,99.932,64H96V48h27.879A8.028,8.028,0,0,1,131.58,53.834ZM40,48H80V64H40a8,8,0,0,1,0-16Z" />
    </svg>
  );
};
