import React from 'react';

export const MondialAnnuairePartenaires = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 15.4 17.5"
      style={{ enableBackground: 'new 0 0 15.4 17.5' }}
    >
      <path
        d="M12.4,17.5H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h10.3c1.1,0,2,0.9,2,2v1h0.1c0.5,0,0.9,0.4,0.9,0.9v1.3
   c0,0.5-0.4,0.9-0.9,0.9h-0.1v1.1h0.1c0.5,0,0.9,0.4,0.9,0.9v1.3c0,0.5-0.4,0.9-0.9,0.9h-0.1v1.1h0.1c0.5,0,0.9,0.4,0.9,0.9v1.3
   c0,0.5-0.4,0.9-0.9,0.9h-0.1v1C14.4,16.6,13.5,17.5,12.4,17.5z M2,1C1.5,1,1,1.5,1,2v13.4c0,0.6,0.5,1,1,1h10.3c0.6,0,1-0.5,1-1v-2
   h1v-1.1h-1V9.3h1V8.2h-1V5.2h1V4.1h-1V2c0-0.6-0.5-1-1-1H2z"
      />
      <path
        d="M8.3,14.5c-0.2,0-0.5-0.1-0.7-0.2c0,0-0.1-0.1-0.1-0.1c-1-0.8-1.9-1.8-2.5-3C4.6,10.2,4.3,9,4.3,7.8C4.2,6.5,4.5,5.2,5,4
   c0.2-0.3,0.4-0.6,0.7-0.7c0.3-0.1,0.7-0.1,1,0.1c0,0,0.1,0,0.1,0.1l1,0.6c0.6,0.3,0.8,1,0.5,1.6C8.3,6,8.2,6.3,8.1,6.6
   c-0.1,0.5-0.5,0.9-1,1L6.9,7.7c0,0.8,0.2,1.6,0.6,2.3l0.3-0.1c0.5-0.1,1,0.1,1.3,0.5c0.2,0.3,0.5,0.5,0.7,0.7
   c0.5,0.4,0.6,1.1,0.3,1.7l-0.6,1c-0.2,0.3-0.5,0.5-0.8,0.6C8.6,14.5,8.5,14.5,8.3,14.5z M6.2,4.2C6.2,4.2,6.1,4.2,6.2,4.2
   C6,4.3,6,4.3,5.9,4.4c-0.5,1-0.7,2.2-0.7,3.3c0,1.1,0.3,2.1,0.8,3.1c0.5,1,1.2,1.9,2.2,2.6c0.1,0,0.2,0.1,0.2,0
   c0.1,0,0.1-0.1,0.2-0.1l0.6-1c0.1-0.1,0.1-0.3-0.1-0.4c-0.3-0.3-0.6-0.6-0.9-0.9c-0.1-0.1-0.2-0.1-0.3-0.1l-0.7,0.2
   c-0.2,0.1-0.4,0-0.6-0.2C6.1,9.8,5.8,8.6,5.9,7.3c0-0.2,0.2-0.4,0.4-0.5l0.7-0.2c0.1,0,0.2-0.1,0.2-0.2C7.2,6,7.3,5.6,7.5,5.3
   c0.1-0.1,0-0.3-0.1-0.4l-1-0.6C6.3,4.2,6.2,4.2,6.2,4.2z M9.6,12.6L9.6,12.6L9.6,12.6z"
      />
    </svg>
  );
};
