import { forwardRef, useEffect } from 'react';
import styles from './ContainerVideo.module.scss';


type ContainerVideoProps = {
    medias:Array<{url:string}>
}

function ContainerVideo({medias},ref){


    return(
        <div className={styles["containter-video"]}>
            <video ref={ref} controls>
            <source src={medias[0]} type="video/mp4"/>
        </video>
        </div>
    )
}

export default forwardRef(ContainerVideo)