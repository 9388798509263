import React from 'react'

export const Phone = (props) => {
  return (
    // V2 https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 191.75 192.04">
      <path
        fill={props.fill??"currentColor"}
        
        d="M159.1,192a12.1,12.1,0,0,1-1.9-.1,177.68,177.68,0,0,1-76.7-27.2,173.89,173.89,0,0,1-53.1-53.2A175.3,175.3,0,0,1,.1,34.7,23.85,23.85,0,0,1,21.7,8.6a13.4,13.4,0,0,1,2-.1H49.5A24,24,0,0,1,73.1,29a101.55,101.55,0,0,0,5.6,22.5,23.77,23.77,0,0,1-5.4,25.2l-7.1,7.1a128.92,128.92,0,0,0,41.4,41.4l7.1-7.1a23.77,23.77,0,0,1,25.2-5.4,102.09,102.09,0,0,0,22.4,5.6A24,24,0,0,1,183,142.5V168a23.66,23.66,0,0,1-6.9,16.9,24.27,24.27,0,0,1-16.9,7.1ZM49.5,22.2H23a9.81,9.81,0,0,0-6.9,3.6,10.08,10.08,0,0,0-2.3,7.5,162.52,162.52,0,0,0,25.1,70.8,160.2,160.2,0,0,0,49,49,163,163,0,0,0,70.6,25.1h.7A10.12,10.12,0,0,0,169.4,168V142.2a10.2,10.2,0,0,0-8.8-10.4,118,118,0,0,1-25.4-6.3,10.27,10.27,0,0,0-10.8,2.3l-10.8,10.8a6.83,6.83,0,0,1-8.2,1.1A143.43,143.43,0,0,1,51.7,86a6.81,6.81,0,0,1,1.1-8.2L63.6,67a10.1,10.1,0,0,0,2.3-10.7,111.94,111.94,0,0,1-6.3-25.5A10.23,10.23,0,0,0,49.5,22.2ZM150.7,81.4a6.85,6.85,0,0,1-6.7-5.5,35.62,35.62,0,0,0-28.3-28.3,6.82,6.82,0,1,1,2.6-13.4,49.29,49.29,0,0,1,39.1,39.1,6.83,6.83,0,0,1-5.4,8A5.7,5.7,0,0,1,150.7,81.4Zm34.1-.1a6.9,6.9,0,0,1-6.8-6.1,69.38,69.38,0,0,0-61.7-61.6,6.73,6.73,0,0,1-6-7.6,6.74,6.74,0,0,1,7.6-6,83.09,83.09,0,0,1,73.8,73.7,6.93,6.93,0,0,1-6,7.6Z"
        transform="translate(0.01 0.04)"
      />
    </svg>
    // V1 <svg id="phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 191.75 192.04"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M159.1,192a12.1,12.1,0,0,1-1.9-.1,177.68,177.68,0,0,1-76.7-27.2,173.89,173.89,0,0,1-53.1-53.2A175.3,175.3,0,0,1,.1,34.7,23.85,23.85,0,0,1,21.7,8.6a13.4,13.4,0,0,1,2-.1H49.5A24,24,0,0,1,73.1,29a101.55,101.55,0,0,0,5.6,22.5,23.77,23.77,0,0,1-5.4,25.2l-7.1,7.1a128.92,128.92,0,0,0,41.4,41.4l7.1-7.1a23.77,23.77,0,0,1,25.2-5.4,102.09,102.09,0,0,0,22.4,5.6A24,24,0,0,1,183,142.5V168a23.66,23.66,0,0,1-6.9,16.9,24.27,24.27,0,0,1-16.9,7.1ZM49.5,22.2H23a9.81,9.81,0,0,0-6.9,3.6,10.08,10.08,0,0,0-2.3,7.5,162.52,162.52,0,0,0,25.1,70.8,160.2,160.2,0,0,0,49,49,163,163,0,0,0,70.6,25.1h.7A10.12,10.12,0,0,0,169.4,168V142.2a10.2,10.2,0,0,0-8.8-10.4,118,118,0,0,1-25.4-6.3,10.27,10.27,0,0,0-10.8,2.3l-10.8,10.8a6.83,6.83,0,0,1-8.2,1.1A143.43,143.43,0,0,1,51.7,86a6.81,6.81,0,0,1,1.1-8.2L63.6,67a10.1,10.1,0,0,0,2.3-10.7,111.94,111.94,0,0,1-6.3-25.5A10.23,10.23,0,0,0,49.5,22.2ZM150.7,81.4a6.85,6.85,0,0,1-6.7-5.5,35.62,35.62,0,0,0-28.3-28.3,6.82,6.82,0,1,1,2.6-13.4,49.29,49.29,0,0,1,39.1,39.1,6.83,6.83,0,0,1-5.4,8A5.7,5.7,0,0,1,150.7,81.4Zm34.1-.1a6.9,6.9,0,0,1-6.8-6.1,69.38,69.38,0,0,0-61.7-61.6,6.73,6.73,0,0,1-6-7.6,6.74,6.74,0,0,1,7.6-6,83.09,83.09,0,0,1,73.8,73.7,6.93,6.93,0,0,1-6,7.6Z" transform="translate(0.01 0.04)"/></svg>

    // V0
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
    //   <path
    //     fillRule="evenodd"
    //     d="M5.6,10.69a3.37,3.37,0,0,1,1-2.18L9,6.12a1.72,1.72,0,0,1,1.18-.55,1.65,1.65,0,0,1,1.16.56c.43.4.84.82,1.28,1.26l.67.68L15.24,10a1.74,1.74,0,0,1,.61,1.2,1.74,1.74,0,0,1-.61,1.2l-.6.6c-.6.61-1.16,1.18-1.78,1.73l0,0a1.25,1.25,0,0,0-.32,1.42l0,.06a14.93,14.93,0,0,0,2.26,3.66A25.51,25.51,0,0,0,21,25.53c.27.18.57.32.85.47a7.81,7.81,0,0,1,.71.38l.07,0a1.33,1.33,0,0,0,.62.16,1.35,1.35,0,0,0,1-.44l2.41-2.4a1.73,1.73,0,0,1,1.18-.57,1.66,1.66,0,0,1,1.15.57l3.88,3.87a1.54,1.54,0,0,1,0,2.42c-.27.29-.55.56-.85.85a18,18,0,0,0-1.35,1.4,3.58,3.58,0,0,1-2.77,1.17l-.33,0A15.48,15.48,0,0,1,22,31.78a31.44,31.44,0,0,1-10.45-8.16,28.57,28.57,0,0,1-5.1-8.45,10.4,10.4,0,0,1-.81-4.48m4.68,13.92a33.19,33.19,0,0,0,11,8.57A17.49,17.49,0,0,0,27.41,35h.44a5.08,5.08,0,0,0,3.94-1.69l0,0A16.5,16.5,0,0,1,33.06,32c.31-.29.62-.6.91-.9a3.09,3.09,0,0,0,0-4.62l-3.88-3.86a3.12,3.12,0,0,0-2.27-1,3.26,3.26,0,0,0-2.29,1l-2.31,2.3c-.21-.12-.42-.23-.64-.33s-.48-.25-.71-.39A24.3,24.3,0,0,1,16,18.9a14.53,14.53,0,0,1-2-3.14c.6-.54,1.17-1.1,1.73-1.67l.59-.6a3.27,3.27,0,0,0,1.07-2.31,3.29,3.29,0,0,0-1.07-2.32L14.45,7l-.66-.67c-.43-.44-.87-.88-1.31-1.29a3.2,3.2,0,0,0-2.27-1A3.29,3.29,0,0,0,7.92,5L5.5,7.4A4.92,4.92,0,0,0,4,10.56a12,12,0,0,0,.89,5.14,29.94,29.94,0,0,0,5.36,8.91"
    //     transform="translate(-4 -4)"
    //   />
    // </svg>
  )
}
