


function SearchIcon(){
  return (
        <svg version="1.1" id="Calque_1" x="0px" y="0px"
	        viewBox="0 0 24 24" >
            <path d="M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75Zm0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5Z"
                />
        </svg>

  );
};

export default SearchIcon
