import styles from './GridIcons.module.scss';
import Icon,{ icons } from '../../../icons';
import { toast } from 'react-toastify';
import CellIcon from './cell-icon';
export default function GridIcons({listIcon}){
    
    return(
        <div className={styles["grid-icons-container"]}>
            {listIcon.map((item,key)=>(<CellIcon key={key} element={item} />))}
        </div>
    )
}