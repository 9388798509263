import React from 'react';

export const WebLink = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.16 340.14">
      <path
        d="M45.49,285.89a7.23,7.23,0,0,0,1.25.95,169.32,169.32,0,0,0,246.74-.08,7.59,7.59,0,0,0,1.19-.85,8.58,8.58,0,0,0,.73-1.24,169,169,0,0,0,0-229.16l0,0a9,9,0,0,0-.73-1.24,8.47,8.47,0,0,0-1.27-1,169.33,169.33,0,0,0-246.62,0,8.38,8.38,0,0,0-1.26,1,7.34,7.34,0,0,0-.73,1.24,169,169,0,0,0,0,229.16A7.34,7.34,0,0,0,45.49,285.89Zm170.94,30a156.4,156.4,0,0,0,29-53.58v0a155.48,155.48,0,0,1,30,18.48A153,153,0,0,1,216.43,315.89Zm70.74-47.47a172.59,172.59,0,0,0-37-22.66,302.7,302.7,0,0,0,8.9-67.18h63.57A152.24,152.24,0,0,1,287.17,268.42Zm35.51-106.85H259.11a302.62,302.62,0,0,0-8.9-67.18,173,173,0,0,0,37-22.65A152.23,152.23,0,0,1,322.68,161.57ZM275.43,59.34a156,156,0,0,1-30,18.49,156.15,156.15,0,0,0-29-53.6A153,153,0,0,1,275.43,59.34ZM178.55,18.19c20.84,5.46,39.28,30.52,50.89,66a180.65,180.65,0,0,1-50.89,9.69Zm0,92.7a196.72,196.72,0,0,0,55.58-10.2,287.91,287.91,0,0,1,8,60.88H178.55Zm0,67.69h63.56a287.81,287.81,0,0,1-8,60.86,196.35,196.35,0,0,0-55.58-10.19Zm0,67.69A180.3,180.3,0,0,1,229.44,256c-11.61,35.48-30.05,60.54-50.89,66ZM64.65,280.75A156.16,156.16,0,0,1,94.7,262.31v0a156.16,156.16,0,0,0,29,53.57A153.06,153.06,0,0,1,64.65,280.75ZM161.54,322c-20.84-5.46-39.28-30.52-50.9-66a180.42,180.42,0,0,1,50.9-9.68V322Zm0-92.7A196.72,196.72,0,0,0,106,239.45a287.83,287.83,0,0,1-8-60.87h63.57Zm0-67.68H98a287.83,287.83,0,0,1,8-60.87,196.35,196.35,0,0,0,55.58,10.19Zm0-143.39v75.7a180.8,180.8,0,0,1-50.9-9.7C122.26,48.7,140.7,23.64,161.54,18.18Zm-37.88,6a156.28,156.28,0,0,0-29,53.63v0a155,155,0,0,1-30-18.49A153,153,0,0,1,123.66,24.2ZM52.92,71.72a172.39,172.39,0,0,0,37,22.67A303.22,303.22,0,0,0,81,161.57H17.41A152.18,152.18,0,0,1,52.92,71.72ZM81,178.58a303.3,303.3,0,0,0,8.91,67.18,172.26,172.26,0,0,0-37,22.64,152.16,152.16,0,0,1-35.51-89.83H81Z"
        transform="translate(0 -0.01)"
      />
    </svg>
  );
};
