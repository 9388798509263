import { useState } from 'react';
import BlockAuth from '../../components/block-page/block-auth';
import EasyButton from '../../easy-ui/button';
import EasyInput from '../../easy-ui/input';
import Icon from '../../icons';
import styles from './LossePassword.module.scss';
import { stats } from '../../config/stats';
import api from '../../config/pci/api';


export default function LoosePassword(){
  const [email,setEmail] = useState(false);
  const [error,setError] = useState<boolean|string>(false);
  const [showMessage,setShowMessage] = useState(false);


  async function sendEmail(){
    try{
     const response = await api.get<any>('/lostpass', {
      params: {
        email: email,
        stat: stats(),
      },
    })

    if(response.data.hasOwnProperty('error')){
      setError('Veuillez renseigner un email valide.')
    }
    else{
      setShowMessage(true)
      }
    }
    catch(error){
      console.log(error)
    }
  }


    return(
      <BlockAuth title={'Mot de passe oublié'}  subtitle={showMessage ? '' :'Veuillez renseigner votre email'}>
        {!showMessage ?<div className={styles["form"]}>
          <EasyInput id='email' name="email" placeholder={"John.Doo@email.com"} label={'Email'} categorie={"standard"} type="text" onchange={(e)=>setEmail(e.target.value)} icon={<Icon name={"identifiant"}/>} />
           {/*@ts-ignore */}
          <EasyButton onClick={sendEmail} type="primary" name="text" text={"Envoyer"}/>
          {error ? <div className={styles["error-form"]}>{error}</div> : null}
        </div>
        :
          <div className={styles["message-mdp"]}>
            <span>{`Pour plus de sécurité un mail a été envoyé à l'adresse :`}</span>
            <span className={styles["mail-mdp"]}>{email}</span> 
            <span>{`avec un lien d'activation.`}</span> 
          </div>
        }
      </BlockAuth>
    )
}