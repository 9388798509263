import { useEffect } from "react";
import {useState} from 'react';

type sizeType = {
    width:number,
    height:number,
    ratio:number
}

export function useMeasureImage(url):{size:sizeType,isLoad:boolean}{


const [size,setSize] = useState<sizeType>({width:0,height:0,ratio:0});
const [isLoad,setIsLoading] = useState<boolean>(false)
    

useEffect(()=>{
    let img = new Image();
    img.src = url;
    img.onload = () =>{
        setSize({
            width:img.width,
            height:img.height,
            ratio:img.width/img.height
        })
    };
    },[url])

    useEffect(()=>{
        if(size.width !== 0){
            setIsLoading(true)
        }
    },[size])

return {size,isLoad};
}