import { PGL_LEADS_LEAD_GET, PGL_LEADS_LEAD_RESET, PGL_SET_CITY, PGL_SET_CP,PGL_SET_STATUS } from '../../actions/pgl/types'

const INITIAL_STATE = { prospect: false, lead: false, qualif: false, isLoading: false }

const leadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PGL_LEADS_LEAD_GET:
      // console.log('logEL -> reducer -> PGL_LEADS_LEAD_GET')
      if (action.payload) {
        // console.log(action.payload)

        return action.payload
        // return [...state, prospect: action.payload.prospect, action.payload]
      } else {
        return state
      }
    case PGL_LEADS_LEAD_RESET:
      // console.log('logEL -> reducer -> PGL_LEADS_LEAD_RESET')
      return []
    // return []
    // case PGL_LEADS_LEAD_SET:
    //   if (action.payload) {
    //     return action.payload
    //   } else {
    //     return state
    //   }

    case PGL_SET_CITY:
      return{
        ...state,
        lead:{...state.lead,
        lead_ville:{
          ...state.lead.lead_ville,
          value:action.payload.city,
        },
        lead_cp:{
          ...state.lead.lead_cp,
          value:action.payload.code
        }
      }
    }

    case 'CLEAN_LEAD':
      return{
        ...state,
        prospect:false,
        lead:false,
        qualif:false,
        isLoading:false
      }


    case PGL_SET_CP:
      return{
        ...state,
        lead:{...state.lead,
          lead_cp:{
          ...state.lead.lead_cp,
          value:action.payload.cp[0].code,
        },
        lead_ville:{
          ...state.lead.lead_ville,
          value:action.payload.ville,
        },
      }
    }

    case PGL_SET_STATUS :
      return{
        ...state,
        lead:{
        ...state.lead,
        statut:{
          ...state.lead.statut,
          value:action.payload
        }
      }
      }


    default:
      return state
  }
}

export default leadReducer
