import React from 'react';

export const MondialActualites = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 14.3 17.5"
      style={{ enableBackground: 'new 0 0 14.3 17.5' }}
    >
      <g>
        <path
          d="M14.1,5L9.3,0.2c0,0,0,0-0.1,0C9.1,0.1,9.1,0.1,9,0.1C8.9,0,8.8,0,8.7,0H2.4C1.1,0,0,1.1,0,2.4v12.8c0,1.3,1.1,2.4,2.4,2.4
      h9.6c1.3,0,2.4-1.1,2.4-2.4V5.5C14.3,5.3,14.2,5.2,14.1,5z M9.5,2.6l2.2,2.2H9.5V2.6z M11.9,16H2.4c-0.5,0-0.9-0.4-0.9-0.9V2.4
      c0-0.5,0.4-0.9,0.9-0.9H8v4C8,6,8.3,6.3,8.7,6.3h4v8.8C12.8,15.6,12.4,16,11.9,16z"
        />
        <path d="M10.3,8.8H3.9c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h6.4c0.4,0,0.8-0.3,0.8-0.8C11.1,9.1,10.7,8.8,10.3,8.8z" />
        <path d="M10.3,12H3.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.4c0.4,0,0.8-0.3,0.8-0.8S10.7,12,10.3,12z" />
        <path d="M3.9,7.1h1.6c0.4,0,0.8-0.3,0.8-0.8S6,5.6,5.5,5.6H3.9c-0.4,0-0.8,0.3-0.8,0.8S3.5,7.1,3.9,7.1z" />
      </g>
    </svg>
  );
};
