import styles from './ErrorFallBack.module.scss';
import ErrorIcon from '../../icons/ErrorIcon';
import { ServiceWorkerUpdateListener } from '../../ServiceWorkerUpdateListeners.js'
import { Fragment,useState,useEffect } from 'react';
import UpdateWaiting from '../update-waiting/UpdateWaiting';
export default function ErrorFallback({error, resetErrorBoundary}) {

  //Service Worker
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [swListener, setSwListener] = useState({});

  useEffect(async () => {
    
    if (process.env.NODE_ENV !== "development") {
  
          let listener = new ServiceWorkerUpdateListener();
          setSwListener(listener);
          listener.onupdateinstalling = (installingEvent) => {
          console.log("SW installed", installingEvent);
        };
        listener.onupdatewaiting = (waitingEvent) => {
          console.log("new update waiting", waitingEvent);
          setUpdateWaiting(true);
        };
        listener.onupdateready = (event) => {
          console.log("updateready event");
          window.location.reload();
        };
        navigator.serviceWorker.getRegistration().then((reg) => {
          listener.addRegistration(reg);
          setRegistration(reg);
        });
  
        return () => listener.removeEventListener();
      } else {
        //do nothing because no sw in development
      }
    },[])

    const handleUpdate = () => {
      swListener.skipWaiting(registration.waiting);
     }



    return (
      <Fragment>
      <div className={styles["error-fallback-container"]}  role="alert">
        <ErrorIcon/>
        <div className={styles['error-message']}>Une erreur est survenue !</div> 
        <div className={styles['error-sub-message']}>Veuillez relancer l'application</div>
       {/*process.env.NODE_ENV == 'development' && <div className={styles['error-container']}><span>code erreur :</span><pre>{error.message}</pre></div> */}
        <button onClick={resetErrorBoundary}>Relancez !</button>
      </div>
      <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate}/>
      </Fragment>
    )
  }