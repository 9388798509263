import EasyInput from '../../../easy-ui/input';
import styles from './ProfileForm.module.scss';

export default function ProfileForm({data,dataSave,setData}){

    return(
        <div className={styles["profile-form-container"]}>
            <EasyInput 
                id='prenom' 
                name="prenom" 
                placeholder={"John"} 
                label={'Prénom'} 
                categorie={"standard"} 
                type="text" 
                onchange={setData} 
                value={data.prenom}
                />
            <EasyInput 
                id='nom' 
                name="nom" 
                placeholder={"Doe"} 
                label={'Nom'} 
                categorie={"standard"} 
                type="text" 
                onchange={setData} 
                value={data.nom}
                />
            <EasyInput 
                disabled
                id='email' 
                name="email" 
                placeholder={"JohnDoe@gmail.com"} 
                label={'Email'} 
                categorie={"standard"} 
                type="text" 
                onchange={setData} 
                value={data.email}
                />
            <EasyInput 
                id='tel' 
                name="tel" 
                placeholder={"Ex: 0345464624"} 
                label={'Téléphone'} 
                categorie={"standard"} 
                type="text" 
                onchange={setData} 
                value={data.tel}
                />
                            
            <EasyInput 
                id='tel_second' 
                name="tel_second" 
                placeholder={"Ex: 0345464624"} 
                label={'Téléphone secondaire'} 
                categorie={"standard"} 
                type="text" 
                onchange={setData} 
                value={data.tel_second}
                />
            <EasyInput 
                id='fonction' 
                name="fonction" 
                placeholder={"Ex: Dirigeant"} 
                label={'Fonction'} 
                categorie={"standard"} 
                type="text" 
                onchange={setData} 
                value={data.fonction}
                />
            </div>
    )
}