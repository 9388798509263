import {
  GET_NAVBAR,
  GET_NAVBAR_NOTIFICATIONS,
  DELETE_POST_NOTIFICATION,
  DELETE_CATEGORY_NOTIFICATION,
  GET_NAVBAR_SOCIALS,
} from '../../actions/pci/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  items: [],
  updated: false,
  socials: false,
  error: false,
  loading:false
}

const navbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'GET_NAVBAR_LOADING':
      return{
        ...state,
        loading:true
      }

    case GET_NAVBAR:
      
      return {
        ...state,
        items: action.payload,
        error: action.payload.error ? true : false,
        loading:false
      }
    case GET_NAVBAR_SOCIALS:
      return {
        ...state,
        socials: action.payload.links,
      }
    case GET_NAVBAR_NOTIFICATIONS:
      let slugs = []
      if (action.payload.length >= 1) {
        action.payload?.forEach((item) => {
          slugs.push(item.slug)
        })
      }

      let navbar_items = state.items
      if (action.payload.length >= 1) {
        navbar_items.forEach((item) => {
          if (slugs.indexOf(item.slug) >= 0) {
            item.new = action.payload[slugs.indexOf(item.slug)].nb_new_posts
            
          }
        })
      }

      
      return {
        ...state,
        updated: true,
      }
    case DELETE_POST_NOTIFICATION:
      const rubric_id = action.rubric_id
      const selected = state.items.find((item) => {
        return item.id === rubric_id
      })
      const new_posts_number = selected.new
      const rubric_index = state.items.indexOf(selected)
      if (rubric_index >= 0) {
        return update(state, {
          items: {
            [rubric_index]: { new: { $set: new_posts_number - 1 } },
          },
        })
      } else {
        return state
      }

    case DELETE_CATEGORY_NOTIFICATION:
      const category_id = action.rubric_id
      const category_selected = state.items.find((item) => {
        return item.id === category_id
      })
      const category_rubric_index = state.items.indexOf(category_selected)

      if (category_rubric_index >= 0) {
        return update(state, {
          items: {
            [category_rubric_index]: { new: { $set: 0 } },
          },
        })
      } else {
        return state
      }

    default:
      return state
  }
}

export default navbarReducer
