import styles from '../styles/pages/AnnuairePartenaires.module.scss';
import { Fragment, useEffect,useState } from 'react'
import {useHistory} from 'react-router-dom'
import Navbar from '../components/Navbar';
import ShoppingBag from '../components/shop/ShoppingBag';
import { useDispatch,useSelector } from 'react-redux';
import {createSelector} from 'reselect';
import { getGroups,getContacts, getPartners } from '../redux/actions/pci';
import MenuSelection from '../components/partenaires/menu-selection/MenuSelection';
import ListContacts from '../components/partenaires/list-contacts/ListContacts';
import Loading from '../components/pgl/Loading';
import SearchBar from '../components/SearchBar';
import Breadcrump from '../components/breadcrump/BreadCrump';
import ListTabItem from '../components/list-tab-item/ListTabItem';
import dayjs from 'dayjs';
import { useQuery } from '../hooks/useQuery';


const selectItemsMenu = createSelector(
    (state) => state.navbar.items,
    (_, id) => id,
    (items, id) =>
      items.find((item) => item.id === id)
  )



export default function AnnuairePartenaire(props){
 
    const authentication = useSelector(state=>state.auth.authentication);
    const infoPage = useSelector(state=>selectItemsMenu(state,"partner"));
    const [fetchInfo,setFetchInfo] = useState(false);
    //const [categorie,setCategorie] = useState({id:'partner',name:'Fournisseurs'});
    const query = useQuery();
    const categorie = query.get('id');
    const groups = useSelector(state=>state.groups);
    const partners = useSelector(state=>state.partners);
    const loading = useSelector(state=>state.partners.loading);

    const history = useHistory();
    const dispatch = useDispatch();

    const [loadingData,setLoadingData] = useState(true);
    const last_cache = window.localStorage.getItem('partner_directory');
    const today = dayjs();
    const is_not_cache = (dayjs(new Date(last_cache)).add(10,'minute').isBefore(today)) || (partners && partners.hasOwnProperty('data') && partners.data.length <= 0) || !partners.hasOwnProperty('data')
   
    useEffect(()=>{
        if(fetchInfo){
            
                dispatch(getPartners(authentication.id, authentication.nonce, '', '',is_not_cache))
                .then(()=>{
                    if(is_not_cache){
                    window.localStorage.setItem('partner_directory',today.toString())
                    }
                })
            
            
            dispatch(getGroups(authentication.id, authentication.nonce,"partner"))
            .then(()=>{
              setLoadingData(false);
            })
            //dispatch(getPartners(authentication.id, authentication.nonce, '', ''))
            ;

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fetchInfo])

    useEffect(()=>{

        //SI NON AUTH GO CONNEXION
        if (!authentication.id || authentication.type === 'prem') 
            history.replace('/connexion')
        else{
            window.scrollTo(0, 0)
            setFetchInfo((value)=>!value)
            //setFetchPartner(true)
        }
        },[]);

      
    
  function onSearch(value){
    dispatch(getPartners(authentication.id,authentication.nonce, value, ''));
  }

  function onSelect(data){
    history.push(`/partner?id=${data.id}`)
  }
    
    return(
        <div className={styles["annuaire-adherent-page"]}>
          <Navbar title={infoPage && infoPage.hasOwnProperty('nom')?infoPage.nom : "loading..."} />
                <ShoppingBag from_global_app={true} />
            
                {((loadingData || loading) && (partners.data.length === 0  || partners.data.hasOwnProperty('error')) )?
                <Loading/>
                :
                <Fragment>
                       <div className={styles["container-breadcrump"]}>    {partners.breadcrumb ? <Breadcrump items={partners.breadcrumb} /> : ''} </div>
                      
                <MenuSelection
                categorieSelected={categorie??'partner'}
                onSelect={onSelect}
                    elements={
                      groups.filter(i=>i.name!=="Entreprise")
                    }
                />
                <ListContacts categorie={categorie??'partner'}/>
                </Fragment>

                }
        </div>
    )
}