import { Fragment } from 'react'
import styles from './Chalances.module.scss'
export default function List(props) {
  if (props.data) {
    return (
      <div className={styles['list-concessionaire']}>
        {props.data.map((item, key) => {
          return (
            <div
              key={String(item.ID)}
              onClick={(e) => item.ID !== "bad" && props.onSelect(item.ID, item.post_title,item.count)}
              className={styles['item-concessionaire']}
            >
              {item.post_title}
            </div>
          )
        })}
      </div>
    )
  } else return <Fragment></Fragment>
}
