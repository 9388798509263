import { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import { getFacebookPages } from "../api/facebook-api";
import { getInstagramBusinessAccount } from "../api/instagram-api";
import { getAccountLinkedin } from "../api/linkedin-api";
import { errorMessage } from "../error_message";

export type accountPage = {
    id:string,
    name:string,
    picture_url:string,
    access_token:string
}


export function useGetPageAccount(token,type){
    const [data,setData]= useState<{data:accountPage[]|false,error:string|boolean,loading:boolean}>({data:false,error:false,loading:false});
    //@ts-ignore next-line
    const {id,nonce} = useSelector(state=>state.auth.authentication)

    useEffect(()=>{
        getPageAccount(token)
    },[])

    async function getPageAccount(token){
        try{
            setData(prev=>{return {...prev,loading:true}})
            const response = type === 'instagram' ? await getInstagramBusinessAccount(token) 
                : type === 'facebook' 
                ? await getFacebookPages(token) 
                : await getAccountLinkedin(id,nonce,token);

            if(response.hasOwnProperty("error")) setData(prev=>{return {...data,loading:false,error:response.error}})
            else   setData(prev=>{return {...prev,loading:false,error:false,data:response}})
           
        }
        catch(error){
            setData(prev=>{return {...prev,loading:false,error:errorMessage.global.error_request,data:false}})
        }
    }


    return {data:data.data,error:data.error,loading:data.loading}
}