import React from 'react';

export const MondialReseaux = () => {
  return (
    <svg
      version="1.1"
      id="Icon_ionic-logo-rss"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 11.6 11.6"
      style={{ enableBackground: 'new 0 0 11.6 11.6' }}
    >
      <path
        id="Tracé_253"
        d="M1.7,8.2C0.8,8.2,0,9,0,9.9c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7C3.4,9,2.6,8.2,1.7,8.2z"
      />
      <path id="Tracé_254" d="M0,3.9v2.4c1.4-0.1,2.8,0.5,3.9,1.5c1,1,1.5,2.4,1.4,3.9h2.4C7.7,7.3,4.3,3.9,0,3.9z" />
      <path id="Tracé_255" d="M0,0v2.4c5-0.1,9.1,3.9,9.2,8.9c0,0.1,0,0.2,0,0.2h2.4C11.6,5.2,6.4,0,0,0z" />
    </svg>
  );
};
