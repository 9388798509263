import React from 'react';

export const Ines = (props) => {
  return (
<svg version="1.1" id="Calque_1" x="0px" y="0px"
	 viewBox="0 0 18 18">
<path id="Icon_awesome-sun" d="M9,5.6C7.1,5.6,5.6,7.1,5.6,9s1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4C12.4,7.1,10.9,5.6,9,5.6z M17.7,8.5
	l-3.3-1.7l1.2-3.5c0.1-0.3-0.1-0.7-0.4-0.8c-0.1,0-0.3,0-0.4,0l-3.5,1.2L9.5,0.3C9.4,0,9-0.1,8.7,0.1C8.6,0.1,8.5,0.2,8.5,0.3
	L6.8,3.7L3.3,2.5C2.9,2.4,2.6,2.6,2.5,2.9c0,0.1,0,0.3,0,0.4l1.2,3.5L0.3,8.5C0,8.6-0.1,9,0.1,9.3c0.1,0.1,0.2,0.2,0.3,0.3l3.3,1.7
	l-1.2,3.5c-0.1,0.3,0.1,0.7,0.4,0.8c0.1,0,0.3,0,0.4,0l3.5-1.2l1.7,3.3C8.6,18,9,18.1,9.3,17.9c0.1-0.1,0.2-0.2,0.3-0.3l1.7-3.3
	l3.5,1.2c0.3,0.1,0.7-0.1,0.8-0.4c0-0.1,0-0.3,0-0.4l-1.2-3.5l3.3-1.7C18,9.4,18.1,9,17.9,8.7C17.9,8.6,17.8,8.5,17.7,8.5L17.7,8.5z
	 M12.2,12.2c-1.8,1.8-4.6,1.8-6.4,0s-1.8-4.6,0-6.4s4.6-1.8,6.4,0c0,0,0,0,0,0C13.9,7.6,13.9,10.4,12.2,12.2z"/>
</svg>
  );
};
