import React from 'react';

export const Options = () => {
  return (
    <svg id="options" height="14" width="4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 14">
      <path
        fillRule="evenodd"
        d="M2,4A2,2,0,1,1,4,2,2,2,0,0,1,2,4ZM2,.5A1.5,1.5,0,1,0,3.5,2,1.5,1.5,0,0,0,2,.5Z"
        transform="translate(0 0)"
      />
      <path
        fillRule="evenodd"
        d="M2,9A2,2,0,1,1,4,7,2,2,0,0,1,2,9ZM2,5.5A1.5,1.5,0,1,0,3.5,7,1.5,1.5,0,0,0,2,5.5Z"
        transform="translate(0 0)"
      />
      <path
        fillRule="evenodd"
        d="M2,14a2,2,0,1,1,2-2A2,2,0,0,1,2,14Zm0-3.5A1.5,1.5,0,1,0,3.5,12,1.5,1.5,0,0,0,2,10.5Z"
        transform="translate(0 0)"
      />
    </svg>
  );
};
