import React from 'react';

export const NavbarLogout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g>
        <path d="M18.928 9.744H11.84c-.308 0-.559-.23-.559-.513 0-.283.25-.513.56-.513h7.086c.308 0 .56.23.56.513 0 .283-.252.513-.56.513" />
        <path d="M16.088 12.647a.543.543 0 0 1-.394-.17.597.597 0 0 1 0-.821l2.395-2.492-2.395-2.492a.597.597 0 0 1 0-.821.542.542 0 0 1 .789 0l2.789 2.902a.597.597 0 0 1 0 .82l-2.79 2.903a.543.543 0 0 1-.394.17M2.56 2.25a.388.388 0 0 0-.384.39v14.044c0 .166.11.32.266.376l4.594 1.557c.034.01.076.017.125.017a.388.388 0 0 0 .384-.39V4.201c0-.166-.11-.32-.266-.376L2.685 2.268a.413.413 0 0 0-.125-.017M7.16 19.804c-.164 0-.32-.023-.475-.072L2.07 18.168a1.573 1.573 0 0 1-1.044-1.484V2.64c0-.86.688-1.56 1.534-1.56.164 0 .32.023.475.072l4.616 1.564a1.573 1.573 0 0 1 1.044 1.484v14.043c0 .86-.687 1.56-1.534 1.56" />
        <path d="M12.736 7.528c-.31 0-.563-.27-.563-.604V3.298c0-.555-.42-1.007-.937-1.007H2.799c-.31 0-.562-.27-.562-.604 0-.334.252-.605.562-.605h8.437c1.137 0 2.062.994 2.062 2.216v3.626c0 .333-.252.604-.562.604" />
      </g>
    </svg>
  );
};
