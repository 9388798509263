import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { lazy } from 'react'
import PrivatePolicy from "./pages/PrivatePolicy";
import InfoLegal from './pages/InfoLegal';
import Chalances from './pages/pgl/Chalance';
import config from './config';
import AnnuairePartenaire from './pages/AnnuairePartenaires';
import SocialNetworksPost from './pages/social-networks-post';

/*CONNECTION IMPORT*/
import Login from './pages/login';
import VerifConnection from './pages/verif-connection';
import LostPassword from './pages/loose-password';
import NewPassword from './pages/new-password';
import AcceptCgu from './pages/accept-cgu';
import Welcome from './features/welcome';
import Publications from './features/publications';
import PostPicture from './features/post-picture';
import Home from './features/home';
import DocumentBoard from './features/document-board';
import Socials from './features/socials';
import PostStandard from './features/post-standard';
import SocialNetwork from './features/social-network';
//import Connexion from './pages/connexion';



// PAGES
const SearchPage = lazy(() => import('./pages/SearchPage'));
const ShoppingProduct = lazy(() => import('./pages/shop/ShoppingProduct'))
const ChiffreAffaires = lazy(() => import('./pages/ChiffreAffaires'))
const ShoppingCart = lazy(() => import('./pages/shop/ShoppingCart'))
/*const FirstConnexion = lazy(() => import('./pages/FirstConnexion'))*/
/*const LostPassword = lazy(() => import('./pages/loose-password'))*/
const Deconnexion = lazy(() => import('./pages/Deconnexion'))
//const NewPassword = lazy(() => import('./pages/NewPassword'))
const Shopping = lazy(() => import('./pages/shop/Shopping'))
const Moderation = lazy(() => import('./pages/Moderation'))
const Partners = lazy(() => import('./pages/Partners'))
const Contacts = lazy(() => import('./pages/Contacts'))
const Linkedin = lazy(() => import('./pages/Linkedin'))
const Pictures = lazy(() => import('./pages/Pictures'))
const Calendar = lazy(() => import('./pages/Calendar'))
const MegaMenu = lazy(() => import('./pages/MegaMenu'))
const Partner = lazy(() => import('./pages/Partner'))
const Contact = lazy(() => import('./pages/Contact'))


const ProfileOLD = lazy(() => import('./pages/Profile'))
const Profile = lazy(() => import('./pages/ProfileUser'))
const NewPost = lazy(() => import('./pages/NewPost'))
const Social = lazy(() => import('./pages/Social'))
const Update = lazy(() => import('./pages/Update'))
const Polls = lazy(() => import('./pages/Polls'))

const Post = lazy(() => import('./pages/Post'))
const Leads = lazy(() => import('./pages/pgl/Leads'))
const LeadsTableMinimal = lazy(() => import('./pages/pgl/LeadsTableMinimal'))
const Lead = lazy(() => import('./pages/pgl/Lead'));
const ImportChalance = lazy(()=>import('./pages/pgl/ImportChalance'))
const NewHome =lazy(()=> import('./pages/NewHome'));
const ConfirmPassword = lazy(()=>import('./pages/new-password'))
const CreateLead = lazy(()=> import('./pages/pgl/CreateLead'));
const Fournisseurs = lazy(()=>import('./pages/Fournisseurs'))
const AnnuaireAdherent = lazy(()=>import('./pages/AnnuaireAdherent'));
const Entreprise = lazy(()=>import('./pages/Entreprise'));
const UserPage = lazy(()=>import('./pages/UserPage'));
const TestFacebook = lazy(()=>import('./pages/TestFacebook'));
const PartnerProfile = lazy(()=>import(('./pages/PartnerProfile')));
const UserPartnerPage = lazy(()=>import('./pages/UserPartnerPage'));
const MapAdherents = lazy(()=>import('./pages/MapAdherent'));

const routes = [
  {path:'/',
    component:Home,
  },
  {path:'/user/:id',
    component:UserPage,
  },
  {
  path:'/actualites/:id',
  component:Publications
  },
  {
    path: '/photos/:id',
    component: Publications,
  },
  {
    path: '/actualite/:category/:id',
    component: PostStandard,
  },
  {
    path: '/photo/:id',
    component:PostPicture,
  },
  {
    path:'/map',
    component:MapAdherents
  },
  {
    path:'/document-board',
    component:DocumentBoard
  },
  {
    path:'/carte-implantations',
    component:MapAdherents
  },
  {
    path: '/connexion',
    component: Login,
    type:'connexion'
  },
   {
    path: '/nouveau-mot-de-passe',
    component: NewPassword,
    type:'connexion'
  },
  {
    path:'/bienvenue',
    component:Welcome,
    type:'connexion'
  },
  {
    path: '/auth',
    component: VerifConnection,
    type:'connexion'
  },
  {
    path: '/mot-de-passe-oublie',
    component: LostPassword,
    type:'connexion'
  },
  {
    path: '/validation-profil',
    component: AcceptCgu,
    type:'connexion'
  },
  {
    path: '/annuaire',
    component: AnnuaireAdherent,
  },
  {
    path: '/annuaire/:id',
    component: Contact,
  },
  {
    path: '/partner',
    component: AnnuairePartenaire,
  },
  {
    path: '/partners/:id',
    component: Partner,
  },
  {
    path: '/agenda',
    component: Calendar,
  },
  {
    path: '/sondage',
    component: Polls,
  },
  {
    path: '/profil',
    component: Profile
  },
  {
    path: '/partage',
    component: SocialNetwork,
  },
  {
    path: '/partage/:id',
    component: SocialNetworksPost,
  },
  {
    path: '/moderation/:id',
    component: Moderation,
  },
  {
    path: '/modification/:id',
    component: Update,
  },
  {
    path: '/modification/:id',
    component: Update,
  },
  {
    path: '/nouveau-profil',
    component: ProfileOLD,
  },
  {
    path: '/nouvelle-publication',
    component: NewPost,
  },
  {
    path: '/deconnexion',
    component: Deconnexion,
    type:'connexion'
  },
  {
    path: '/boutique',
    component: Shopping,
    configShopping: true,
  },
  {
    path: '/boutique/:id',
    component: Shopping,
    configShopping: true,
  },
  {
    path: '/boutique/produit/:id',
    component: ShoppingProduct,
    configShopping: true,
  },
  {
    path: '/menu/:id',
    component: MegaMenu,
  },
  {
    path: '/linkedin',
    component: Linkedin,
  },
  {
    path: '/linkedin-popup',
    component: LinkedInCallback,
  },
  {
    path: '/chiffre-affaires',
    component: ChiffreAffaires,
  },{
    path:'/fournisseurs',
    component:Fournisseurs
  },
  {
    path:'/entreprise/:id',
    component:Entreprise
  },
  {
    path:'/entreprise-partner/:id',
    component:PartnerProfile
  },
  {
    path:'/user-partner/:id',
    component:UserPartnerPage
  },
  {
    path:'/adherents',
    component:AnnuaireAdherent
  },{
    path:'/partenaires',
    component:AnnuairePartenaire
  },
  {
    path: '/panier',
    component: ShoppingCart,
    configShopping: true,
  },
  {
    path: '/leads',
    component: Leads,
    partOfPciApp: true,
  },
  {
    path: '/leadsTable',
    component: LeadsTableMinimal,
    partOfPciApp: true,
  },
  {
    path: '/leads/lead/:id',
    component: Lead,
    partOfPciApp: true,
  },
  {
    path: '/leads/chalances',
    component: Chalances,
    partOfPciApp: true,
  },
  {
    path:'/leads/chalances/import',
    component:ImportChalance,
    partOfPciApp:true
  },
  {
    path:'/leads/create',
    component:CreateLead,
    partOfPciApp:true
  },
  {
    path:'/search',
    component:SearchPage,
  }
]

export default routes
