import React from 'react';

export const Exclamation = () => {
  return (
    <svg width="6" height="24" viewBox="0 0 6 24">
      <path fillRule="evenodd" id="Fill-4" class="cls-1" d="M3,18a3,3,0,1,1-3,3,3,3,0,0,1,3-3" transform="translate(0 0)" />
      <path
        id="Fill-1"
        class="cls-1"
        d="M3,0A1.9,1.9,0,0,0,1,1.76V13.13a1.9,1.9,0,0,0,2,1.76,1.9,1.9,0,0,0,2-1.76V1.76A1.9,1.9,0,0,0,3,0"
        transform="translate(0 0)"
      />
    </svg>
  );
};
