import { PGL_CALL } from '../../actions/pgl/types'

const callReducer = (state = [], action) => {
  switch (action.type) {
    case PGL_CALL:
      if (action.payload) {
        return action.payload
      } else {
        return state
      }
    default:
      return state
  }
}

export default callReducer
