import styles from './ItemSelection.module.scss';
import { useState } from 'react';
export default function ItemSelection({item,categorieSelected,onSelect}){

    const [ClientXMoudseDown,setClientXMouseDown] = useState(null);
    const [ClientYMoudseDown,setClientYMouseDown] = useState(null);

    function handleOnMouseDown (e) {
        setClientXMouseDown(e.clientX)
        setClientYMouseDown(e.clientY)
        e.preventDefault()
      }

      function onClick(e){
        e.stopPropagation()
        if (ClientXMoudseDown!== e.clientX || 
            ClientYMoudseDown !== e.clientY) {
      
          e.preventDefault()
        
        }else{
            onSelect({id:item.id,name:item.name})
        }
      
      }
        return(
                    
            <span 
                 onMouseDown={handleOnMouseDown}
                 onClick={onClick} 
                 className={`${styles["button-select"]} ${categorieSelected == item.id ?styles["active"] : ''}`} 
                >{item.name}
                </span>
        )
    
}