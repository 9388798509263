import React from 'react';

export const MondialRealisations = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16.5 16.5"
      style={{ enableBackground: 'new 0 0 16.5 16.5' }}
    >
      <path
        id="Icon_material-wallpaper"
        d="M1.6,1.6h5.8V0H1.6C0.7,0,0,0.7,0,1.6v5.8h1.6V1.6z M6.6,9l-3.3,4.1h9.9l-2.5-3.3L9,12.1
   L6.6,9z M12.3,5.3c0-0.7-0.6-1.2-1.2-1.2S9.9,4.7,9.9,5.3c0,0.7,0.6,1.2,1.2,1.2C11.8,6.6,12.3,6,12.3,5.3
   C12.3,5.3,12.3,5.3,12.3,5.3L12.3,5.3z M14.8,0H9.1v1.6h5.8v5.8h1.6V1.6C16.5,0.7,15.7,0,14.8,0z M14.8,14.8H9.1v1.6h5.8
   c0.9,0,1.6-0.7,1.6-1.6V9.1h-1.6V14.8z M1.6,9.1H0v5.8c0,0.9,0.7,1.6,1.6,1.6h5.8v-1.6H1.6V9.1z"
      />
    </svg>
  );
};
