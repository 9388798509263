import React from 'react';

export const NavbarCadeau = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20.8 20.8"
      style={{ enableBackground: 'new 0 0 20.8 20.8' }}
    >
      <path
        d="M19.8,4.7h-2C17.9,4.3,18,3.9,18,3.4C18,1.6,16.6,0,14.7,0c0,0-0.1,0-0.1,0c-2.1,0-3.4,1.5-4.2,2.9C9.6,1.5,8.3,0,6.2,0
	c0,0-0.1,0-0.1,0C5.1,0,4.3,0.4,3.7,1C3.1,1.7,2.7,2.5,2.8,3.4c0,0.4,0.1,0.9,0.3,1.3H1c-0.6,0-1,0.4-1,1v4.7c0,0.6,0.4,1,1,1h0.9
	v8.4c0,0.6,0.4,1,1,1h15c0.6,0,1-0.4,1-1v-8.4h0.9c0.6,0,1-0.4,1-1V5.7C20.8,5.1,20.3,4.7,19.8,4.7z M18.8,9.4h-7.4V6.7h3.2
	c0,0,0,0,0.1,0c0,0,0,0,0,0h4.1V9.4z M14.7,2C15.4,2,16,2.6,16,3.4c0,0.7-0.6,1.3-1.4,1.3h-2.9C12.2,3.5,13.1,2,14.7,2z M5.2,4.3
	C4.9,4.1,4.8,3.7,4.8,3.4c0-0.4,0.1-0.7,0.4-1C5.4,2.2,5.7,2,6.2,2C7.7,2,8.6,3.5,9,4.7H6.1C5.8,4.7,5.4,4.6,5.2,4.3z M2,6.7h4.1
	c0,0,0,0,0,0c0,0,0,0,0.1,0h3.2v2.7H2V6.7z M3.9,11.4h5.5v7.4H3.9V11.4z M16.9,18.8h-5.5v-7.4h5.5V18.8z"
      />
    </svg>
  );
};
