import React from 'react';

export const MondialFacebook = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 21.6 21.6"
      style={{ enableBackground: 'new 0 0 21.6 21.6' }}
    >
      <path
        id="Icon_ionic-logo-facebook"
        d="M20.4,0H1.2C0.5,0,0,0.5,0,1.2v19.2c0,0.7,0.5,1.2,1.2,1.2h9.6V13H8.2V9.9h2.6V7.6
   c-0.1-2.3,1.6-4.2,3.9-4.3c0.2,0,0.4,0,0.5,0c1.2,0,2.5,0.1,2.8,0.1v2.9h-2c-1.4,0-1.6,0.6-1.6,1.6v2h3.2L17.2,13h-2.8v8.5h6
   c0.7,0,1.2-0.5,1.2-1.2V1.2C21.6,0.5,21.1,0,20.4,0z"
      />
    </svg>
  );
};
