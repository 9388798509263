import React from 'react';

export const MondialDeconnexion = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18.3 18.3"
      style={{ enableBackground: 'new 0 0 18.3 18.3' }}
    >
      <g>
        <path
          d="M6.3,16.8H2.6c-0.6,0-1.1-0.5-1.1-1.1v-13C1.5,2,2,1.5,2.6,1.5h3.7c0.4,0,0.8-0.3,0.8-0.8S6.8,0,6.3,0H2.6
     C1.2,0,0,1.2,0,2.6v13c0,1.4,1.2,2.6,2.6,2.6h3.7c0.4,0,0.8-0.3,0.8-0.8S6.8,16.8,6.3,16.8z"
        />
        <path
          d="M18.3,9.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2l-4.7-4.7c-0.3-0.3-0.8-0.3-1.1,0S12,4.7,12.3,5l3.4,3.4H6.3
     c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h9.4l-3.4,3.4c-0.3,0.3-0.3,0.8,0,1.1c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2L18,9.7
     c0.1-0.1,0.1-0.2,0.2-0.2C18.2,9.3,18.3,9.2,18.3,9.1z"
        />
      </g>
    </svg>
  );
};
