import { useRef } from 'react';
import Icon from '../../../icons';
import styles from './HeaderGallery.module.scss';
import SearchBarIcon from './search-bar-icons';
import {useHistory} from 'react-router-dom';

export default function HeaderGallery({onSelect}:{onSelect:(val:string)=>void}){

    const history = useHistory();

    return(
        <div className={styles["header-gallery"]}>
            <div className={styles["logo"]}>IconFound</div>
            <SearchBarIcon  onSelect={onSelect}/>
            <div className={styles["right-side"]}>
                <button onClick={()=>history.push('/')}><Icon name='home'/></button>
            </div>
        </div>
    )
}