import React from 'react';

export const NavbarReporting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
      <g>
        <path d="M15.67 4.247V18.33H3.655V4.247h3.276v.539c0 .297.245.538.546.538h4.369a.542.542 0 0 0 .546-.538v-.54h3.276zM8.023 3.169h.546a.543.543 0 0 0 .547-.54c0-.296.244-.538.546-.538.3 0 .546.242.546.539 0 .297.244.539.546.539h.546v1.078H8.024V3.169zm3.823-1.078h-.64a1.64 1.64 0 0 0-1.544-1.078c-.712 0-1.32.45-1.545 1.078h-.64a.543.543 0 0 0-.546.539v.539H3.11a.543.543 0 0 0-.546.539V18.87c0 .298.245.54.546.54h13.105a.543.543 0 0 0 .546-.54V3.708a.543.543 0 0 0-.546-.54h-3.822V2.63a.543.543 0 0 0-.546-.54z" />
        <path d="M5.128 7.614c0 .284.295.513.66.513h7.911c.365 0 .66-.23.66-.513 0-.283-.295-.512-.66-.512H5.787c-.364 0-.659.23-.659.512M13.7 8.64H5.786c-.364 0-.659.23-.659.513 0 .283.295.513.66.513h7.911c.365 0 .66-.23.66-.513 0-.283-.295-.513-.66-.513M13.7 11.204H5.786c-.364 0-.659.23-.659.513 0 .283.295.513.66.513h7.911c.365 0 .66-.23.66-.513 0-.283-.295-.513-.66-.513M6.21 16.35h7.58v-2.086H6.21v2.085zm8.12-3.095H5.67c-.3 0-.542.226-.542.505v3.094c0 .278.242.504.541.504h8.661c.3 0 .542-.226.542-.504V13.76c0-.279-.243-.505-.542-.505z" />
      </g>
    </svg>
  );
};
