import React from 'react';

export const MondialClub = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 15.5 14.8"
      style={{ enableBackground: 'new 0 0 15.5 14.8' }}
    >
      <path
        d="M3.3,14.8c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.2-0.3-0.2-0.5l0.8-4.7L0.2,6.1C0,6,0,5.8,0,5.6s0.2-0.3,0.4-0.3l4.7-0.7l2.1-4.3
   C7.4,0.1,7.5,0,7.7,0l0,0c0.2,0,0.4,0.1,0.4,0.3l2.1,4.3L15,5.3c0.2,0,0.3,0.2,0.4,0.3c0.1,0.2,0,0.4-0.1,0.5l-3.4,3.3l0.8,4.7
   c0,0.2,0,0.4-0.2,0.5c-0.2,0.1-0.4,0.1-0.5,0l-4.2-2.2l-4.2,2.2C3.4,14.8,3.3,14.8,3.3,14.8z M7.7,11.4c0.1,0,0.2,0,0.2,0.1l3.6,1.9
   l-0.7-4c0-0.2,0-0.3,0.1-0.4l2.9-2.8l-4-0.6c-0.2,0-0.3-0.1-0.4-0.3L7.7,1.6L5.9,5.3C5.9,5.4,5.7,5.5,5.6,5.5l-4,0.6l2.9,2.8
   C4.6,9,4.6,9.2,4.6,9.4l-0.7,4l3.6-1.9C7.6,11.4,7.7,11.4,7.7,11.4z"
      />
    </svg>
  );
};
