import React from 'react';

export const NavbarPolls = () => {
  return (
    <svg id="Calque_1" width="35" height="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <path
        fillRule="evenodd"
        d="M30.42,4.6v0A15.69,15.69,0,0,0,19.36,0a.8.8,0,0,0-.8.81h0V15.67a.78.78,0,0,0,.76.82H34.18a.82.82,0,0,0,.82-.82A15.66,15.66,0,0,0,30.42,4.6ZM20.19,1.66a14.63,14.63,0,0,1,4.43,1L20.19,7.09Zm0,7.74,6-6a14,14,0,0,1,2.49,1.75l-8.47,8.48Zm1.08,5.45L29.8,6.32A14.85,14.85,0,0,1,31.56,8.8l-6,6Zm6.56,0,4.48-4.49a14,14,0,0,1,1,4.49Z"
      />
      <path
        fillRule="evenodd"
        d="M28.39,19.66H15.31v-13a.76.76,0,0,0-.74-.77h0A14.48,14.48,0,0,0,4.25,10.11,14.59,14.59,0,0,0,14.57,35a14.47,14.47,0,0,0,10.32-4.27,14.66,14.66,0,0,0,4.27-10.31A.77.77,0,0,0,28.39,19.66Zm-4.59,10a13,13,0,0,1-9.23,3.82,13.06,13.06,0,0,1-.79-26.09v13a.79.79,0,0,0,.79.76h13A13,13,0,0,1,23.8,29.66Z"
      />
    </svg>
  );
};
