import React from 'react';

export const Search = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width??'12px'} height={props.height??'12px'} viewBox="0 0 12 12">
      <path
        fill={props.fill??''}
        fillRule="evenodd"
        d="M1 5.501A4.506 4.506 0 0 1 5.5 1C7.98 1 10 3.02 10 5.501 10 7.981 7.98 10 5.5 10A4.504 4.504 0 0 1 1 5.501zm8.811 3.537A5.534 5.534 0 1 0 0 5.532a5.532 5.532 0 0 0 9.037 4.28l2.028 2.027a.547.547 0 0 0 .774-.771l-2.028-2.03z"
      />
    </svg>
  );
};
