import Icon from '../../../../../icons';
import styles from './FastSearchIcons.module.scss';

export default function FastSearchIcons({list,text,onSelectIcon}:{list:never[]|string[],text:string,onSelectIcon:(val:string)=>void}){
    return(
        <div className={styles["fast-seach-icons-wrapper"]}>
            <div className={styles["fast-seach-icons-container"]}>
            <div className={styles["triangle"]}></div>
                <div className={styles["list-fast-search-container"]}>
                    {list.map((item,key)=>{
                        return <div key={key} onClick={()=>onSelectIcon(item)} className={styles["item-list-fast-search"]}>
                                    <Icon name='search'/>
                                    <div>{item}</div>
                                </div>
                    })}
                </div>
            </div>
        </div>
    )
}