

const Globe = ({fill,width,height}) => (
  <svg    viewBox="0 0 32.1 32.1" width={width} height={height} xmlns="http://www.w3.org/2000/svg" >
 
 <path fill={fill} d="M16.7 0H16C11.1 0 6.5 2.3 3.5 6.1c-5.5 7-4.3 17.1 2.7 22.5.8.6 2 .5 2.6-.3.6-.8.5-2-.3-2.6-1.3-1-2.4-2.3-3.2-3.8h4c.3 2 .9 4 1.7 5.9 1.6 3.5 3.7 4.2 5.1 4.2 8.6 0 15.7-6.8 16.1-15.4C32.5 7.8 25.6.4 16.7 0zM8.8 18.3H3.9c-.3-1.5-.3-3 0-4.4h4.9c0 .8-.1 1.5-.1 2.2.1.7.1 1.4.1 2.2zM19.1 22c-.3 1.6-.8 3.2-1.5 4.7-.7 1.3-1.2 1.7-1.6 1.7-.3 0-1-.4-1.7-2.1-.6-1.4-1-2.8-1.3-4.3h6.1zm.5-6c0 .8 0 1.5-.1 2.2h-7c0-.7-.1-1.5-.1-2.2s0-1.5.1-2.2h7c.1.7.1 1.5.1 2.2zM10.7 5c-.7 1.7-1.2 3.4-1.5 5.2h-4C6.5 7.9 8.4 6.1 10.7 5zm10.7 0c2.3 1.1 4.2 2.9 5.4 5.2h-4c-.2-1.8-.7-3.6-1.4-5.2zM13 10.1c.3-1.6.8-3.2 1.5-4.7.7-1.3 1.2-1.7 1.6-1.7s.9.4 1.6 1.7c.7 1.5 1.2 3.1 1.5 4.7H13zM26.9 22c-1.2 2.2-3.1 4.1-5.4 5.2.7-1.7 1.2-3.4 1.5-5.2h3.9zm-3.7-8.2h4.9c.1.7.2 1.5.2 2.2 0 .7-.1 1.5-.2 2.2h-4.9c0-.8.1-1.5.1-2.2s0-1.3-.1-2.2z" />
  </svg>
)

export default Globe
