import { CSSProperties, forwardRef, Fragment, useState,useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import MultiImageIcon from '../../../../icons/MultiImageIcon';
import useChangeSizeStyle from '../../hooks/useChangeSizeStyle';
import { useMeasureImage } from '../../hooks/useMeasureImage';
import VisioMedia from '../../visio-media';
import styles from './ContainerCaroussel.module.scss';
import { useSpring,animated} from 'react-spring';
import { easeCubicInOut } from 'd3-ease';
import CarousselSwiper from '../../../caroussel-swiper';


type ContainerPictureProps = {
    medias:Array<string>,
    type:string
}

function ContainerPicture({medias,type}:ContainerPictureProps,ref){
   const [hover,setHover] = useState<boolean>(false);
   const [showVisio,setShowVisio] = useState<boolean | Array<string>>(false);
   const overlayStyles = {
    '--overlay-bg':hover ? 'rgba(0,0,0,0.5)' : 'transparent',
   
    } as CSSProperties
   function onShowViso(index){
    setShowVisio([medias[index]]);
    }

    if(medias[0])
        return(
            <Fragment>
                <div 
                    ref={ref}
                    style={{...overlayStyles}}
                    onMouseEnter={()=>setHover(true)} 
                    onMouseLeave={()=>setHover(false)} 
                    className={styles["container-picture"]}
                >
                <CarousselSwiper onClick={onShowViso} medias={medias}/>
      
            </div>
            {showVisio ? <VisioMedia medias={showVisio} type={'picture'} onClose={()=>setShowVisio(false)}/> : null}
        </Fragment>
        )
    return null
}

export default forwardRef(ContainerPicture)
