import React from 'react';

export const NavbarLink2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g>
        <path d="M18.612 11.553H1.388V3.198c0-.15.121-.27.27-.27h16.684c.149 0 .27.12.27.27v8.355zm0 3.177c0 .15-.121.271-.27.271H1.658a.271.271 0 0 1-.27-.27v-2.302h17.224v2.301zM7.773 18.1c.299-.384.477-.865.477-1.388v-.834h3.5v.834c0 .523.178 1.004.477 1.388H7.773zM18.342 2.05H1.658c-.631 0-1.145.515-1.145 1.147V14.73c0 .632.514 1.147 1.145 1.147h5.717v.834c0 .765-.622 1.388-1.386 1.388H4.73a.438.438 0 0 0 0 .875h10.542a.438.438 0 0 0 0-.875h-1.26a1.388 1.388 0 0 1-1.386-1.388v-.834h5.717c.631 0 1.145-.515 1.145-1.147V3.198c0-.632-.514-1.147-1.145-1.147z" />
        <path d="M10 13.333a.513.513 0 1 1 0 1.026.513.513 0 0 1 0-1.026M10.637 4.235a.452.452 0 0 0-.64 0L6.799 7.433a.452.452 0 1 0 .64.64l3.198-3.198a.452.452 0 0 0 0-.64M12.183 6.279a.426.426 0 0 0-.603 0l-1.199 1.199a.426.426 0 1 0 .603.602l1.199-1.199a.426.426 0 0 0 0-.602" />
      </g>
    </svg>
  );
};
