const UserPhotoIcon = (props) => (
    <svg
      fill="none"
      height={props.height}
      width={props.width}
      viewBox='0 0 24 24'
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
    <path
      d="M4.297 4h15.449A2.252 2.252 0 0 1 22 6.249v11.502A2.249 2.249 0 0 1 19.75 20H4.25A2.25 2.25 0 0 1 2 17.751v-7.207c.063.074.133.141.21.2a1.384 1.384 0 0 0 1.29.143v6.861a.75.75 0 0 0 .75.75h2.749v-2.753a1.749 1.749 0 0 1 1.607-1.743l.143-.006h6.495a1.75 1.75 0 0 1 1.744 1.606l.006.143v2.749h2.752a.75.75 0 0 0 .75-.75V6.249a.75.75 0 0 0-.75-.75H5.418l-.281-.09a.891.891 0 0 1-.564-.56L4.297 4Zm4.197 14.494h7l-.005-2.75a.25.25 0 0 0-.193-.242l-.057-.007H8.744a.25.25 0 0 0-.243.193l-.007.057v2.749Zm5.627-10.616a2.998 2.998 0 0 1-3.27 4.89A3.002 3.002 0 0 1 9 9.999 2.998 2.998 0 0 1 12 7a3 3 0 0 1 2.121.878Zm-3.182 1.06a1.499 1.499 0 1 0 2.12 2.12 1.499 1.499 0 0 0-2.12-2.12Zm-8.578-.1.3.917a.363.363 0 0 0 .676.023l.008-.023.3-.916a1.888 1.888 0 0 1 1.199-1.197l.918-.3a.363.363 0 0 0 0-.684h-.018l-.919-.3a1.882 1.882 0 0 1-1.198-1.197l-.3-.918a.364.364 0 0 0-.552-.175.364.364 0 0 0-.132.175l-.3.917-.007.023a1.892 1.892 0 0 1-1.173 1.175l-.919.3a.364.364 0 0 0-.175.55.36.36 0 0 0 .175.134l.919.3a1.9 1.9 0 0 1 1.198 1.197Zm2.53 1.71.536-.175a1.118 1.118 0 0 0 .587-.472c.041-.071.075-.147.1-.226l.174-.536a.217.217 0 0 1 .12-.124.21.21 0 0 1 .202.024.222.222 0 0 1 .077.102l.174.536a1.099 1.099 0 0 0 .7.698l.535.174h.01a.213.213 0 0 1 .141.2.211.211 0 0 1-.139.199l-.535.174a1.1 1.1 0 0 0-.7.699l-.173.534a.22.22 0 0 1-.078.1.212.212 0 0 1-.321-.1l-.174-.534a1.1 1.1 0 0 0-.7-.702l-.535-.174a.213.213 0 0 1-.1-.32.2.2 0 0 1 .1-.078Z"
      fill={props.fill}
    />
    </svg>
  )
  
  export default UserPhotoIcon
  