import styles from '../styles/pages/NoMatch.module.scss';
import {useHistory} from 'react-router-dom';
import config from '../config';
function  NoMatch(props){
    const history = useHistory();

    const goHome = () =>{
      if(history.location.pathname.startsWith('/leads') && config.apis.pgl.open)
      history.replace('/leads')
      else{
        history.replace('/')
      }
    }


    return(<div className={styles["no-match-page"]}>
                 <div className={styles["no-match-container"]}>
                    <div className={styles["no-match-404"]}>
                      <h1>404</h1>
                    </div>
                    <h2 className={styles["subtitle-1"]}></h2>
                    <h2>Page non trouvée !</h2>
                    <p>La page que vous recherchez a peut-être été supprimée, son nom a changé ou est temporairement indisponible.</p>
                    <button onClick={goHome}>Accueil</button>
                 </div>
            </div>
                
                  
          
    )
  }

  export default NoMatch