import styles from './Caroussel.module.scss';
import {useTransition,animated} from 'react-spring';
import { Fragment, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { easeCubicInOut } from 'd3-ease';
import { useMeasure } from 'react-use';


export default function Caroussel({
    className,
    renderItems,
    renderTexts,
    duration=4000,
    animateTime=2000,
    size={
      width:'100%',
      height:'100%',
    },
    animationConfig={
        initial:{
            position:'absolute',
            transform:'translateX(0%)',
            opacity:1
        },
        from:{
            position:'absolute',
            transform:'translateX(100%)',
            opacity:0
        },
        enter:{
            position:'absolute',
            transform:'translateX(-0%)',
            opacity:1
        },
        leave:{
            position:'absolute',
            transform:'translateX(-100%)',
            opacity:0
        }
    },
    textAnimationConfig = {
        initial:{
            position:'absolute',
            transform:'translateY(0%)',
            opacity:1,
        },
        from:{
            position:'absolute',
            transform:'translateY(100%)',
            opacity:0,
        },
        enter:{
            position:'absolute',
            transform:'translateY(-0%)',
            opacity:1,
        },
        leave:{
            position:'absolute',
            transform:'translateY(-100%)',
            opacity:0
        }  
    }
}){


    const [refCaroussel, { x, y, width, height, top, right, bottom, left }] = useMeasure<HTMLDivElement>();

    const [index,setIndex] = useState<number>(0);
    const [isFocus,setIsFocus] = useState<boolean>(true)

    const [transitionItems,api] = useTransition(index,()=>({
        config:{
            duration:animateTime,
            easing:easeCubicInOut,
        },
        ...animationConfig
    }));

    const transitionTexts = useTransition(index,{
        config:{
            duration:animateTime,
            easing:easeCubicInOut
        },
        ...textAnimationConfig
    })

   /* useEffect(()=>{
        window.addEventListener("focus", function(){
            api.start();
            api.resume();
            setIsFocus(true)
        } )

        window.addEventListener("blur", function(){
            api.pause();
            setIsFocus(false)
        } )
    
    },[])*/

    useInterval(()=>{
        if(renderItems.length >0 /*&& isFocus*/){
            setIndex(prev=>(prev+1)%renderItems.length) 
        }
    },duration)

    return(
        <div ref={refCaroussel} style={{...size}} className={`${styles["caroussel-container"]} ${className??''}`}>
                {
                renderItems && renderItems.length > 0 ?
                transitionItems((style,item)=>{
                    const ItemAnimate = animated(renderItems[item]);
                    return <ItemAnimate  className={styles["img-caroussel"]} style={style} />
                }) 
                : null}
                {renderTexts && renderTexts.length >0 ?   
                <div className={styles['text-slider-container']}>
                
                    {transitionTexts((style,item)=>{
                    const TextAnimate = animated(renderTexts[item])
                        return <TextAnimate className={styles["text-animate"]} style={style}/>
                })}
                </div>
            :null}
        </div>
     
    )
}