import React from 'react';

export const MondialBoutiqueMarketing = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16.1 15.5"
      style={{ enableBackground: 'new 0 0 16.1 15.5' }}
    >
      <g>
        <path
          d="M6.1,12.6C6.1,12.6,6.1,12.6,6.1,12.6c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4v0
     c0-0.4-0.1-0.7-0.4-1C6.8,12.8,6.5,12.6,6.1,12.6z M6,14.1C6,14.1,6,14.1,6,14.1l0.1-0.1C6.2,14.1,6.1,14.1,6,14.1z M6,14.1
     C6,14,6,14,6,14.1c0.1-0.1,0.2,0,0.2,0H6z"
        />
        <path
          d="M13.4,12.6C13.4,12.6,13.4,12.6,13.4,12.6c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4v0
     c0-0.4-0.1-0.7-0.4-1C14.1,12.8,13.8,12.6,13.4,12.6z M13.3,14.1C13.3,14.1,13.3,14.1,13.3,14.1c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1
     S13.4,14.1,13.3,14.1z"
        />
        <path
          d="M16,3.6c-0.1-0.2-0.4-0.3-0.6-0.3H4.7L4.1,0.6C4.1,0.3,3.8,0,3.4,0H0.8C0.3,0,0,0.3,0,0.7s0.3,0.8,0.8,0.8h2l0.5,2.7
     c0,0,0,0,0,0.1l1.1,5.5c0.2,1,1,1.7,2,1.7c0,0,0,0,0,0H13c1,0,1.9-0.7,2.1-1.7l1.1-5.6C16.2,4,16.1,3.8,16,3.6z M13.6,9.5
     C13.5,9.8,13.3,10,13,10H6.5C6.2,10,6,9.8,5.9,9.5L5,4.8h9.5L13.6,9.5z"
        />
      </g>
    </svg>
  );
};
