import React from 'react';

export const NavbarPartners = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
      <path d="M15.5 12.25C15.5 11.8358 15.1642 11.5 14.75 11.5H9.75C9.33579 11.5 9 11.8358 9 12.25V12.7495C9 13.75 10.3831 14.5 12.25 14.5C14.1168 14.5 15.5 13.75 15.5 12.7495V12.25ZM14 8.74547C14 7.77863 13.2168 7 12.25 7C11.2832 7 10.5 7.77863 10.5 8.74547C10.5 9.71231 11.2832 10.4961 12.25 10.4961C13.2168 10.4961 14 9.71231 14 8.74547ZM4 4.5C4 3.11929 5.11929 2 6.5 2H18C19.3807 2 20.5 3.11929 20.5 4.5V18.75C20.5 19.1642 20.1642 19.5 19.75 19.5H5.5C5.5 20.0523 5.94772 20.5 6.5 20.5H19.75C20.1642 20.5 20.5 20.8358 20.5 21.25C20.5 21.6642 20.1642 22 19.75 22H6.5C5.11929 22 4 20.8807 4 19.5V4.5ZM5.5 4.5V18H19V4.5C19 3.94772 18.5523 3.5 18 3.5H6.5C5.94772 3.5 5.5 3.94772 5.5 4.5Z"/>
    </svg>
  );
};


