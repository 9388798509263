import { truncate } from 'fs'
import { Fragment, useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import config from '../../config'
import api from '../../config/pci/api'
import AuthTvShow from './auth-tv-show'
import SlideTvShow from './slide-tv-show'
import styles from './TvShow.module.scss';


export default function TvShow(){

    const [{data,loading,error},setDataFetching] = useState<{data:any,loading:boolean,error:boolean|string}>({data:false,loading:false,error:false})
    

    
    /**
     * Auth
     */
    async function onAuth(password){
        setDataFetching({data:false,loading:true,error:false});
        try{
             //@ts-ignore
            const response = await api.get(`${config?.apis?.pci?.baseURL.slice(0, -1)}tvshow?pass=${password}`)
             //@ts-ignore
            if(response.data.hasOwnProperty('error') || response.data.length <=0 || !response.data){
                 //@ts-ignore
               return setDataFetching({data:false,loading:false,error:response.data.error ?? 'Une erreur est survenue'})
            }
            return setDataFetching({data:response.data,loading:false,error:false})
        }
        catch(error){
            setDataFetching({data:false,loading:false,error:'Une erreur est survenue'})
        }
    }


    async function fastRefresh(){
        try{
             //@ts-ignore
            const response = await api.get(`${config?.apis?.pci?.baseURL.slice(0, -1)}tvshow?pass=${process.env.REACT_APP_TV_SHOW}`)
            return setDataFetching({data:response.data,loading:false,error:false})
        }
        catch(error){
            setDataFetching({data:false,loading:false,error:'Une erreur est survenue'})
        }
        }


        useInterval(()=>{
            if(data)
            fastRefresh()
        },20000)

    return(
        <Fragment>
            {!data && <AuthTvShow error={error} loading={loading} onAuth={onAuth}/>}
            {data && <SlideTvShow data={data}/>}
        </Fragment>
    )
}