// ---------------- ADMIN ----------------
export const ADMIN_SET_POST = 'ADMIN_SET_POST'
export const ADMIN_DELETE_POST = 'ADMIN_DELETE_POST'
export const ADMIN_DELETE_PICTURE = 'ADMIN_DELETE_PICTURE'
export const ADMIN_GET_GROUPS = 'ADMIN_GET_GROUPS'
export const ADMIN_GET_CATEGORIES = 'ADMIN_GET_CATEGORIES'
export const ADMIN_GET_POSTS = 'ADMIN_GET_POSTS'
export const ADMIN_GET_SOCIAL_TAGS = 'ADMIN_GET_SOCIAL_TAGS'
export const ADMIN_RESET_SOCIAL_TAGS = 'ADMIN_RESET_SOCIAL_TAGS'
export const ADMIN_GET_SOCIAL_THEMES = 'ADMIN_GET_SOCIAL_THEMES'
export const ADMIN_RESET_SOCIAL_THEMES = 'ADMIN_RESET_SOCIAL_THEMES'
export const ADMIN_GET_POST_FOR_UPDATE = 'ADMIN_GET_POST_FOR_UPDATE'
export const ADMIN_RESET_POST_FOR_UPDATE = 'ADMIN_RESET_POST_FOR_UPDATE'
export const ADMIN_DELETE_SOCIAL_POST = 'ADMIN_DELETE_SOCIAL_POST'

// ---------------- COMMUN À TOUS LES UTILISATEURS ----------------
// Authentification
export const CHECK_AUTH = 'CHECK_AUTH'
export const SET_TOKEN = 'SET_TOKEN'
export const SIGN_IN = 'SIGN_IN'
export const SET_LOGIN_AUTH = 'SET_LOGIN_AUTH'
export const RESET_SIGNING_MESSAGE = 'RESET_SIGNING_MESSAGE'
export const GET_TEMPORARY_CODE = 'GET_TEMPORARY_CODE'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_AUTH = 'RESET_AUTH'
export const RESET_AUTH_MESSAGE = 'RESET_AUTH_MESSAGE'
// Mails
export const SEND_MAIL = 'SEND_MAIL'
export const SEND_EMAIL_TO_ADMIN = 'SEND_EMAIL_TO_ADMIN'
// Menu
export const GET_NAVBAR = 'GET_NAVBAR'
export const GET_NAVBAR_SOCIALS = 'GET_NAVBAR_SOCIALS'
export const GET_NAVBAR_NOTIFICATIONS = 'GET_NAVBAR_NOTIFICATIONS'
// Listes de contenus
export const GET_POSTS = 'GET_POSTS'
export const RESET_POSTS = 'RESET_POSTS'
export const GET_POSTS_TAGS = 'GET_POSTS_TAGS'
export const GET_EVENTS = 'GET_EVENTS'
export const GET_POLLS = 'GET_POLLS'
export const GET_CONTACTS = 'GET_CONTACTS'
export const GET_CONTACT = 'GET_CONTACT'
export const TOGGLE_FAVORITE_CONTACT = 'TOGGLE_FAVORITE_CONTACT'
export const GET_PARTNERS = 'GET_PARTNERS'
export const TOGGLE_FAVORITE_PARTNER = 'TOGGLE_FAVORITE_PARTNER'
export const GET_ADMINS = 'GET_ADMINS'
// Gestion du profil
export const GET_PROFILE = 'GET_PROFILE'
export const SET_PROFILE = 'SET_PROFILE'
export const ACCEPT_CGU = 'ACCEPT_CGU'
export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE'
export const SET_PROFILE_MESSAGE = 'SET_PROFILE_MESSAGE'
export const RESET_PROFILE_MESSAGE = 'RESET_PROFILE_MESSAGE'
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'
export const SET_PROFILE_STATUS = 'SET_PROFILE_STATUS'
// Affichage d'un post
export const GET_POST = 'GET_POST'
export const RESET_POST = 'RESET_POST'
export const SET_LIKE = 'SET_LIKE'
export const SET_COMMENT = 'SET_COMMENT'
export const MODERATE_COMMENT = 'MODERATE_COMMENT'
export const GET_PDF = 'GET_PDF'
export const DELETE_POST_NOTIFICATION = 'DELETE_POST_NOTIFICATION'
export const DELETE_CATEGORY_NOTIFICATION = 'DELETE_CATEGORY_NOTIFICATION'
export const DELETE_POSTS_NOTIFICATION = 'DELETE_POSTS_NOTIFICATION'
export const DELETE_POLLS_NOTIFICATION = 'DELETE_POLLS_NOTIFICATION'
export const DELETE_EVENTS_NOTIFICATION = 'DELETE_EVENTS_NOTIFICATION'
export const DELETE_SOCIALS_NOTIFICATION = 'DELETE_SOCIALS_NOTIFICATION'
// EVENEMENT
export const ACCEPT_EVENT_PARTICIPATION = 'ACCEPT_EVENT_PARTICIPATION'
export const REFUSE_EVENT_PARTICIPATION = 'REFUSE_EVENT_PARTICIPATION'
// SONDAGE
export const SET_POLL_ANSWER = 'SET_POLL_ANSWER'
// CONTEXTE
export const SET_CONTEXT = 'SET_CONTEXT'
// Listes des groupes créés
export const GET_GROUPS = 'GET_GROUPS'
// Rubrique de partage sur les réseaux sociaux
export const GET_SOCIAL_POSTS = 'GET_SOCIAL_POSTS'
export const GET_SOCIAL_POST_LOADING = 'GET_SOCIAL_POST_LOADING'
export const GET_SOCIAL_POST = 'GET_SOCIAL_POST'
export const GET_SOCIAL_POST_ERROR = 'GET_SOCIAL_POST_ERROR'
export const EDIT_CAPTION_SOCIAL_NETWORK = 'EDIT_CAPTION_SOCIAL_NETWORK'
export const ADD_HASTAGS = 'ADD_HASTAGS'
export const REMOVE_HASTAGS = 'REMOVE_HASTAGS'

export const GET_SOCIAL_THEMES = 'GET_SOCIAL_THEMES'

// Boutique
export const GET_SHOPPING_POSTS = 'GET_SHOPPING_POSTS'
export const GET_SHOPPING_POST = 'GET_SHOPPING_POST'
export const RESET_SHOPPING_POST = 'RESET_SHOPPING_POST'
export const GET_SHOPPING_CART = 'GET_SHOPPING_CART'
export const RESET_SHOPPING_CART = 'RESET_SHOPPING_CART'
export const SET_SHOPPING_PROMOCODE = 'SET_SHOPPING_PROMOCODE'
export const SET_SHOPPING_TYPOLOGIE = 'SET_SHOPPING_TYPOLOGIE'
export const GET_SHOPPING_TYPOLOGIES = 'GET_SHOPPING_TYPOLOGIES'
export const UPDATE_CART = 'UPDATE_CART'
export const DELETE_SHOPPING_ITEM = 'DELETE_SHOPPING_ITEM'
export const VALIDATE_SHOPPING_ORDER = 'VALIDATE_SHOPPING_ORDER'
export const RESET_SHOPPING_ORDER = 'RESET_SHOPPING_ORDER'

// Tracking
export const TRACKING_CONTACT_CALL = 'TRACKING_CONTACT_CALL'
export const TRACKING_CONTACT_EMAIL = 'TRACKING_CONTACT_EMAIL'
export const TRACKING_DOCUMENT_DOWNLOAD = 'TRACKING_DOCUMENT_DOWNLOAD'
export const TRACKING_EXTERNAL_LINK = 'TRACKING_EXTERNAL_LINK'
export const TRACKING_SOCIAL_PUBLICATION = 'TRACKING_SOCIAL_PUBLICATION'

// Linkedin
export const LINKEDIN_CONNECT = 'LINKEDIN_CONNECT'
export const LINKEDIN_GET_ACCOUNTS = 'LINKEDIN_GET_ACCOUNTS'
export const LINKEDIN_SET_POST = 'LINKEDIN_SET_POST'
export const LINKEDIN_RESET_POST = 'LINKEDIN_RESET_POST'

// Chiffre d'affaires
export const GET_CA = 'GET_CA'
export const SET_CA = 'SET_CA'
export const UPDATE_CA ='UPDATE_CA'


//NOTIFICATION MESSAGE 
export const SET_MESSAGE_NOTIFICATION = 'SET_MESSAGE_NOTIFICATION'

//FLUX
export const GET_FLUX = 'GET_FLUX'
export const INIT_PAGE_FLUX = 'INIT_PAGE_FLUX'

//BREADCRUMB
export const SET_BREADCRUMB = 'SET_BREADCRUMB'

//FLUX