import React from 'react';

export const MondialLinkedin = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 21.6 21.6"
      style={{ enableBackground: 'new 0 0 21.6 21.6' }}
    >
      <path
        id="Icon_ionic-logo-linkedin"
        d="M19.9,0h-18C0.9,0,0,0.7,0,1.7c0,0,0,0,0,0v18.1c0,1,0.8,1.8,1.8,1.8h18c1,0,1.8-0.9,1.7-1.8
   V1.7C21.6,0.8,20.9,0,19.9,0C19.9,0,19.9,0,19.9,0z M6.7,18H3.6V8.4h3.1V18z M5.3,6.9L5.3,6.9c-0.9,0-1.6-0.7-1.7-1.5
   c0,0,0-0.1,0-0.1c0-0.9,0.7-1.6,1.5-1.7c0,0,0.1,0,0.1,0c0.9,0,1.6,0.7,1.7,1.5c0,0,0,0.1,0,0.1c0,0.9-0.7,1.6-1.5,1.7
   C5.3,6.9,5.3,6.9,5.3,6.9z M18,18h-3.1v-5.3c0-1.3-0.5-2.1-1.6-2.1c-0.7,0-1.4,0.5-1.6,1.1c-0.1,0.2-0.1,0.5-0.1,0.8V18H8.5V8.4h3.1
   v1.3c0.6-1,1.6-1.6,2.8-1.6c2,0,3.6,1.3,3.6,4.2C18,12.4,18,18,18,18z"
      />
    </svg>
  );
};
