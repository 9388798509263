import React from 'react';

export const Edit = () => {
  return (
    <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
      <title>Modifier</title>
      <path
        fillRule="evenodd"
        d="M13.6,3.8l-.54.55L10.65,1.94l.55-.54a1.27,1.27,0,0,1,1.8,0h0l.6.6A1.27,1.27,0,0,1,13.6,3.8ZM2,11.09,3.91,13l-2.66.73Zm.48-1L9.93,2.66l2.41,2.41L4.87,12.54ZM13.73.67a2.32,2.32,0,0,0-3.26,0L1.35,9.8a.5.5,0,0,0-.13.22L0,14.35A.51.51,0,0,0,.37,15a.45.45,0,0,0,.28,0L5,13.78a.45.45,0,0,0,.22-.13l9.13-9.12a2.32,2.32,0,0,0,0-3.26Z"
        transform="translate(0 0)"
      />
    </svg>
  );
};
