import * as React from "react"

const MultiImageIcon = (props) => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    height={props.height}
    width={props.width}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.748 8.996a1.248 1.248 0 1 0 0-2.496 1.248 1.248 0 0 0 0 2.496Z"
      fill={props.fill}
    />
    <path
      d="M6.25 3A3.25 3.25 0 0 0 3 6.25v9a3.25 3.25 0 0 0 3.25 3.25h9a3.25 3.25 0 0 0 3.25-3.25v-9A3.25 3.25 0 0 0 15.25 3h-9ZM4.5 6.25c0-.966.784-1.75 1.75-1.75h9c.966 0 1.75.784 1.75 1.75v9c0 .231-.045.452-.126.654l-4.587-4.291a2.25 2.25 0 0 0-3.074 0l-4.587 4.29a1.745 1.745 0 0 1-.126-.653v-9Zm6.762 6.458 4.505 4.214c-.163.05-.337.078-.517.078h-9c-.18 0-.354-.027-.517-.078l4.504-4.214a.75.75 0 0 1 1.025 0Z"
      fill={props.fill}
    />
    <path
      d="M8.75 21a3.247 3.247 0 0 1-2.74-1.5h9.74a3.75 3.75 0 0 0 3.75-3.75V6.011a3.248 3.248 0 0 1 1.5 2.74v7C21 18.65 18.65 21 15.75 21h-7Z"
      fill={props.fill}
    />
  </svg>
)

export default MultiImageIcon