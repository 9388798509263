import styles from './Teasing.module.scss';
import {animated,useSpring} from 'react-spring';

export default function Teasing({width}){

    const [props,api] = useSpring(()=>({
        from:{x:-200},
        to:{x:width},
        config:{duration:6000},
        loop:true
    }))
        return(
        <animated.span className={styles["teasing"]} style={props}>
            Rendez-vous sur votre application pour en savoir plus.
         </animated.span>
    )
}