import React, { Component } from 'react'

export default class Loader extends Component {
  render() {
    return (
      <div id="loader-container" className={this.props.class && this.props.class}>
        <div className="loader">
          <div className="arc-1"></div>
          <div className="arc-2"></div>
        </div>
      </div>
    )
  }
}
