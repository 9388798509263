import React from 'react';

export const NavbarArobase = () => {
  return (
    <svg
      version="1.1"
      id="_x31__x2C_5"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
    >
      <g>
        <path
          d="M256,170.7c-70.6,0-128,57.4-128,128s57.4,128,128,128h20.4c8.8,0,16-7.2,16-16c0-8.8-7.2-16-16-16H256
		c-52.9,0-96-43.1-96-96c0-52.9,43.1-96,96-96s96,43.1,96,96v38.8c0,8-6.5,14.5-14.5,14.5s-14.5-6.5-14.5-14.5v-86.8
		c0-8.8-7.2-16-16-16c-7.1,0-13,4.6-15.2,10.9c-10.2-6.9-22.5-10.9-35.7-10.9c-35.3,0-64,28.7-64,64s28.7,64,64,64
		c13.7,0,26.5-4.4,36.9-11.8c5.8,19.1,23.6,33.1,44.6,33.1c25.7,0,46.5-20.9,46.5-46.5v-38.8C384,228.1,326.6,170.7,256,170.7z
		 M256,330.7c-17.6,0-32-14.4-32-32c0-17.6,14.4-32,32-32s32,14.4,32,32C288,316.3,273.6,330.7,256,330.7z"
        />
        <path
          d="M453.3,21.3H58.7C26.3,21.3,0,47.6,0,80v352c0,32.4,26.3,58.7,58.7,58.7h394.7c32.4,0,58.7-26.3,58.7-58.7V80
		C512,47.6,485.7,21.3,453.3,21.3z M58.7,53.3h394.7c14.7,0,26.7,12,26.7,26.7v16H32V80C32,65.3,44,53.3,58.7,53.3z M453.3,458.7
		H58.7C44,458.7,32,446.7,32,432V128h448v304C480,446.7,468,458.7,453.3,458.7z"
        />
      </g>
    </svg>
  );
};
