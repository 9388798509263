import styles from './DocumentFast.module.scss';
import {Link} from 'react-router-dom';
import Icon from '../../../icons';
export default function DocumentFast({count,libelle}){
    return(
        <div className={styles["document-fast"]}>
            <span className={styles['icon']}><Icon name='add-document'/></span>
            <span className={styles["sentence"]}>{libelle}</span>
            <Link className={styles["link"]} to='/document-board'>Accédez à vos documents</Link>
            <span>.</span>
        </div>
    )
}