import { useEffect,useState } from "react";
import { ServiceWorkerUpdateListener } from "../ServiceWorkerUpdateListeners";

export function useUpdateApp(){

    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [registration, setRegistration] = useState<ServiceWorkerRegistration | undefined | null>(null);
    const [swListener, setSwListener] = useState({});


    async function updateAppListener(){
        let listener = new ServiceWorkerUpdateListener() as any;
            setSwListener(listener);
            listener.onupdateinstalling = (installingEvent) => {
            console.log("SW installed", installingEvent);
          };
          listener.onupdatewaiting = (waitingEvent) => {
            console.log("new update waiting", waitingEvent);
            setUpdateWaiting(true);
          };
          listener.onupdateready = (event) => {
            console.log("updateready event");
            window.location.reload();
          };
          navigator.serviceWorker.getRegistration().then((reg) => {
            listener.addRegistration(reg);
            setRegistration(reg);
          });
    
          return () => listener.removeEventListener();
        } 

    useEffect(()=>{
        if(process.env.NODE_ENV !== "development" && 'serviceWorker' in navigator )
            updateAppListener();
    },[])


    return {updateWaiting,registration,swListener}

}

