import HtmlEditable from "./html-editable";
import TextEditable from "./text-editable";

const Components = {
    html:HtmlEditable,
    text:TextEditable
}


export default function ContentEditable({type,...props}):JSX.Element | null{
    console.log(type)
    let Component = Components[type];

    if(Component!==undefined) return <Component {...props} />
    return null
}