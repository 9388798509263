import React from 'react';

export const NavbarAdmin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g>
        <path d="M18.5 10.456a.58.58 0 0 1-.821 0l-7.313-7.313a.581.581 0 0 0-.82-.002l-7.392 7.315a.582.582 0 0 1-.823-.82L9.545 1.5a.58.58 0 0 1 .821 0L18.5 9.634a.582.582 0 0 1 0 .822zm-2.153 8.382H12.86v-5.307c0-.32-.26-.58-.58-.58H7.63a.581.581 0 0 0-.58.58v5.307H3.486v-8.066l6.466-6.399 6.394 6.394v8.071zm-8.135 0h3.486v-4.726H8.212v4.726zm5.81-16.346h1.163v2.183l-1.162-1.162v-1.02zm5.3 6.32l-2.975-2.975V1.911a.58.58 0 0 0-.581-.581H13.44a.58.58 0 0 0-.58.58v.441L11.188.678A1.742 1.742 0 0 0 8.725.676L.51 8.813a1.741 1.741 0 0 0 0 2.464 1.741 1.741 0 0 0 1.814.412v7.73c0 .321.26.581.58.581h14.023c.32 0 .58-.26.58-.581v-7.73a1.742 1.742 0 0 0 2.325-1.644c0-.465-.182-.903-.511-1.232z" />
        <path d="M10 10.64a1.155 1.155 0 0 1-1.154-1.153 1.155 1.155 0 0 1 2.308 0c0 .636-.518 1.154-1.154 1.154m0-3.462a2.31 2.31 0 0 0-2.308 2.308A2.31 2.31 0 0 0 10 11.795a2.31 2.31 0 0 0 2.308-2.308A2.31 2.31 0 0 0 10 7.179" />
      </g>
    </svg>
  );
};
