import React from 'react'

export const Email = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 156.43">
      <path
        fill={props.fill??"currentColor"}
        fillRule="evenodd"
        d="M192,24.83V24.7A25,25,0,0,0,167.09,0H24.88A25,25,0,0,0,0,24.7V131.55a25,25,0,0,0,24.9,24.88H167.12A25,25,0,0,0,192,131.53ZM24.91,14.22H167.07a10.71,10.71,0,0,1,10.12,7.32L96,78.4,14.81,21.58A10.73,10.73,0,0,1,24.91,14.22Zm142.19,128H24.92a10.71,10.71,0,0,1-10.66-10.65v-93L91.92,93a7.16,7.16,0,0,0,8.18,0L177.74,38.6v92.91A10.71,10.71,0,0,1,167.1,142.22Z"
        transform="translate(0 0)"
      />
    </svg>

    // <svg
    //   id="Calque_1"
    //   data-name="Calque 1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 192 156.43"
    // >
    //   <path
    //     d="M192,42.61s0-.08,0-.13a25,25,0,0,0-24.91-24.7H24.88A25,25,0,0,0,0,42.48V149.33a25,25,0,0,0,24.9,24.88H167.12A25,25,0,0,0,192,149.31V42.61ZM24.91,32H167.07a10.71,10.71,0,0,1,10.12,7.32L96,96.18,14.81,39.36A10.73,10.73,0,0,1,24.91,32ZM167.1,160H24.92a10.71,10.71,0,0,1-10.66-10.65V56.37l77.66,54.36a7.17,7.17,0,0,0,8.18,0l77.64-54.35v92.91A10.71,10.71,0,0,1,167.1,160Z"
    //     transform="translate(0 -17.78)"
    //   />
    // </svg>

    // <svg width="30" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 23">
    //   <path
    //     fillRule="evenodd"
    //     d="M33.24,28.49,24.82,20l8.42-8.48ZM8,29.75l8.47-8.53,3,3a.88.88,0,0,0,1.24,0h0l2.89-2.91L32,29.75ZM6.76,28.5v-17L15.22,20,6.76,28.49ZM32,10.28l-11.94,12-12-12Zm.36-1.77H7.64A2.66,2.66,0,0,0,5,11.17V28.86a2.65,2.65,0,0,0,2.64,2.65H32.36A2.65,2.65,0,0,0,35,28.86V11.17A2.65,2.65,0,0,0,32.36,8.51Z"
    //     transform="translate(-5 -8.51)"
    //   />
    // </svg>
  )
}
