import Bowser from 'bowser';

export const stats = (action) => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const stats = {
    src: '',
    mod: browser.getPlatform().type,
    os: browser.getBrowser().name,
    ratio: window.devicePixelRatio,
    l: window.innerWidth,
    h: window.innerHeight,
  };

  return stats;
};
