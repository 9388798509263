import React from 'react';

export const LongArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13" viewBox="0 0 24 13">
      <g fill="" fillRule="evenodd">
        <path d="M.97 6.998h22.06c.536 0 .97-.259.97-.578 0-.318-.434-.577-.97-.577H.97c-.536 0-.97.259-.97.577 0 .32.434.578.97.578" />
        <path d="M22.396 6.421l-5.201 5.272a.68.68 0 0 0 0 .952c.26.263.68.263.94 0l5.67-5.747a.68.68 0 0 0 0-.953L18.135.197a.658.658 0 0 0-.94 0 .68.68 0 0 0 0 .953l5.2 5.271z" />
      </g>
    </svg>
  );
};
