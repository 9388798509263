import api from "../../../config/pci/api";
import { stats } from "../../../config/stats";
import { errorMessage } from "../error_message";
export const getAccountLinkedin = async (user_id,nonce,token)  => {
   const response =  await api.get('/linkedinpages',{params: {
        user: user_id,
        nonce: nonce,
        access_token: token,
        stat: stats(),
      }
    })
    
    if(response.data.hasOwnProperty("error")){
        return {error:errorMessage.linkedin.noaccount}
    }
    else if(response.data.length>0){
       return response.data.map(({avatar,name,id,access_token})=>{
            return {
                    name:name,
                    id:id,
                    picture_url:avatar,
                    access_token:null
                }
            })
    }
    else return {error:errorMessage.linkedin.noaccount}
  
  
};