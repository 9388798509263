import React from 'react';

export const NavbarPictures = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g>
        <path d="M17.949 10.074l-3.142-3.346a1.27 1.27 0 0 0-1.966.13l-4.09 5.684a.222.222 0 0 1-.16.092.22.22 0 0 1-.175-.059L6.1 10.425a1.28 1.28 0 0 0-1.735.001L2.051 12.58V5.727c0-2.129 1.713-3.86 3.82-3.86h8.259c2.106 0 3.819 1.731 3.819 3.86v4.347zm-3.82 8.203H5.87c-2.106 0-3.819-1.769-3.819-3.943v-.048l3.029-2.878a.222.222 0 0 1 .305 0l2.317 2.196c.27.255.631.38.995.342.363-.037.694-.234.909-.538l4.089-5.805a.22.22 0 0 1 .346-.023l3.907 4.25v2.504c0 2.174-1.712 3.943-3.818 3.943zM5.885 1.354a4.864 4.864 0 0 0-4.858 4.858v8.232c0 2.679 2.18 4.858 4.858 4.858h8.232a4.864 4.864 0 0 0 4.858-4.858V6.212a4.864 4.864 0 0 0-4.858-4.858H5.884z" />
        <path d="M4.615 5.712a.77.77 0 1 1 1.54.002.77.77 0 0 1-1.54-.002zm-1.025 0c0 .99.92 1.795 2.051 1.795 1.131 0 2.051-.805 2.051-1.795 0-.99-.92-1.794-2.051-1.794-1.13 0-2.051.805-2.051 1.794z" />
      </g>
    </svg>
  );
};
