import { SET_MESSAGE_NOTIFICATION } from '../../actions/pci/types'

const INITIAL_STATE = {
  message:null,
}

const contextReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGE_NOTIFICATION:
      return {
        ...state,
        message:action.payload.message,
      }
    default:
      return state
  }
}

export default contextReducer
