export const config_template_std = {

    colors: {
      annuaire:{
        favorite:process.env.REACT_APP_PRIMARY_COLOR,
        tabsheader:process.env.REACT_APP_PRIMARY_COLOR,
        linkheader:process.env.REACT_APP_SECONDARY_COLOR
      },
      partner:{
        circle:`${process.env.REACT_APP_PRIMARY_COLOR}40`,
        logo:{
          circleLogoOne:`${process.env.REACT_APP_PRIMARY_COLOR}1a`
        }
      },
      entreprise:{
        circle:`${process.env.REACT_APP_PRIMARY_COLOR}40`,
        logo:{
          circleLogoOne:`${process.env.REACT_APP_PRIMARY_COLOR}1a`,
          circleLogoTwo:process.env.REACT_APP_PRIMARY_DARKER
        },
        nom:process.env.REACT_APP_BLACK_COLOR,
        jetonUrl:process.env.REACT_APP_SECONDARY_COLOR,
        jetonEmail:process.env.REACT_APP_GREY_LIGHTER,
        linkFile:process.env.REACT_APP_PRIMARY_COLOR,
  
      },
      user:{
        entreprise:process.env.REACT_APP_SECONDARY_COLOR,
      },
      button:{
        gradient:{
          primary:process.env.REACT_APP_PRIMARY_COLOR
        }
      },
      navbar: {
        top: process.env.REACT_APP_PRIMARY_COLOR,
        font_color: process.env.REACT_APP_BLANK_COLOR,
        bottom:process.env.REACT_APP_PRIMARY_COLOR,
        top_admin: process.env.REACT_APP_PRIMARY_COLOR,
        bottom_admin:process.env.REACT_APP_PRIMARY_COLOR,
        top_burger_active: process.env.REACT_APP_BLANK_COLOR,
        bottom_burger_active: process.env.REACT_APP_BLANK_COLOR,
        social_top:  process.env.REACT_APP_PRIMARY_LIGHTER,
        social_bottom: process.env.REACT_APP_PRIMARY_LIGHTER,
      },
      alert: {
        bg_color: process.env.REACT_APP_ALERT_COLOR,
        color: process.env.REACT_APP_BLANK_COLOR,
      },
      searchbar: {
        color: process.env.REACT_APP_PRIMARY_COLOR,
        
      },
      content: {
        color: process.env.REACT_APP_CONTENT_BG_COLOR,
      },
      tuto: {
        color: process.env.REACT_APP_PRIMARY_COLOR,
        icons: process.env.REACT_APP_SECONDARY_COLOR,
      },
      graph_remplissage: {
        actif: process.env.REACT_APP_ALERT_COLOR,
        inactif:process.env.REACT_APP_BLANK_COLOR,
      },
    },
  }