import styles from './BlockAuth.module.scss';
import config from '../../../config';
import { Fragment } from 'react';
import EasyLink from '../../../easy-ui/link';
import {useLocation} from 'react-router-dom';
import EasyLoader from '../../../easy-ui/loader';

type BlockAuthProps = {
  title:string,
  subtitle:string,
  children:JSX.Element[] | JSX.Element,
  loading:boolean
}


export default function BlockAuth({
    title,
    subtitle,
    children,
    loading=false
}):JSX.Element{

  const location = useLocation();

  if(loading)
    return(<div className={styles["conainer-spinner"]}>
              <EasyLoader/>
            </div>
    )
    return(
      <Fragment>
        <div className={styles['block-auth-container']}>
          <div className={styles["auth-wrapper"]}>
            <div className={styles["top-ghost"]}/>
            <div className={styles["logo-container"]}>
              <img className="logo" alt={`Logo ${config.name}`} src={config.logo.default} />
            </div>
            <div className={styles["content"]}>
              <div className={styles["title"]}>{title}</div>
              <div className={styles["subtitle"]}>{subtitle}</div>
              {children}
            </div>
            <div className={styles["footer-auth"]}>
              <EasyLink type="primary" to={{pathname:"/mentions-legales",state:{background:location}}}><span>Mentions légales</span></EasyLink>
              <EasyLink type='primary' to={{pathname:"/politique-de-confidentialite",state:{background:location}}}><span>Politique de confidentialité</span></EasyLink>
        </div>
            <div className={styles["bottom-ghost"]}/>
          </div>
        
        </div>
     
        </Fragment>
    )
  
}