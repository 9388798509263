import { LINKEDIN_CONNECT, LINKEDIN_GET_ACCOUNTS, LINKEDIN_SET_POST, LINKEDIN_RESET_POST } from '../../actions/pci/types'

const INITIAL_STATE = {
  linkedin_codes: false,
  linkedin_accounts: [],
  linkedin_publication: false,
}

const linkdinReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LINKEDIN_CONNECT:
      return {
        ...state,
        linkedin_codes: action.payload,
      }
    case LINKEDIN_GET_ACCOUNTS:
      return {
        ...state,
        linkedin_accounts: action.payload,
      }
    case LINKEDIN_SET_POST:
      return {
        ...state,
        linkedin_publication: action.payload.id ? true : false,
      }
    case LINKEDIN_RESET_POST:
      return {
        ...state,
        linkedin_publication: false,
      }
    default:
      return state
  }
}

export default linkdinReducer
