// import {
//   CHECK_AUTH,
//   SIGN_IN,
//   GET_TEMPORARY_CODE,
//   SEND_MAIL,
//   SEND_EMAIL_TO_ADMIN,
//   GET_NAVBAR,
//   GET_POSTS,
//   GET_CONTACTS,
//   GET_PARTNERS,
//   GET_ADMINS,
//   GET_PROFILE,
//   SET_PROFILE,
//   SET_PROFILE_PICTURE,
//   SET_NEW_PASSWORD,
//   GET_POST,
//   SET_LIKE,
//   SET_COMMENT,
//   GET_PDF,
//   ADMIN_SET_POST,
//   ADMIN_DELETE_POST,
//   ADMIN_GET_GROUPS,
//   ADMIN_GET_CATEGORIES,
//   ADMIN_GET_POSTS,
//   ADMIN_GET_POST_FOR_UPDATE
// } from "reduxactions/pci/types";
import { GET_CA, SET_CA,UPDATE_CA } from '../../actions/pci/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  ca:{},
  error: false,
  breadcrumb: false,
  exercices:{},
  entreprise:{},
}

const caReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CA:
      
    
      return{
        ...state,
        ca:action.payload.ca,
        exercices:action.payload.exercices,
        entreprise:action.payload.entreprise,
        breadcrumb:action.payload.breadcrumb,
        error:action.payload.error
      }


    case UPDATE_CA:
      return{
        ...state,
        ca:action.payload.ca,
        breadcrumb:action.payload.breadcrumb,
        error:action.payload.error,
        entreprise:action.payload.entreprise,
        exercices:action.payload.exercices,
      }

    case SET_CA:
  
      return {
        ...state,
        ca:action.payload.ca,
        exercices:action.payload.exercices,
        entreprise:action.payload.entreprise,
        breadcrumb:action.payload.breadcrumb,
        error:action.payload.error

      }
    default:
      return state
  }
}
export default caReducer
