export function  goBack(history){

    if(history.action=="POP"){
      history.replace('/leads')
    }
    else if(history.action=="PUSH"){
      history.goBack();
    }
    else{
      history.goBack();
    }
  }