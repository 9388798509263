export const base_config = {
dev_mode: window.location.host.split('.')[0].includes('localhost'),
mel: {
  date: '23/09/20 à 10h35',
},
slug: process.env.REACT_APP_CLIENT,
name: process.env.REACT_APP_CLIENT_NAME,
template:{
    colors:process.env.REACT_APP_TEMPLATE_COLOR,
    type:process.env.REACT_APP_TEMPLATE_TYPE
  },
name_company:process.env.REACT_APP_NAME_COMPANY,
cgu: process.env.REACT_APP_CGU,
logo: require(`../clients/${process.env.REACT_APP_CLIENT}/logo.png`),
logo_boutique: require(`../clients/${process.env.REACT_APP_CLIENT}/logo-boutique.png`),
logo_connexion: require(`../clients/${process.env.REACT_APP_CLIENT}/logo-connexion.png`),
logo_lost_password: require(`../clients/${process.env.REACT_APP_CLIENT}/logo-mdp-oublie.png`),
apis: {
  pci: {
    baseURL: process.env.REACT_APP_PCI_BASE_URL,
    version: process.env.REACT_APP_PCI_API_VERSION,
  },
  pgl: {
    baseURL: process.env.REACT_APP_PGL_BASE_URL,
    version: process.env.REACT_APP_PGL_API_VERSION,
    open:process.env.REACT_APP_PGL_OPEN,
  },

},
admin_url: process.env.REACT_APP_PCI_ADMIN_URL,
firebase_configuration: {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:process.env.REACT_APP_FIREBASE_BUCKET_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
},
firebase_public_key: process.env.REACT_APP_FIREBASE_PUBLIC_KEY,
linkedin_redirection_url:process.env.REACT_APP_LINKEDIN_REDIRECTION,
text:{
  ca:{
    effectif:process.env.REACT_APP_CA_TEXT
        }
    },
admin_roles: ['administrator', 'admin', 'admin_reseau'],
moderation_roles: ['administrator', 'admin', 'admin_reseau'],
moderation_for_posts: process.env.REACT_APP_MODERATION_POST,
sms: {
    active: process.env.REACT_APP_SMS_ACTIVE,
    title_limit: 100,
},
shopping: process.env.REACT_APP_SHOPPING_OPEN,
}