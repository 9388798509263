import React from 'react';

export const Campus = (props) => {
  return (
<svg version="1.1" id="Calque_1"  x="0px" y="0px"
	 viewBox="0 0 24.3 24.3">
<path d="M24,5.4L12.4,0.1C12.3,0,12.3,0,12.2,0c-0.1,0-0.2,0-0.3,0.1L0.3,5.4C0.2,5.5,0.1,5.6,0.1,5.7C-0.1,6,0,6.4,0.3,6.5l4,1.9
	v5.2c0,1.3,0.8,2.4,2,2.8l1.3,0.5c2.9,1,6,1,8.9,0l0.3-0.1v2.1c-0.5,0.3-0.8,1-0.5,1.6l-0.5,3.2c0,0,0,0,0,0
	c-0.1,0.3,0.2,0.6,0.5,0.7c0,0,0.1,0,0.1,0h2.1c0,0,0.1,0,0.1,0c0.3-0.1,0.5-0.4,0.5-0.7l-0.5-3.2c0.3-0.6,0.1-1.2-0.5-1.6v-2.5
	c1.1-0.5,1.8-1.6,1.8-2.8V8.4l4-1.9c0.1-0.1,0.2-0.2,0.3-0.3C24.4,5.9,24.3,5.5,24,5.4z M5.6,8.9l6.3,2.9c0.1,0,0.2,0.1,0.3,0.1l0,0
	c0.1,0,0.2,0,0.3-0.1L17,9.8v5.7l-0.7,0.3c-2.6,0.9-5.5,0.9-8.1,0l-1.3-0.5c-0.7-0.3-1.2-0.9-1.2-1.7V8.9z M11.6,5.7
	c-0.2,0.3,0,0.7,0.3,0.8l4.4,2.3l-4.1,1.9L2,6l10.1-4.7L22.3,6l-4.7,2.2l-5.2-2.7C12.1,5.3,11.8,5.4,11.6,5.7z M18.8,8.9v4.7
	c0,0.5-0.2,1-0.6,1.4V9.2L18.8,8.9z M17.6,21.2l0.3,1.9h-0.6L17.6,21.2z"/>
</svg>
  );
};
