import React from 'react';

export default ({...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.1 36.17">
      <g id="bulle">
        <path
          fillRule="evenodd"
          fill={props.bg}
          d="M172.7,8H64.2a9.34,9.34,0,0,1-2.1-6A1.51,1.51,0,0,0,61.2.6a1.36,1.36,0,0,0-1.6.2A15.31,15.31,0,0,0,55.3,8h-37a14.3,14.3,0,0,0,0,28.6H172.8A14.3,14.3,0,1,0,172.7,8Z"
          transform="translate(-4 -0.43)"
        />
      </g>
      <g id="texte">
        <g id="App">
          <g id="Privée-32-Chrome">
            <g id="Downlad">
              <g>
                <path
                 fill={'var(--ui-primary-color)'}
                  d="M19,17.8h.8L23.6,27h-1l-1.2-2.9H17.3L16.1,27h-1Zm2.3,5.6-1.9-4.6-1.9,4.6Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                 fill={'var(--ui-primary-color)'}
                  d="M24,29.4a2,2,0,0,1-.8-.1,1.61,1.61,0,0,1-.7-.4l.4-.6a.76.76,0,0,0,.5.2c.2,0,.3.1.5.1s.3,0,.4-.1.3-.1.4-.3.2-.2.3-.4a.9.9,0,0,0,.1-.5V20.2H26v7.1a1.88,1.88,0,0,1-.2.8,4.35,4.35,0,0,1-.4.7,2.09,2.09,0,0,1-.6.4A1.88,1.88,0,0,1,24,29.4Zm1.1-10.6V17.5H26v1.3Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                 fill={'var(--ui-primary-color)'}
                  d="M31.1,27.1a3.53,3.53,0,0,1-1.4-.3,2.84,2.84,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1,3,3,0,0,1-.2-1.3,3.53,3.53,0,0,1,.3-1.4,4.13,4.13,0,0,1,.7-1.1,4.44,4.44,0,0,1,1.1-.8,3,3,0,0,1,2.6,0,2.84,2.84,0,0,1,1.1.8,6.73,6.73,0,0,1,.7,1.1,3.19,3.19,0,0,1,.3,1.4,5.07,5.07,0,0,1-.2,1.3,4.13,4.13,0,0,1-.7,1.1,4.44,4.44,0,0,1-1.1.8A3.53,3.53,0,0,1,31.1,27.1Zm-2.5-3.5a4.25,4.25,0,0,0,.2,1.1,4.05,4.05,0,0,0,.5.9,2.41,2.41,0,0,0,.8.6,3,3,0,0,0,1,.2,3.55,3.55,0,0,0,1-.2,1.56,1.56,0,0,0,.8-.6,1.93,1.93,0,0,0,.5-.9,3.59,3.59,0,0,0,.2-1.1,4.25,4.25,0,0,0-.2-1.1,4.05,4.05,0,0,0-.5-.9,5.55,5.55,0,0,0-.8-.6,3,3,0,0,0-1-.2,2.39,2.39,0,0,0-.9.2,1.56,1.56,0,0,0-.8.6,2.62,2.62,0,0,0-.5.9A2.61,2.61,0,0,0,28.6,23.6Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                 fill={'var(--ui-primary-color)'}
                  d="M38.3,27.1a1.75,1.75,0,0,1-1.6-.7,3.43,3.43,0,0,1-.5-2.2v-4H37V24c0,1.5.5,2.3,1.6,2.3a2.2,2.2,0,0,0,.8-.1,1.61,1.61,0,0,0,.7-.4l.6-.6a2,2,0,0,0,.4-.8V20.1H42v5.6c0,.2.1.4.3.4V27h-.4a.76.76,0,0,1-.5-.2.85.85,0,0,1-.2-.6v-.8A3.45,3.45,0,0,1,40,26.6,2.79,2.79,0,0,1,38.3,27.1Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                 fill={'var(--ui-primary-color)'}
                  d="M47.4,26.7c-.1,0-.1.1-.2.1s-.2.1-.3.1-.3.1-.4.1-.3.1-.5.1a.75.75,0,0,1-.5-.1c-.1-.1-.3-.1-.5-.2s-.2-.2-.3-.4a1.27,1.27,0,0,1-.1-.6V21h-.9v-.7h.9V18h.9v2.3h1.6V21H45.5v4.6a.71.71,0,0,0,.3.6,1.07,1.07,0,0,0,.6.2,1.48,1.48,0,0,0,.7-.1c.2-.1.3-.1.4-.2Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M51.6,27.1a3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1,3.19,3.19,0,0,1-.3-1.4,2.77,2.77,0,0,1,.3-1.3,4.13,4.13,0,0,1,.7-1.1,4.44,4.44,0,0,1,1.1-.8,3.19,3.19,0,0,1,1.4-.3,3.53,3.53,0,0,1,1.4.3,2.26,2.26,0,0,1,1.1.8,4.13,4.13,0,0,1,.7,1.1,3,3,0,0,1,.2,1.3v.3H49.2a1.69,1.69,0,0,0,.3,1,2.41,2.41,0,0,0,.6.8,2.18,2.18,0,0,0,.8.5,2.92,2.92,0,0,0,.9.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.2a1.76,1.76,0,0,0,.5-.4c.1-.2.3-.3.3-.5l.8.2a4.35,4.35,0,0,1-.4.7,1.79,1.79,0,0,1-.7.5,3,3,0,0,1-.8.4A2.56,2.56,0,0,1,51.6,27.1Zm2.5-3.8a1.69,1.69,0,0,0-.3-1,5.58,5.58,0,0,0-.5-.8,2.18,2.18,0,0,0-.8-.5,2.12,2.12,0,0,0-1.8,0,2.1,2.1,0,0,0-1.3,1.3,3,3,0,0,0-.2,1Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M59.9,21a3,3,0,0,0-1.6.5,2.07,2.07,0,0,0-.9,1.3V27h-.9V20.2h.8v1.6a4,4,0,0,1,1-1.2,2.3,2.3,0,0,1,1.4-.4h.2Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                 fill={'var(--ui-primary-color)'}
                  d="M66.3,27.1a2.92,2.92,0,0,1-.9-.2,4.35,4.35,0,0,1-.7-.4,1.79,1.79,0,0,1-.5-.7A2.35,2.35,0,0,1,64,25a1.88,1.88,0,0,1,.2-.8,1.34,1.34,0,0,1,.6-.7,3.55,3.55,0,0,1,.9-.4,3.75,3.75,0,0,1,1.1-.1,4.48,4.48,0,0,1,1.1.1c.4.1.7.1.9.3v-.6a2,2,0,0,0-.5-1.4,1.62,1.62,0,0,0-1.4-.5,5.16,5.16,0,0,0-1.1.2,3,3,0,0,0-1.1.6l-.2-.7a4.5,4.5,0,0,1,2.5-.9,2.72,2.72,0,0,1,2,.7,2.58,2.58,0,0,1,.7,2v3.1c0,.2.1.4.3.4V27h-.3a.76.76,0,0,1-.5-.2.52.52,0,0,1-.2-.4v-.5a2.73,2.73,0,0,1-1.2.9A3.34,3.34,0,0,1,66.3,27.1Zm.2-.6a2.77,2.77,0,0,0,1.3-.3,2.18,2.18,0,0,0,.9-.7c.1-.1.1-.2.2-.3s.1-.2.1-.3V23.8l-.9-.3a3.08,3.08,0,0,0-1-.1,2.41,2.41,0,0,0-1.5.4,1.22,1.22,0,0,0-.6,1,1.27,1.27,0,0,0,.1.6c.1.2.2.3.3.5s.3.2.5.3A.86.86,0,0,0,66.5,26.5Zm-.7-9h.9l.8,1.2-.5.2Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                 fill={'var(--ui-primary-color)'}
                  d="M75.6,17.5h.9v8a1.07,1.07,0,0,0,.2.6.85.85,0,0,0,.6.2h.3c.1,0,.3-.1.4-.1l.2.7c-.2.1-.4.1-.6.2s-.4.1-.6.1a1.69,1.69,0,0,1-1-.3,1.53,1.53,0,0,1-.4-1Z"
                  transform="translate(-4 -0.43)"
                />
                <path fill={'var(--ui-primary-color)'} d="M79.1,20.2l.2-1.4H79V17.5h.9v1.3l-.3,1.4Z" transform="translate(-4 -0.43)" />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M84.6,27.1a3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1,3.19,3.19,0,0,1-.3-1.4,2.77,2.77,0,0,1,.3-1.3,4.13,4.13,0,0,1,.7-1.1,4.44,4.44,0,0,1,1.1-.8,3.19,3.19,0,0,1,1.4-.3,3.53,3.53,0,0,1,1.4.3,2.26,2.26,0,0,1,1.1.8,4.13,4.13,0,0,1,.7,1.1,3,3,0,0,1,.2,1.3v.3H82.2a1.69,1.69,0,0,0,.3,1,2.41,2.41,0,0,0,.6.8,2.18,2.18,0,0,0,.8.5,2.92,2.92,0,0,0,.9.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.2a1.76,1.76,0,0,0,.5-.4c.1-.2.3-.3.3-.5l.8.2a4.35,4.35,0,0,1-.4.7,1.79,1.79,0,0,1-.7.5,3,3,0,0,1-.8.4A2.56,2.56,0,0,1,84.6,27.1Zm2.5-3.8a1.69,1.69,0,0,0-.3-1,5.58,5.58,0,0,0-.5-.8,2.18,2.18,0,0,0-.8-.5,2.13,2.13,0,0,0-1.8,0,2.1,2.1,0,0,0-1.3,1.3,3,3,0,0,0-.2,1Zm-2.5-4.4-.6-.2.8-1.2h.9Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M89.1,23.6a6.75,6.75,0,0,1,.2-1.4,2.1,2.1,0,0,1,.7-1.1,2.59,2.59,0,0,1,1.1-.7,3.19,3.19,0,0,1,1.4-.3,3.29,3.29,0,0,1,1.7.4,3.18,3.18,0,0,1,1.1,1.2l-.9.3a3,3,0,0,0-.8-.8,2,2,0,0,0-1.1-.3,3.55,3.55,0,0,0-1,.2,1.56,1.56,0,0,0-.8.6,1.93,1.93,0,0,0-.5.9,3.59,3.59,0,0,0-.2,1.1,4.25,4.25,0,0,0,.2,1.1,2.62,2.62,0,0,0,.5.9,5.55,5.55,0,0,0,.8.6,3,3,0,0,0,1,.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.3s.3-.2.5-.4.2-.3.3-.4l.9.3a2.51,2.51,0,0,1-.4.7,2.65,2.65,0,0,1-.6.5,2,2,0,0,1-.8.4,2.77,2.77,0,0,1-.9.1,3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1A3.07,3.07,0,0,1,89.1,23.6Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M100.3,21a3,3,0,0,0-1.6.5,2.07,2.07,0,0,0-.9,1.3V27H97V20.2h.8v1.6a2.94,2.94,0,0,1,1-1.2,2.3,2.3,0,0,1,1.4-.4h.2V21Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M103.4,27.1a2.92,2.92,0,0,1-.9-.2,4.35,4.35,0,0,1-.7-.4,1.79,1.79,0,0,1-.5-.7,2.35,2.35,0,0,1-.2-.8,1.88,1.88,0,0,1,.2-.8,1.34,1.34,0,0,1,.6-.7,3.55,3.55,0,0,1,.9-.4A4.87,4.87,0,0,1,104,23a4.48,4.48,0,0,1,1.1.1c.4.1.7.1.9.3v-.6a2,2,0,0,0-.5-1.4,1.62,1.62,0,0,0-1.4-.5,5.16,5.16,0,0,0-1.1.2,3,3,0,0,0-1.1.6l-.3-.7a4.5,4.5,0,0,1,2.5-.9,2.72,2.72,0,0,1,2,.7,2.58,2.58,0,0,1,.7,2v3.1c0,.2.1.4.3.4V27h-.3a.68.68,0,0,1-.7-.7v-.5a2.73,2.73,0,0,1-1.2.9A2.6,2.6,0,0,1,103.4,27.1Zm.2-.6a2.77,2.77,0,0,0,1.3-.3,2.18,2.18,0,0,0,.9-.7c.1-.1.1-.2.2-.3s.1-.2.1-.3V23.8l-.9-.3a3.08,3.08,0,0,0-1-.1,2.41,2.41,0,0,0-1.5.4,1.22,1.22,0,0,0-.6,1,1.27,1.27,0,0,0,.1.6c.1.2.2.3.3.5a2.19,2.19,0,0,0,.5.3A.86.86,0,0,0,103.6,26.5Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M114.7,27h-.9V23.2a3,3,0,0,0-.4-1.8,1.17,1.17,0,0,0-1.1-.5,2.2,2.2,0,0,0-.8.1,1.61,1.61,0,0,0-.7.4l-.6.6a2,2,0,0,0-.4.8V27H109V20.2h.8v1.5a3.45,3.45,0,0,1,1.2-1.2,3.37,3.37,0,0,1,1.7-.5,2,2,0,0,1,.9.2.9.9,0,0,1,.6.6,1.61,1.61,0,0,1,.3.9,4.87,4.87,0,0,1,.1,1.2V27Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M123.2,27.1a2.77,2.77,0,0,1-1.3-.3,3.92,3.92,0,0,1-1-.8,4.13,4.13,0,0,1-.7-1.1,3,3,0,0,1-.2-1.3,6.75,6.75,0,0,1,.2-1.4,2.87,2.87,0,0,1,.6-1.1,3.92,3.92,0,0,1,1-.8A2.66,2.66,0,0,1,123,20a2.41,2.41,0,0,1,1.5.4,3.46,3.46,0,0,1,1,1.1V17.4h.9v8.3c0,.2.1.4.3.4V27h-.3a.76.76,0,0,1-.5-.2c-.2-.1-.2-.3-.2-.5v-.7a3.84,3.84,0,0,1-1.1,1.1A2.5,2.5,0,0,1,123.2,27.1Zm.2-.8a2,2,0,0,0,.7-.1,2.51,2.51,0,0,0,.7-.4c.2-.2.4-.3.6-.5s.2-.4.3-.7V22.5a2.51,2.51,0,0,0-.4-.7,2.65,2.65,0,0,0-.6-.5,1.61,1.61,0,0,0-.7-.4,1.7,1.7,0,0,0-.7-.1,3,3,0,0,0-1,.2c-.3.2-.5.4-.8.6a2.62,2.62,0,0,0-.5.9,4.4,4.4,0,0,0-.2,1,3,3,0,0,0,.2,1,4.05,4.05,0,0,0,.5.9,5.55,5.55,0,0,0,.8.6A2.28,2.28,0,0,0,123.4,26.3Z"
                  transform="translate(-4 -0.43)"
                />
                <path fill={'var(--ui-primary-color)'} d="M128.7,20.2l.2-1.4h-.3V17.5h.9v1.3l-.3,1.4Z" transform="translate(-4 -0.43)" />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M133.1,27.1a2.92,2.92,0,0,1-.9-.2,4.35,4.35,0,0,1-.7-.4,1.79,1.79,0,0,1-.5-.7,2.35,2.35,0,0,1-.2-.8,1.88,1.88,0,0,1,.2-.8,1.34,1.34,0,0,1,.6-.7,3.55,3.55,0,0,1,.9-.4,3.75,3.75,0,0,1,1.1-.1,4.48,4.48,0,0,1,1.1.1c.4.1.7.1.9.3v-.6a2,2,0,0,0-.5-1.4,1.62,1.62,0,0,0-1.4-.5,5.16,5.16,0,0,0-1.1.2,3,3,0,0,0-1.1.6l-.3-.6a4.5,4.5,0,0,1,2.5-.9,2.72,2.72,0,0,1,2,.7,2.58,2.58,0,0,1,.7,2V26c0,.2.1.4.3.4V27h-.3a.68.68,0,0,1-.7-.7v-.5a2.73,2.73,0,0,1-1.2.9A2.3,2.3,0,0,1,133.1,27.1Zm.2-.6a2.77,2.77,0,0,0,1.3-.3,2.18,2.18,0,0,0,.9-.7c.1-.1.1-.2.2-.3s.1-.2.1-.3V23.8l-.9-.3a3.08,3.08,0,0,0-1-.1,2.41,2.41,0,0,0-1.5.4,1.22,1.22,0,0,0-.6,1,1.27,1.27,0,0,0,.1.6c.1.2.2.3.3.5a2.19,2.19,0,0,0,.5.3A.6.6,0,0,0,133.3,26.5Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M138.2,23.6a6.75,6.75,0,0,1,.2-1.4,4.13,4.13,0,0,1,.7-1.1,2.59,2.59,0,0,1,1.1-.7,3.19,3.19,0,0,1,1.4-.3,3.29,3.29,0,0,1,1.7.4,3.18,3.18,0,0,1,1.1,1.2l-.9.3a3,3,0,0,0-.8-.8,2,2,0,0,0-1.1-.3,3.55,3.55,0,0,0-1,.2,1.56,1.56,0,0,0-.8.6,1.93,1.93,0,0,0-.5.9,3.59,3.59,0,0,0-.2,1.1,4.25,4.25,0,0,0,.2,1.1,2.62,2.62,0,0,0,.5.9,5.55,5.55,0,0,0,.8.6,3,3,0,0,0,1,.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.3s.3-.2.5-.4.2-.3.3-.4l.9.3a2.51,2.51,0,0,1-.4.7,2.65,2.65,0,0,1-.6.5,2,2,0,0,1-.8.4,2.77,2.77,0,0,1-.9.1,3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1A3.07,3.07,0,0,1,138.2,23.6Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M145.6,23.6a6.75,6.75,0,0,1,.2-1.4,4.13,4.13,0,0,1,.7-1.1,2.59,2.59,0,0,1,1.1-.7,3.19,3.19,0,0,1,1.4-.3,3.29,3.29,0,0,1,1.7.4,3.18,3.18,0,0,1,1.1,1.2l-.9.3a3,3,0,0,0-.8-.8,2,2,0,0,0-1.1-.3,3.55,3.55,0,0,0-1,.2,1.56,1.56,0,0,0-.8.6,1.93,1.93,0,0,0-.5.9,3.59,3.59,0,0,0-.2,1.1,4.25,4.25,0,0,0,.2,1.1,2.62,2.62,0,0,0,.5.9,5.55,5.55,0,0,0,.8.6,3,3,0,0,0,1,.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.3s.3-.2.5-.4.2-.3.3-.4l.9.3a2.51,2.51,0,0,1-.4.7,2.65,2.65,0,0,1-.6.5,2,2,0,0,1-.8.4,2.77,2.77,0,0,1-.9.1,3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1A3.07,3.07,0,0,1,145.6,23.6Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M155.6,27.1a1.75,1.75,0,0,1-1.6-.7,4.2,4.2,0,0,1-.5-2.2v-4h.9V24c0,1.5.5,2.3,1.6,2.3a2.2,2.2,0,0,0,.8-.1,1.61,1.61,0,0,0,.7-.4l.6-.6a2,2,0,0,0,.4-.8V20.1h.9v5.6c0,.2.1.4.3.4V27h-.4a.76.76,0,0,1-.5-.2.85.85,0,0,1-.2-.6v-.8a3.45,3.45,0,0,1-1.2,1.2A3.52,3.52,0,0,1,155.6,27.1Z"
                  transform="translate(-4 -0.43)"
                />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M164.3,27.1a3.53,3.53,0,0,1-1.4-.3,2.26,2.26,0,0,1-1.1-.8,4.13,4.13,0,0,1-.7-1.1,3.19,3.19,0,0,1-.3-1.4,2.77,2.77,0,0,1,.3-1.3,4.13,4.13,0,0,1,.7-1.1,4.44,4.44,0,0,1,1.1-.8,3.19,3.19,0,0,1,1.4-.3,3.53,3.53,0,0,1,1.4.3,2.26,2.26,0,0,1,1.1.8,4.13,4.13,0,0,1,.7,1.1,3,3,0,0,1,.2,1.3v.3h-5.8a1.69,1.69,0,0,0,.3,1,1.56,1.56,0,0,0,.6.8,3.51,3.51,0,0,0,.8.5,2.92,2.92,0,0,0,.9.2,1.27,1.27,0,0,0,.6-.1c.2-.1.4-.1.6-.2a1.76,1.76,0,0,0,.5-.4c.2-.2.3-.3.3-.5l.8.2a4.35,4.35,0,0,1-.4.7,1.79,1.79,0,0,1-.7.5,3,3,0,0,1-.8.4A2.15,2.15,0,0,1,164.3,27.1Zm2.5-3.8a1.69,1.69,0,0,0-.3-1,2.1,2.1,0,0,0-1.3-1.3,2.12,2.12,0,0,0-1.8,0,2.1,2.1,0,0,0-1.3,1.3,3,3,0,0,0-.2,1Z"
                  transform="translate(-4 -0.43)"
                />
                <path fill={'var(--ui-primary-color)'} d="M169.3,18.8V17.5h.9v1.3Zm0,8.2V20.2h.9V27Z" transform="translate(-4 -0.43)" />
                <path
                  fill={'var(--ui-primary-color)'}
                  d="M172.4,17.5h.9v8a1.07,1.07,0,0,0,.2.6.85.85,0,0,0,.6.2h.3c.1,0,.3-.1.4-.1l.2.7c-.2.1-.4.1-.6.2s-.4.1-.6.1a1.69,1.69,0,0,1-1-.3,1.16,1.16,0,0,1-.4-1V17.5Z"
                  transform="translate(-4 -0.43)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>




  );
};
