import { CSSProperties } from 'react';
import styles from './EasySpinner.module.scss';

export default function EasySpinner({color,size,className}:{color?:string,size?:number,className?:string}):JSX.Element{
    const styled = {
        spinner:{
            '--color-punto':color??'#FFF',
            '--size-spinner': size == 1 ? '20px' : size == 2 ? '40px' : size===0.5 ?  '18px' : '20px',
            '--dotted-spinner':size == 1 ? "3px" : size == 2 ? '6px' : size===0.5 ? '2px' : '3px'
        } as CSSProperties
    }
    return(
        <span style={styled.spinner} className={`${styles["spinner"]} ${className}`}/>
    )
}
