import RocketIcon from '../../icons/RocketIcon';
import styles from './UpdateWaiting.module.scss';
import Portal from '../common/portal/Portal';
import {animated,useSpring} from 'react-spring';
import { Cross } from '../../icons/Cross';
import { useMedia } from 'react-use';
import EasyButton from '../../easy-ui/button'

const UpdateWaiting = ({updateWaiting, handleUpdate}) => {
  const [springModal,api] = useSpring(()=>({
    opacity:updateWaiting? 1 : 0,
    transform:updateWaiting?'translate3d(-50%,-50%,0)' : 'translate3d(-50%,-150%,0)',
 
}),[updateWaiting])

function onClose(){
    api.start({
        transform:'translate3d(-50%,-200%,0)',
        opacity:0,
        onRest:()=>handleUpdate()
    })
}



    if (!updateWaiting) return <></>
    return (
      <Portal id={`modal-upload`}>
      <animated.div style={{opacity:springModal.opacity}} className={styles["block-protected-background"]}>
          <animated.div style={{transform:springModal.transform}} className={styles["block-protected-container"]}>
              <button onClick={onClose} className={styles["close-block-protected"]}><Cross/></button>
              <div className={styles["container-upload"]}>
                  <h3>Nouvelle version de l'application disponible.</h3>
                  <div className={styles["sentence-upload"]}>
                    Cliquez sur "Mise à jour" pour continuer à utiliser l'application.
                  </div>
                  <EasyButton onClick={onClose} text="Mise à jour" name="text" type='primary'/>
              </div>
          </animated.div>
      </animated.div>
 </Portal> 
    )
  }

  export default UpdateWaiting