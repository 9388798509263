const ChevronLeftIcon = (props) => (
    <svg
      fill="none"
      height={props.height}
      width={props.width}
      viewBox='0 0 24 24'
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
   <path
      d="M15.53 4.22a.75.75 0 0 1 0 1.06L8.81 12l6.72 6.72a.75.75 0 1 1-1.06 1.06l-7.25-7.25a.75.75 0 0 1 0-1.06l7.25-7.25a.75.75 0 0 1 1.06 0Z"
      fill={props.fill}
    />
    </svg>
  )
  
  export default ChevronLeftIcon
  